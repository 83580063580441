import React, { useCallback, useState } from "react";

import BaseTable from "@reusables/BaseTable";
import { Menu, Pagination } from "@mui/material";
import BaseTooltip from "@reusables/BaseTooltip";
import { Link, useHistory } from "react-router-dom";
import BaseAdminFilter from "@reusables/BaseAdminFilter";

import customParseFormat from "dayjs/plugin/customParseFormat";
import BaseDatepicker from "@reusables/BaseDatepickerLegacy";
import { DatepickerRangeContext } from "@reusables/BaseDatepickerLegacy/context";

import dayjs from "dayjs";
import {
    calculateOrderPrice,
    genT,
    normalizePrice,
    PredefinedTranslations,
    removeEmpty,
    useDatesRangeFilter,
    useOrdering,
    usePagination
} from "@helpers/utils";
import { Product, SaleOrder } from "@/types/general";
import { useTranslation } from "react-i18next";
import { useGetCustomersQuery } from "@redux/features/customers/customersApi";
import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { BaseTinyActionIcon, TinyActionIconType } from "@components/reusables/BaseTinyActionIcon";
import { useGetSaleOrdersFullQuery } from "@redux/features/sales/orders/salesOrdersApi";
import OrderDeletionModal from "./components/modals/DeleteOrder";
import { ReactComponent as FastCartSVG } from "@assets/icons/ic_fast_cart.svg";
import { useAppDispatch } from "@redux/hooks";
import { toast } from "react-toastify";
import { formRefillModalLines } from "@components/Dashboard/pages/Sales/Orders/utils";
import { RefillModalData } from "@components/Dashboard/pages/Sales/Orders/types";
import LowStockRefillModal from "./components/modals/LowStockRefillModal";
import _ from "lodash";
import SaleOrdersExportModal from "@components/Dashboard/pages/Sales/Orders/components/modals/SaleOrdersExportModal";
import { productsApi } from "@redux/features/products/productsApi";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import { isErrorWithMessage } from "@redux/api/query";
import i18n from "@/i18n";
import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";

dayjs.extend(customParseFormat);

export default function SalesOrdersPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "sales.orders.main" });

    const dispatch = useAppDispatch();

    const ability = useAbility(AbilityContext);

    // ===> ACTIONS DOTS CONFIGURATION <===
    const [actionsAnchor, setActionsAnchor] = useState<HTMLDivElement>();
    const [actionEntity, setActionEntity] = useState<SaleOrder.Root>();

    // MODALS RELATED
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    // FILTERS RELATED
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

    const { data: customersOptions, isLoading: customersOptionsLoading } = useGetCustomersQuery();
    const [selectedCustomers, setSelectedCustomers] = useState<typeof customersOptions>([]);

    // Products options for dropdown filter (lazy loading)
    const [productsOptions, setProductsOptions] = useState<Product.Slim[]>([]);
    // Selected products for filtering by products (dropdown)
    const [selectedProducts, setSelectedProducts] = useState<Product.Slim[]>();

    const { datesRange, setDatesRange, filterAdaptedDatesRange } = useDatesRangeFilter();

    const pagination = usePagination({ page: 1, limit: 8 });

    const { orderBy, setOrderBy } = useOrdering<SaleOrder.DTO.OrderBy>({ name: "id", type: "desc" });

    const filters: SaleOrder.DTO.Filters = removeEmpty({
        search: searchingValue,
        customers: selectedCustomers?.map(x => x.id),
        products: selectedProducts?.map(x => x.id),
        dates_range: filterAdaptedDatesRange
    });

    const { data: ordersResponse, isFetching: areOrdersLoading } = useGetSaleOrdersFullQuery({
        filters,
        orderBy: orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    const handlePOCreation = async (saleOrder: SaleOrder.Root) => {
        const linesToRefill = formRefillModalLines(saleOrder.orders);

        // This data will be transferred to the purchase order creation page via redux
        if (linesToRefill?.length) {
            setRefillModalData({
                // Order is needed for the RefillModal to allow filling needed data in the purchase order + referencing the parent SO.
                order: {
                    id: saleOrder.id,
                    code: saleOrder.code
                },
                lines: linesToRefill
            });
            setIsRefillModalOpen(true);
        } else {
            toast.warn(t("warnings.createPOAllInStock"));
        }
    };

    const [isRefillModalOpen, setIsRefillModalOpen] = useState<boolean>(false);
    const [refillModalData, setRefillModalData] = useState<RefillModalData>();


    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);

    return (
        <>
            {/* FILTERS BLOCK */}
            <BaseAdminFilter
                permissionModule="sale_order"
                filterItems={
                    <div className="w-[364px]">
                        <div className="grid grid-cols-1 gap-[24px]">
                            <BaseDropdown
                                label={t("filters.customer.label")}
                                options={customersOptions}
                                value={selectedCustomers}
                                getter={{
                                    label: (item) => item.name,
                                    key: (item) => item.id
                                }}

                                multiple
                                autocomplete
                                brightLabel

                                onChange={(_, opts) => setSelectedCustomers(opts)}

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={customersOptionsLoading}
                            />

                            <BaseInfiniteLoader
                                fetch={(search,page, limit) => {
                                    setIsProductLazyLoadingFetching(true);
                                    dispatch(productsApi.endpoints.getProductsFull.initiate(
                                        {
                                            filters: {
                                                search
                                            },
                                            pagination: {
                                                page,
                                                limit
                                            },
                                        },
                                        {
                                            subscribe: false,
                                        }
                                    )
                                ).unwrap()
                                    .then(res => {
                                        setProductsOptions(res?.payload ?? []);
                                    })
                                    .catch(e => {
                                        if (isErrorWithMessage(e)) {
                                            toast.error(e.message);
                                        } else {
                                            toast.error(i18n.t("general.responses.somethingWentWrong"));
                                        }
                                    })
                                    .finally(() => {
                                        setIsProductLazyLoadingFetching(false);
                                    });
                                }}
                                limit={100}
                                result={productsOptions}
                                isLoading={isProductLazyLoadingFetching}
                            >
                                {
                                    (infinity) => (
                                        <BaseDropdown
                                            {...adaptInfiniteLoader(infinity)}
                                            label={t("filters.product.label")}
                                            // options={productsOptions}
                                            value={selectedProducts}
                                            getter={{
                                                label: opt => opt.name,
                                                key: opt => opt.id,
                                                renderOption: (opt, icon) => (
                                                    <div>
                                                        <span className="grow">{opt.name}</span>
                                                        {
                                                            selectedProducts?.includes(opt) ? icon :
                                                                <span className="text-lightGreen-500 font-thin">{opt.code}</span>
                                                        }
                                                    </div>
                                                )
                                            }}

                                            onChange={(_, opts) => setSelectedProducts(opts)}

                                            virtualize
                                            autocomplete
                                            multiple

                                            emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                            brightLabel
                                        />
                                    )
                                }
                            </BaseInfiniteLoader>

                            <DatepickerRangeContext.Provider value={{
                                "delivery_date": { range: datesRange, setRange: setDatesRange }
                            }}>
                                <BaseDatepicker
                                    label={t("filters.date.label")}
                                    placeholder={t("filters.date.placeholder")}
                                    rangeConfig={{ groupKey: "delivery_date", role: "solo" }}
                                    brightLabel
                                />
                            </DatepickerRangeContext.Provider>
                        </div>
                    </div>
                }

                handleSearch={searchInputDebounce}

                handleFilter={(e) => console.log(e)}
                handleCreate={() => history.push("/dashboard/sales/orders/new")}

                handleExport={() => void setIsExportModalOpen(true)}
            />

            {/* TABLE BLOCK */}
            <div className="levitation-extended mt-[32px]">
                <Can not I="index" a="sale_order">
                    <NoPermissionBanner />
                </Can>

                <Can I="index" a="sale_order">
                    <BaseTable
                        data={ordersResponse?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) => <div className="bold-highlight">{row.code ?? row.id}</div>
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) => <div className="bold-highlight">{row.customer.name}</div>,
                                comparator: () => 0
                            },
                            // TODO: add shipment column
                            // TODO: add invoice column
                            {
                                header: t("table.columns.4"),
                                getter: (row) => row.order_date.format("YYYY-MM-DD") ?? "-",
                                comparator: () => 0
                            },
                            // {
                            //     header: t("table.columns.3"),
                            //     getter: (row, index, collapseState) => {
                            //         switch(row.receive_state){
                            //             case 0: return <BaseChip fill="green">Received</BaseChip>;
                            //             case 1: return <BaseChip fill="blue">In progress</BaseChip>;
                            //             case 2: return <BaseChip fill="none" className={collapseState ? "bg-gray-300 text-accent" : ""}>Not received</BaseChip>
                            //         }
                            //     },
                            //     comparator: () => 0
                            // },
                            {
                                header: t("table.columns.5"),
                                getter: (row) => {
                                    if (row.orders.length) {
                                        const total = row.orders.reduce((acc, order) => acc + calculateOrderPrice(order.quantity, order.unit_price, order.discount ?? 0) * (1 + (order.tax?.rate ?? 0) / 100), 0);
                                        return <div>{total.toFixed(2)}</div>;
                                    } else {
                                        return <div>-</div>;
                                    }
                                }
                            },
                            {
                                visible: ability.can("show", "sale_order") || ability.can("delete", "sale_order"),
                                header: <div className="text-center">{t("table.columns.6")}</div>,
                                getter: (row, index, collapseState) => (
                                    <div
                                        className="flex space-x-[4px] justify-center align-center py-[10px] cursor-pointer group"
                                        onClick={(e) => {
                                            setActionsAnchor(e.currentTarget);
                                            setActionEntity(row);
                                        }}>
                                        {
                                            [...Array(3)].map((x, i) => <div key={i}
                                                                             className={`${collapseState ? "bg-gray-600" : "bg-gray-300"} rounded-full w-[5px] h-[5px] group-hover:bg-gray-600 transition-[.15s]`}></div>)
                                        }
                                    </div>
                                ),
                                preventCollapsePropagation: true
                            }
                        ]}
                        collapse={{
                            content: (rowRelated, state) => {
                                return (
                                    <BaseTable
                                        data={rowRelated.orders}
                                        columns={[
                                            // Product code
                                            {
                                                header: t("subtable.columns.0"),
                                                getter: (row) => <Link
                                                    to={`/dashboard/products/${row.product.id}/details`}><u>{row.product.code}</u></Link>
                                            },
                                            // Product name
                                            {
                                                header: t("subtable.columns.1"),
                                                getter: (row) => row.product.name
                                            },
                                            // Quantity
                                            {
                                                header: t("subtable.columns.2"),
                                                getter: (row) => row.quantity
                                            },
                                            // Unit price
                                            {
                                                header: t("subtable.columns.3"),
                                                getter: (row) => normalizePrice(row.unit_price)
                                            },
                                            // Discount
                                            {
                                                header: t("subtable.columns.4"),
                                                getter: (row) => row.discount ? row.discount + "%" : "-"
                                            },
                                            // Tax
                                            {
                                                header: t("subtable.columns.5"),
                                                getter: (row) => row.tax ? row.tax.rate.toFixed(2) + "%" : "-"
                                            },
                                            // Total
                                            {
                                                header: t("subtable.columns.6"),
                                                getter: (row) => {
                                                    const total = calculateOrderPrice(row.quantity, row.unit_price, row.discount ?? 0);
                                                    const taxed = total * (1 + (row.tax?.rate ?? 0) / 100);

                                                    return normalizePrice(taxed);
                                                }
                                            }
                                        ]}
                                        size={"small"}
                                        boldHeaders
                                        hideTableWhenNothingFound
                                        nothingFound={{
                                            height: 200
                                        }}
                                    />
                                );
                            },
                            fill: "#D9DAFD",
                            borderColor: "#B1B2E1"
                        }}

                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: SaleOrder.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 1:
                                            name = "customer";
                                            break;
                                        case 2:
                                            name = "date";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}

                        alternate
                        isDataLoading={areOrdersLoading}
                    />

                    <Menu
                        id="basic-menu"
                        anchorEl={actionsAnchor}
                        open={!!actionsAnchor}
                        onClose={() => setActionsAnchor(undefined)}
                    >
                        <div
                            className="flex space-x-[16px] p-[18px] bg-gradient-to-r from-gradients-primaryLinear-from to-gradients-primaryLinear-to text-[#fff]">
                            {/*<Can I="receive" a="sale_order">*/}
                            {/*    <BaseTooltip title={t("actions.receive") || ""} placement="bottom">*/}
                            {/*        <BaseTinyActionIcon*/}
                            {/*            type={TinyActionIconType.Receive}*/}
                            {/*            onClick={() => setIsReceiveModalOpen(true)}}*/}
                            {/*            variant="gradient"*/}
                            {/*        />*/}
                            {/*    </BaseTooltip>*/}
                            {/*</Can>*/}
                            <Can I="show" a="sale_order">
                                <BaseTooltip title={t("actions.details") || ""} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.ViewDetails}
                                        onClick={() => actionEntity && history.push(`/dashboard/sales/orders/${actionEntity.id}/details`)}
                                        variant="gradient"
                                    />
                                </BaseTooltip>
                            </Can>
                            <Can I="create" a="picking">
                                <BaseTooltip title={t("actions.picking") || ""} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.StartPicking}
                                        onClick={() => void history.push(`/dashboard/picking/new`, { saleOrder: actionEntity })}
                                        variant="gradient"
                                    />
                                </BaseTooltip>
                            </Can>
                            <BaseTooltip title={t("actions.createPO") + ""} placement="bottom">
                                <FastCartSVG onClick={async () => {
                                    const order = actionEntity;
                                    if (order) {
                                        await handlePOCreation(order);
                                    }
                                }} />
                            </BaseTooltip>
                            {/*<Can I="duplicate" a="sale_order">*/}
                            {/*    <BaseTooltip title={t("actions.duplicate") || ""} placement="bottom">*/}
                            {/*        <BaseTinyActionIcon*/}
                            {/*            type={TinyActionIconType.Duplicate}*/}
                            {/*            variant="gradient"*/}
                            {/*        />*/}
                            {/*    </BaseTooltip>*/}
                            {/*</Can>*/}
                            <Can I="update" a="sale_order">
                                <BaseTooltip title={t("actions.edit") || ""} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Edit}
                                        onClick={() => actionEntity && history.push(`/dashboard/sales/orders/${actionEntity.id}/edit`)}
                                        variant="gradient"
                                    />
                                </BaseTooltip>
                            </Can>
                            <Can I="delete" a="sale_order">
                                <BaseTooltip title={t("actions.delete") || ""} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Delete}
                                        onClick={() => {
                                            setIsDeletionModalOpen(true);
                                            setActionsAnchor(undefined);
                                        }}
                                        variant="gradient"
                                    />
                                </BaseTooltip>
                            </Can>
                        </div>
                    </Menu>

                    {/* PAGINATION */}
                    <Pagination
                        className="mt-[32px]"
                        {...pagination.adapt(ordersResponse)}
                    />
                </Can>
            </div>

            {/* REFILL MODAL */}
            <LowStockRefillModal
                isOpen={isRefillModalOpen}
                onClose={() => setIsRefillModalOpen(false)}
                refill={refillModalData}
            />

            {/* DELETION MODAL */}
            <OrderDeletionModal
                isOpen={isDeletionModalOpen}
                entity_id={actionEntity?.id}
                onClose={() => setIsDeletionModalOpen(false)}
            />

            {/* EXPORT MODAL */}
            <SaleOrdersExportModal
                isOpen={isExportModalOpen}
                onClose={() => void setIsExportModalOpen(false)}
                filters={filters}
                orderBy={orderBy}
            />
        </>
    );
}
