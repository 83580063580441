import BaseInputsGrid from "@/components/reusables/BaseInputsGrid";
import React, { useEffect, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AdjustmentFormTyping } from "@components/Dashboard/pages/Inventory/Adjustments/components/EditingLayout/types";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import { useGetProductDefaultLocationsQuery, useLazyGetProductsFullQuery } from "@redux/features/products/productsApi";
import BaseDropdown, { adaptInfiniteLoader } from "@/components/reusables/dropdowns/BaseDropdown";
import { useGetLocationsNestedQuery } from "@/redux/features/locations/locationsApi";
import { locationSlimToOption } from "@helpers/utils";
import { addSign, getDifference, LocationOption } from "@components/Dashboard/pages/Inventory/Adjustments/logic";
import Fade from "@mui/material/Fade/Fade";
import BaseInput from "@reusables/BaseInput";
import _ from "lodash";

export default function Fields() {
    const { t } = useTranslation("", { keyPrefix: "inventory.adjustments.createViewEditAdjustments" });
    const { control, resetField } = useFormContext<AdjustmentFormTyping>();

    const selectedProduct = useWatch({
        control,
        name: "product"
    });

    const selectedLocation = useWatch({
        control,
        name: "location"
    });

    const selectedAdjustmentType = useWatch({
        control,
        name: "adjustment_type"
    });

    // =========== DROPDOWNS RELATED =========== //

    // ---> Product selection <--- //
    const [triggerProductLazyLoading, productLazyLoadingResult] = useLazyGetProductsFullQuery();


    // ---> Location selection <--- //
    // General locations list to show even the ons that product is not present on
    const {
        data: generalLocationsOptions = [],
        isLoading: generalLocationOptionsLoading
    } = useGetLocationsNestedQuery();

    // Getting stock level data from the "GET: /defaultLocations/{id}" route
    const {
        data: defaultLocationsOptions = [],
        isLoading: defaultLocationOptionsLoading
    } = useGetProductDefaultLocationsQuery(selectedProduct?.id ?? 0, { skip: !selectedProduct });

    // Storing combined locations options to allow using both arrays, mentioned earlier, in one dropdown
    const combinedLocationsOptions = useMemo(() => {
        // Mapping default locations to object with keys by store and by section (underlying)
        const keyedDefaultLocations = _.keyBy(_.map(defaultLocationsOptions, (location) => {
            return {
                ...location,
                sections: _.keyBy(location.sections, "id")
            };
        }), "id");

        // Iterating global locations and forming locations list with quantities based on the keyedDefaultLocations
        const mergedLocations = generalLocationsOptions.map(locationSlimToOption).flat().map(loc => {
            const quantifiedLocationOption = loc as LocationOption<{ quantity: number }>;

            // If product is assigned to some location, then we will extract it's quantity based on the store or section quantities
            // the "else" paths here are handled by ternary operators
            if (loc.section) {
                const quantity = keyedDefaultLocations[loc.store.id]?.sections[loc.section?.id ?? 0]?.quantity ?? 0;
                _.set(quantifiedLocationOption, "section.quantity", quantity);
            }
            _.set(quantifiedLocationOption, "store.quantity", keyedDefaultLocations[loc.store.id]?.quantity ?? 0);

            return quantifiedLocationOption;
        });

        return mergedLocations;
    }, [generalLocationsOptions, defaultLocationsOptions]);

    // Getting stock level based on the selected location
    const availableStock = selectedLocation ? selectedLocation.section?.quantity ?? selectedLocation.store.quantity : 0;

    // =========== HOOKS =========== //

    useEffect(() => {
        resetField("location");
    }, [selectedProduct, resetField]);

    return (
        <>

            <BaseInputsGrid>
                <Controller
                    control={control}
                    name="product"
                    render={({ field, fieldState }) => (
                        <BaseInfiniteLoader
                            fetch={(search, page) => {
                                triggerProductLazyLoading({
                                    filters: {
                                        search
                                    },
                                    pagination: {
                                        page: page,
                                        limit: 100
                                    }
                                });
                            }}
                            result={productLazyLoadingResult.data?.payload ?? []}
                            isLoading={productLazyLoadingResult.isFetching}
                        >
                            {
                                (infinity) => (
                                    <>
                                        <BaseDropdown
                                            {...field}
                                            {...fieldState}

                                            {...adaptInfiniteLoader(infinity)}

                                            isLoading={productLazyLoadingResult.isLoading}

                                            label={t("fieldsGeneral.product.label") + " *"}
                                            placeholder={t("fieldsGeneral.product.placeholder")}

                                            getter={{
                                                label: opt => opt.name,
                                                key: opt => opt.id,
                                                caption: opt => opt.code
                                            }}

                                            disableClearable

                                            autocomplete
                                        />
                                    </>
                                )
                            }
                        </BaseInfiniteLoader>
                    )}
                />

                {
                    selectedAdjustmentType === 0 &&
                    <Controller
                        control={control}
                        name="location"
                        render={({ field, fieldState }) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}

                                label={t("fieldsGeneral.location.label") + " *"}
                                placeholder={t("fieldsGeneral.location.placeholder")}
                                options={combinedLocationsOptions}
                                getter={{
                                    label: opt => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                    key: opt => opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <div className="grow">
                                            <span
                                                className="bold-highlight">{opt.store.name}</span>{opt.section ? <> - {opt.section.name}</> : null}
                                            </div>
                                            {
                                                field.value == opt ? icon : null
                                            }
                                        </div>
                                    )
                                }}

                                autocomplete
                                isLoading={generalLocationOptionsLoading || defaultLocationOptionsLoading}
                                disableClearable

                                disabled={!selectedProduct}
                                disableReason={t("fieldsGeneral.location.disableReason")}
                            />
                        )}
                    />
                }

                <Controller
                    control={control}
                    name={"date"}
                    render={({ field, fieldState }) => <>
                        <BaseInput
                            label={t("fieldsGeneral.date.label")}
                            value={field.value?.format("YYYY-MM-DD")}
                            error={fieldState.error}
                            disabled
                        />
                    </>}
                />
            </BaseInputsGrid>

            <Fade in={selectedAdjustmentType === 0 && !selectedProduct?.is_service}>
                <div className={selectedAdjustmentType === 0 ? "block" : "hidden"}>
                    <BaseInputsGrid>
                        <BaseInput
                            label={t("fieldsCreateQuantityAdjustment.availableQuantity.label")}
                            placeholder="0.00"
                            value={`${availableStock.toFixed(2)}`}
                            disabled
                        />


                        <Controller
                            control={control}
                            name="actual_quantity"
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fieldsCreateQuantityAdjustment.actualQuantity.label")}
                                    placeholder="0.00"
                                    type="number"
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="actual_quantity"
                            render={({ field }) => (
                                <BaseInput
                                    value={selectedProduct ? addSign(getDifference(availableStock, field.value)) : "0.00"}
                                    label={t("fieldsCreateQuantityAdjustment.availableQuantityDifference.label")}
                                    placeholder="0.00"
                                    disabled
                                />
                            )}
                        />
                    </BaseInputsGrid>
                </div>
            </Fade>

            <Fade in={selectedAdjustmentType === 1}>
                <div className={selectedAdjustmentType === 1 ? "block" : "hidden"}>
                    <BaseInputsGrid>
                        <BaseInput
                            label={t("fieldsCreateCostPriceAdjustment.actualCostPrice.label")}
                            placeholder="0.00"
                            value={`${(selectedProduct?.prices.purchase_price ?? 0).toFixed(2)}`}
                            disabled
                        />

                        <Controller
                            control={control}
                            name="actual_cost_price"
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fieldsCreateCostPriceAdjustment.costPrice.label")}
                                    placeholder="0.00"
                                    type="number"
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="actual_cost_price"
                            render={({ field }) => (
                                <BaseInput
                                    value={selectedProduct ? addSign(getDifference(selectedProduct.prices.purchase_price, field.value)) : "0.00"}
                                    label={t("fieldsCreateCostPriceAdjustment.actualCostPriceDifference.label")}
                                    placeholder="0.00"
                                    disabled
                                />
                            )}
                        />
                    </BaseInputsGrid>
                </div>
            </Fade>
        </>
    );
}


