import React, { useMemo } from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { BaseInputLikeContainer } from "@reusables/BaseInputLikeContainer";
import { useTranslation } from "react-i18next";
import { jsxSwitch } from "@helpers/utils";
import BaseChip from "@reusables/BaseChip";
import BaseTable from "@reusables/BaseTable";
import { Stack } from "@mui/material";
import { ReactComponent as DetailsSVG } from "@assets/icons/ic_document_search.svg";
import SerialNumbersModal from "@components/Dashboard/pages/Sales/Picking/components/modals/SerialNumbersModal";
import BatchNumbersModal from "@components/Dashboard/pages/Sales/Picking/components/modals/BatchNumbersModal";
import { Link } from "react-router-dom";
import BaseBadge from "@reusables/BaseBadge";
import { ViewLayoutProps } from "./index";

export default function PickingDetails({ picking }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.general" });

    const ordersSummary = useMemo(() => {
        return {
            picked: picking.lines.reduce((acc, curr) => acc + curr.picks.reduce((pick_acc, pick_curr) => pick_acc + (pick_curr.picked_quantity ?? 0), 0), 0),
            ordered: picking.lines.reduce((acc, curr) => acc + (curr.product.quantity ?? 0), 0)
        };
    }, [picking]);


    const serialNumbersLines = picking.lines.filter(line => line.serial_numbers?.length);
    const batchNumbersLines = picking.lines.filter(line => line.batch_numbers?.length);

    // Modals
    const [isSerialNumberModalOpen, setIsSerialNumberModalOpen] = React.useState(false);
    const [isBatchNumberModalOpen, setIsBatchNumberModalOpen] = React.useState(false);

    return (
        <>
            <div className="space-y-[40px]">
                <BaseInputsGrid cols={{
                    lg: 3,
                    sm: 2
                }}>
                    <BaseInputLikeContainer
                        label={t("fields.customer.label")}
                        value={picking.order.customer.name}
                    />
                    <BaseInputLikeContainer
                        label={t("fields.saleOrder.label")}
                        value={picking.order.code ?? picking.order.id}
                        linkValue={`/dashboard/sales/orders/${picking.order.id}/details`}
                    />
                    <BaseInputLikeContainer
                        label={t("fields.pickingDate.label")}
                        value={picking.date.format("DD.MM.YYYY")}
                    />
                    <BaseInputLikeContainer
                        label={t("fields.invoicing.label")}
                        value={jsxSwitch(
                            {
                                0: <BaseChip className={"bg-[#F3F2EF]"}>{t(`invoicing.status.0`)}</BaseChip>,
                                1: <BaseChip fill={"green"}>{t(`invoicing.status.1`)}</BaseChip>
                            },
                            Object.values(picking.invoicing).filter(x => x != null).length ? 1 : 0
                        )}
                    />
                    <BaseInputLikeContainer
                        label={t("fields.delivery.label")}
                        value={
                            <div className={"flex flex-row items-center space-x-[8px]"}>
                                <div>
                                {
                                    jsxSwitch(
                                        {
                                            "not_shipped": <BaseChip
                                                className={"bg-[#F3F2EF]"}>{t(`delivery.status.not_shipped.0`)}</BaseChip>,
                                            "in_progress": <BaseChip
                                                fill={"purple"}>{t(`delivery.status.in_progress`)}</BaseChip>,
                                            "delivered": <BaseChip
                                                fill={"green"}>{t(`delivery.status.delivered`)}</BaseChip>
                                        },
                                        picking.delivery_status
                                    )
                                }
                                </div>
                                {
                                    !!picking.shipment_change_date && <div className={"text-gray-600 text-sm"}>
                                        ({picking.shipment_change_date.format("DD.MM.YYYY")})
                                    </div>
                                }
                            </div>
                        } />
                </BaseInputsGrid>

                <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                    <div className="flex mb-[46px] items-center space-x-[8px]">
                        <div className="text-xl font-thin text-gray-600 grow">{t("products.heading")}</div>
                        <Stack direction={"row"} spacing={3}>
                            {
                                serialNumbersLines.length > 0 &&
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    className={"hover:text-accent cursor-pointer"}
                                    onClick={() => setIsSerialNumberModalOpen(true)}
                                >
                                    <span>{t("products.modalReferences.serialNumbers")}</span>
                                    <DetailsSVG />
                                </Stack>
                            }
                            {
                                batchNumbersLines.length > 0 &&
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    className={"hover:text-accent cursor-pointer"}
                                    onClick={() => setIsBatchNumberModalOpen(true)}
                                >
                                    <span>{t("products.modalReferences.batchNumbers")}</span>
                                    <DetailsSVG />
                                </Stack>
                            }
                        </Stack>
                    </div>
                    <BaseTable
                        hideTableWhenNothingFound
                        data={picking.lines}
                        columns={[
                            {
                                header: t("products.columns.0"),
                                getter: (row) => <Link
                                    to={`/dashboard/products/${row.product.id}/details`}><u>{row.product.code}</u></Link>
                            },
                            {
                                header: t("products.columns.1"),
                                getter: (row) => row.product.name
                            },
                            {
                                header: t("products.columns.2"),
                                getter: (row) => row.product.quantity.toFixed(2)
                            },
                            {
                                header: t("products.columns.6"),
                                getter: (row) => row.picks.reduce((acc, curr) => acc + (curr.picked_quantity ?? 0), 0).toFixed(2)
                            },
                            {
                                header: t("products.columns.7"),
                                getter: (row) => {
                                    let pickStatus: 0 | 1 | 2 = 0;
                                    const pickedQuantity = row.picks.reduce((acc, curr) => acc + (curr.picked_quantity ?? 0), 0);

                                    if ((pickedQuantity ?? 0) > 0) {
                                        pickStatus = pickedQuantity == row.product.quantity ? 2 : 1;
                                    }

                                    return jsxSwitch(
                                        {
                                            0: <BaseChip fill={"yellow"} asDot>{t("products.pickStatus.0")}</BaseChip>,
                                            1: <BaseChip fill={"blue"} asDot>{t("products.pickStatus.1")}</BaseChip>,
                                            2: <BaseChip fill={"green"} asDot>{t("products.pickStatus.2")}</BaseChip>
                                        },
                                        pickStatus
                                    );
                                }
                            }
                        ]}
                        headerSx={{
                            background: "rgba(249, 249, 249, 1)"
                        }}
                        nothingFound={{
                            text: t("products.noProducts")
                        }}
                        size="small"
                    />
                    <div className="flex justify-end mt-[48px] font-thin">
                        <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                            <div className="text-right font-semibold text-accent">
                                {t("products.summary.ordered")}
                            </div>
                            <div className="text-right font-semibold pr-[16px] text-accent">
                                {
                                    ordersSummary.ordered.toFixed(2)
                                }
                            </div>
                            <div className="text-right font-semibold text-accent">
                                {t("products.summary.picked")}
                            </div>
                            <div className="text-right font-semibold pr-[16px] text-[#A6DD4C]">
                                {
                                    ordersSummary.picked.toFixed(2)
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <BaseInputsGrid cols={{
                    lg: 3,
                    sm: 2
                }}>
                    <BaseInputLikeContainer
                        label={t("fields.packaging.label")}
                        value={
                            <div className="flex flex-row items-center space-x-[8px]">
                                {picking.packages.length ? picking.packages?.map(pkg => (
                                    <BaseBadge className={"bg-[#F3F2EF]"} key={pkg.id}>
                                        {`${pkg.quantity} x ${pkg.name}`}
                                    </BaseBadge>
                                )) : t("fields.packaging.notSelected")}
                            </div>
                        }
                    />
                </BaseInputsGrid>
            </div>

            {/* Serial numbers modal */}
            {
                serialNumbersLines.length > 0 &&
                <SerialNumbersModal
                    isOpen={isSerialNumberModalOpen}
                    onClose={() => setIsSerialNumberModalOpen(false)}
                    lines={serialNumbersLines}
                    preview
                />
            }

            {/* Batch numbers modal */}
            {
                batchNumbersLines.length > 0 &&
                <BatchNumbersModal
                    isOpen={isBatchNumberModalOpen}
                    onClose={() => setIsBatchNumberModalOpen(false)}
                    lines={batchNumbersLines}
                    preview
                />
            }
        </>
    );
}