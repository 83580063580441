import React from "react";

import {BaseLoadingBlocker} from "@reusables/blockers/BaseLoadingBlocker";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import MutationLayout from "../components/MutationLayout";
import {useGetPurchaseOrderQuery, useUpdatePurchaseOrderMutation} from "@redux/features/purchases/purchasesApi";
import {isErrorWithMessage} from "@redux/api/query";
import {toast} from "react-toastify";

export default function PurchaseOrderEditingPage() {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.mutations.editing"});

    const history = useHistory();
    const {id} = useParams<{ id: string }>();

    const [updatePurchaseOrder, {isLoading: isUpdateLoading}] = useUpdatePurchaseOrderMutation();
    const {data: purchaseOrder, isLoading: isPurchaseOrderLoading} = useGetPurchaseOrderQuery(parseInt(id));

    return (
        <BaseRecordRenderer
            record={purchaseOrder}
            loading={isPurchaseOrderLoading}
        >
            <BaseLoadingBlocker active={isUpdateLoading}>
                <div className="space-y-[40px]">
                    <div className="flex items-center">
                        <h6 className="text-accent grow">{t("heading")} #{purchaseOrder?.code ?? purchaseOrder?.id}</h6>
                        <div id={"reference_number"}></div>
                    </div>
                    <MutationLayout
                        purchaseOrder={purchaseOrder}
                        onSubmit={data => {
                            const mutationData = {
                                reference_number: data.reference_number.code,

                                purchase_date: data.purchase_date.format("YYYY-MM-DD"),
                                preferred_delivery_date: data.preferred_delivery_date?.format("YYYY-MM-DD"),
                                our_reference: data.our_reference?.id,
                                their_reference: data.their_reference?.id,
                                payment_terms: data.payment_terms?.id,
                                delivery_terms: data.delivery_terms?.id,
                                language: data.language?.id,


                                ...(!data.is_billing_for_delivery && !!data.delivery_address && {
                                    delivery_address: data.delivery_address.id,
                                }),

                                shipping_cost: data.shipping_cost,
                                is_split_by_products: data.is_split_by_products,

                                is_billing_for_delivery: data.is_billing_for_delivery,

                                // TODO: Uncomment when the backend will be ready to handle order line prices update
                                // orders: data.orders.map(order => ({
                                //     id: order.id,
                                //     product: order.product.id,
                                //     quantity: order.quantity,
                                //     unit_price: order.unit_price.converted,
                                //     tax: order.tax?.id,
                                //     discount: order.discount,
                                // })),

                                // deleted_orders: data.deleted_orders
                            }

                            updatePurchaseOrder({
                                id: purchaseOrder?.id as number,
                                ...mutationData
                            }).unwrap().then(() => {
                                toast.success(t("responses.success"))

                                history.push(`/dashboard/purchasing/${purchaseOrder?.id}/details`)
                            }).catch(e => {
                                if(isErrorWithMessage(e)){
                                    toast.error(e.message);
                                }else{
                                    toast.error(t("responses.error"))
                                }
                            })
                        }}
                    />
                </div>
            </BaseLoadingBlocker>
        </BaseRecordRenderer>
    );
}