import React from "react";
import { NavLink } from "react-router-dom";

import "./index.scss";
import { Link, LinksGroup } from "./types";
import { useTranslation } from "react-i18next";
import { useAbility } from "@casl/react";
import { AbilityContext } from "@/casl.config";

function SettingsPage() {

    const { t } = useTranslation("", { keyPrefix: "settings.sidebarLinks" });
    const ability = useAbility(AbilityContext);

    /**
     * Keep in mind, that /settings/*general* is obsolete variant of specifying settings routes
     */
    const links = [
        new LinksGroup(
            t("general.title"),
            [
                new Link("Company information", "/dashboard/settings/company/details"),
                new Link("Defaults", "/dashboard/settings/general/defaults/product", true),
                // new Link("Locations", "/dashboard/settings/locations"),
                new Link("Units", "/dashboard/settings/units"),
                Link.createWithPermission("Locations", "/dashboard/settings/locations", ability, "location", ["index", "create"]),
                new Link("Currencies", "/dashboard/settings/currencies"),
                Link.createWithPermission("Payment Terms", "/dashboard/settings/general/payment-terms", ability, "payment_term", ["index", "create"]),
                Link.createWithPermission("Delivery Terms", "/dashboard/settings/general/delivery-terms", ability, "delivery_term", ["index", "create"]),
                Link.createWithPermission("Packaging", "/dashboard/settings/general/packaging", ability, "package", ["index", "create"]),
                Link.createWithPermission("Languages", "/dashboard/settings/general/languages", ability, "language", ["index", "create"]),
                Link.createWithPermission("Tax rates", "/dashboard/settings/general/tax", ability, "tax", ["index", "create"]),
                Link.createWithPermission("Categories", "/dashboard/settings/general/categories", ability, "category", ["index", "create"]),
                new Link("Custom fields", "/dashboard/settings/general/custom-fields"),
                new Link("My Account", "/dashboard/settings/general/account/profile")
            ]
        ),
        new LinksGroup(
            "Documents",
            [
                new Link("Sales order", "/dashboard/settings/layouts/sales-order"),
                new Link("Purchase order", "/dashboard/settings/layouts/purchase-order"),
                new Link("Confirmation email", "/dashboard/settings/layouts/order-confirmation-email")
            ],
            true
        ),
        new LinksGroup(
            "Integrations",
            [
                new Link("API", "/dashboard/settings/api/keys", true),
                new Link("Integrations", "/dashboard/settings/third-party")
            ]
        )
    ];


    return (
        <div
            className="settings min-w-[200px] ml-[16px] pb-[56px] h-screen [border-right:1px_solid_#E9E9F6] sticky top-0"
            style={{ overflowY: "auto" }}>
            <div className="mt-[140px]">
                {links.map((group, index) => !group.isHidden && (
                    <div key={index} className="settingsBox">
                        <p className="text-md-semibold">
                            {group.title}
                        </p>

                        <ul className="flex flex-column gap-[24px]">
                            {group.links.map((link, linkIndex) => !link.isHidden && (
                                <li key={linkIndex}>
                                    <NavLink className="settings-link text-md-regular" to={link.link}>
                                        {link.text}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>

    );
}

export default SettingsPage;
