import BaseTable from "@/components/reusables/BaseTable";
import { jsxSwitch, useOrdering, usePagination } from "@/helpers/utils";
import { useGetPurchaseOrdersFullQuery } from "@/redux/features/purchases/purchasesApi";
import { Product, PurchaseOrder } from "@/types/general";
import { Pagination } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BaseChip from "@reusables/BaseChip";

interface PurchaseOrdersTabProperties {
    product: Product.Extended;
}

export default function PurchaseOrdersTab(props: PurchaseOrdersTabProperties) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.purchaseOrder" });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { orderBy, setOrderBy } = useOrdering<PurchaseOrder.DTO.OrderBy>({ name: "id", type: "desc" });

    const { data: purchasesResponse, isLoading: isPurchasesResponseLoading } = useGetPurchaseOrdersFullQuery({
        filters: {
            products: [props.product.id]
        },
        orderBy,
        pagination
    });
    return (
        <>
            <BaseTable
                isDataLoading={isPurchasesResponseLoading}
                data={purchasesResponse?.payload ?? []}
                columns={[
                    {
                        header: t("table.columns.0"),
                        getter: (row) => <Link to={`/dashboard/purchasing/${row.id}/details`}><u
                            className={"font-semibold"}>{row.code ?? row.id}</u></Link>
                    },
                    {
                        header: t("table.columns.1"),
                        getter: (row) => <Link
                            to={`/dashboard/suppliers/${row.supplier.id}/details`}><u>{row.supplier.name}</u></Link>,
                        comparator: () => 0
                    },
                    {
                        header: t("table.columns.2"),
                        getter: (row) => <>{row.orders
                            .filter(o => o.product.id === props.product.id)
                            .reduce((cum, curVal) => cum + curVal.quantity, 0)}
                        </>
                    },
                    {
                        header: t("table.columns.3"),
                        getter: (row) => jsxSwitch(
                            {
                                0: <BaseChip
                                    className={"bg-[#F3F2EF]"}>{t(`table.receiveStatuses.notReceived`)}</BaseChip>,
                                2: <BaseChip fill={"blue"}>{t(`table.receiveStatuses.partiallyReceived`)}</BaseChip>,
                                3: <BaseChip fill={"green"}>{t(`table.receiveStatuses.received`)}</BaseChip>
                            },
                            row.receive_state === 1 ? 0 : row.receive_state
                        )
                    },
                    {
                        header: t("table.columns.4"),
                        getter: (row) => <>{row.purchase_date.format("DD.MM.YYYY")}</>,
                        comparator: () => 0
                    }
                ]}
                manualControls={{
                    ordering: (newOrdering) => {
                        if (newOrdering) {
                            let name: PurchaseOrder.DTO.OrderBy | undefined;

                            switch (newOrdering?.index) {
                                case 1:
                                    name = "supplier";
                                    break;
                                case 4:
                                    name = "delivery_date";
                                    break;
                                default:
                                    name = undefined;
                            }

                            if (name)
                                setOrderBy({
                                    name,
                                    type: newOrdering.order
                                });
                        } else {
                            setOrderBy(undefined);
                        }
                    }
                }}
                alternate
            />
            <Pagination className="mt-[32px]" {...pagination.adapt(purchasesResponse)} />
        </>
    );
}
