import React from "react";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {
    PurchaseOrdersFormTyping
} from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/types";
import BaseInput from "@reusables/BaseInput";
import {useTranslation} from "react-i18next";
import BaseCheckbox from "@reusables/BaseCheckbox";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import {CircularProgress, Stack} from "@mui/material";
import CreateBillingAddressModal from "./CreateBillingAddressModal";
import { cn } from "@/helpers/utils";

export default function BillingAddressInput({isLoading}: { isLoading: boolean }) {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general.fields.billingAddress"});

    const {control, formState} = useFormContext<PurchaseOrdersFormTyping>();

    const billingAddressErrors = formState.errors.billing_address;

    const billingAddress = useWatch({
        control,
        name: "billing_address"
    });

    const formattedAddress = billingAddress ? [billingAddress.street, billingAddress.city, billingAddress.country.name].join(", ") : "";

    const [isCreateBillingAddressModalOpen, setIsCreateBillingAddressModalOpen] = React.useState(false);

    if (!billingAddress) {
        return (
            <>
                <div>
                    <p className={"mb-2 text-inputs-label-dim font-semibold"}>
                        {t("label")} *
                    </p>
                    {
                        // When billing address is not found because of loading
                        isLoading ?
                            <div className="relative w-full animate-in fade-in anime-out slide-out-to-top-0.5">
                                <BaseInput
                                    disabled
                                />

                                <div
                                    className="absolute flex top-1/2 right-1/2 translate-middle-y translate-middle-x">
                                    <CircularProgress size={18} color={"secondary"}/>
                                </div>
                            </div>
                            :
                            // When billing address is loaded, but not found -> showing ability to create
                            <Stack direction={"row"} gap={2} className="animate-in fade-in">
                                <BaseInput
                                    placeholder={t("placeholderWhenUndefined")}
                                    disabled
                                    error={billingAddressErrors?.message}
                                />

                                <BaseMaterialButton className="w-[150px]"
                                                    onClick={() => setIsCreateBillingAddressModalOpen(true)}>
                                    {t("buttons.create")}
                                </BaseMaterialButton>
                            </Stack>
                    }
                </div>

                <CreateBillingAddressModal
                    isOpen={isCreateBillingAddressModalOpen}
                    onClose={() => setIsCreateBillingAddressModalOpen(false)}
                />
            </>
        )
    }

    return (
        <div className="space-y-[18px] animate-in slide-in-from-bottom-0.5">
            <BaseInput
                value={formattedAddress}
                label={t("label") + " *"}
                disabled
            />

            <Controller
                name="is_billing_for_delivery"
                control={control}
                render={({field}) => (
                    <BaseCheckbox
                        {...field}
                        label={t("checkboxes.useForDelivery") + ""}
                    />
                )}
            />
        </div>
    )
}