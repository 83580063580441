import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BaseTable from "@reusables/BaseTable";
import { selectPaymentTermsById, useGetPaymentTermsQuery } from "@redux/features/paymentTerms/paymentTermsApi";

import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import MutationModal from "./modals/MutationModal";
import { useAppSelector } from "@redux/hooks";
import DeletionModal from "./modals/DeletionModal";
import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function PaymentTermsPage() {

    const { t } = useTranslation("", { keyPrefix: "settings.general.paymentTerms" });

    const ability = useAbility(AbilityContext);

    // Getting payment terms data using RTK Query
    const { data: paymentTerms = [], isLoading: isLoadingPaymentTerms } = useGetPaymentTermsQuery();

    // Open states determine visibility of modals
    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allows modals to work with data
    const [selectedPaymentTermsId, setSelectedPaymentTermsId] = useState<number>();
    const [deletablePaymentTermsId, setDeletablePaymentTermsId] = useState<number>();

    const selectedPaymentTerms = useAppSelector(state => selectPaymentTermsById(state, selectedPaymentTermsId));
    const deletablePaymentTerms = useAppSelector(state => selectPaymentTermsById(state, deletablePaymentTermsId));

    return (
        <>
            <div className="levitation-extended">
                <div className="flex flex-row justify-between items-center mb-[44px]">
                    <div className="font-bold text-2xl text-accent">{t("heading")}</div>
                    <Can I="create" a="payment_term">
                        <BaseMaterialButton fontWeight={500} onClick={() => {
                            setSelectedPaymentTermsId(undefined);
                            setIsMutationModalOpen(true);
                        }}>
                            {t("table.buttons.add")}
                        </BaseMaterialButton>
                    </Can>
                </div>

                <Can not I="index" a="payment_term">
                    <NoPermissionBanner />
                </Can>

                <Can I="index" a="payment_term">
                    <BaseTable
                        data={paymentTerms}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) => <div className="bold-highlight">{row.name}</div>,
                                comparator: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) => row.days,
                                comparator: (a, b) => a.days - b.days
                            },
                            {
                                header: t("table.columns.2"),
                                getter: (row) => "" + (row.type === 0 ? t("paymentTypes.daysAfter") : t("paymentTypes.prepayment")),
                                comparator: (a, b) => {
                                    const a_type_text = a.type === 0 ? t("paymentTypes.daysAfter") : t("paymentTypes.prepayment");
                                    const b_type_text = b.type === 0 ? t("paymentTypes.daysAfter") : t("paymentTypes.prepayment");

                                    return a_type_text.localeCompare(b_type_text);
                                }
                            },
                            {
                                visible: ability.can("update", "payment_term") || ability.can("delete", "payment_term"),
                                header: () => "",
                                getter: (row) => (
                                    <div className="text-tables-secondaryIcon">
                                        <Can I="update" a="payment_term">
                                            <EditSVG
                                                className="mr-[8px] cursor-pointer hover:text-tables-highlightedIcon"
                                                onClick={() => {
                                                    setSelectedPaymentTermsId(row.id);
                                                    setIsMutationModalOpen(true);
                                                }}
                                            />
                                        </Can>
                                        <Can I="delete" a="payment_term">
                                            <DeleteSVG
                                                className="cursor-pointer hover:text-tables-highlightedIcon"
                                                onClick={() => {
                                                    setDeletablePaymentTermsId(row.id);
                                                    setIsDeletionModalOpen(true);
                                                }}
                                            />
                                        </Can>
                                    </div>
                                ),
                                sx: {
                                    textAlign: "right"
                                }
                            }
                        ]}
                        isDataLoading={isLoadingPaymentTerms}

                        headerSx={{
                            backgroundColor: "#F9F9F9"
                        }}

                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}

                        size={"small"}
                    />
                </Can>
            </div>

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => {
                    setIsMutationModalOpen(false);
                }}
                paymentTerms={selectedPaymentTerms}
            />

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                paymentTerms={deletablePaymentTerms}
            />
        </>
    );
}