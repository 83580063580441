import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import ViewLayout from "../components/ViewLayout";

import { useGetSaleOrderQuery } from "@redux/features/sales/orders/salesOrdersApi";

import { Can } from "@/casl.config";
import { BaseTinyActionIcon, TinyActionIconType } from "@components/reusables/BaseTinyActionIcon";
import { useExportOrderMutation } from "@redux/api/internalApiSlice";
import { downloadBlobExport } from "@helpers/utils";
import OrderDeletionModal from "../components/modals/DeleteOrder";

export default function SaleOrderDetailsPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.details" });

    const { id } = useParams<{ id: string }>();

    const { data: saleOrder, isLoading: isSaleOrderLoading } = useGetSaleOrderQuery(parseInt(id));

    const [isDeletionModalOpen, setIsDeletionModalOpen] = React.useState<boolean>(false);

    const [exportPDF, { isLoading: isExportLoading }] = useExportOrderMutation();

    return (
        <>
            <BaseRecordRenderer record={saleOrder} loading={isSaleOrderLoading || isExportLoading} hideLevitation>
                <div className="space-y-[40px] levitation-extended">
                    <div className="flex items-center">
                        <h6 className="text-accent grow">
                            {t("heading")} #{saleOrder?.code ?? saleOrder?.id}
                        </h6>
                        <div className="space-x-[8px] flex items-center h-full">
                            <BaseTinyActionIcon
                                type={TinyActionIconType.PDF}
                                onClick={() =>
                                    exportPDF({ id: saleOrder?.id as number, type: "sale" })
                                        .unwrap()
                                        .then((data) => {
                                            downloadBlobExport(() => data, `sales_order_${saleOrder?.code ?? saleOrder?.id}`, "pdf");
                                        })
                                }
                            />
                            <Can I="create" a="picking">
                                <BaseTinyActionIcon type={TinyActionIconType.StartPicking} onClick={() => void history.push(`/dashboard/picking/new`, { saleOrder: saleOrder })} />
                            </Can>
                            <Can I="update" a="sale_order">
                                <BaseTinyActionIcon type={TinyActionIconType.Edit} onClick={() => history.push(`/dashboard/sales/orders/${saleOrder?.id}/edit`)} />
                            </Can>
                            <Can I="delete" a="sale_order">
                                <BaseTinyActionIcon type={TinyActionIconType.Delete} onClick={() => setIsDeletionModalOpen(true)} />
                            </Can>
                        </div>
                    </div>
                    <ViewLayout.Main saleOrder={saleOrder as NonNullable<typeof saleOrder>} />
                </div>

                <ViewLayout.Pickings saleOrder={saleOrder as NonNullable<typeof saleOrder>} />
            </BaseRecordRenderer>

            <OrderDeletionModal isOpen={isDeletionModalOpen} entity_id={parseInt(id)} onClose={() => setIsDeletionModalOpen(false)} onDeletion={() => history.push("/dashboard/sales/orders")} />
        </>
    );
}
