import React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Styled components for the accordion
const StyledAccordion = styled(Accordion)`
    background-color: #F9F9F9;
    border-radius: 8px;
    border: none;
    margin-bottom: 16px;
    box-shadow: none; // Removes default shadow
    // Removes the default MUI dividing line
    &.MuiPaper-root::before {
        content: none;
    }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
    background-color: #F9F9F9;
    border: none;
    border-radius: 8px; // Top rounded corners
    padding: 24px; // Padding for left and right
    &.Mui-expanded {
        border-radius: 8px 8px 0 0; // Keep top rounded corners when expanded
    }
    .MuiSvgIcon-root {
        color: #7556FA; // Color for the expand icon
    }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
    padding: 24px;
    background-color: #F9F9F9; // White background for content
    border: none;
    border-radius: 8px; // Bottom rounded corners
`;

interface AccordionProps {
    summary: React.ReactNode;
    details: React.ReactNode;
    expanded?: boolean;
    onExpandChange?: (e: React.SyntheticEvent, isExpanded: boolean) => void;
}

const BaseAccordion: React.FC<AccordionProps> = ({ summary, details, expanded, onExpandChange }) => {
    return (
        <StyledAccordion expanded={expanded === undefined ? true : expanded} onChange={onExpandChange}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                {summary}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                {details}
            </StyledAccordionDetails>
        </StyledAccordion>
    );
};

export default BaseAccordion;
