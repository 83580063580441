import { z, ZodTypeDef } from "zod";
import { CustomFields } from "@/types/general";


export const customFieldsModulesList: [CustomFields.Components.Modules, ...CustomFields.Components.Modules[]] = [
    "product"
    // "purchase_order"
];

const customFieldSchema = z.object({
    id: z.number(),
    module: z.string(),
    label: z.string(),
    placeholder: z.string(),
    is_disabled: z.boolean()
});

export const customFieldsInjectSchema = z.object({
    custom_fields: z.array(
        customFieldSchema.merge(
            z.object({
                value: z.string().nullish()
            })
        )
    )
});

export type CustomFieldsInjectTyping = z.infer<typeof customFieldsInjectSchema>;

export function withCustomFields<T, R extends ZodTypeDef, X>(schema: z.ZodType<T, R, X>) {
    return customFieldsInjectSchema.and(schema);
}

