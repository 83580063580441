import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseTable from "@reusables/BaseTable";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { PurchaseOrdersFormTyping } from "../../types";
import { useGetTaxesQuery } from "@redux/features/taxes/taxesApi";
import AddProductModal from "./AddProductModal";
import TotalCell from "./TotalCell";
import { ReactComponent as TrashIcon } from "@assets/icons/ic_trash.svg";
import { Percent } from "lucide-react";
import { normalizePrice } from "@helpers/utils";
import SummaryBlock
    from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/parts/ProductSection/SummaryBlock";
import { useGetSupplierQuery } from "@redux/features/suppliers/suppliersApi";
import AddCollectionModal from "./AddCollectionModal";
import _ from "lodash";
import { Alert, Collapse } from "@mui/material";

export default function ProductSection() {
    const { t } = useTranslation("", {
        keyPrefix: "purchaseOrder.general.orders"
    });

    const { control, getValues, setValue, formState } =
        useFormContext<PurchaseOrdersFormTyping>();

    const {
        fields: orders,
        append,
        remove,
        replace
    } = useFieldArray({
        control,
        name: "orders",
        keyName: "loop_id"
    });

    const [isAddProductModalOpen, setIsAddProductModalOpen] =
        React.useState(false);

    const [isAddCollectionModalOpen, setIsAddCollectionModalOpen] =
        React.useState(false);

    const currency = useWatch({
        control,
        name: "currency"
    });

    const exchangeRate = useWatch({
        control,
        name: "exchange_rate"
    });

    const supplier = useWatch({
        control,
        name: "supplier"
    });

    const {
        data: extendedSupplierData,
        isLoading: isExtendedSupplierDataLoading
    } = useGetSupplierQuery(supplier?.id as number, { skip: !supplier });

    useEffect(() => {
        if (supplier) {
            orders.map((order, index) => {
                setValue(`orders.${index}.tax`, extendedSupplierData?.tax_rate);
            });
        }
    }, [supplier, orders.length]);

    const mode = useWatch({
        control,
        name: "mode"
    });

    const isEditing = mode === "editing";

    const { data: taxesOptions, isLoading: isTaxesOptionsLoading } =
        useGetTaxesQuery(undefined, {
            skip: isEditing
        });

    useEffect(() => {
        const upToDateOrders = getValues("orders");
        replace(
            upToDateOrders.map((order) => ({
                ...order,
                unit_price: {
                    converted: normalizePrice(
                        order.unit_price.original * (exchangeRate ?? 1)
                    ),
                    original: order.unit_price.original
                }
            }))
        );
    }, [exchangeRate]);

    return (
        <>
            <Collapse in={!!formState.errors.orders?.message}>
                <Alert severity={"error"}>{formState.errors.orders?.message}</Alert>
            </Collapse>
            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {orders.length ? (
                    <>
                        <div className="flex mb-[46px] items-center space-x-[8px]">
                            <div className="text-xl font-thin text-gray-600 grow">
                                {t("heading")}
                            </div>
                            {!isEditing && (
                                <BaseMaterialButton
                                    fontWeight={500}
                                    onClick={() => setIsAddProductModalOpen(true)}
                                >
                                    {t("buttons.addProduct")}
                                </BaseMaterialButton>

                            )}
                            {!isEditing && (
                                <BaseMaterialButton
                                    fontWeight={500}
                                    onClick={() => setIsAddCollectionModalOpen(true)}
                                >
                                    {t("buttons.addCollection")}
                                </BaseMaterialButton>

                            )}
                        </div>

                        <BaseTable
                            data={orders}
                            immutable
                            rowKeyGetter={(row) => row.loop_id}
                            columns={[
                                {
                                    // PRODUCT CODE
                                    header: t("columns.0"),
                                    getter: (row) => row.product.code,
                                    sx: {
                                        minWidth: "150px"
                                    }
                                },
                                {
                                    // PRODUCT
                                    header: t("columns.1"),
                                    getter: (row) => row.product.name
                                },
                                {
                                    // QUANTITY
                                    header: t("columns.2"),
                                    getter: (row, index) => (
                                        <Controller
                                            name={`orders.${index}.quantity`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <BaseInput
                                                    {...field}
                                                    error={fieldState.error}
                                                    type="number"
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            padding: "8px"
                                                        }
                                                    }}
                                                    hideErrors
                                                    disabled={isEditing}
                                                />
                                            )}
                                        />
                                    ),

                                    sx: {
                                        maxWidth: "150px",
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    // UNIT PRICE
                                    header: t("columns.3"),
                                    getter: (row, index) => (
                                        <Controller
                                            name={`orders.${index}.unit_price.converted`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <BaseInput
                                                    {...field}
                                                    error={fieldState.error}
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            padding: "8px",
                                                            paddingRight: "20px"
                                                        }
                                                    }}
                                                    icon={{
                                                        right: {
                                                            el: (
                                                                <span className={"text-purple-500"}>
                                {currency?.symbol}
                              </span>
                                                            ),
                                                            offset: "10px"
                                                        }
                                                    }}
                                                    hideErrors
                                                    disabled={isEditing}
                                                />
                                            )}
                                        />
                                    ),

                                    sx: {
                                        maxWidth: "150px",
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    // DISCOUNT
                                    header: t("columns.4"),
                                    getter: (row, index) => (
                                        <Controller
                                            name={`orders.${index}.discount`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <BaseInput
                                                    {...field}
                                                    error={fieldState.error}
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            padding: "8px",
                                                            paddingRight: "30px"
                                                        }
                                                    }}
                                                    icon={{
                                                        right: {
                                                            el: (
                                                                <Percent
                                                                    width={14}
                                                                    height={14}
                                                                    className={"text-purple-500"}
                                                                />
                                                            ),
                                                            offset: "10px"
                                                        }
                                                    }}
                                                    hideErrors
                                                    disabled={isEditing}
                                                />
                                            )}
                                        />
                                    ),
                                    sx: {
                                        maxWidth: "150px",
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    // TAX
                                    header: t("columns.5"),
                                    getter: (row, index) => (
                                        <Controller
                                            name={`orders.${index}.tax`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <BaseDropdown
                                                    {...field}
                                                    error={fieldState.error}
                                                    options={taxesOptions}
                                                    getter={{
                                                        key: (opt) => opt.id,
                                                        label: (opt) => `${opt.rate}%`
                                                    }}
                                                    customize={{
                                                        padding: "4px"
                                                    }}
                                                    isLoading={
                                                        isTaxesOptionsLoading || isExtendedSupplierDataLoading
                                                    }
                                                    disabled={isEditing}
                                                />
                                            )}
                                        />
                                    ),
                                    sx: {
                                        maxWidth: "150px",
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    // TOTAL
                                    header: t("columns.6"),
                                    getter: (row, index) => (
                                        <TotalCell index={index} lines_number={orders.length} />
                                    ),
                                    sx: {
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    visible: !isEditing,
                                    // ACTION
                                    header: () => (
                                        <div className="text-center">{t("columns.7")}</div>
                                    ),
                                    getter: (row, index) => (
                                        <TrashIcon
                                            className="cursor-pointer"
                                            onClick={() => {
                                                if (row.id) {
                                                    setValue("deleted_orders", [
                                                        ...getValues("deleted_orders"),
                                                        row.id
                                                    ]);
                                                }
                                                remove(index);
                                            }}
                                        />
                                    ),
                                    sx: {
                                        textAlign: "center"
                                    }
                                }
                            ]}
                            size={"small"}
                            headerSx={{
                                background: "#F9F9F9"
                            }}
                        />

                        <SummaryBlock />
                    </>
                ) : (
                    <>
                        <div className="text-xl text-center text-gray-300 font-thin">
                            {t("noProducts")}
                        </div>
                        <div className="grid grid-cols-2 gap-[40px] mt-[24px]">
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddProductModalOpen(true)}
                            >
                                {t("buttons.addProduct")}
                            </BaseMaterialButton>
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddCollectionModalOpen(true)}
                            >
                                {t("buttons.addCollection")}
                            </BaseMaterialButton>
                        </div>
                    </>
                )}

                <AddProductModal
                    isOpen={isAddProductModalOpen}
                    onClose={() => setIsAddProductModalOpen(false)}
                    onOrderAddition={(product) => {
                        if (product.should_use_bom) {
                            if (product.selected_bom) {
                                product.selected_bom.components.map(component => {
                                    append({
                                        product: component.product,
                                        quantity: component.quantity * (product.quantity ?? 1),
                                        unit_price: {
                                            converted: normalizePrice(
                                                component.product.unit_price * (exchangeRate ?? 1)
                                            ),
                                            original: component.product.unit_price
                                        },
                                        discount: product.discount
                                    });
                                });
                            }
                        } else {
                            append({
                                ...product,
                                quantity: product.quantity as number,
                                unit_price: {
                                    converted: normalizePrice(product.unit_price * (exchangeRate ?? 1)),
                                    original: product.unit_price
                                }
                            });
                        }
                        setIsAddProductModalOpen(false);
                    }}
                />


                <AddCollectionModal
                    isOpen={isAddCollectionModalOpen}
                    onClose={() => setIsAddCollectionModalOpen(false)}
                    onOrderAddition={(collection) => {
                        const currentOrderLinesList = _.keyBy(
                            _.map(orders, order => _.omit(order, "loop_id")),
                            "product.id"
                        );

                        collection.collection.products.forEach((prod) => {
                            if (!currentOrderLinesList[prod.id]) {
                                currentOrderLinesList[prod.id] = {
                                    product: prod,
                                    quantity: prod.quantity * collection.quantity,
                                    unit_price: {
                                        converted: normalizePrice(prod.prices.purchase_price * (exchangeRate ?? 1)),
                                        original: prod.prices.purchase_price
                                    },
                                    tax: prod.tax
                                };
                            } else {
                                currentOrderLinesList[prod.id].quantity += prod.quantity * collection.quantity;
                            }
                        });

                        replace(Object.values(currentOrderLinesList));
                    }}
                />
            </div>
        </>
    );
}
