import {useEffect} from 'react'
import {useTranslation} from 'react-i18next';
import BaseButton from '@reusables/BaseButton';
import BaseCheckbox from '@reusables/BaseCheckbox';
import BaseInput from '@reusables/BaseInput';
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import BaseModal from '@reusables/Modals/BaseModal';
import {useCreateTaxMutation, useUpdateTaxMutation} from '@redux/features/taxes/taxesApi';
import {Tax} from '@/types/general';
import {toast} from 'react-toastify';

import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";

interface MutationModalProperties {
    isOpen: boolean;
    onClose: () => void;
    tax?: Tax.Root;
}

const formScheme = z.object({
    name: z.string().nonempty(),
    rate: z.coerce.number().positive().max(100),
    is_sales_tax: z.boolean(),
    is_purchase_tax: z.boolean(),
});

type FormTyping = z.infer<typeof formScheme>

export default function MutationModal(props: MutationModalProperties): JSX.Element {
    const {t} = useTranslation("", {keyPrefix: "settings.general.taxRates.modals.mutation"});

    // Getting RTK Query hooks for saving data
    const [createTax, {isLoading: isCreationLoading}] = useCreateTaxMutation();
    const [updateTax, {isLoading: isUpdateLoading}] = useUpdateTaxMutation();

    const { control, handleSubmit, setValue, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    // Updating values depending on whether we are updating or creating new
    useEffect(() => {
        reset();
        if (props.tax) {
            setValue("name", props.tax.name);
            setValue("rate", props.tax.rate);
            setValue("is_sales_tax", !!props.tax.is_sales_tax);
            setValue("is_purchase_tax", !!props.tax.is_purchase_tax);
        }
    }, [props.tax]);

    const onSubmit = handleSubmit((data) => {
        if (props.tax) {
            updateTax({
                id: props.tax.id,
                name: data.name,
                rate: data.rate,
                is_sales_tax: data.is_sales_tax,
                is_purchase_tax: data.is_purchase_tax,
            }).then(() => {
                toast.success(t("responses.update.success"));
            }).catch(() => {
                toast.error(t("responses.update.error"));
            })
            .finally(() => props.onClose());
        } else {
            createTax({
                name: data.name,
                rate: data.rate,
                is_sales_tax: data.is_sales_tax,
                is_purchase_tax: data.is_purchase_tax,
            }).then(() => {
                toast.success(t("responses.creation.success"));
            }).catch(() => {
                toast.error(t("responses.creation.error"));
            })
            .finally(() => props.onClose());
        }
    });

    return (
        <BaseModal 
            isOpen={props.isOpen} 
            onClose={props.onClose} 
            width={562} 
            padding='56px' 
            useCloseIcon 
            isLoading={isCreationLoading || isUpdateLoading}
        >
            <form className='space-y-[32px]' onSubmit={onSubmit}>
                <div className='text-xl font-semibold text-center text-accent'>
                    {
                        props.tax ? props.tax.name : t("creationHeading")
                    }
                </div>

                <Controller 
                    name={'name'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.name.label")}
                        />
                    )}
                />
                    
                <Controller
                    name={'rate'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.rate.label")}
                            type={'number'}
                        />
                    )}
                />

                <BaseInputsGrid cols={{ md: 2}}>
                    <Controller 
                        name={'is_sales_tax'}
                        control={control} 
                        defaultValue={false}
                        render={({field, fieldState}) => (
                            <BaseCheckbox 
                                {...field}
                                {...fieldState}
                                label={<span className="ml-[16px]">{t("fields.salesTax.label")}</span>} 
                                
                            />
                        )}
                    />
                    
                    <Controller 
                        name={'is_purchase_tax'}
                        control={control}
                        defaultValue={false}
                        render={({field, fieldState}) => (
                            <BaseCheckbox 
                                {...field}
                                {...fieldState}
                                label={<span className="ml-[16px]">{t("fields.purchaseTax.label")}</span>} 
                                
                            />
                        )}
                    />
                </BaseInputsGrid>

                <div>
                    <BaseButton
                        buttonWidth="100%"
                        text={`${t("buttons.save")}`}
                        size="md"
                        type={"submit"}
                    />
                </div>
            </form>
        </BaseModal>
    )
}