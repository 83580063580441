import React, { useEffect, useMemo } from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { useGetPackagesQuery } from "@redux/features/packaging/packagingApi";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";

import BaseButton from "@reusables/BaseButton";
import BaseInput from "@reusables/BaseInput";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { Plus } from "lucide-react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { stopPropagate } from "@helpers/utils";
import { PickingMutationFormTyping } from "../../MutationLayout/types";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import { useTranslation } from "react-i18next";
import _ from "lodash";

interface PackagesModalProps {
    isOpen: boolean;
    onClose: () => void;
    packages: PickingMutationFormTyping["packages"];
    onSave: (data: FormSchemeTyping) => void;
}

const formScheme = z.object({
    packages: z.array(
        z.object({
            package: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string().nullish()
            }),
            quantity: z.coerce.number().positive()
        })
    )
});

type FormSchemeTyping = z.infer<typeof formScheme>;

export default function PackagesModal(props: PackagesModalProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.modals.packaging" });

    const { data: packagesOptions, isLoading: packagesOptionsLoading } = useGetPackagesQuery();

    const { control, handleSubmit, setValue, getValues, reset } = useForm<FormSchemeTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            packages: [{}]
        }
    });

    const { fields: packages, append, remove, update } = useFieldArray({
        control,
        name: "packages"
    });

    const onSubmit = handleSubmit((data) => {
        props.onSave?.(data);
    }, console.error);

    useEffect(() => {
        if (props.packages?.length) {
            setValue("packages",
                props.packages?.map((pack) => ({
                    package: {
                        id: pack.package.id,
                        name: pack.package.name,
                        code: pack.package.code
                    },
                    quantity: pack.quantity
                }))
            );
        } else {
            reset({
                packages: [{}]
            })
        }
    }, [props.isOpen, reset, props.packages]);

    const selectedPackageIds = useMemo(() => {
        return _.keyBy(packages, (pkg) => pkg.package?.id);
    }, [packages]);

    const availableOptions = useMemo(() => {
        return packagesOptions?.filter((opt) => !(opt?.id in selectedPackageIds)) || [];
    }, [packagesOptions, selectedPackageIds]);

    return (
        <BaseModal isOpen={props.isOpen} onClose={props.onClose} padding="56px" width={836} useCloseIcon>
            <form onSubmit={stopPropagate(onSubmit)} className="space-y-8">
                <h3 className="text-2xl text-center text-accent font-semibold">{t("heading")}</h3>
                {packages.map((field, index) => (
                        <div key={field.id} className="flex gap-6">
                            <div className="flex-grow">
                                <BaseInputsGrid cols={2} gap={24}>
                                    <Controller
                                        name={`packages.${index}.package`}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <BaseDropdown
                                                {...field}
                                                {...fieldState}
                                                label={t("dropdowns.shippingBox.label")}
                                                placeholder={t("dropdowns.shippingBox.placeholder")}
                                                options={availableOptions}
                                                getter={{
                                                    label: (opt) => opt.name,
                                                    key: (opt) => opt.id
                                                }}
                                                isLoading={packagesOptionsLoading}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`packages.${index}.quantity`}
                                        control={control}
                                        render={({ field, fieldState }) => 
                                            <BaseInput 
                                                {...field} 
                                                error={fieldState?.error} 
                                                type="number" 
                                                label={t("fields.quantity")} 
                                            />
                                        }
                                    />
                                </BaseInputsGrid>
                            </div>
                            <div className="flex mt-8 gap-2 items-center min-w-[68px]">
                                    {index === packages.length - 1 && 
                                        <BaseMaterialIconButton 
                                            color="neutral" 
                                            icon={<Plus size={24} color={"#686868"} />} 
                                            onClick={() => append({} as any)} 
                                        />
                                    }
                                    <DeleteSVG 
                                        onClick={() => (index === 0 ? update(index, [{}] as any) : remove(index))} 
                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer" 
                                    />
                            </div>
                        </div>
                    )
                )}

                <BaseButton 
                    type="submit" 
                    size="large" 
                    text={t("buttons.save")} 
                    buttonWidth="100%" 
                />
            </form>
        </BaseModal>
    );
}
