import React from "react";
import { useTranslation } from "react-i18next";
import { isAddressFilled, removeEmpty } from "@helpers/utils";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { Customer } from "@/types/general";
import { useGetCustomerQuery, useUpdateCustomerMutation } from "@redux/features/customers/customersApi";
import _ from "lodash";
import { isErrorWithMessage } from "@redux/api/query";
import TabsController from "../components/tabs/TabsController";
import dayjs from "dayjs";

export default function CustomerEditingPage() {
    const { t } = useTranslation("", { keyPrefix: "customers.editing" });

    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const { data: customer, isLoading: customerLoading } = useGetCustomerQuery(parseInt(id));

    const [updateCustomer, { isLoading: isUpdatingLoading }] = useUpdateCustomerMutation();

    return (
        <BaseRecordRenderer
            record={customer}
            loading={customerLoading}
        >
            <div className="space-y-[40px]">
                <BaseLoadingBlocker active={isUpdatingLoading}>
                    <div className="flex items-center mb-[40px]"  id={"customer-header"}>
                        <h6 className="text-accent grow">
                            {
                                customer?.name
                            }
                        </h6>
                    </div>
                    <TabsController
                        customer={customer}
                        onSubmit={data => {
                            if (!customer) return;

                            const updateData: Customer.DTO.Update = {
                                name: data.name,
                                code: data.code,
                                ...(!data.is_person && customer.registration_number !== data.registration_number && {registration_number: data.registration_number}),
                                
                                is_person: data.is_person,

                                ...(data.is_person && {contacts: data.person_contacts?.filter(con => !_.isEmpty(removeEmpty(con))).map(contact => {
                                    return {
                                        id: contact.id,
                                        phone: contact.phone as string,
                                        email: contact.email as string
                                    }
                                })}),
                                ...(!data.is_person && {contacts: data.contacts?.filter(con => !_.isEmpty(removeEmpty(con))).map(contact => {
                                    return {
                                        id: contact.id,
                                        name: contact.name as string,
                                        phone: contact.phone as string,
                                        email: contact.email as string
                                    }
                                })}),
    
                                ...(isAddressFilled(data.billing_address, ["is_used_for_shipping"]) && {billing: {
                                    name: data.billing_address.name,
                                    street: data.billing_address.street ?? "",
                                    street_2: data.billing_address.street_2,
                                    zipcode: data.billing_address.zipcode ?? "",
                                    city: data.billing_address.city ?? "",
                                    country: data.billing_address.country?.id,
                                    phone: data.billing_address.phone ?? "",
                                    email: data.billing_address.email ?? "",
                                    is_used_for_shipping: data.billing_address.is_used_for_shipping ?? false,
                                }}),
    
                                deliveries: data.deliveries?.filter(del => isAddressFilled(del, ["is_primary"])).map(delivery => ({
                                    id: delivery.id,
                                    name: delivery.name,
                                    street: delivery.street ?? "",
                                    street_2: delivery.street_2,
                                    zipcode: delivery.zipcode ?? "",
                                    city: delivery.city ?? "",
                                    country: delivery.country?.id,
                                    phone: delivery.phone ?? "",
                                    email: delivery.email,
                                    contact_person: delivery.contact_person,
                                    is_primary: delivery.is_primary ?? false,
                                })),
    
                                language: data.language?.id,
                                tax_rate: data.tax_rate?.id,
                                ...(data.discount && {discount: data.discount}),
                                payment_terms: data.payment_terms?.id,
                                delivery_terms: data.delivery_terms?.id,
                                reference: data.reference?.id,

                                products: data.products?.map(product => {
                                    return {
                                        id: product.product.id,
                                        sale_price: product.sale_price,
                                        discount: product.discount,
                                        min_quantity: product.min_quantity,
                                        valid_from: product.valid_from?.format("YYYY-MM-DD") || dayjs().format("YYYY-MM-DD"),
                                        valid_to: product.valid_to?.format("YYYY-MM-DD")
                                    }
                                }) || [],

                                deleted_contacts: data.deleted_contacts,
                                deleted_deliveries: data.deleted_deliveries,
                            };

                            updateCustomer({ id: customer.id, ...updateData }).unwrap().then(() => {
                                history.push(`/dashboard/customers`);

                                toast.success(t("responses.success"));
                            }).catch(e => {
                                if (isErrorWithMessage(e)) {
                                    toast.error(e.message)
                                } else {
                                    toast.error(t("responses.error"))
                                }
                            })
                        }}
                    />
                </BaseLoadingBlocker>
            </div>
        </BaseRecordRenderer>
    );
}