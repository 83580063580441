import { RootState } from "@redux/hooks";
import { Role } from "@/types/general";
import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { createSelector } from "@reduxjs/toolkit";

export const rolesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRoles: builder.query<Role.Root[], void>({
            query: () => ({
                url: `role`,
            }),
            transformResponse,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "Roles" as const, id })), "Roles"]
                    : ["Roles"],
        }),

        getRole: builder.query<Role.Root, number>({
            query: (id) => ({
                url: `role/${id}`,
            }),
            transformResponse,
            providesTags: (result, error, id) => [{ type: "Roles", id }],
        }),

        createRole: builder.mutation<Role.Root, Role.DTO.Create>({
            query: (body) => ({
                url: `role`,
                method: "POST",
                data: body,
            }),
            transformResponse,
            invalidatesTags: ["Roles"],

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const {data: createdResult} = await queryFulfilled;
                    dispatch(rolesApi.util.updateQueryData("getRoles", undefined, (draft) => {
                        draft.push({
                            id: createdResult.id,
                            name: createdResult.name,
                            description: createdResult.description,
                            permissions: createdResult.permissions,
                        });
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        updateRole: builder.mutation<Role.Root, Role.DTO.Update & {id: number}>({
            query: ({id, ...body}) => ({
                url: `role/${id}`,
                method: "PUT",
                data: body,
            }),
            transformResponse,
            invalidatesTags: ["Roles"],

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const {data: updatedResult} = await queryFulfilled;
                    dispatch(rolesApi.util.updateQueryData("getRoles", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === updatedResult.id);
                        if (index !== -1) {
                            draft[index] = {
                                id: updatedResult.id,
                                name: updatedResult.name,
                                description: updatedResult.description,
                                permissions: updatedResult.permissions,
                            };
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        deleteRole: builder.mutation<void, number>({
            query: (id) => ({
                url: `role/${id}`,
                method: "DELETE",
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(rolesApi.util.updateQueryData("getRoles", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),
    }),
});

const selectRoles = rolesApi.endpoints.getRoles.select();

const selectAllRoles = createSelector(
    selectRoles,
    (roles) => roles.data ?? []
);

export const selectRoleById = createSelector(
    selectAllRoles,
    (state: RootState, r_id: number | undefined) => r_id,
    (roles, r_id) => r_id !== undefined ? roles.find(r => r.id === r_id) : undefined
);

export const { 
    useGetRolesQuery,
    useGetRoleQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation
} = rolesApi;