import { useGetLanguagesQuery } from "@/redux/features/languages/languagesApi";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import React, { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { PurchaseOrdersFormTyping } from "../../types";
import { useTranslation } from "react-i18next";
import { useGetSupplierQuery } from "@redux/features/suppliers/suppliersApi";

export default function LanguageSection() {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.language" });
    const { data: languageOptions, isLoading: isLanguageOptionsLoading } = useGetLanguagesQuery();
    const { control, setValue, watch } = useFormContext<PurchaseOrdersFormTyping>();

    const supplier = useWatch({
        control,
        name: "supplier"
    })

    const mode = useWatch({
        control,
        name: "mode"
    });

    const {data: extendedSupplierData, isLoading: isExtendedSupplierDataLoading} = useGetSupplierQuery(
        supplier?.id as number,
        {skip: !supplier || mode === "editing"}
    );

    useEffect(() => {
        if (extendedSupplierData && mode === "creation") {
            setValue("language", extendedSupplierData.language || undefined);
        }
    }, [extendedSupplierData, setValue]);

    return (
        <>
            <BaseInputsGrid title={t("header")} cols={3}>
                <Controller
                    control={control}
                    name="language"
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}
                            options={languageOptions}
                            getter={{
                                key: (opt) => opt.id,
                                label: (opt) => opt.name
                            }}
                            label={t("label")}
                            placeholder={t("placeholder")}
                            isLoading={isLanguageOptionsLoading}
                            autocomplete
                        />
                    )}
                />
            </BaseInputsGrid>
        </>
    );
}