import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrayElementType, normalizeNumber } from "@helpers/utils";
import BaseDropdownLegacy from "@reusables/dropdowns/BaseDropdownLegacy";
import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import dayjs from "dayjs";
import BaseInput from "@reusables/BaseInputLegacy";
import BaseTextarea from "@reusables/BaseTextarea";
import BaseButton from "@reusables/BaseButton";
import { validate, ValidationStore } from "@reusables/Validator/validationStore";
import BaseValidationManager from "@reusables/Validator";
import { BoundedBetween, NotEmpty } from "@reusables/Validator/types";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { DefaultLocation, Location, Product } from "@/types/general";
import { LocDropdownDisable } from "../../types";
import { productsApi, useGetProductDefaultLocationsQuery } from "@redux/features/products/productsApi";
import { useGetLocationsNestedQuery } from "@redux/features/locations/locationsApi";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import i18n from "i18next";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import { useAppDispatch } from "@redux/hooks";

type CreationLayoutProperties = {
    onSubmit: (transfer: {
        product: number;
        from_location: number;
        from_section?: number;
        to_location: number;
        to_section?: number;
        date: string;
        quantity: number;
        remarks?: string;
    }) => void;
}

export default function CreationLayout({ onSubmit }: CreationLayoutProperties) {
    const { t } = useTranslation("", { keyPrefix: "inventory.stockTransfers.stockTransfer" });
    const dispatch = useAppDispatch();

    // =========== DROPDOWNS RELATED =========== //
    // ---> Product selection <--- //
    const [productsOptions, setProductsOptions] = useState<Product.Slim[]>([]);
    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState<ArrayElementType<typeof productsOptions>>();

    const productsOptionsWithoutServices = useMemo(() => {
        return productsOptions?.filter(product => !product.is_service);
    }, [productsOptions]);

    // ---> Location FROM selection <--- //
    const {
        data: locationsFromOptions = [],
        isLoading: locationsFromOptionsLoading
    } = useGetProductDefaultLocationsQuery(
        selectedProduct?.id as number,
        {
            skip: !selectedProduct
        }
    );

    const [selectedLocationFrom, setSelectedLocationFrom] = useState<ArrayElementType<typeof locationsFromOptions>>();

    // ----> Section FROM selection <---- //
    const sectionsFromOptions = useMemo<DefaultLocation.Root["sections"] | undefined>(() => {
        if (!selectedLocationFrom || !selectedLocationFrom.sections?.filter(x => !!x).length)
            return undefined;

        return selectedLocationFrom.sections;
    }, [selectedLocationFrom]);

    const [selectedSectionFrom, setSelectedSectionFrom] = useState<ArrayElementType<typeof sectionsFromOptions>>();
    useEffect(() => {
        if (selectedSectionFrom && !sectionsFromOptions?.includes(selectedSectionFrom))
            setSelectedSectionFrom(undefined);
    }, [sectionsFromOptions]);

    // ----> Location TO selection <---- //
    const { data: allLocationsOptions = [], isLoading: allLocationsOptionsLoading } = useGetLocationsNestedQuery();

    const locationsToOptions = useMemo(() => {
        // If there is no location from -> we should not allow selection "TO" location
        if (!selectedLocationFrom) return [];

        const extendedLocationFromInfo = allLocationsOptions.find(loc => loc.id === selectedLocationFrom.id);

        // If there is more than 1 section at the selected location -> we won't filter location, because we can transfer to the same location, but another section
        if (extendedLocationFromInfo?.sections && extendedLocationFromInfo.sections.length)
            return allLocationsOptions;

        // If there is only 1 section at the selected location -> we should filter location, because we can't transfer to the same location (lack of sections)
        return allLocationsOptions.filter(loc => {
            return selectedLocationFrom.id !== loc.id;
        });
    }, [allLocationsOptions, selectedLocationFrom]);

    const [selectedLocationTo, setSelectedLocationTo] = useState<ArrayElementType<typeof locationsToOptions>>();


    // ----> Section TO selection <---- //
    const sectionsToOptions = useMemo<Location.Slim["sections"] | undefined>(() => {
        if (!selectedLocationTo || !selectedLocationTo.sections?.filter(x => !!x).length)
            return undefined;

        return selectedLocationTo.sections?.filter(section => {
            if (!selectedSectionFrom)
                return true;

            return section.id !== selectedSectionFrom.id;
        });
    }, [selectedLocationTo, selectedSectionFrom]);

    const [selectedSectionTo, setSelectedSectionTo] = useState<NonNullable<ArrayElementType<Location.Slim["sections"]>>>();
    useEffect(() => {
        if (selectedSectionTo && !sectionsToOptions?.includes(selectedSectionTo))
            setSelectedSectionTo(undefined);
    }, [sectionsToOptions, selectedSectionTo]);

    // =========== OTHER INPUTS RELATED =========== //
    const [date, setDate] = useState<dayjs.Dayjs>(dayjs());

    const [transferQuantity, setTransferQuantity] = useState<number>();
    const [remarks, setRemarks] = useState<string>();

    // =========== ACTION RELATED =========== //
    const [isOperationLoading, setIsOperationLoading] = useState<boolean>(false);

    // ========= ALLOWANCE TRIGGERS ======= //

    // --> Allow location FROM selection (depends on product) <-- //
    const allowLocationFromSelection = useMemo<boolean>(() => !!selectedProduct, [selectedProduct]);


    // --> Allow section FROM selection (depends on location from)
    const allowSectionFromSelection = useMemo<LocDropdownDisable>(() => {
        if (!selectedLocationFrom)
            return new LocDropdownDisable("no_loc", t("disables.section.noLocation"));

        if (!sectionsFromOptions)
            return new LocDropdownDisable("no_sec", t("disables.section.noSection"));

        return new LocDropdownDisable("allow");
    }, [selectedLocationFrom, sectionsFromOptions]);


    // --> Allow location TO selection (depends on location from / section from) <-- //
    const allowLocationToSelection = useMemo<LocDropdownDisable>(() => {
        if (!selectedLocationFrom)
            return new LocDropdownDisable("no_loc", t("disables.toLocation.noFromLocation"));

        if (!allowSectionFromSelection.isDisabled() && !selectedSectionFrom)
            return new LocDropdownDisable("no_sec", t("disables.toLocation.noFromSection"));

        return new LocDropdownDisable("allow");
    }, [selectedLocationFrom, selectedSectionFrom, allowSectionFromSelection]);


    // --> Allow section TO selection (depends on location to) <-- //
    const allowSectionToSelection = useMemo<LocDropdownDisable>(() => {
        if (!selectedLocationTo)
            return new LocDropdownDisable("no_loc", t("disables.section.noLocation"));

        if (!sectionsToOptions)
            return new LocDropdownDisable("no_sec", t("disables.section.noSection"));

        return new LocDropdownDisable("allow");
    }, [selectedLocationTo, sectionsToOptions]);


    useEffect(() => {
        setSelectedLocationFrom(undefined);
    }, [selectedProduct]);

    // ===== DATA OBSERVERS ===== //

    // Getting available quantity from location or from section
    const [availableQuantity, setAvailableQuantity] = useState<number>();
    useEffect(() => {
        if (selectedLocationFrom) {
            // If there are no sections -> get quantity from location
            if (!sectionsFromOptions) {
                setAvailableQuantity(selectedLocationFrom.quantity);
            } else if (selectedSectionFrom) {
                setAvailableQuantity(selectedSectionFrom.quantity);
            } else {
                setAvailableQuantity(undefined);
            }
        } else {
            setAvailableQuantity(undefined);
        }
    }, [selectedLocationFrom, selectedSectionFrom]);


    // Validation errors
    const [validationStore, setValidationStore] = useState<ValidationStore>();

    // =========== HANDLERS =========== //
    const handleSave = () => {
        validationStore?.dispatch(validate({
            onSuccess: () => {
                onSubmit({
                    product: selectedProduct?.id as number,
                    from_location: selectedLocationFrom?.id as number,
                    from_section: selectedSectionFrom?.id,
                    to_location: selectedLocationTo?.id as number,
                    to_section: selectedSectionTo?.id,
                    date: date?.format("YYYY-MM-DD"),
                    quantity: transferQuantity as number,
                    remarks
                });
            }
        }));
    };

    return (
        <BaseLoadingBlocker active={isOperationLoading}>
            <div className="space-y-[40px]">
                <BaseValidationManager onStoreInit={setValidationStore}>
                    <BaseInputsGrid>
                        <BaseInfiniteLoader
                            fetch={(search, page, limit) => {
                                setIsProductLazyLoadingFetching(true);
                                dispatch(productsApi.endpoints.getProductsFull.initiate(
                                        {
                                            filters: {
                                                search
                                            },
                                            pagination: {
                                                page,
                                                limit
                                            }
                                        },
                                        {
                                            subscribe: false
                                        }
                                    )
                                ).unwrap()
                                    .then(res => {
                                        setProductsOptions(res?.payload ?? []);
                                    })
                                    .catch(e => {
                                        if (isErrorWithMessage(e)) {
                                            toast.error(e.message);
                                        } else {
                                            toast.error(i18n.t("general.responses.somethingWentWrong"));
                                        }

                                        console.error("An error occurred in the products lazy loader", e);
                                    })
                                    .finally(() => {
                                        setIsProductLazyLoadingFetching(false);
                                    });
                            }}
                            limit={100}
                            result={productsOptions}
                            isLoading={isProductLazyLoadingFetching}
                        >
                            {
                                (infinity) => (
                                    <>
                                        <BaseDropdown
                                            {...adaptInfiniteLoader(infinity)}

                                            label={t("fields.product.label") + " *"}
                                            placeholder={t("fields.product.placeholder")}
                                            value={selectedProduct}

                                            getter={{
                                                label: opt => opt.name,
                                                key: opt => opt.id,
                                                caption: opt => opt.code
                                            }}
                                            onChange={opt => setSelectedProduct(opt)}

                                            autocomplete

                                            disableClearable
                                        />
                                    </>
                                )
                            }
                        </BaseInfiniteLoader>


                        {/* FROM LOCATION */}
                        <BaseDropdownLegacy
                            label={t("fields.fromLocation.label") + " *"}
                            placeholder={t("fields.fromLocation.placeholder")}
                            options={locationsFromOptions}
                            value={selectedLocationFrom}
                            getter={{
                                label: opt => opt.name,
                                key: opt => opt.id
                            }}

                            onChange={newState => setSelectedLocationFrom(newState[0])}

                            autocomplete
                            isLoading={locationsFromOptionsLoading}

                            disabled={{
                                state: !allowLocationFromSelection,
                                reason: t("disables.fromLocation.noProduct")
                            }}
                            disableClearable

                            constraints={[new NotEmpty()]}
                        />

                        {/* FROM SECTION */}
                        <BaseDropdownLegacy
                            label={t("fields.section.label")}
                            placeholder={t("fields.section.placeholder")}
                            options={sectionsFromOptions ?? []}
                            value={selectedSectionFrom}
                            getter={{
                                label: opt => opt.name,
                                key: opt => opt.id
                            }}

                            onChange={newState => setSelectedSectionFrom(newState[0])}

                            autocomplete
                            isLoading={locationsFromOptionsLoading}

                            disabled={{
                                state: allowSectionFromSelection.isDisabled(),
                                reason: allowSectionFromSelection.getReason()
                            }}

                            disableClearable

                            constraints={allowSectionFromSelection.isDisabled() ? undefined : [new NotEmpty()]}
                        />

                        {/* TO LOCATION */}
                        <BaseDropdownLegacy
                            label={t("fields.toLocation.label") + "*"}
                            placeholder={t("fields.toLocation.placeholder")}
                            options={locationsToOptions}
                            value={selectedLocationTo}
                            getter={{
                                label: opt => opt.name,
                                key: opt => opt.id
                            }}

                            onChange={newState => setSelectedLocationTo(newState[0])}

                            autocomplete
                            isLoading={allLocationsOptionsLoading}

                            disabled={{
                                state: allowLocationToSelection.isDisabled(),
                                reason: allowLocationToSelection.getReason()
                            }}

                            constraints={[new NotEmpty()]}

                            disableClearable
                        />

                        {/* TO SECTION */}
                        <BaseDropdownLegacy
                            label={t("fields.section.label")}
                            placeholder={t("fields.section.placeholder")}
                            options={sectionsToOptions ?? []}
                            value={selectedSectionTo}
                            getter={{
                                label: opt => opt.name,
                                key: opt => opt.id
                            }}

                            onChange={newState => setSelectedSectionTo(newState[0])}

                            autocomplete
                            isLoading={allLocationsOptionsLoading}

                            disabled={{
                                state: allowSectionToSelection.isDisabled(),
                                reason: allowSectionToSelection.getReason()
                            }}

                            constraints={allowSectionToSelection.isDisabled() ? undefined : [new NotEmpty()]}

                            disableClearable
                        />

                        {/* DATE (disabled) */}
                        <BaseInput label={t("fields.date.label")} value={date?.format("YYYY-MM-DD")} disabled />

                        {/* AVAILABLE QUANTITY (disabled)*/}
                        <BaseInput label={t("fields.availableQuantity.label")}
                                   value={availableQuantity?.toString() ?? ""} disabled />

                        {/*TRANSFER QUANTITY*/}
                        <BaseInput
                            label={t("fields.transferQuantity.label")}
                            value={transferQuantity?.toString() ?? ""}
                            onChange={val => setTransferQuantity(normalizeNumber(val, "float"))}
                            type="number"

                            constraints={availableQuantity ? [new NotEmpty(), new BoundedBetween(0, availableQuantity)] : [new NotEmpty()]}
                        />
                    </BaseInputsGrid>

                    <BaseTextarea
                        label={t("fields.remarks.label") + " *"}
                        value={remarks}
                        onChange={val => setRemarks(val)}
                        height="115px"

                        constraints={[new NotEmpty()]}
                    />
                </BaseValidationManager>

                <div className="flex justify-center">
                    <BaseButton
                        onClick={handleSave}
                        text={t("saveButton")}
                        size="md"
                        loading={isOperationLoading}
                        className="!px-[80px] !py-[14px]"
                    />
                </div>
            </div>
        </BaseLoadingBlocker>
    );
}