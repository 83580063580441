import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseMaterialButton from "@reusables/BaseMaterialButton";

import { ReactComponent as IcFilter } from "@assets/images/icons/dashboard/filter.svg";
import { ReactComponent as SearchSVG } from "@assets/icons/ic_search.svg";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as UserRenewSVG } from "@assets/icons/ic_user_renew.svg";
import { ReactComponent as UserSuspendSVG } from "@assets/icons/ic_user_suspend.svg";

import BaseTable from "@reusables/BaseTable";
import { fetchArrayServerside, useFilters } from "@helpers/utils";
import { Users } from "@/types/general";
import BasePagination from "@reusables/BasePagination";
import { Button, Menu } from "@mui/material";

import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import _ from "lodash";
import { BackendReadyOrdering } from "@reusables/BaseTable/types";
import MutationModal from "./modals/MutationModal";
import { useGetMeQuery } from "@redux/features/auth/authApi";
import SuspendedModal from "./modals/SuspendedModal";
import ActivationModal from "./modals/ActivationModal";
import BaseDropdown from "@reusables/dropdowns/BaseDropdownLegacy";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { useGetRolesQuery } from "@redux/features/roles/rolesApi";


export default function UsersTab() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.users" });

    const { data: me, isLoading: isMeLoading } = useGetMeQuery();

    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchingValue, setSearchingValue] = useState<string>();

    const [showFilter, setShowFilter] = useState(false);
    const toggleShowFilter = () => setShowFilter((prev) => !prev);
    const filterBtnRef = useRef(null);

    const statusOptions = [
        {
            id: -1,
            value: "All"
        },
        {
            id: 0,
            value: "Active"
        },
        {
            id: 1,
            value: "Suspended"
        }
    ];
    const [selectedStatus, setSelectedStatus] = useState<typeof statusOptions>([]);

    const { data: roles = [], isLoading: roleOptionsLoading } = useGetRolesQuery();

    const rolesOptions = [
        {
            id: -1,
            value: "All"
        },
        ...roles.map(role => ({
            id: role.id,
            value: role.name
        }))
    ];
    const [selectedRoles, setSelectedRoles] = useState<typeof rolesOptions>([]);

    // Debounce the search input handler
    const searchInputDebounce = _.debounce(setSearchingValue, 1000);

    const [ordering, setOrdering] = useState<BackendReadyOrdering>();

    const filters = useFilters(() => ({
        search: searchingValue,
        status: selectedStatus.length ? selectedStatus[0].id : undefined,
        role: selectedRoles.length ? selectedRoles[0].id : undefined
    }), [searchingValue, selectedStatus, selectedRoles]);

    const {
        loading: dataLoading,
        data: data,
        pagination: pagination,
        call: fetchData
    } = fetchArrayServerside<Users.Root>({
        route: "user",
        debug: true,
        filters: filters,
        orderBy: useMemo(() => ordering ? [ordering] : [], [ordering]),
        dynamicRefetch: true,
        parseDates: ["last_seen"]
    });

    const [activeSupension, setActiveSuspension] = useState<Users.Root>();
    const [activateUser, setActivateUser] = useState<Users.Root>();


    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [activeMutation, setActiveMutation] = useState<Users.Root>();

    return (
        <>
            <div>
                <div className="flex flex-row justify-between items-center mb-[44px]">
                    {showSearch ? (
                        <>
                            <div className="relative w-full mr-[16px]">
                                <input
                                    type="text"
                                    placeholder={"Search"}
                                    onBlur={() => setShowSearch(false)}
                                    onChange={(e) => searchInputDebounce(e.target.value)}
                                    className="w-full h-[40px] px-10 py-[7px] rounded-lg border-solid border-[#B49FFB]"
                                    autoFocus
                                />
                                <div className="absolute top-50 left-[18px] translate-middle-y">
                                    <SearchSVG className="mt-[-2px]" />
                                </div>
                            </div>
                            <BaseMaterialButton
                                className="h-[40px]"
                                fontWeight={500}
                                onClick={() => {
                                    setIsMutationModalOpen(true);
                                }}
                                sx={{
                                    minWidth: "fit-content"
                                }}
                            >
                                {t("table.buttons.invite")}
                            </BaseMaterialButton>
                        </>
                    ) : (
                        // Heading and search button shown
                        <>
                            <div className="font-bold text-2xl text-accent">
                                {t("heading")}
                            </div>
                            <div className="flex items-center gap-[16px]">
                                <Button
                                    ref={filterBtnRef}
                                    variant="contained"
                                    size="medium"
                                    onClick={toggleShowFilter}
                                    className="max-w-[90px] h-[40px]"
                                    sx={{
                                        backgroundColor: "#F3F2EF !important",
                                        color: "#686868",
                                        "&:active": {
                                            backgroundColor: "#e0dfdc !important"
                                        }
                                    }}
                                >
                                    <div className="flex justify-center items-center space-x-2 px-[32px]">
                                        <span>{t("filter.title")}</span>
                                        <IcFilter />
                                    </div>
                                </Button>
                                <Menu
                                    anchorEl={filterBtnRef?.current}
                                    id="filterMenu"
                                    open={showFilter}
                                    onClose={toggleShowFilter}
                                    elevation={0}
                                    className="!shadow-sm-1 mt-2 p-6"
                                    sx={{
                                        ".MuiPaper-root": {
                                            p: "24px",
                                            boxShadow: "-6px 5px 33px rgba(59, 65, 208, 0.11)",
                                            width: "300px"
                                        }
                                    }}
                                >
                                    <BaseInputsGrid cols={1} gap={24}>
                                        <BaseDropdown
                                            label="Status"
                                            options={statusOptions}
                                            value={selectedStatus}
                                            getter={{
                                                label: opt => opt.value,
                                                key: opt => opt.id
                                            }}
                                            onChange={newState => setSelectedStatus(newState)}
                                        />
                                        <BaseDropdown
                                            label="Role"
                                            options={rolesOptions}
                                            isLoading={roleOptionsLoading}
                                            value={selectedRoles}
                                            getter={{
                                                label: opt => opt.value,
                                                key: opt => opt.id
                                            }}
                                            onChange={newState => setSelectedRoles(newState)}
                                        />
                                    </BaseInputsGrid>
                                </Menu>

                                <BaseMaterialIconButton
                                    icon={<SearchSVG />}
                                    onClick={() => setShowSearch(true)}
                                    className="w-[40px] h-[40px]"
                                />

                                <BaseMaterialButton
                                    fontWeight={500}
                                    className="h-[40px]"
                                    onClick={() => {
                                        setIsMutationModalOpen(true);
                                    }}
                                >
                                    {t("table.buttons.invite")}
                                </BaseMaterialButton>
                            </div>
                        </>
                    )}
                </div>

                <BaseTable
                    data={data ?? []}
                    columns={[
                        {
                            header: t("table.columns.0"),
                            getter: row => (
                                <span className={`bold-highlight ${row.status ? "opacity-25" : ""}`}>
                                    {row.first_name} {row.last_name}
                                </span>
                            ),
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.1"),
                            getter: row => (
                                <span className={row.status ? "opacity-25" : ""}>
                                    {row.email}
                                </span>
                            ),
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.2"),
                            getter: row => <span className={row.status ? "opacity-25" : ""}>{row.role.name}</span>
                        },
                        {
                            header: t("table.columns.3"),
                            getter: row => (
                                <span className={row.status ? "opacity-25" : ""}>
                                    {row.status ? "Suspended" : "Active"}
                                </span>
                            )

                        },
                        {
                            header: t("table.columns.4"),
                            getter: row => <span className={row.status ? "opacity-25" : ""}>
                                {row.activity.last_seen?.format("DD.MM.YYYY hh:mm") || "Never"}
                            </span>
                        },
                        {
                            header: () => "",
                            getter: (row) => (
                                row.status === 0 ? (
                                    <div className="flex justify-end text-tables-secondaryIcon">
                                        {row.id !== me?.id && // Prevents the user from deleting themselves.
                                            <>
                                                <EditSVG
                                                    className="mr-[16px] cursor-pointer hover:text-tables-highlightedIcon"
                                                    onClick={() => {
                                                        setActiveMutation(row);
                                                    }} />
                                                <UserSuspendSVG
                                                    className="ml-[16px] cursor-pointer hover:text-tables-highlightedIcon"
                                                    onClick={() => setActiveSuspension(row)}
                                                />
                                            </>
                                        }
                                    </div>
                                ) : (
                                    <div className="flex justify-end text-tables-secondaryIcon">
                                        <UserRenewSVG
                                            className="cursor-pointer hover:text-tables-highlightedIcon"
                                            onClick={() => setActivateUser(row)}
                                        />
                                    </div>
                                )
                            )
                        }
                    ]}

                    isDataLoading={dataLoading || isMeLoading}

                    manualControls={{
                        ordering: (newOrdering) => {
                            if (newOrdering?.index) {
                                let name: string | undefined;

                                switch (newOrdering?.index) {
                                    case 0:
                                        name = "name";
                                        break;
                                    case 1:
                                        name = "email";
                                        break;
                                    default:
                                        name = undefined;
                                }

                                if (name)
                                    setOrdering({
                                        name,
                                        type: newOrdering.order
                                    });
                            } else {
                                setOrdering(undefined);
                            }
                        }
                    }}


                    headerSx={{
                        backgroundColor: "#F9F9F9"
                    }}
                    sx={{
                        borderCollapse: "separate",
                        borderSpacing: "0 15px"
                    }}
                />
            </div>

            {pagination && <BasePagination

                className="mt-[32px]"
                config={pagination}
                disabled={dataLoading}
            />}

            <SuspendedModal
                isOpen={!!activeSupension}
                onClose={() => setActiveSuspension(undefined)}
                onSuspension={() => fetchData()}
                enitity={activeSupension}
            />

            <ActivationModal
                isOpen={!!activateUser}
                onClose={() => setActivateUser(undefined)}
                onActivate={() => fetchData()}
                enitity={activateUser}
            />

            <MutationModal
                isOpen={!!activeMutation || isMutationModalOpen === true}
                user={activeMutation}
                onClose={() => {
                    setActiveMutation(undefined);
                    setIsMutationModalOpen(false);
                }}
                onMutation={() => fetchData()}
            />
        </>
    );
}