import React from "react";
import DashboardCard from "../../DashboardCard";
import BaseTable from "@reusables/BaseTable";
import { useTranslation } from "react-i18next";
import TableButton from "@reusables/BaseTable/TableButton";
import { useGetRestockingQuery } from "@redux/features/purchases/purchasesApi";
import { useRestockingRedirect } from "@components/Dashboard/DashboardPage/logic";
import { useAppDispatch } from "@redux/hooks";

export default function RestockingPage(): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "dashboard.dashboard.stats" });
    const dispatch = useAppDispatch();

    const { data: restockingData = [], isLoading: restockingIsLoading } = useGetRestockingQuery();

    const handleRestockingRedirect = useRestockingRedirect(dispatch);

    return (
        <div className="flex flex-column content-between gap-[24px]">
            <DashboardCard dashboardAbility={"restocking"} title={t("restocking.heading")} className="w-full">
                <BaseTable
                    data={restockingData}
                    isDataLoading={restockingIsLoading}
                    hideTableWhenNothingFound
                    columns={[
                        {
                            header: t("restocking.columns.0"),
                            getter: (row) => row.product.name
                        },
                        {
                            header: t("restocking.columns.1"),
                            getter: (row) => `${row.in_stock}/${row.min_inventory_quantity}`
                        },
                        {
                            header: "",
                            getter: (row) => (
                                <div className="text-right">
                                    <TableButton
                                        onClick={() => {
                                            handleRestockingRedirect(row.product.id, row.in_stock);
                                        }}
                                    >
                                        {t("restocking.orderButton")}
                                    </TableButton>
                                </div>
                            ),
                            cellClasses: "w-full"
                        }
                    ]}
                    stickyHeader
                    alternate
                    nothingFound={{
                        text: t("restocking.notFound")
                    }}
                />
            </DashboardCard>
        </div>
    );
}
