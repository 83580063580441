import { Integration } from "@/types/general";
import { useGetIntegrationsQuery } from "@redux/features/integrations/integrationsApi";
import _ from "lodash";

interface BaseIntegrationInjectProps {
    children: (integration?: Integration.Components.Integration) => JSX.Element;
    integration: Integration.Components.Slug;
}

export default function BaseIntegrationInject(props: BaseIntegrationInjectProps) {
    /**
     * Loading available integrations list
     */
    const { data: availableIntegrationsList = [] } = useGetIntegrationsQuery();

    /**
     * Transforming integrations array into map with slug as key
     */
    const availableIntegrationsMap = _.keyBy(availableIntegrationsList, "slug");

    return props.children(availableIntegrationsMap[props.integration]);
}