import React, {useState} from "react";
import ToggleWrapper from "../../../ToggleWrapper";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import {useTranslation} from "react-i18next";
import {ProductSchemeTyping} from "../../../types";
import CategoryCreationModal from "../modals/category/CategoryCreationModal";
import {useGetCategoriesQuery} from "@redux/features/categories/categoriesApi";
import {Controller, useFormContext} from "react-hook-form";
import {Stack} from "@mui/material";
import BaseBadge from "@reusables/BaseBadge";


export default function CategoryDropdown() {
    const {t} = useTranslation("", {keyPrefix: "products"});

    const {data: options, isLoading: optionsLoading} = useGetCategoriesQuery();

    const [isCreationModalOpen, setIsCreationModalOpen] = useState<boolean>(false);

    const {control, setValue} = useFormContext<ProductSchemeTyping>();

    return (
        <>
            <ToggleWrapper label={t("general.fields.category.label")} fieldKey="category">
                <Controller
                    name="general.category"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}
                            placeholder={t("general.fields.category.placeholder")}
                            options={options}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                                renderOption: (opt, icon) => (
                                    <div>
                                        <Stack direction={"row"} spacing={1} className="grow">
                                            <span className="bold-highlight">{opt.name}</span>
                                            {
                                                opt.is_service &&
                                                <BaseBadge>
                                                    {t("general.fields.category.serviceBadge")}
                                                </BaseBadge>
                                            }
                                        </Stack>
                                        {opt.id !== field.value?.id ?
                                            <span className="text-lightGreen-500 font-thin">{opt.code ?? opt.id}</span>
                                            : icon
                                        }
                                    </div>
                                )
                            }}

                            filterOptions={opt => {
                                const values = [opt.name, opt.code, opt.id];

                                if(opt.is_service) {
                                    values.push(t("general.fields.category.serviceBadge") + "");
                                }

                                return values.join(" ").toLowerCase();
                            }}

                            isLoading={optionsLoading}
                            action={{
                                title: t("createEditProduct.modals.category.createButton"),
                                onClick: () => setIsCreationModalOpen(true)
                            }}

                            autocomplete
                        />
                    )}
                />
            </ToggleWrapper>

            <CategoryCreationModal
                isOpen={isCreationModalOpen}
                onClose={() => setIsCreationModalOpen(false)}
                onCreation={category => {
                    setValue("general.category", category)
                }}
            />
        </>
    )
}