import React, { useEffect } from 'react'
import {useTranslation} from 'react-i18next';
import {TabProperties} from '../../types';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {SupplierFormTyping} from './TabsController';
import {useGetCurrenciesQuery} from '@redux/features/currencies/currenciesApi';
import {useGetTaxesQuery} from '@redux/features/taxes/taxesApi';
import {useGetPaymentTermsQuery} from '@redux/features/paymentTerms/paymentTermsApi';
import {useGetDeliveryTermsQuery} from '@redux/features/deliveryTerms/deliveryTermsApi';
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import BaseDropdown from '@reusables/dropdowns/BaseDropdown';
import BaseButton from '@reusables/BaseButton';
import FormErrorWarning from '../modals/FormErrorWarning';
import { useGetLanguagesQuery } from '@redux/features/languages/languagesApi';

export default function SettingsTab({onNext, onPrev}: TabProperties & {onPrev: () => void}) {
    const { t } = useTranslation("", { keyPrefix: "suppliers.general.tabs.settings" });

    const {control, setValue} = useFormContext<SupplierFormTyping>();

    const {data: currencyOptions = [], isLoading: currencyOptionsLoading} = useGetCurrenciesQuery();

    const {data: taxOptions = [], isLoading: taxOptionsLoading} = useGetTaxesQuery();

    const {data: paymentTermsOptions = [], isLoading: paymentTermsOptionsLoading} = useGetPaymentTermsQuery();

    const {data: DeliveryTermsOptions = [], isLoading: DeliveryTermsOptionsLoading} = useGetDeliveryTermsQuery();

    const {data: languageOptions = [], isLoading: isLanguageOptions} = useGetLanguagesQuery();

    const mode = useWatch({
        control,
        name: "mode"
    });

    useEffect(() => {
        if(currencyOptions && mode === "creation") {
            console.log(mode, currencyOptions)
            setValue("currency", currencyOptions.find(opt => opt.isBase));
        }
    }, [mode, currencyOptions]);

    return (
        <form className="space-y-[40px]" onSubmit={onNext}>
            <FormErrorWarning />

            <BaseInputsGrid>
                <Controller
                    control={control}
                    name='currency'
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            label={t("dropdowns.currency.label")}
                            placeholder={t("dropdowns.currency.placeholder")}

                            options={currencyOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                                caption: opt => opt.isBase ? `${t("dropdowns.currency.base").toUpperCase()}` : opt.code,
                            }}
                            isLoading={currencyOptionsLoading}

                            autocomplete
                        />
                    )}
                />

                <Controller
                    control={control}
                    name='tax_rate'
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            label={t("dropdowns.taxRate.label")}
                            placeholder={t("dropdowns.taxRate.placeholder")}

                            options={taxOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.rate.toString() + " %",
                            }}

                            isLoading={taxOptionsLoading}

                            autocomplete
                        />
                    )}
                />

                <Controller
                    control={control}
                    name='payment_terms'
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            label={t("dropdowns.paymentTerms.label")}
                            placeholder={t("dropdowns.paymentTerms.placeholder")}

                            options={paymentTermsOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                            }}
                            isLoading={paymentTermsOptionsLoading}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name='delivery_terms'
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            label={t("dropdowns.deliveryTerms.label")}
                            placeholder={t("dropdowns.deliveryTerms.placeholder")}

                            options={DeliveryTermsOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                            }}
                            isLoading={DeliveryTermsOptionsLoading}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="language"
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            options={languageOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name
                            }}
                            
                            label={t("dropdowns.language.label")}
                            placeholder={t("dropdowns.language.placeholder")}
                            isLoading={isLanguageOptions}
                            autocomplete
                        />
                    )}
                />
            </BaseInputsGrid>
            <div className="flex justify-center space-x-[24px]" id={"navigation"}>
                <BaseButton
                    text={t("buttons.prev")}
                    size="md"
                    buttonWidth="200px"
                    primaryOutlined
                    type={"button"}

                    onClick={onPrev}
                />

                {mode === "creation" && (
                    <BaseButton
                        text={t("buttons.save")}
                        size="md"
                        buttonWidth="200px"
                        onClick={onNext}
                    />
                )}
            </div>
        </form>
    )
}
