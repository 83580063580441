import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { PickingMutationFormTyping } from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/types";
import { Control, useWatch } from "react-hook-form";
import { Fade, Stack } from "@mui/material";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import SerialNumbersModal from "@components/Dashboard/pages/Sales/Picking/components/modals/SerialNumbersModal";
import BatchNumbersModal from "@components/Dashboard/pages/Sales/Picking/components/modals/BatchNumbersModal";
import { useTranslation } from "react-i18next";

type TableHeadingProps = {
    control: Control<PickingMutationFormTyping>;
    onLinesUpdate: (lines: PickingMutationFormTyping["lines"]) => void;
}

export default function TableHeading({control, onLinesUpdate}: TableHeadingProps) {
    const {t} = useTranslation("", {keyPrefix: "sales.picking.general"});

    const lines = useWatch({
        control,
        name: "lines",
    })

    // Split lines into two groups: with serial numbers and with batch numbers
    // then passing them into modals, because modals doesn't perform any filtering and mapping
    // to allow using them for View Page as well
    const serialAndBatchNumberLines = useMemo(() => {
        const localLines = lines.map(line => {
            return {
                ..._.pick(line, ["id", "product", "serial_numbers", "batch_numbers"]),
                picked_quantity: line.picks
                    .filter(pick => pick.outgoing_location && pick.picked_quantity)
                    .reduce((acc, {picked_quantity}) => acc + Number(picked_quantity ?? 0), 0),
            };
        }).filter(line => line.picked_quantity > 0);

        return {
            withSerialNumbers: localLines.filter(line => line.product.is_serial_numbers),
            withBatchNumbers: localLines.filter(line => line.product.is_batch_numbers)
        }
    }, [lines])

    const [serialNumberModalOpen, setSerialNumberModalOpen] = useState(false);
    const [batchNumberModalOpen, setBatchNumberModalOpen] = useState(false);

    return (
        <>
            <div className="flex mb-[46px] items-center space-x-[8px]">
                <div className="text-xl font-thin text-gray-600 grow">{t("products.heading")}</div>
                <Stack direction={"row"} spacing={2}>
                    {/* Button for adding serial numbers*/}
                    <Fade in={serialAndBatchNumberLines.withSerialNumbers.length > 0}>
                        <div>
                            <BaseMaterialButton type={"button"} fontWeight={500}
                                                onClick={() => setSerialNumberModalOpen(true)}>
                                {t("products.buttons.addSerialNumbers")}
                            </BaseMaterialButton>
                        </div>
                    </Fade>

                    {/* Button for adding batch numbers */}
                    <Fade in={serialAndBatchNumberLines.withBatchNumbers.length > 0}>
                        <div>
                            <BaseMaterialButton type={"button"} fontWeight={500}
                                                onClick={() => setBatchNumberModalOpen(true)}>
                                {t("products.buttons.addBatchNumbers")}
                            </BaseMaterialButton>
                        </div>
                    </Fade>
                </Stack>
            </div>

            {/* Serial numbers modal */}
            {
                serialAndBatchNumberLines.withSerialNumbers.length > 0 &&
                <SerialNumbersModal
                    isOpen={serialNumberModalOpen}
                    onClose={() => setSerialNumberModalOpen(false)}
                    lines={serialAndBatchNumberLines.withSerialNumbers}
                    onSave={data => {
                        // 1. Transform the array of lines in 'data' to an object, keyed by 'sale order line id'
                        const idBasedData = _.keyBy(data.lines, (line) => line.id);

                        // 2. Iterate through 'controlledLines' to generate a new array 'formReadyLines'.
                        // For each line in 'controlledLines', merge its properties with the 'serial_numbers' property
                        // from the corresponding line in 'idBasedData' (using the same 'sale order line id' for matching).
                        const formReadyLines = lines.map(line => {
                            return {
                                ...line,
                                serial_numbers: (line.id in idBasedData) ? idBasedData[line.id].serial_numbers : []
                            };
                        });

                        onLinesUpdate(formReadyLines);

                        setSerialNumberModalOpen(false)
                    }}
                />
            }

            {/* Batch numbers modal */}
            {
                serialAndBatchNumberLines.withBatchNumbers.length > 0 &&
                <BatchNumbersModal
                    isOpen={batchNumberModalOpen}
                    onClose={() => setBatchNumberModalOpen(false)}
                    lines={serialAndBatchNumberLines.withBatchNumbers}
                    onSave={data => {
                        // 1. Transform the array of lines in 'data' to an object, keyed by 'sale order line id'
                        const idBasedData = _.keyBy(data.lines, (line) => line.id);

                        // 2. Iterate through 'controlledLines' to generate a new array 'formReadyLines'.
                        // For each line in 'controlledLines', merge its properties with the 'batch_numbers' property
                        // from the corresponding line in 'idBasedData' (using the same 'sale order line id' for matching).
                        const formReadyLines = lines.map(line => {
                            return {
                                ...line,
                                batch_numbers: (line.id in idBasedData)
                                    ?
                                    idBasedData[line.id].batch_numbers : []
                            };
                        });

                        onLinesUpdate(formReadyLines);

                        setBatchNumberModalOpen(false)
                    }}
                />
            }
        </>
    );
}