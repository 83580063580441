import React from "react";
import { ViewLayoutProps } from "./index";
import { useTranslation } from "react-i18next";
import { Collapse, Stack } from "@mui/material";
import { ReactComponent as CircleChevronSVG } from "@assets/icons/ic_expand_down_circled.svg";
import BaseTooltip from "@reusables/BaseTooltip";
import { cn, jsxSwitch } from "@helpers/utils";
import BaseTable from "@reusables/BaseTable";
import { Link } from "react-router-dom";
import BaseChip from "@reusables/BaseChip";
import _ from "lodash";

export default function OrderPickings({ saleOrder }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.pickingsSection" });

    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);

    if (!saleOrder?.pickings.length)
        return (
            <div className="levitation-extended">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <div className={"text-accent font-semibold text-xl"}>{t("heading")}</div>
                    <BaseTooltip title={t("noPickingsFoundTooltip") + ""}>
                        <CircleChevronSVG className={"text-[#D7D4DA]"} />
                    </BaseTooltip>
                </Stack>
            </div>
        );

    return (
        <div className="levitation-extended">
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <div className={"text-accent font-semibold text-xl"}>{t("heading")}</div>
                <CircleChevronSVG
                    onClick={() => void setIsCollapsed(prevState => !prevState)}
                    className={cn("cursor-pointer text-accent transition-[.25s]")}
                    style={{ transform: !isCollapsed ? "rotate(0deg)" : "rotate(180deg)" }}
                />
            </Stack>

            <Collapse in={isCollapsed}>
                <div className={"mt-[24px]"}>
                    <BaseTable
                        data={saleOrder.pickings}
                        columns={[
                            {
                                header: t("columns.0"),
                                getter: (row) =>
                                    <Link to={`/dashboard/picking/${row.id}/details`}><u>{row.code ?? row.id}</u></Link>
                            },
                            // {
                            //     header: t("columns.1"),
                            //     getter: (row) => {
                            //         if (row.is_fully_picked) {
                            //             return <BaseChip fill={"green"} asDot>{t("pickingStatus.1")}</BaseChip>;
                            //         } else {
                            //             return <BaseChip fill={"blue"} asDot>{t("pickingStatus.0")}</BaseChip>;
                            //         }
                            //     }
                            // },
                            {
                                header: t("columns.2"),
                                getter: (row) => jsxSwitch(
                                    {
                                        0: <BaseChip
                                            className={"bg-[#F3F2EF]"}>{t(`invoicing.status.0`)}</BaseChip>,
                                        1: <BaseChip fill={"green"}>{t(`invoicing.status.1`)}</BaseChip>
                                    },
                                    Object.values(row.invoicing).filter(x => x != null).length ? 1 : 0
                                )
                            },
                            {
                                header: t("columns.3"),
                                getter: (row) =>
                                    <div>
                                        <div>
                                            {
                                                jsxSwitch(
                                                    {
                                                        "not_shipped": <BaseChip
                                                            className={"bg-[#F3F2EF]"}>{t(`delivery.status.not_shipped`)}</BaseChip>,
                                                        "in_progress": <BaseChip
                                                            fill={"purple"}>{t(`delivery.status.in_progress`)}</BaseChip>,
                                                        "delivered": <BaseChip
                                                            fill={"green"}>{t(`delivery.status.delivered`)}</BaseChip>
                                                    },
                                                    row.delivery_status
                                                )
                                            }
                                        </div>
                                        {/*TODO: uncomment, if needed, cause for me it looks ugly */}
                                        {/*{*/}
                                        {/*    !!row.shipment_change_date && <div className={"text-gray-600 text-sm"}>*/}
                                        {/*        ({row.shipment_change_date.format("DD.MM.YYYY")})*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                    </div>
                            },
                            {
                                header: t("columns.4"),
                                getter: (row) => row.date.format("DD.MM.YYYY"),
                                comparator: (a, b) => a.date.isAfter(b.date) ? -1 : 1
                            },
                            {
                                header: t("columns.5"),
                                getter: (row) => row.worker ? [row.worker.first_name, row.worker.last_name].filter(x => !_.isEmpty(x)).join(" ") : "-"
                            }
                        ]}
                        headerSx={{
                            backgroundColor: "rgba(0, 0, 0, 0.03)"
                        }}
                        size={"small"}
                    />
                </div>
            </Collapse>
        </div>
    );
}