import React, { useState } from "react";

import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useTranslation } from "react-i18next";
import { removeEmpty } from "@helpers/utils";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useCreateSaleOrderMutation } from "@redux/features/sales/orders/salesOrdersApi";
import MutationLayout from "../components/MutationLayout";
import { isErrorWithMessage } from "@redux/api/query";
import { RefillModalData } from "@components/Dashboard/pages/Sales/Orders/types";
import LowStockRefillBanner from "@components/Dashboard/pages/Sales/Orders/CreationPage/LowStockRefillBanner";
import { formRefillModalLines } from "@components/Dashboard/pages/Sales/Orders/utils";

export default function SaleOrderCreationPage() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.creation" });

    const history = useHistory();

    const [createSaleOrder, { isLoading: isCreationLoading }] = useCreateSaleOrderMutation();

    const [refillData, setRefillData] = useState<RefillModalData>();

    return (
        <BaseLoadingBlocker active={isCreationLoading}>
            <div className="levitation-extended space-y-[40px] relative">
                <div className="flex items-center">
                    <h6 className="text-accent grow">{t("heading")}</h6>
                </div>
                <MutationLayout
                    saleOrder={undefined}
                    onSubmit={(data) => {
                        createSaleOrder(
                            removeEmpty({
                                customer: data.customer.id,
                                order_date: data.order_date.format("YYYY-MM-DD"),
                                preferred_delivery_date: data.preferred_delivery_date?.format("YYYY-MM-DD"),
                                our_reference: data.our_reference?.id,
                                their_reference: data.their_reference?.id,
                                payment_terms: data.payment_terms?.id,
                                delivery_terms: data.delivery_terms?.id,

                                language: data.language?.id,

                                ...(!!data.delivery_address && {
                                    delivery_address: data.delivery_address.id
                                }),

                                is_billing_for_delivery: data.is_billing_for_delivery,

                                orders: data.orders.map((order) => ({
                                    product: order.product.id,
                                    quantity: order.quantity,
                                    unit_price: order.unit_price,
                                    tax: order.tax?.id,
                                    discount: order.discount
                                }))
                            })
                        )
                            .unwrap()
                            .then((saleOrder) => {
                                toast.success(t("responses.success"));

                                // Getting the lines, that will be prompted to be refilled via the modal.
                                const linesToRefill = formRefillModalLines(saleOrder.orders);
                                if (linesToRefill?.length) {
                                    // This will cause the LowStockRefillBanner to be shown.
                                    setRefillData({
                                        // Order is needed for the RefillModal to allow filling needed data in the purchase order + referencing the parent SO.
                                        order: {
                                            id: saleOrder.id,
                                            code: saleOrder.code
                                        },
                                        lines: linesToRefill
                                    });
                                } else {
                                    history.push(`/dashboard/sales/orders/${saleOrder?.id}/details`);
                                }
                            })
                            .catch((e) => {
                                if (isErrorWithMessage(e)) {
                                    toast.error(e.message);
                                } else {
                                    toast.error(t("responses.error"));
                                }
                            });
                    }}
                />

                {refillData && <LowStockRefillBanner refill={refillData} />}
            </div>
        </BaseLoadingBlocker>
    );
}
