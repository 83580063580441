import React from "react";
import OutgoingLocationCell
    from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/TableBody/OutgoingLocationCell";
import { Box, Stack } from "@mui/material";
import { Control, useFieldArray } from "react-hook-form";
import BaseTable from "@reusables/BaseTable";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import { useTranslation } from "react-i18next";
import QuantityCell
    from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/TableBody/QuantityCell";
import type { PickingMutationFormTyping } from "../../types";

type TableBodyProps = {
    control: Control<PickingMutationFormTyping>;
}

export default function TableBody({control}: TableBodyProps) {
    const {t} = useTranslation("", {keyPrefix: "sales.picking.general"});

    const {fields: lines, update} = useFieldArray({
        control,
        name: "lines",
        keyName: "loop_id"
    });

    return (
        <BaseTable
            hideTableWhenNothingFound
            data={lines}
            rowKeyGetter={row => row.loop_id}
            sx={{
                "& tbody td": {
                    verticalAlign: "top",
                    "&:nth-child(-n+3)": {
                        paddingTop: "16px"
                    }
                }
            }}
            columns={[
                // Product code
                {
                    header: t("products.columns.0"),
                    getter: (row) => `${row.product.code}`
                },
                // Product name
                {
                    header: t("products.columns.1"),
                    getter: (row) => row.product.name
                },
                // Quantity ordered (with quantity left indicator)
                {
                    header: t("products.columns.2"),
                    getter: (row) => {
                        const quantityLeft = row.product.quantity - row.product.already_picked_quantity;
                        return <>
                            {row.product.quantity.toFixed(2)}
                            {
                                quantityLeft < row.product.quantity && (
                                    quantityLeft >= 0 ?
                                        <span className="text-[#D9D6DA] ml-1">
                                                        ({quantityLeft.toFixed(2)} {t("products.quantityLeftPostfix")})
                                                    </span>
                                        :
                                        <span className="text-[#D9D6DA] ml-1">
                                                        ({row.product.already_picked_quantity.toFixed(2)} {t("products.quantityPickedPostfix")})
                                                    </span>
                                )
                            }
                        </>;
                    }
                },
                // Outgoing locations
                {
                    header: t("products.columns.3"),
                    getter: (row, index) => <OutgoingLocationCell
                        index={index}
                        control={control}
                        onAdd={() => void update(index, {
                            ...row,
                            picks: [
                                ...row.picks,
                                {
                                    outgoing_location: undefined,
                                    picked_quantity: undefined
                                }
                            ]
                        })}
                    />,
                    sx: {
                        minWidth: "400px"
                    }
                },
                // Picked quantities
                {
                    header: t("products.columns.4"),
                    getter: (row, index) => <QuantityCell index={index} control={control}/>,
                    sx: {
                        width: "200px"
                    }
                },
                // Actions
                {
                    header: t("products.columns.5"),
                    getter: (row, index) =>
                        <Stack
                            spacing={"16px"}
                        >
                            {
                                row.picks.map((pick, pickIndex) => {
                                    return (
                                        <Box
                                            key={pickIndex}
                                            display="flex"
                                            alignItems="center"
                                            height={44}
                                        >
                                            {
                                                pickIndex !== 0 &&
                                                <DeleteSVG
                                                    className={"text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"}
                                                    onClick={() => update(index, {
                                                        ...row,
                                                        picks: [...row.picks.filter((_, i) => i !== pickIndex)]
                                                    })}
                                                />
                                            }
                                        </Box>
                                    );
                                })
                            }
                        </Stack>,
                    sx: {
                        width: "100px"
                    }
                }
            ]}
            headerSx={{
                background: "rgba(249, 249, 249, 1)"
            }}
            nothingFound={{
                text: t("products.noProducts"),
                height: 300
            }}
            size="small"
        />
    )
}