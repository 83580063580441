import BaseTable from "@/components/reusables/BaseTable";
import { jsxSwitch, useOrdering, usePagination } from "@/helpers/utils";
import { useGetSaleOrdersFullQuery } from "@/redux/features/sales/orders/salesOrdersApi";
import { Product, SaleOrder } from "@/types/general";
import { Pagination } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BaseChip from "@reusables/BaseChip";

interface SaleOrdersTabProperties {
    product: Product.Extended;
}

export default function SaleOrdersTab(props: SaleOrdersTabProperties) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.saleOrder" });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { orderBy, setOrderBy } = useOrdering<SaleOrder.DTO.OrderBy>({ name: "id", type: "desc" });

    const { data: salesResponse, isLoading: isSalesResponseLoading } = useGetSaleOrdersFullQuery({
        filters: {
            products: [props.product.id]
        },
        orderBy,
        pagination
    });

    return (
        <>
            <BaseTable
                isDataLoading={isSalesResponseLoading}
                data={salesResponse?.payload ?? []}
                columns={[
                    {
                        header: t("table.columns.0"),
                        getter: (row) => <Link to={`/dashboard/sales/orders/${row.id}/details`}><u
                            className={"font-semibold"}>{row.code ?? row.id}</u></Link>
                    },
                    {
                        header: t("table.columns.1"),
                        getter: (row) => <Link
                            to={`/dashboard/customers/${row.customer.id}/details`}><u>{row.customer.name}</u></Link>,
                        comparator: () => 0
                    },
                    {
                        header: t("table.columns.2"),
                        getter: (row) => <>{row.orders.filter(o => o.product.id === props.product.id).reduce((cum, curVal) => cum + curVal.quantity, 0)}</>
                    },
                    {
                        header: t("table.columns.3"),
                        getter: (row) => jsxSwitch(
                            {
                                0: <BaseChip
                                    className={"bg-[#F3F2EF]"}>{t(`table.invoicingStatuses.notInvoiced`)}</BaseChip>,
                                1: <BaseChip fill={"green"}>{t(`table.invoicingStatuses.invoiced`)}</BaseChip>
                            },
                            row.is_invoiced ? 1 : 0
                        )
                    },
                    {
                        header: t("table.columns.4"),
                        getter: (row) => <>{row.order_date.format("DD.MM.YYYY")}</>,
                        comparator: () => 0
                    }
                ]}
                manualControls={{ // TODO: @nekear how to make ordering work?

                    ordering: (newOrdering) => {
                        if (newOrdering) {
                            let name: SaleOrder.DTO.OrderBy | undefined;

                            switch (newOrdering?.index) {
                                case 1:
                                    name = "customer";
                                    break;
                                case 4:
                                    name = "date";
                                    break;
                                default:
                                    name = undefined;
                            }

                            if (name)
                                setOrderBy({
                                    name,
                                    type: newOrdering.order
                                });
                        } else {
                            setOrderBy(undefined);
                        }
                    }
                }}
                alternate
            />
            <Pagination className="mt-[32px]" {...pagination.adapt(salesResponse)} />
        </>
    );
}
