import Icon from "@mui/material/Icon";
import React, { useEffect, useState } from "react";

import "./index.scss";
import { LinearProgress } from "@mui/material";
import { cn } from "@helpers/utils";

/**
 * I have formatted shit piece of shit, so please (🙏) pay attention I AM NOT THE AUTHOR!
 */

type BaseButtonType = {
    text: string;
    color?: string;
    textColor?: string;
    size: string;
    type?: "submit" | "reset" | "button";
    buttonWidth?: string;
    icon?: string;
    ownIcon?: boolean;
    iconPos?: string;
    loading?: boolean;
    loaderType?: "linear";

    onClick?: (e: any) => void;

    height?: string;

    margin?: string;
    noPadding?: boolean;

    className?: string;

    primaryOutlined?: boolean;
    secondary?: boolean;

    disabled?: boolean;
    elRef?: React.LegacyRef<HTMLButtonElement>
    style?: Record<string, any>
}

const BaseButton = ({
                        text,
                        color,
                        textColor,
                        size,
                        type,
                        buttonWidth,
                        icon,
                        ownIcon,
                        iconPos,
                        loading,
                        onClick,
                        height,
                        margin,
                        noPadding,
                        className,
                        primaryOutlined,
                        secondary,
                        disabled,
                        elRef,
                        style,
                        loaderType = "linear"
                    }: BaseButtonType) => {

    const [padding, setPadding] = useState("");
    const [fontSize, setFontSize] = useState("");

    useEffect(() => {
        if (size == "lg") {
            setPadding("20px 40px");
            setFontSize("20px");
        }

        if (size == "md") {
            setPadding("16px 32px");
            setFontSize("16px");
        }

        if (size == "sm") {
            setPadding("14px 28px");
            setFontSize("14px");
        }
    }, []);

    return (
        <button
            ref={elRef}
            className={cn(`relative overflow-hidden button-box btn btn-filled`, (loading || disabled) && "disabled", className)}
            style=
                {{
                    background: color ? color : (primaryOutlined ? "white" : (secondary ? "transparent" : "#7556FA")),
                    color: textColor ? textColor : (primaryOutlined ? "#7556FA" : (secondary ? "#3C3769" : "white")),
                    fontSize: fontSize,
                    padding: noPadding ? "" : padding,
                    margin: margin ?? "",
                    width: buttonWidth,

                    height: height,
                    border: primaryOutlined ? "1px solid #7556FA" : (secondary ? "1px solid #3C3769" : ""),

                    // backgroundImage: secondary ? 'linear-gradient(90.89deg, #2B2A35 0.67%, #6959FC 99.24%) 1' : ''
                    ...style
                }}
            type={type}
            disabled={disabled}
            onClick={onClick ?? onClick}
        >
            {
                iconPos == "left" && !ownIcon && icon ? <Icon>{icon}</Icon> : ""
            }

            {
                iconPos == "left" && ownIcon ? <img src={icon} alt="" /> : ""
            }
            <div className="mx-1">
                {text}
            </div>


            {
                iconPos == "right" && !ownIcon && icon ? <Icon>{icon}</Icon> : ""
            }

            {
                iconPos == "right" && ownIcon ? <img src={icon} alt="" /> : ""
            }

            {
                loaderType === "linear" && loading &&
                <div className={"absolute top-0 left-0 w-full h-full opacity-20"}>
                    <LinearProgress sx={{ height: "100%" }} />
                </div>
            }
        </button>
    );
};

export default BaseButton;