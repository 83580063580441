import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { Industry } from "@/types/general";

export const industriesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getIndustries: builder.query<Industry.Root[], void>({
            query: () => ({
                url: `industry`,
            }),
            transformResponse
        }),
    })
});

export const {
    useGetIndustriesQuery
} = industriesApi;