import React from "react";
import {Supplier} from "@/types/general";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {useTranslation} from "react-i18next";

export default function GeneralTabPreview({ supplier }: { supplier: Supplier.Extended }) {
    const { t } = useTranslation("", { keyPrefix: "suppliers.general.tabs.general" });

    return (
      <div className="space-y-[40px]">
          <BaseInputsGrid>
              <InputLikeContainer
                label={t("fields.supplierName.label")}
                content={supplier.name}
              />

              <InputLikeContainer
                label={t("fields.supplierCode.label")}
                content={supplier.code}
              />

              <InputLikeContainer
                label={t("fields.registrationNumber.label")}
                content={supplier.vat}
              />
          </BaseInputsGrid>

          {
              supplier.contacts.map((contact, index) =>
                <div key={index} className="space-y-[24px]">
                    {
                        index === 0 ?
                          <div>
                              <div className="text-xl text-accent">{t("categories.contactInfo")}</div>
                          </div>
                          :
                          <div>
                              <div className="text-xl text-accent">{t("categories.additionalContactInfo")}</div>
                          </div>
                    }
                    <div className="grid grid-cols-3 gap-x-[40px]">
                        <InputLikeContainer
                          label={t("fields.contactName.label")}
                          content={contact.name}
                        />

                        <InputLikeContainer
                          label={t("fields.phone.label")}
                          content={contact.phone}
                        />

                        <InputLikeContainer
                          label={t("fields.email.label")}
                          content={contact.email}
                        />
                    </div>
                </div>
              )
          }
      </div>
    );
}

function InputLikeContainer({ label, content }: { label: string, content: JSX.Element | string | undefined | number }) {
    return (
      <div>
          <div className="font-semibold text-inputs-label-dim mb-[8px]">{label}</div>
          <div className="text-accent">
              {content ?? "-"}
          </div>
      </div>
    );
}