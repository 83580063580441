import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {
    PurchaseOrdersFormTyping
} from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/types";
import {calculateOrderPrice, normalizePrice, smartParseFloat} from "@helpers/utils";

export default function TotalCell({index, lines_number}: { index: number, lines_number: number }) {
    const {control} = useFormContext<PurchaseOrdersFormTyping>();

    const orderLine = useWatch({
        control,
        name: `orders.${index}`
    });

    const shipping_cost = useWatch({
        control,
        name: "shipping_cost"
    });

    const split_by_products = useWatch({
        control,
        name: "is_split_by_products"
    });

    const calculatedValue = calculateOrderPrice(orderLine.quantity ?? 0, smartParseFloat(orderLine.unit_price.converted) ?? 0, orderLine.discount ?? 0)
        + (split_by_products ? (smartParseFloat(shipping_cost) ?? 0) / lines_number : 0);

    return <>
        {
            normalizePrice(calculatedValue)
        }
    </>
}