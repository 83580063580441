import React, {useMemo} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {
    PurchaseOrdersFormTyping
} from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/types";
import {calculateOrderPrice, normalizePrice, smartParseFloat} from "@helpers/utils";

export default function SummaryBlock() {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general.orders"});

    const {control} = useFormContext<PurchaseOrdersFormTyping>();

    const orders = useWatch({
        control,
        name: "orders"
    });

    const shippingCost = useWatch({
        control,
        name: "shipping_cost"
    });

    const splitByProducts = useWatch({
        control,
        name: "is_split_by_products"
    });

    const ordersSummary = useMemo(() => {
        let subtotal = 0;
        let taxes = 0;

        orders.forEach(order => {
            const orderPrice = calculateOrderPrice(order.quantity ?? 0, order.unit_price.converted ?? 0, order.discount ?? 0);
            subtotal += orderPrice;
            taxes += orderPrice * ((order.tax?.rate ?? 0) / 100);
        });

        const shipping = smartParseFloat(shippingCost) ??  0;
        const totalWithTaxes = subtotal + taxes + shipping;

        return {
            subtotal,
            taxes,
            shipping,
            totalWithTaxes
        }
    }, [orders, shippingCost]);

    return (
        <div className="flex justify-end mt-[48px] font-thin">
            <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                <div className="text-right">
                    {t("summary.subtotal")}
                </div>
                <div className="text-right pr-[16px]">
                    {
                        normalizePrice(ordersSummary.subtotal)
                    }
                </div>
                <div className="text-right">
                    {t("summary.taxes")}
                </div>
                <div className="text-right pr-[16px]">
                    {
                        normalizePrice(ordersSummary.taxes)
                    }
                </div>

                {
                    !splitByProducts &&
                    (
                        <>
                            <div className="text-right animate-in fade-in">
                                {t("summary.shipping")}
                            </div>
                            <div className="text-right pr-[16px] animate-in fade-in">
                                {
                                    normalizePrice(ordersSummary.shipping)
                                }
                            </div>
                        </>
                    )
                }

                <div className="text-right font-semibold text-accent">
                    {t("summary.total")}
                </div>
                <div className="text-right pr-[16px] font-semibold text-accent">
                    {
                        normalizePrice(ordersSummary.totalWithTaxes)
                    }
                </div>
            </div>
        </div>
    )
}