import { z } from "zod";
import dayjs, { Dayjs } from "dayjs";
import { refinements } from "@helpers/refinements";
import i18n from "@/i18n";

const availableServiceScheme = z.object({
    id: z.number(),
    name: z.string(),
    code: z.string()
});

const formScheme = z.object({
    extractedFromTransit: z.boolean(),

    customer: z.object({
        id: z.number(),
        code: z.string().nullish(),
        name: z.string()
    }),

    sale_order: z
        .object({
            id: z.number(),
            code: z.string().nullish()
        })
        .readonly(),

    order_destination_country: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string()
    }).nullish(),

    picking_date: z.object({
        date: z.instanceof(dayjs as unknown as typeof Dayjs).refine(refinements.validDate.refine, refinements.validDate.message)
    }),

    lines: z
        .array(
            z
                .object({
                    id: z.number(),
                    product: z.object({
                        id: z.number(),
                        name: z.string(),
                        code: z.string(),
                        is_service: z.boolean(),
                        is_serial_numbers: z.boolean().nullish(),
                        is_batch_numbers: z.boolean().nullish(),
                        quantity: z.number(),
                        already_picked_quantity: z.number(),

                        locations: z.array(
                            z.object({
                                location_id: z.number(),
                                sub_location_id: z.number().nullable().optional(),
                                location_name: z.string(),
                                section_name: z.string().nullable().optional(),
                                in_stock: z.number()
                            })
                        )
                    }),
                    picked_quantity: z.coerce.number().min(0).optional(), // used for services, since they don't require locations
                    picks: z.array(
                        z
                            .object({
                                outgoing_location: z
                                    .object({
                                        location_id: z.number(),
                                        sub_location_id: z.number().nullable().optional(),
                                        location_name: z.string(),
                                        section_name: z.string().nullable().optional(),
                                        in_stock: z.number()
                                    })
                                    .optional(),
                                picked_quantity: z.coerce.number().positive().optional()
                            })
                            .refine(
                                (scheme) => {
                                    return (scheme.picked_quantity ?? 0) <= (scheme.outgoing_location?.in_stock ?? 0);
                                },
                                {
                                    message: "Picked quantity cannot be greater than the in stock limit",
                                    path: ["picked_quantity"]
                                }
                            )
                            .superRefine((scheme, ctx) => {
                                if (scheme.outgoing_location && !scheme.picked_quantity) {
                                    ctx.addIssue({
                                        code: z.ZodIssueCode.custom,
                                        message: "Required (if location is selected)",
                                        path: ["picked_quantity"]
                                    });
                                } else if (scheme.picked_quantity && !scheme.outgoing_location) {
                                    ctx.addIssue({
                                        code: z.ZodIssueCode.custom,
                                        message: "Required",
                                        path: ["outgoing_location"]
                                    });
                                }
                            })
                    ),

                    serial_numbers: z.array(
                        z.object({
                            serial_number: z.string()
                        })
                    ),

                    batch_numbers: z.array(
                        z.object({
                            batch_number: z.string(),
                            expiration_date: z
                                .instanceof(dayjs as unknown as typeof Dayjs)
                                .refine((value) => value.isValid(), {
                                    message: "Invalid date"
                                })
                                .optional()
                        })
                    )
                })
                .superRefine((scheme, ctx) => {
                    if (scheme.product.is_service && (scheme.picked_quantity ?? 0) > scheme.product.quantity) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Picked quantity cannot be greater than the product's quantity",
                            path: ["picked_quantity"]
                        });
                    }
                })
        )
        .refine(
            (scheme) => {
                return scheme.some((line) => {
                    return line.picked_quantity || line.picks.some((pick) => pick.picked_quantity && pick.outgoing_location);
                });
            },
            {
                message: "You should pick at least one product"
            }
        )
        .superRefine((lines, ctx) => {
            lines.forEach((line, index) => {
                const totalPicked = line.picks.reduce((total, pick) => total + (pick.picked_quantity || 0), 0);
                if (totalPicked > line.product.quantity - line.product.already_picked_quantity) {
                    line.picks.forEach((pick, pickIndex) => {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Total picked quantity cannot exceed the product's left quantity",
                            path: [index, "picks", pickIndex, "picked_quantity"]
                        });
                    });
                }
            });
        })
        .superRefine((scheme, ctx) => {
            // Filter lines that either have serial numbers or batch numbers
            // and also have at least one pick with a defined outgoing location and picked quantity.
            const filteredLines = scheme.filter(({ product, picks }) => {
                const { is_serial_numbers, is_batch_numbers } = product;
                return (is_serial_numbers || is_batch_numbers) && picks.some(({
                                                                                  outgoing_location,
                                                                                  picked_quantity
                                                                              }) => outgoing_location && picked_quantity);
            });

            // Check if there are any lines with serial number errors
            const hasSerialError = filteredLines.some(({ product, serial_numbers, picks }) => {
                if (!product.is_serial_numbers) return false;

                const totalPickedQuantity = picks.reduce((acc, { picked_quantity }) => acc + (picked_quantity ?? 0), 0);
                return serial_numbers.length !== Math.ceil(totalPickedQuantity);
            });

            // Check if there are any lines with batch number errors
            const hasBatchError = filteredLines.some(({ product, batch_numbers, picks }) => {
                if (!product.is_batch_numbers) return false;

                const totalPickedQuantity = picks.reduce((acc, { picked_quantity }) => acc + (picked_quantity ?? 0), 0);

                return batch_numbers.length == 0 || batch_numbers.length > Math.ceil(totalPickedQuantity);
            });

            // Add an issue if serial number errors are found
            if (hasSerialError) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Serial numbers are filled incorrectly!",
                    path: ["serial_numbers"]
                });
            }

            // Add an issue if batch number errors are found
            if (hasBatchError) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Batch numbers are filled incorrectly!",
                    path: ["batch_numbers"]
                });
            }
        }),
    packages: z.array(
        z.object({
            package: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string().nullish()
            }),
            quantity: z.number()
        })
    ).optional(),

    shipmondo: z.object({
        enabled: z.boolean(),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).optional(),
        carrier: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).optional(),
        product: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string(),
            available_services: z.array(availableServiceScheme)
        }).optional(),
        services: z.array(availableServiceScheme).optional()
    }).superRefine((scheme, ctx) => {
        if (scheme.enabled && !scheme.product) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("general.validation.global.required"),
                path: ["product"]
            });
        }
        if (scheme.enabled && (!scheme.services || scheme.services.length === 0)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("general.validation.global.required"),
                path: ["service"]
            });
        }
    })
}).superRefine((scheme, ctx) => {
    if (scheme.shipmondo?.enabled && (!scheme.packages || scheme.packages.length === 0)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("sales.picking.general.fields.packaging.validation.shipmondoRequirement"),
            path: ["packages"]
        });
    }

    if (scheme.shipmondo?.enabled) {
        if (!scheme.order_destination_country) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("general.validation.global.required"),
                path: ["order_destination_country"]
            });
        }
    }
});

export { formScheme };
export type PickingMutationFormTyping = z.infer<typeof formScheme>;
