import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {calculateOrderPrice, normalizePrice} from "@helpers/utils";
import {SaleOrdersFormTyping} from "../../types";

export default function TotalCell({index}: { index: number }) {
    const {control} = useFormContext<SaleOrdersFormTyping>();

    const orderLine = useWatch({
        control,
        name: `orders.${index}`
    });

    return <>
        {
            normalizePrice(calculateOrderPrice(orderLine.quantity ?? 0, orderLine.unit_price ?? 0, orderLine.discount ?? 0))
        }
    </>
}