import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetPurchaseOrderQuery } from "@redux/features/purchases/purchasesApi";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import ViewLayout from "../components/ViewLayout";

import PurchaseOrderDeletionModal
    from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/modals/DeletePurchaseOrder";

import { Can } from "@/casl.config";
import { BaseTinyActionIcon, TinyActionIconType } from "@reusables/BaseTinyActionIcon";
import { useExportOrderMutation } from "@redux/api/internalApiSlice";
import { downloadBlobExport } from "@helpers/utils";
import { Stack } from "@mui/material";
import { useAppDispatch } from "@redux/hooks";
import { handleQuickReceive } from "@redux/features/receipts/thunks";

export default function PurchaseDetailsPage() {
    const history = useHistory();
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.mutations.details"});

    const dispatch = useAppDispatch();

    const {id} = useParams<{ id: string }>();

    const {data: purchaseOrder, isLoading: isPurchaseOrderLoading} = useGetPurchaseOrderQuery(parseInt(id));

    const [isDeletionModalOpen, setIsDeletionModalOpen] = React.useState<boolean>(false);

    const [exportPDF, {isLoading: isExportLoading}] = useExportOrderMutation();

    return (
        <>
            <BaseRecordRenderer
                record={purchaseOrder}
                loading={isPurchaseOrderLoading || isExportLoading}
                hideLevitation
            >
                <div className="space-y-[40px] mb-[24px] levitation-extended">
                    <div className="flex items-center">
                        <h6 className="text-accent grow">{t("heading")} #{purchaseOrder?.code ?? purchaseOrder?.id}</h6>
                        <Stack direction={"row-reverse"} spacing={4} alignItems={"center"}>
                            <div className="space-x-[8px] flex items-center h-full">
                                <BaseTinyActionIcon
                                    type={TinyActionIconType.PDF}
                                    onClick={() =>
                                        exportPDF({id: purchaseOrder?.id as number, type: "purchase"})
                                            .unwrap()
                                            .then(data => {
                                                downloadBlobExport(() => data, `purchase_order_${purchaseOrder?.code ?? purchaseOrder?.id}`, "pdf");
                                            })
                                    }
                                />
                                <Can I="create" a="receive">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.StartReceiving}
                                        onClick={() => {
                                            if (purchaseOrder?.id) {
                                                dispatch(handleQuickReceive(purchaseOrder.id, history))
                                            }
                                        }}
                                    />
                                </Can>
                                <Can I="update" a="purchase_order">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Edit}
                                        onClick={() => void history.push(`/dashboard/purchasing/${purchaseOrder?.id}/edit`)}
                                    />
                                </Can>
                                <Can I="delete" a="purchase_order">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Delete}
                                        onClick={() => setIsDeletionModalOpen(true)}
                                    />
                                </Can>
                            </div>
                            <div id="reference_number"/>
                        </Stack>
                    </div>
                    <ViewLayout.Main
                        purchaseOrder={purchaseOrder as NonNullable<typeof purchaseOrder>}
                    />
                </div>
                <ViewLayout.Receipts
                    purchaseOrder={purchaseOrder as NonNullable<typeof purchaseOrder>}
                />
            </BaseRecordRenderer>

            <PurchaseOrderDeletionModal
                isOpen={isDeletionModalOpen}
                entity_id={parseInt(id)}
                onClose={() => setIsDeletionModalOpen(false)}
                onDeletion={() => history.push("/dashboard/purchasing")}
            />
        </>
    );
}
