import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import {useGetReceiptQuery} from "@redux/features/receipts/receiptsApi";
import DeletionModal from "../components/modals/DeletionModal";
import ViewLayout from "../components/ViewLayout";
import {AbilityContext, Can} from "@/casl.config";
import {useAbility} from "@casl/react";
import {BaseTinyActionIcon, TinyActionIconType} from "@reusables/BaseTinyActionIcon";

export default function ReceiveViewPage() {

  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: "receive.details" });

  const {id} = useParams<{id: string}>();

  const ability = useAbility(AbilityContext);

  const {data: receipt, isLoading: isReceiptLoading} = useGetReceiptQuery(parseInt(id));

  const [isDeletionModalOpen, setIsDeletionModalOpen] = React.useState<boolean>(false);

  return(
    <>
      <BaseRecordRenderer
        record={receipt}
        loading={isReceiptLoading}
      >
        <div className="space-y-[40px]">
          <div className="flex items-center">
            <h6 className="text-accent grow">{t("heading")} #{receipt?.code ?? receipt?.id}</h6>
            <div className="space-x-[8px] flex items-center h-full">
              <Can I="update" a="receive">
                <BaseTinyActionIcon
                  type={TinyActionIconType.Delete}
                  onClick={() => setIsDeletionModalOpen(true)}
                />
              </Can>
            </div>
          </div>
          <ViewLayout receipt={receipt as NonNullable<typeof receipt>} />
        </div>
      </BaseRecordRenderer>

      <DeletionModal
        isOpen={isDeletionModalOpen}
        entity_id={parseInt(id)}
        onClose={() => setIsDeletionModalOpen(false)}
        onDeletion={() => history.push("/dashboard/receive")}
      />
    </>
  );
}