import React from 'react'
import BaseButton from '@reusables/BaseButton';
import BaseModal from '@reusables/Modals/BaseModal';
import { isErrorWithMessage } from '@redux/api/query';
import { setIntegrationStatus } from '@redux/features/auth/authSlice';
import { useDetachIntegrationMutation } from '@redux/features/integrations/integrationsApi';
import { useAppDispatch } from '@redux/hooks';
import { Integration } from '@/types/general';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface DisableModalProps {
    isOpen: boolean;
    onClose: () => void;
    slug?: Integration.Components.Slug;
}

export default function DisableModal({ isOpen, onClose, slug}: DisableModalProps) {

    const { t } = useTranslation("", { keyPrefix: "settings.integrations.thirdParty.modals.disable" });

    const dispatch = useAppDispatch();

    const [disableIntegration, { isLoading: isDisablingLoading }] = useDetachIntegrationMutation();

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={800}
            padding="45px"
            useCloseIcon
            isLoading={isDisablingLoading}
        >
            <div className="space-y-8">
                <div className="text-xl font-semibold text-center text-accent mb-4">
                    {t("heading", { slug })}
                </div>
                <div>
                    <div className="text-center text-accent mb-2">
                        <Trans i18nKey="settings.integrations.thirdParty.modals.disable.subheading" values={{ slug }} components={{ strong: <span className="font-semibold" /> }} />
                    </div>
                    <div className="text-center text-accent">
                        {t("warning")}
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={onClose} />
                    <BaseButton text={t("buttons.disable")} size="md" onClick={() => {
                        if (slug) {
                            disableIntegration(slug)
                            .unwrap()
                            .then(() => {
                                toast.success(t("responses.success"));
                                dispatch(setIntegrationStatus({
                                    slug,
                                    integrated: false
                                }))
                            })
                            .catch((e) => {
                                if (isErrorWithMessage(e)) {
                                    toast.error(e.message);
                                } else {
                                    toast.error(t("responses.error"));
                                }
                            })
                            .finally(() => onClose());
                        }
                    }} />
                </div>
            </div>
        </BaseModal>
    )
}
