import { Permission } from "@/types/general";

export class LinksGroup{
  private readonly _title: string;
  private readonly _links: Link[];

  private readonly _hidden: boolean;

  constructor(title: string, links: Link[], hidden?: boolean) {
    this._title = title;
    this._links = links;
    this._hidden = hidden ?? false;
  }

  get title(): string {
    return this._title;
  }

  get links(): Link[] {
    return this._links;
  }

  get isHidden(): boolean {
    return this._hidden;
  }
}

export class Link{
  private readonly _text: string;
  private readonly _link: string;

  private readonly _hidden: boolean;


  constructor(text: string, link: string, hidden?: boolean) {
    this._text = text;
    this._link = link;
    this._hidden = hidden ?? false;
  }

  static createWithPermission(
    text: string, 
    link: string, 
    ability: any, 
    moduleKey?: Permission.Components.Modules, 
    actions: Permission.Components.Ability[] = ["index"]
  ): Link {
    const hidden = !actions.some(action => ability.can(action, moduleKey));
    return new Link(text, link, hidden);
  }

  get text(): string {
    return this._text;
  }

  get link(): string {
    return this._link;
  }

  get isHidden(): boolean {
    return this._hidden;
  }
}