import React, { useMemo, useState } from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { Trans, useTranslation } from "react-i18next";
import BaseCheckbox from "@reusables/BaseCheckbox";
import BaseButton from "@reusables/BaseButton";
import { getDifference } from "@components/Dashboard/pages/Inventory/Adjustments/logic";
import { cn } from "@helpers/utils";

interface SellingPriceSuggestionModal {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    selling_price?: number;
    purchase_prices?: {
        old: number;
        new: number;
    };
    onUpdate?: (value: number) => void;
    onDecline?: () => void;
}

export default function SellingPriceSuggestionModal({
                                                        purchase_prices = {
                                                            old: 0,
                                                            new: 0
                                                        },
                                                        selling_price = 0,
                                                        ...props
                                                    }: SellingPriceSuggestionModal) {
    const { t } = useTranslation("", { keyPrefix: "inventory.adjustments.modals.sellingPriceSuggestion" });

    const [selectedOption, setSelectedOption] = useState<"price" | "percent">();

    const handleOptionSelection = (value: "price" | "percent") => {
        setSelectedOption(value);
    };

    /**
     * Calculate the difference between the old and new purchasing prices both in discrete value and percent relation
     */
    const purchasingPriceDifference = useMemo(() => {
        const discrete = getDifference(purchase_prices.old, purchase_prices.new);

        return {
            discrete,
            percent: parseFloat((purchase_prices.new / purchase_prices.old).toFixed(4))
        };
    }, [purchase_prices]);

    /**
     * Calculate the resulting prices for both options
     */
    const resultingPrices = {
        price: parseFloat((purchasingPriceDifference.discrete + selling_price).toFixed(2)),
        percent: parseFloat((purchasingPriceDifference.percent * selling_price).toFixed(2))
    };

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={900}
            padding="56px"
            useCloseIcon
            isLoading={props.isLoading}
        >
            <div className={"space-y-[32px]"}>
                <div className="text-xl font-semibold text-center text-accent">
                    {t("heading")}
                </div>
                <div className={"text-accent text-center font-light"}>
                    <Trans i18nKey={"prompt"}
                           t={t}
                           components={{
                               b: <div className={"font-[400]"} />
                           }}
                    />
                </div>
                <div className={"space-y-[16px] text-accent"}>
                    <div className={"text-center font-[600]"}>
                        {t("currentSellingPrice", { current: selling_price })}
                    </div>
                    <div className={cn("flex flex-row", resultingPrices.price < 0 && "pointer-events-none opacity-30")}>
                        <BaseCheckbox
                            label={
                                <span>
                                    <span className={"font-light mr-2"}>{t("options.addedPrice")}</span>
                                    <span className={"font-[600]"}>
                                        ({t("newPrice")}: {resultingPrices.price})
                                    </span>
                                </span>
                            }
                            value={selectedOption === "price"}
                            onChange={() => void handleOptionSelection("price")}
                        />
                    </div>
                    <div className={cn("flex flex-row", resultingPrices.percent < 0 && "pointer-events-none opacity-30")}>
                        <BaseCheckbox
                            label={
                                <div>
                                    <span className={"font-light mr-2"}>{t("options.percent", {percent: purchasingPriceDifference.percent * 100})}</span>
                                    <span className={"font-[600]"}>
                                        ({t("newPrice")}: {resultingPrices.percent})
                                    </span>
                                </div>
                            }
                            value={selectedOption === "percent"}
                            onChange={() => void handleOptionSelection("percent")}
                        />
                    </div>
                </div>
                <div className={"mt-8 grid grid-cols-2 gap-2"}>
                    <BaseButton
                        buttonWidth="100%"
                        text={t("buttons.decline")}
                        size="md"
                        type="button"
                        primaryOutlined
                        onClick={props.onDecline}
                    />
                    <BaseButton
                        buttonWidth="100%"
                        text={t("buttons.update")}
                        size="md"
                        type="submit"
                        disabled={!selectedOption}
                        onClick={() => {
                            props.onUpdate?.(selectedOption === "price" ? resultingPrices.price : resultingPrices.percent);
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    );
}