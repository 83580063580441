import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { ProductSchemeTyping } from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";
import { Link2 } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import { Fade, Stack } from "@mui/material";
import BaseSwitch from "@reusables/BaseSwitch";
import { cn } from "@helpers/utils";

export default function IsServiceCheckbox() {
    const { t } = useTranslation("", { keyPrefix: "products.general.fields.isService" });

    const { control } = useFormContext<ProductSchemeTyping>();

    const { field, fieldState } = useController({
        name: "is_service",
        control
    });

    const category = useWatch({
        name: "general.category",
        control
    });

    useEffect(() => {
        if (category?.is_service) {
            field.onChange(true);
        }
    }, [category]);

    return (
        <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            className={cn(
                "cursor-pointer px-[16px] py-[8px] rounded-[8px] " + "border-[1px] border-solid border-inputs-border-default hover:bg-inputs-border-default !bg-opacity-30 transition-[.25s]",
                field.value && "border-purple-400"
            )}
            onClick={() => {
                field.onChange(!field.value);
            }}
        >
            <BaseSwitch checked={field.value} onChange={field.onChange} />
            <div>{t("label")}</div>

            <Fade in={category?.is_service && field.value}>
                <div>
                    <BaseTooltip title={t("obtainedFromCategory") + ""}>
                        <div className="bg-[#D9DAFD] w-[20px] h-[20px] rounded-full flex justify-center items-center transition-all hover:bg-lightGreen-400 cursor-pointer">
                            <Link2 size={14} color={"#fff"} />
                        </div>
                    </BaseTooltip>
                </div>
            </Fade>
        </Stack>
    );
}
