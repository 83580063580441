import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ArrayElementType,
    createPagination,
    getCountryFlag,
    isFilledValue,
    manualRequest,
    PartialBy,
    removeEmpty,
    updateObjectsArray
} from "@helpers/utils";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseButton from "@reusables/BaseButton";
import BaseInputLegacy from "@reusables/BaseInputLegacy";
import BaseDropdown from "@reusables/dropdowns/BaseDropdownLegacy";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";
import { Alphabetical, Email, NotEmpty, Street } from "@reusables/Validator/types";
import BaseValidationManager from "@reusables/Validator";
import { validate, ValidationStore } from "@reusables/Validator/validationStore";
import Fade from "@mui/material/Fade/Fade";
import BaseTable from "@reusables/BaseTable";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import { Location } from "@/types/general";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import BaseModal from "@reusables/Modals/BaseModal";
import { useGetCountriesQuery } from "@redux/api/internalApiSlice";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { ReactComponent as PlusSVG } from "@assets/icons/ic_plus.svg";

import BasePagination from "@reusables/BasePagination";
import { Stack } from "@mui/material";
import _ from "lodash";
import SearchInput from "@components/Dashboard/pages/SettingsPage/General/Locations/components/SearchInput";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";

type EditingLayoutProperties = {
    location: Location.Extended | undefined,
    buttonText: string,
    onLocationUpdate?: (location: Location.Extended) => void
}

export default function EditingLayout({ location, buttonText, onLocationUpdate }: EditingLayoutProperties) {
    const history = useHistory();

    const { t } = useTranslation("", { keyPrefix: "settings.general.locations.createEditLocation" });
    const { t: generalT } = useTranslation();

    // =========== DROPDOWNS RELATED =========== //
    // ---> Country selection <--- //
    const { data: countriesOptions, isLoading: countriesOptionsLoading } = useGetCountriesQuery();
    const [selectedCountry, setSelectedCountry] = useState<ArrayElementType<typeof countriesOptions>>();

    // =========== OTHER INPUTS RELATED =========== //
    const [name, setName] = useState<string>("");
    const [city, setCity] = useState<string>();
    const [street, setStreet] = useState<string>();
    const [zipcode, setZipcode] = useState<string>();
    const [contactName, setContactName] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [email, setEmail] = useState<string>();

    const [isWithSections, setIsWithSections] = useState<boolean>(false); // visual trigger

    const [sections, setSections] = useState<PartialBy<Location.Section.Extended, "id">[]>([]);

    const [validationStore, setValidationStore] = useState<ValidationStore>();
    const [tableSectionsWithEmptyNames, setTableSectionsWithEmptyNames] = useState<number[]>([]);
    const [isOperationLoading, setIsOperationLoading] = useState<boolean>(false);
    const [activeSectionDeletion, setActiveSectionDeletion] = useState<Location.Section.Extended>();

    const setValues = (location?: Location.Extended) => {
        if (location) {
            setName(location.store);
            setCity(location.city);
            setStreet(location.street);
            setZipcode(location.zipcode);
            setContactName(location.contactName);
            setPhoneNumber(location.phoneNumber);
            setEmail(location.email);
            setSections(location.sections?.sort(((a, b) => b.id - a.id)) ?? []);
            setIsWithSections(!!location.sections);
        }
    };

    useEffect(() => {
        setValues(location);
    }, [location]);

    useEffect(() => {
        if (location && !selectedCountry) {
            setSelectedCountry(countriesOptions?.filter(country => country.name.localeCompare(location.country) === 0)[0]);
        }
    }, [countriesOptions]);

    const pagination = createPagination();

    useEffect(() => {
        pagination.perPage.setElementsPerPage(5);
    }, []);


    const [search, setSearch] = useState<string>("");
    const debouncedSearch = useRef(_.debounce(setSearch, 1000));

    const filteredSections = useMemo(() => sections.filter(section => {
        const lowerCaseSearch = search.toLowerCase();
        return section.name.toLowerCase().includes(lowerCaseSearch) ||
            section.sector?.toString().toLowerCase().includes(lowerCaseSearch) ||
            section.row?.toString().toLowerCase().includes(lowerCaseSearch) ||
            section.shelf_height?.toLowerCase().includes(lowerCaseSearch);
    }), [sections, search]);


    useEffect(() => {
        pagination.calculate(filteredSections);
    }, [filteredSections]);

    return (
        <BaseLoadingBlocker active={isOperationLoading}>
            <div className="space-y-[40px]">
                <BaseValidationManager onStoreInit={store => setValidationStore(store)}>
                    <BaseInputsGrid>
                        <BaseInputLegacy label={t("location.fields.locationName.label") + " *"} value={name}
                                         onChange={val => setName(val)} constraints={[new NotEmpty()]} />
                        <BaseDropdown
                            label={t("location.fields.country.label") + " *"}
                            placeholder={t("location.fields.country.placeholder")}
                            options={countriesOptions}
                            value={selectedCountry}
                            getter={{
                                label: (opt) => opt.name,
                                key: (opt) => opt.name,
                                renderOption: (opt, icon) => (
                                    <div>
                                        <img src={getCountryFlag(opt.code)} width={20} alt={opt.name + " flag"} />
                                        <span className="ml-[8px] grow">{opt.name}</span>
                                        {icon}
                                    </div>
                                )
                            }}

                            onChange={(_, sel) => setSelectedCountry(sel)}

                            constraints={[new NotEmpty()]}

                            autocomplete
                        />

                        <BaseInputLegacy label={t("location.fields.city.label")} value={city}
                                         onChange={val => setCity(val)} constraints={[new Alphabetical()]} />

                        <BaseInputLegacy label={t("location.fields.street.label")} value={street}
                                         onChange={val => setStreet(val)} constraints={[new Street()]} />

                        <BaseInputLegacy label={t("location.fields.zipCode.label")} value={zipcode}
                                         onChange={val => setZipcode(val)} />

                        <BaseInputLegacy label={t("location.fields.contactName.label")} value={contactName}
                                         onChange={val => setContactName(val)} />

                        <BaseInputLegacy label={t("location.fields.phoneNumber.label")} value={phoneNumber}
                                         onChange={val => setPhoneNumber(val)} />

                        <BaseInputLegacy label={t("location.fields.email.label")} value={email}
                                         onChange={val => setEmail(val)} constraints={[new Email()]} />

                        <div>
                            <div
                                className="font-semibold text-inputs-label-dim mb-[24px]">{t("location.fields.options.label")}</div>

                            <BaseCheckbox label={`${t("location.fields.options.sectionsOption")}`}
                                          checked={isWithSections}
                                          onChange={(checked) => {
                                              setIsWithSections(checked);
                                              if (checked && !sections.length) {
                                                  setSections([{ name: "" }]);
                                              }
                                          }} />
                        </div>
                    </BaseInputsGrid>

                    <Fade in={isWithSections}>
                        <div style={{ display: isWithSections ? "block" : "none" }}>
                            <div className="flex flex-row justify-between items-center">
                                <div className="text-xl text-accent">{t("sections.title")}</div>
                                <Stack direction={"row"} spacing={2}>
                                    <SearchInput onSearch={debouncedSearch.current} />
                                    <div>
                                        <BaseMaterialIconButton
                                            icon={<PlusSVG />}
                                            onClick={() => setSections(prevState => [{ name: "" }, ...prevState])}
                                        />
                                    </div>
                                </Stack>
                            </div>
                            <BaseTable
                                pagination={pagination}
                                data={filteredSections}
                                columns={[
                                    {
                                        header: "",
                                        getter: (section, index) => {
                                            const currentIndex = ((pagination.page.currentPage-1) * pagination.perPage.elementsPerPage) + index;

                                            return <>
                                                <BaseInputLegacy
                                                    label={t("sections.fields.name.label") + " *"}
                                                    value={section.name}
                                                    onChange={val => {
                                                        setSections(updateObjectsArray(
                                                            sections,
                                                            (section, localIndex) => currentIndex === localIndex,
                                                            prevState => ({ ...prevState, name: val })
                                                        ));

                                                        setTableSectionsWithEmptyNames(prevState => prevState.filter(el => el != currentIndex));
                                                    }}
                                                    containerClasses="text-base"
                                                    externalErrors={tableSectionsWithEmptyNames.includes(currentIndex) ? [new NotEmpty().setHideMessage()] : undefined}
                                                />
                                            </>
                                        },
                                        sx: { paddingLeft: 0 }
                                    },
                                    {
                                        header: "",
                                        getter: (section, index) => <BaseInputLegacy
                                            label={t("sections.fields.sector.label")}
                                            value={`${section.sector}`}
                                            onChange={val => setSections(updateObjectsArray(
                                                sections,
                                                (section, localIndex) => index === localIndex,
                                                prevState => ({
                                                    ...prevState,
                                                    sector: parseInt(val)
                                                })
                                            ))}
                                            type="number"
                                            containerClasses="text-base" />
                                    },
                                    {
                                        header: "",
                                        getter: (section, index) => <BaseInputLegacy
                                            label={t("sections.fields.row.label")}
                                            value={section.row?.toString() ?? ""}
                                            onChange={val => setSections(updateObjectsArray(
                                                sections,
                                                (section, localIndex) => index === localIndex,
                                                prevState => ({
                                                    ...prevState,
                                                    row: isFilledValue(val) ? Math.abs(parseInt(val)) : undefined
                                                })
                                            ))}
                                            type="number"
                                            containerClasses="text-base" />
                                    },
                                    {
                                        header: "",
                                        getter: (section, index) => <BaseInputLegacy
                                            label={t("sections.fields.shelfHeight.label")}
                                            value={section.shelf_height ?? ""}
                                            onChange={val => setSections(updateObjectsArray(
                                                sections,
                                                (section, localIndex) => index === localIndex,
                                                prevState => ({
                                                    ...prevState,
                                                    shelf_height: val
                                                })
                                            ))}
                                            containerClasses="text-base" />
                                    },
                                    {
                                        header: "",
                                        getter: (section, index) => <TrashSVG
                                            className="text-tables-secondaryIcon mt-[32px] hover:text-tables-highlightedIcon cursor-pointer"
                                            onClick={() => {
                                                if (section.id) {
                                                    setActiveSectionDeletion(section as Location.Section.Extended);
                                                } else {
                                                    setSections(sections.filter((item, item_i) => item_i != index));
                                                }
                                                setTableSectionsWithEmptyNames([]);
                                            }} />,
                                        sx: { paddingRight: 0 }
                                    }
                                ]}

                                headerSx={{
                                    display: "none"
                                }}

                                nothingFound={{
                                    height: 100,
                                    text: t("sections.nothingFound")
                                }}
                            />

                            {pagination &&
                                <BasePagination
                                    config={pagination}
                                />
                            }
                        </div>
                    </Fade>
                </BaseValidationManager>
                <div className="flex justify-center" onClick={() => {
                    const newTableSectionsWithEmptyNames: number[] = [];

                    if (isWithSections) {
                        sections.forEach((item, index) => {
                            if (!isFilledValue(item.name))
                                newTableSectionsWithEmptyNames.push(index);
                        });

                        setTableSectionsWithEmptyNames(newTableSectionsWithEmptyNames);
                    }

                    if (validationStore)
                        validationStore.dispatch(validate({
                            onSuccess: () => {
                                if (!newTableSectionsWithEmptyNames.length) {
                                    const data: Location.DTO.CreateUpdate = removeEmpty<Location.DTO.CreateUpdate>({
                                        name: name,
                                        country: selectedCountry?.name as string,
                                        city: city,
                                        street: street,
                                        zipcode: zipcode,
                                        contactName: contactName,
                                        phone: phoneNumber,
                                        email: email,
                                        sections: isWithSections ? sections : undefined
                                    });

                                    if (location) { // if location is acquired = we are in the editing mode
                                        manualRequest({
                                            route: `location/${location.id}`,
                                            method: "PUT",
                                            body: data,
                                            onLoadingChange: (state) => setIsOperationLoading(state),
                                            then: (resp) => {
                                                toast.success(generalT("settings.general.locations.editLocation.responses.success"));
                                                onLocationUpdate?.(resp.data as Location.Extended);
                                                setValues(resp.data as Location.Extended);
                                            },
                                            catch: (e) => {
                                                toast.error(generalT("settings.general.locations.editLocation.responses.error"));
                                            }
                                        });
                                    } else {
                                        manualRequest({
                                            route: `location`,
                                            method: "POST",
                                            body: data,
                                            onLoadingChange: (state) => setIsOperationLoading(state),
                                            then: (resp) => {
                                                toast.success(generalT("settings.general.locations.createLocation.responses.success"));
                                                history.push("/dashboard/settings/locations");
                                            },
                                            catch: (e) => {
                                                toast.error(generalT("settings.general.locations.createLocation.responses.error"));
                                            }
                                        });
                                    }
                                }
                            }
                        }));
                }}>
                    <BaseButton text={buttonText} size="md" />
                </div>
            </div>

            {/* DELETION MODAL */}
            <BaseModal
                isOpen={!!activeSectionDeletion}
                onClose={() => setActiveSectionDeletion(undefined)}
                width={700}
                padding="56px"
            >

                <div className="space-y-[32px]">
                    <div
                        className="text-xl font-semibold text-center text-accent">{generalT("settings.general.locations.modals.confirmDelete.label")}</div>
                    <div className="text-center">{generalT("settings.general.locations.modals.confirmDelete.hint")}
                        <span
                            className="bold-highlight">{generalT("settings.general.locations.modals.confirmDelete.sectionDeletionItem")}{activeSectionDeletion?.name ? ` ${activeSectionDeletion?.name}` : ""}?</span>
                    </div>
                    <div className="grid grid-cols-2 gap-[16px]">
                        <BaseButton text={generalT("settings.general.locations.modals.confirmDelete.cancelButton")}
                                    size="md" primaryOutlined onClick={() => setActiveSectionDeletion(undefined)} />
                        <BaseButton text={generalT("settings.general.locations.modals.confirmDelete.deleteButton")}
                                    size="md" onClick={() => {
                            if (activeSectionDeletion) {
                                setSections(prevState => prevState.filter(section => !section.id || section.id != activeSectionDeletion.id));
                                setActiveSectionDeletion(undefined);
                            }
                        }} />
                    </div>
                </div>
            </BaseModal>
        </BaseLoadingBlocker>
    );
}
