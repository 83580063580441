import React from "react";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import {Control, Controller, useWatch} from "react-hook-form";
import BaseCheckbox from "@reusables/BaseCheckbox";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import {getCountryFlag} from "@helpers/utils";
import BasePhoneInput from "@reusables/BasePhoneInput";
import {useTranslation} from "react-i18next";
import {useGetCountriesQuery} from "@redux/api/internalApiSlice";
import {SupplierFormTyping} from "@components/Dashboard/pages/Suppliers/components/tabs/TabsController";
import {ReactComponent as PlusSVG} from "@assets/icons/ic_plus.svg";
import {ReactComponent as TrashSVG} from "@assets/icons/ic_trash.svg";

type ReturnAddressProps = {
    control: Control<SupplierFormTyping>;
    index: number;
    onAppend: () => void;
    onRemove: () => void;
    onPrimaryChange: () => void;
}

export default function ReturnAddress({control, index, onAppend, onRemove, onPrimaryChange}: ReturnAddressProps) {
    const {t} = useTranslation("", {keyPrefix: "suppliers.general.tabs.address"});

    const {data: countryOptions = [], isLoading: countryOptionsLoading} = useGetCountriesQuery();

    const country = useWatch({
        control,
        name: `returns.${index}.country`
    });

    return (
        <div className="space-y-[24px]">
            {
                index === 0 ?
                    <div className="flex items-center justify-between">
                        <div className="text-xl text-accent">{t("categories.delivery")}</div>
                        <div className='flex  items-center gap-2'>
                            {/*TODO: commented due to RHF bug on deletion of the last element */}
                            {/*TODO: opened issue https://github.com/react-hook-form/react-hook-form/issues/11415*/}
                            {/*<div*/}
                            {/*    className="text-gray-300 space-x-[10px] font-thin cursor-pointer hover:text-actionableIcons"*/}
                            {/*    onClick={onRemove}*/}
                            {/*>*/}
                            {/*    <TrashSVG/>*/}
                            {/*    <span className="align-middle">{t("buttons.delete")}</span>*/}
                            {/*</div>*/}
                            <BaseMaterialIconButton
                                icon={<PlusSVG/>}
                                onClick={onAppend}
                            />
                        </div>
                    </div>
                    :
                    <div className="flex items-center justify-between">
                        <div className="text-xl text-accent">{t("categories.delivery")}</div>
                        {/* {address.id} */}
                        <div
                            className="text-gray-300 space-x-[10px] font-thin cursor-pointer hover:text-actionableIcons"
                            onClick={onRemove}
                        >
                            <TrashSVG/>
                            <span className="align-middle">{t("buttons.delete")}</span>
                        </div>
                    </div>
            }
            <div>
                <Controller
                    control={control}
                    name={`returns.${index}.is_primary`}
                    render={({field}) => (
                        <BaseCheckbox
                            {...field}

                            label={t("checkboxes.useAsPrimary")}
                            onChange={(e) => {
                                field.onChange(!field.value); // Toggle the value
                                if (!field.value) {
                                    onPrimaryChange();
                                }
                            }}
                        />
                    )}
                />
            </div>
            <BaseInputsGrid>
                <Controller
                    control={control}
                    name={`returns.${index}.name`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.name.label")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`returns.${index}.street`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.street.label")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`returns.${index}.street_2`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.street2.label")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`returns.${index}.zipcode`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.zipcode.label")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`returns.${index}.city`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.city.label")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`returns.${index}.country`}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            options={countryOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                                renderOption: (opt, icon) => (
                                    <div>
                                        <img src={getCountryFlag(opt.code)} width={20} alt={opt.name + " flag"}/>
                                        <span className="ml-[8px] grow">{opt.name}</span>
                                        {icon}
                                    </div>
                                )
                            }}
                            label={t("fields.country.label")}
                            isLoading={countryOptionsLoading}
                            autocomplete
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`returns.${index}.contact_person`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.contact_person.label")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`returns.${index}.phone`}
                    render={({field, fieldState}) => (
                        <BasePhoneInput
                            {...field}
                            {...fieldState}
                            label={t('fields.phone.label')}
                            size='md'
                            variant='custom'
                            country={!field.value ? country?.code : ""}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`returns.${index}.email`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.email.label")}
                            type={"email"}
                        />
                    )}
                />
            </BaseInputsGrid>
        </div>
    )
}