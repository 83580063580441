import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@redux/hooks";

import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseTable from "@reusables/BaseTable";
import { selectEmployeeById, useGetEmployeesFullQuery } from "@redux/features/employees/employeesApi";

import { ReactComponent as SearchSVG } from "@assets/icons/ic_search.svg";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import DeletionModal from "./modals/DeletionModal";
import MutationModal from "./modals/MutationModal";
import { useAbility } from "@casl/react";
import { removeEmpty, useOrdering, usePagination } from "@helpers/utils";
import { Employee } from "@/types/general";
import { AbilityContext, Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import _ from "lodash";
import { Pagination } from "@mui/material";

export default function EmployeesTab() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.employees" });

    // const company = useAppSelector(state => state.companies.selectedCompany);

    // // Pass the company ID as an argument to the query hook
    // const { data: employees = [], isLoading: isLoadingEmployees, refetch } = useGetEmployeesQuery();

    // // Add an effect to refetch employees when the selected company changes
    // useEffect(() => {
    //   if (company) {
    //     refetch();
    //   }
    // }, [company, refetch]);

    const ability = useAbility(AbilityContext);

    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchingValue, setSearchingValue] = useState<string>();

    // Debounce the search input handler
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

    const { orderBy, setOrderBy } = useOrdering<Employee.DTO.OrderBy>({ name: "id", type: "desc" });

    const pagination = usePagination({ page: 1, limit: 8 });

    const filters = removeEmpty({
        search: searchingValue
    })

    const {data: employeesResponse, isFetching: isEmployeesLoading} = useGetEmployeesFullQuery({
        filters,
        orderBy,
        pagination
    });

    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();
    const [deletableEmployeeId, setDeletableEmployeeId] = useState<number>();

    const selectedEmployee = useAppSelector((state) => selectEmployeeById(state, selectedEmployeeId));
    const deletableEmployee = useAppSelector((state) => selectEmployeeById(state, deletableEmployeeId));

    return (
        <>
            <div>
                <div className="flex flex-row justify-between items-center mb-[44px]">
                    {showSearch ? (
                        <>
                            <div className="relative w-full mr-[16px]">
                                <input
                                    type="text"
                                    placeholder={"Search"}
                                    onBlur={() => setShowSearch(false)}
                                    onChange={(e) => searchInputDebounce(e.target.value)}
                                    className="w-full px-10 py-[7px] rounded-lg border-solid border-[#B49FFB]"
                                    style={{ display: showSearch ? "block" : "none" }}
                                    autoFocus
                                />
                                <div className="absolute top-50 left-[18px] translate-middle-y">
                                    <SearchSVG className="mt-[-2px]" />
                                </div>
                            </div>

                            <Can I="create" a="employee">
                                <BaseMaterialButton
                                    fontWeight={500}
                                    onClick={() => {
                                        setSelectedEmployeeId(undefined);
                                        setIsMutationModalOpen(true);
                                    }}
                                    sx={{
                                        minWidth: "fit-content"
                                    }}
                                >
                                    {t("table.buttons.add")}
                                </BaseMaterialButton>
                            </Can>
                        </>
                    ) : (
                        <>
                            <div className="font-bold text-2xl text-accent">{t("title")}</div>
                            <div className="flex items-center gap-[16px]">
                                <input
                                    type="text"
                                    placeholder={"Search"}
                                    onBlur={() => setShowSearch(false)}
                                    onChange={(e) => searchInputDebounce(e.target.value)}
                                    className="w-full px-10 py-[7px] rounded-lg border-solid border-[#B49FFB]"
                                    style={{ display: showSearch ? "block" : "none" }}
                                    autoFocus
                                />
                                <BaseMaterialIconButton
                                    icon={<SearchSVG />}
                                    onClick={() => {
                                        setShowSearch(true);
                                    }}
                                />
                                <Can I="create" a="employee">
                                    <BaseMaterialButton fontWeight={500} onClick={() => {
                                        setSelectedEmployeeId(undefined);
                                        setIsMutationModalOpen(true);
                                    }}>
                                        {t("table.buttons.add")}
                                    </BaseMaterialButton>
                                </Can>
                            </div>
                        </>
                    )}
                </div>

                <Can not I="index" a="employee">
                    <NoPermissionBanner />
                </Can>

                <Can I="index" a="employee">
                    <BaseTable
                        data={employeesResponse?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) => <div className="bold-highlight">{row.code}</div>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) => <div className="bold-highlight">{row.name}</div>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.2"),
                                getter: (row) => <div className="bold-highlight">{row.job_title}</div>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.3"),
                                getter: (row) => <div className="bold-highlight">{row.email}</div>,
                                comparator: () => 0
                            },
                            {
                                visible: ability.can("update", "employee") || ability.can("delete", "employee"),
                                header: () => "",
                                getter: (row) => (
                                    <div className="text-tables-secondaryIcon">
                                        <Can I="update" a="employee">
                                            <EditSVG
                                                className="mr-[8px] cursor-pointer hover:text-tables-highlightedIcon"
                                                onClick={() => {
                                                    setSelectedEmployeeId(row.id);
                                                    setIsMutationModalOpen(true);
                                                }}
                                            />
                                        </Can>
                                        <Can I="delete" a="employee">
                                            <DeleteSVG
                                                className="cursor-pointer hover:text-tables-highlightedIcon"
                                                onClick={() => {
                                                    setDeletableEmployeeId(row.id);
                                                    setIsDeletionModalOpen(true);
                                                }}
                                            />
                                        </Can>
                                    </div>
                                )
                            }
                        ]}
                        isDataLoading={isEmployeesLoading}

                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering?.index) {
                                    let name: Employee.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "id";
                                            break;
                                        case 1:
                                            name = "name";
                                            break;
                                        case 2:
                                            name = "jobTitle";
                                            break;
                                        case 3:
                                            name = "email";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                        headerSx={{
                            backgroundColor: "#F9F9F9"
                        }}
                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}
                        size={"small"}
                    />
                </Can>
            </div>

            <Pagination
                className="mt-[32px]"
                {...pagination.adapt(employeesResponse)}
            />

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => setIsMutationModalOpen(false)}
                employee={selectedEmployee}
            />

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                employee={deletableEmployee}
            />
        </>
    );
}
