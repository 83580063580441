import React from "react";
import {useState} from 'react';
import {useTranslation} from 'react-i18next'

import {selectPackgageById, useGetPackagesQuery} from '@redux/features/packaging/packagingApi';
import {useAppSelector} from '@redux/hooks';

import BaseMaterialButton from '@reusables/BaseMaterialButton';
import BaseTable from '@reusables/BaseTable';

import {ReactComponent as EditSVG} from "@assets/icons/ic_edit.svg";
import {ReactComponent as DeleteSVG} from "@assets/icons/ic_trash.svg";

import MutationModal from './modals/MutationModal';
import DeletionModal from './modals/DeletionModal';
import {AbilityContext, Can} from "@/casl.config";
import {useAbility} from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function PackagingPage() {
    const {t} = useTranslation('', {keyPrefix: 'settings.general.packaging'});

    const ability = useAbility(AbilityContext);

    // Getting packaging data using RTK Query
    const {data: packaging = [], isLoading: isLoadingPackaging} = useGetPackagesQuery();

    // Open states determine visibility of modals
    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allows modals to work with data
    const [selectedPackageId, setSelectedPackageId] = useState<number>();
    const [deletablePackageId, setDeletablePackageId] = useState<number>();

    const selectedPackage = useAppSelector(state => selectPackgageById(state, selectedPackageId));
    const deletablePackage = useAppSelector(state => selectPackgageById(state, deletablePackageId));

    return (
        <>
            <div className='levitation-extended'>
                <div className='flex fleex-row justify-between items-center mb-[44px]'>
                    <div className='font-bold text-2xl text-accent'>{t('heading')}</div>
                    <Can I="create" a="package">
                        <BaseMaterialButton fontWeight={500} onClick={() => {
                            setSelectedPackageId(undefined);
                            setIsMutationModalOpen(true);
                        }}>
                            {t('table.buttons.add')}
                        </BaseMaterialButton>
                    </Can>
                </div>

                <Can not I="index" a="package">
                    <NoPermissionBanner />
                </Can>

                <Can I="index" a="package">
                    <BaseTable
                        data={packaging}
                        columns={[
                            {
                                header: t('table.columns.0'),
                                getter: row => <div className='bold-highlight'>{row.name}</div>,
                                comparator: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                                header: t('table.columns.1'),
                                getter: row => <div>{row.code}</div>,
                            },
                            {
                                header: t('table.columns.2'),
                                getter: row => row.width.toFixed(2),
                                comparator: (a, b) => a.width - b.width
                            },
                            {
                                header: t('table.columns.3'),
                                getter: row => row.length.toFixed(2),
                                comparator: (a, b) => a.height - b.height
                            },
                            {
                                header: t('table.columns.4'),
                                getter: row => row.height.toFixed(2),
                                comparator: (a, b) => a.length - b.length
                            },
                            {
                                visible: ability.can("update", "package") || ability.can("delete", "package"),
                                header: () => "",
                                getter: (row) => (
                                <div className="text-tables-secondaryIcon">
                                    <Can I="update" a="package">
                                        <EditSVG
                                            className="mr-[8px] cursor-pointer hover:text-tables-highlightedIcon"
                                            onClick={() => {
                                                setSelectedPackageId(row.id);
                                                setIsMutationModalOpen(true);
                                            }}
                                        />
                                    </Can>
                                    <Can I="delete" a="package">
                                        <DeleteSVG
                                            className="cursor-pointer hover:text-tables-highlightedIcon"
                                            onClick={() => {
                                                setDeletablePackageId(row.id);
                                                setIsDeletionModalOpen(true);
                                            }}
                                        />
                                    </Can>
                                </div>
                                ),
                                sx: {
                                    textAlign: "right"
                                }
                            }
                        ]}

                        isDataLoading={isLoadingPackaging}

                        headerSx={{
                            backgroundColor: '#F9F9F9'
                        }}

                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}

                        size={"small"}
                    />
                </Can>
            </div>

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => {
                    setSelectedPackageId(undefined);
                    setIsMutationModalOpen(false);
                }}
                package={selectedPackage}
            />

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => {
                    setIsDeletionModalOpen(false);
                }}
                package={deletablePackage}
            />
        </>
    );
}
