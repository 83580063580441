import React, { useCallback, useState } from "react";
import DashboardCard from "../../DashboardCard";
import { useTranslation } from "react-i18next";
import { FeedTableDTA } from "../../types";
import FeedTable from "@components/Dashboard/DashboardPage/subpages/FeedPage/FeedTable";
import _ from "lodash";
import BaseInput from "@reusables/BaseInput";
import { Search } from "lucide-react";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";

export default function FeedPage(props: { feedData: FeedTableDTA }): JSX.Element {

    const { t } = useTranslation("", { keyPrefix: "dashboard.dashboard.stats" });

    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

    return (
        <div>
            <DashboardCard
                dashboardAbility={"feed"}
                title={t("feed.heading")}
                className="w-full"
                headerActions={<SearchInput onSearch={searchInputDebounce}/>}
            >
                <FeedTable variant={"extended"} search={searchingValue}/>
            </DashboardCard>
        </div>
    );
}

export function SearchInput(props: {onSearch: (search: string) => void;}) {
    const [isShowSearch, setIsShowSearch] = React.useState(false);
    const [search, setSearch] = React.useState("");

    if (isShowSearch || search) {
        return (
            <BaseInput
                className={"animate-in slide-in-from-right-0.5"}
                type="text"
                placeholder={"Search"}
                value={search}
                onBlur={() => {
                    if (!search || !search.length)
                        setIsShowSearch(false);
                }}

                onChange={val => {
                    setSearch(val ?? "");
                    props.onSearch(val ?? "");
                }}

                autoFocus

                sx={{
                    "& .MuiInput-input": {
                        padding: "7.5px"
                    }
                }}

                icon={{
                    right: {
                        el: <Search size={16} style={{ marginTop: "-3px" }} />,
                        offset: "12px"
                    }
                }}
            />
        );
    } else {
        return (
            <BaseMaterialIconButton
                icon={<Search size={16} />}
                onClick={() => setIsShowSearch(true)}
            />
        );
    }
}