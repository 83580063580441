import React, {useEffect} from "react";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {
    PurchaseOrdersFormTyping
} from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/types";
import {useTranslation} from "react-i18next";
import {useGetCurrenciesQuery} from "@redux/features/currencies/currenciesApi";
import ExchangeRateInput from "./ExchangeRateInput";
import {useGetSupplierQuery} from "@redux/features/suppliers/suppliersApi";

export default function CurrencySection() {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general"});

    const {control, setValue} = useFormContext<PurchaseOrdersFormTyping>();

    const {data: companyCurrenciesOptions = [], isLoading: isCompanyCurrenciesOptionsLoading} = useGetCurrenciesQuery();

    const currency = useWatch({
        control,
        name: "currency"
    });

    const exchangeRate = useWatch({
        control,
        name: "exchange_rate"
    });

    const mode = useWatch({
        control,
        name: "mode"
    });

    const supplier = useWatch({
        control,
        name: "supplier"
    });

    const {data: extendedSupplierData, isLoading: isExtendedSupplierDataLoading} = useGetSupplierQuery(
        supplier?.id as number,
        {skip: !supplier}
    );

    useEffect(() => {
        if(extendedSupplierData?.currency && mode === "creation"){
            setValue("currency", extendedSupplierData.currency);
            setValue("exchange_rate", extendedSupplierData.currency?.rate);
        }
    }, [extendedSupplierData]);

    useEffect(() => {
        if(currency && mode === "creation"){
            setValue("exchange_rate", currency.rate);
        }
    }, [currency]);

    return (
        <>
            <BaseInputsGrid>
                {/* Currency */}
                <div>
                    <Controller
                        name="currency"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}

                                label={t("dropdowns.currency.label")}
                                placeholder={t("dropdowns.currency.placeholder")}
                                options={companyCurrenciesOptions}
                                getter={{
                                    label: (opt) => opt.code,
                                    key: (opt) => opt.id,
                                    caption: (opt) => opt.isBase ? `${t("dropdowns.currency.base").toUpperCase()}` : ""
                                }}

                                autocomplete
                                isLoading={isCompanyCurrenciesOptionsLoading || isExtendedSupplierDataLoading}


                                disabled={mode === "editing"}
                                disableClearable
                            />
                        )}
                    />
                </div>
                {/* Currency exchange rate */}
                {
                    currency && (
                        <ExchangeRateInput
                            currency={currency}
                            parentRate={exchangeRate}
                            onRateChange={(rate) => setValue("exchange_rate", rate)}
                            disabled={mode === "editing"}
                        />
                    )
                }
            </BaseInputsGrid>
        </>
    )
}
