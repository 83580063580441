import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./features/auth/authSlice";
import themeReducer from "./slices/themeReducer";
import companiesReducer from "./features/companies/companiesSlice";
import purchasesReducer from "./features/purchases/purchasesSlice";
import importReducer from "./features/importExport/import/importSlice";
import exportReducer from "./features/importExport/export/exportSlice";
import importInfoReducer from "./features/importExport/import/importInfoSlice";
import { internalApiSlice } from "./api/internalApiSlice";
import { externalApiSlice } from "./api/externalApiSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistedAuthReducer = persistReducer(
    {
        key: "auth",
        storage
    },
    authSlice.reducer
);

export const globalStore = configureStore({
    reducer: {
        [authSlice.name]: persistedAuthReducer,
        theme: themeReducer,
        [internalApiSlice.reducerPath]: internalApiSlice.reducer,
        [externalApiSlice.reducerPath]: externalApiSlice.reducer,
        companies: companiesReducer,
        purchases: purchasesReducer,
        import: importReducer,
        export: exportReducer,
        importInfo: importInfoReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
        .concat(internalApiSlice.middleware)
        .concat(externalApiSlice.middleware)
});

export const persistor = persistStore(globalStore);

export type GlobalStoreState = ReturnType<typeof globalStore.getState>;