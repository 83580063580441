import React, { useMemo } from "react";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseTable from "@reusables/BaseTable";
import { useTranslation } from "react-i18next";
import { calculateOrderPrice, normalizePrice } from "@helpers/utils";
import _ from "lodash";
import { ViewLayoutProps } from "./index";

export default function OrderDetails({ saleOrder }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general" });

    // Calculating orders summary
    const ordersSummary = useMemo(() => {
        let subtotal = 0;
        let taxes = 0;

        saleOrder.orders.forEach(order => {
            const orderPrice = calculateOrderPrice(order.quantity ?? 0, order.unit_price ?? 0, order.discount ?? 0);
            subtotal += orderPrice;
            taxes += orderPrice * ((order.tax?.rate ?? 0) / 100);
        });

        const totalWithTaxes = subtotal + taxes;

        return {
            subtotal,
            taxes,
            totalWithTaxes
        };
    }, []);

    const blocks = useMemo<Record<"accent" | "references" | "address", InfoItem[]>>(() => ({
        accent: [
            {
                title: t("dropdowns.customer.label"),
                data: () => saleOrder?.customer.name ?? ""
            },
            {
                title: t("accentFields.orderDate.label"),
                data: () => saleOrder?.order_date.format("DD.MM.YYYY") ?? "-"
            },
            {
                title: t("accentFields.deliveryStatus.label"),
                data: () => <div>
                    <div>
                        {
                            t("accentFields.deliveryStatus.variants." + saleOrder.shipment_state)
                        }
                    </div>
                    {
                        !!saleOrder.delivery_date &&
                        <div>
                            ({saleOrder.delivery_date.format("DD.MM.YYYY")})
                        </div>
                    }
                </div>
            },
        ],
        references: [
            {
                title: t("dropdowns.ourReference.label"),
                data: () => saleOrder?.our_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.theirReference.label"),
                data: () => saleOrder?.their_reference?.name ?? "-"
            },
            {
                title: t("dropdowns.paymentTerms.label"),
                data: () => saleOrder?.payment_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.deliveryTerms.label"),
                data: () => saleOrder?.delivery_terms?.name ?? "-"
            },
            {
                title: t("dropdowns.documentLanguage.label"),
                data: () => saleOrder?.language?.name ?? "-"
            }
        ],
        address: [
            {
                title: t("fields.billingAddress.label"),
                data: () => (<>
                    <div
                        className="mb-[24px]">{_.join([saleOrder.billing_address?.street, saleOrder.billing_address?.city, saleOrder.billing_address?.country?.name].filter(x => !_.isEmpty(x)), ", ")}</div>
                    <BaseCheckbox
                        label={`${t("fields.billingAddress.checkboxes.useForDelivery")}`}
                        checked={saleOrder.is_billing_for_delivery}
                        disabled
                    />
                </>)
            }
        ]
    }), [saleOrder, t]);

    return (
        <div className="space-y-[40px]">
            <div className="p-[16px] rounded-[8px] bg-informationAccentBlock text-accent">
                <BaseInputsGrid cols={3}>
                    {
                        blocks.accent.map((item, index) => (
                            <div key={index}>
                                <div className="mb-[16px]">{item.title}</div>
                                <div className="space-x-[24px] font-semibold">{item.data()}</div>
                            </div>
                        ))
                    }
                </BaseInputsGrid>
            </div>
            <BaseInputsGrid title="References">
                {
                    blocks.references.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="space-x-[24px] text-accent">{item.data()}</div>
                        </div>
                    ))
                }
            </BaseInputsGrid>
            <BaseInputsGrid title="Address">
                {
                    blocks.address.map((item, index) => (
                        <div key={index}>
                            <div className="mb-[16px] font-semibold">{item.title}</div>
                            <div className="text-accent">{item.data()}</div>
                        </div>
                    ))
                }
                {
                    !saleOrder?.is_billing_for_delivery &&
                    <div>
                        <div className="mb-[16px] font-semibold">{t("dropdowns.deliveryAddress.label")}</div>
                        <div className="space-x-[24px] text-accent">
                            {_.join([saleOrder.delivery_address?.street, saleOrder.delivery_address?.city, saleOrder.delivery_address?.country?.name].filter(x => !_.isEmpty(x)), ", ")}
                        </div>
                    </div>
                }
            </BaseInputsGrid>

            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {
                    saleOrder?.orders.length
                        ?
                        <>
                            <div className="flex mb-[46px] items-center space-x-[8px]">
                                <div className="text-xl font-thin text-gray-600 grow">{t("orders.heading")}</div>
                            </div>

                            <BaseTable
                                data={saleOrder.orders}
                                columns={[
                                    {
                                        header: t("orders.columns.0"),
                                        getter: (row) => `${row.product.code}`,
                                        sx: {
                                            minWidth: "200px"
                                        }
                                    },
                                    {
                                        header: t("orders.columns.1"),
                                        getter: (row) => row.product.name
                                    },
                                    {
                                        header: t("orders.columns.2"),
                                        getter: (row) => row.quantity
                                    },
                                    {
                                        header: t("orders.columns.3"),
                                        getter: (row) => normalizePrice(row.unit_price)
                                    },
                                    {
                                        header: t("orders.columns.4"),
                                        getter: (row) => row.discount ? row.discount + "%" : ""
                                    },
                                    {
                                        header: t("orders.columns.5"),
                                        getter: (row) => row.tax?.rate ? row.tax.rate + "%" : ""
                                    },
                                    {
                                        header: () => <div className="text-right">{t("orders.columns.6")}</div>,
                                        getter: (row) => normalizePrice(calculateOrderPrice(row.quantity, row.unit_price, row.discount ?? 0)),
                                        cellClasses: "!text-right"
                                    }
                                ]}

                                size={"small"}
                                headerSx={{
                                    background: "#F9F9F9"
                                }}
                            />
                            <div className="flex justify-end mt-[48px] font-thin">
                                <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                                    <div className="text-right">
                                        {t("orders.summary.subtotal")}
                                    </div>
                                    <div className="text-right pr-[16px]">
                                        {
                                            normalizePrice(ordersSummary.subtotal)
                                        }
                                    </div>
                                    <div className="text-right">
                                        {t("orders.summary.taxes")}
                                    </div>
                                    <div className="text-right pr-[16px]">
                                        {
                                            normalizePrice(ordersSummary.taxes)
                                        }
                                    </div>
                                    <div className="text-right font-semibold text-accent">
                                        {t("orders.summary.total")}
                                    </div>
                                    <div className="text-right pr-[16px] font-semibold text-accent">
                                        {
                                            normalizePrice(ordersSummary.totalWithTaxes)
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="text-xl text-center text-gray-300 font-thin">
                                {t("orders.noProducts")}
                            </div>
                        </>
                }
            </div>
        </div>
    );
}

type InfoItem = {
    title: string;
    data: () => JSX.Element | string;
}
