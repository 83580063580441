import React from "react";
import {useController, useFormContext, useWatch} from "react-hook-form";
import {SaleOrdersFormTyping} from "../../types";
import BaseInput from "@reusables/BaseInput";
import {Stack} from "@mui/material";
import {AlertOctagon} from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import {useTranslation} from "react-i18next";

export default function QuantityCell({index, isEditing}: { index: number, isEditing: boolean }): JSX.Element {
    const {t} = useTranslation("", {keyPrefix: "sales.orders.general.orders.quantityCell"});

    const {control} = useFormContext<SaleOrdersFormTyping>();

    const {field: quantityField, fieldState: quantityFieldState} = useController({
        name: `orders.${index}.quantity`,
        control
    })

    const product = useWatch({
        control,
        name: `orders.${index}.product`
    })

    const inStock = product?.in_stock ?? 0;

    const isMoreThanInStock = quantityField.value > inStock;

    return <Stack direction={"row"}>
        <BaseInput
            {...quantityField}
            error={quantityFieldState.error}

            sx={{
                "& .MuiInputBase-input": {
                    padding: "8px",
                }
            }}

            {
                ...(isMoreThanInStock && !product.is_service && !isEditing && {
                    icon: {
                        right: {
                            el:
                                <BaseTooltip title={t("lowStockWarning", {inStock}) + ""} className={"animate-in fade-in"}>
                                    <AlertOctagon size={14} color={"orange"} />
                                </BaseTooltip>,
                            offset: "8px"
                        }
                    }
                })
            }

            disabled={isEditing}

            hideErrors
        />
    </Stack>
}