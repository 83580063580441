import React, {useEffect} from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import {useTranslation} from "react-i18next";
import BaseNotFound from "@reusables/BaseNotFound";
import {z} from "zod";
import {useFieldArray, useForm} from "react-hook-form";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {zodResolver} from "@hookform/resolvers/zod";
import dayjs, {Dayjs} from "dayjs";
import {BatchGroup} from "./BatchGroup";
import {stopPropagate} from "@helpers/utils";

/**
 * This modal handles the validation of batch numbers.
 * Users can attach these numbers (click the "attach" button) if they meet the following criteria:
 * 1. All input fields are filled.
 * 2. For batch numbers, the number of inputs should not exceed the selected quantity. This applies to every product line.
 *
 * Once batch numbers are attached, users have the option to modify them or use them during the creation of a receipt record.
 * If used in the receipt creation, there might be instances where the number of batches are not filled (at least 1) for a product line.
 * In such cases:
 * 1. Users can edit and add more batch numbers.
 * 2. An error message will be displayed, prompting the user to add at least one batch number to each product line.
 *
 * P.S. This modal operates with dates in dayjs format, however the original batch_numbers are stored as strings in the parent RHF.
 */


const formScheme = z.object({
    receipts: z.array(
        z.object({
            sale_order_line_id: z.number(),

            product: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string()
            }),

            received_quantity: z.coerce.number(),

            batch_numbers: z.array(
                z.object({
                    batch_number: z.string().nonempty(),
                    expiration_date: z.instanceof(dayjs as unknown as typeof Dayjs).refine(value => value.isValid(), {
                        message: "Invalid date",
                    }).optional()
                })
            )
        }).refine(
            scheme => scheme.batch_numbers.length <= scheme.received_quantity,
            {
                message: "Batch numbers count cannot be greater than received quantity",
                path: ["batch_numbers"]
            }
        )
    )
});

export type BatchNumbersScheme = z.infer<typeof formScheme>

interface IncomingReceipt {
    order: {
        id: number;
        product: {
            id: number;
            name: string;
            code: string;
        }
    }
    received_quantity?: number | null;
    batch_numbers?: {
        batch_number: string;
        expiration_date?: dayjs.Dayjs;
    }[]
}

interface BatchNumbersModalProps {
    isOpen: boolean;
    onClose: () => void;

    receipts: IncomingReceipt[];
    onSave?: (data: BatchNumbersScheme) => void;
    preview?: boolean;
}

export default function BatchNumbersModal(props: BatchNumbersModalProps) {
    const { t } = useTranslation("", { keyPrefix: "receive.modals.batchNumbers" });

    const { control, handleSubmit, setValue, reset } = useForm<BatchNumbersScheme>({
        resolver: zodResolver(formScheme)
    });

    const { fields: receipts } = useFieldArray({
        control,
        name: "receipts"
    });

    const onSubmit = handleSubmit(data => props.onSave?.(data));

    useEffect(() => {
        if (props.isOpen) {
            const formReadyLines = props.receipts
            .map(line => {
                const batch_numbers = line.batch_numbers?.length
                    ?
                    line.batch_numbers
                    : [{ batch_number: ""}];
                return {
                    sale_order_line_id: line.order.id,
                    product: {
                        id: line.order.product.id,
                        name: line.order.product.name,
                        code: line.order.product.code
                    },
                    received_quantity: line.received_quantity ?? 0, // however here received_quantity will always be defined because of filter
                    batch_numbers
                };
            })

            setValue(
                "receipts",
                formReadyLines
            )
        }else{
            reset();
        }
    }, [props.receipts, props.isOpen, setValue, reset]);

    return <BaseModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        width={900}
        padding="56px"
        position={"center"}
        useCloseIcon={props.preview}
    >
        <form onSubmit={stopPropagate(onSubmit)} className="space-y-[32px]">
            {
                receipts.length ?
                    receipts.map((line, lineIndex) => (
                        <BatchGroup
                            key={line.id}
                            index={lineIndex}
                            control={control}
                            preview={props.preview}
                        />
                    ))
                    :
                    <BaseNotFound text={t("noProductsPicked")} height={250} />
            }
            {
                !props.preview &&
                <BaseInputsGrid cols={2}>
                    {/* "Cancel" button */}
                    <BaseButton text={t("buttons.cancel")}
                                size="md"
                                buttonWidth={"100%"}
                                primaryOutlined
                                type={"button"}
                                onClick={props.onClose}
                    />
                    {/* "Attach" button */}
                    <BaseButton text={t("buttons.attach")}
                                size="md"
                                buttonWidth={"100%"}
                    />
                </BaseInputsGrid>
            }
        </form>
    </BaseModal>;
}