import {Box, Stack} from "@mui/material";
import BaseTable from "@reusables/BaseTable";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import BaseInput from "@reusables/BaseInput";
import React from "react";
import {ReceiptsMutationFormTyping} from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout";
import FullCheckbox
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout/parts/TableBody/checkboxes/FullCheckbox";
import LineCheckbox
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout/parts/TableBody/checkboxes/LineCheckbox";
import {useTranslation} from "react-i18next";
import ReceiptLocationDropdown
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout/parts/TableBody/ReceiptLocationDropdown";

export default function TableBody() {
    const {t} = useTranslation("", {keyPrefix: "receive.mutation"});

    const {control, setValue} = useFormContext<ReceiptsMutationFormTyping>()

    const {fields: receipts} = useFieldArray({
        control,
        name: "receipts"
    })

    return (
        <BaseTable
            hideTableWhenNothingFound
            data={receipts}
            rowKeyGetter={row => row.id}
            columns={[
                // Selection checkbox indicator
                {
                    header: <FullCheckbox/>,
                    getter: (row, index) => <LineCheckbox index={index}/>,
                    sx: {
                        width: "40px"
                    }
                },
                // Product code
                {
                    header: t("products.columns.0"),
                    getter: (row) => row.order.product.code,
                    sx: {
                        width: "200px"
                    }
                },
                // Product name
                {
                    header: t("products.columns.1"),
                    getter: (row) => row.order.product.name,
                },
                // Location
                {
                    header: t("products.columns.2"),
                    getter: (row, index) => {
                        if(!row.order.product.is_service){
                            return <ReceiptLocationDropdown product_id={row.order.product.id} index={index}/>
                        }else{
                            return <></>
                        }
                    },
                    sx: {
                        minWidth: "300px"
                    }
                },
                // Received quantity
                {
                    header: t("products.columns.3"),
                    getter: (row, index) =>
                        <Stack direction={"row"} spacing={1} alignItems="center">
                            <Box width={80}>
                                <Controller
                                    name={`receipts.${index}.received_quantity`}
                                    control={control}
                                    render={({field, fieldState}) =>
                                        <BaseInput
                                            {...field}

                                            error={fieldState.error}

                                            type={"number"}
                                            hideErrors
                                            sx={{
                                                ".MuiInput-input": {
                                                    padding: "8px"
                                                }
                                            }}
                                        />
                                    }
                                />
                            </Box>
                            <span>
                                            of <span className="font-semibold">{row.available_quantity}</span>
                                            </span>
                        </Stack>,
                    sx: {
                        width: "200px"
                    }
                }
            ]}
            headerSx={{
                background: "rgba(249, 249, 249, 1)"
            }}
            nothingFound={{
                text: t("products.noProducts")
            }}
            size="small"
        />
    )
}