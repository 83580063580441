import {
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "@redux/api/internalApiSlice";
import { BOM, BOMHistory } from "@/types/general";
import _ from "lodash";

export const bomApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBOMs: builder.query<BOM.Root[], void>({
            query: () => ({
                url: `bom`
            }),
            transformResponse,
            providesTags: (result) => [
                {
                    type: "BOM",
                    id: "LIST"
                },
                ...flatBOMs(result)
            ]
        }),

        getBOMsFull: builder.query<PaginationResponse<BOM.Root>, PaginationRequest<BOM.DTO.Filters, BOM.DTO.OrderBy>>({
            query: (req) => ({
                url: `bom`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                }
            }),
            // providesTags: (result, error, page) => [{ type: "BOM", id: "LIST" }]
            providesTags: (result) =>
                result
                    ? [
                          ...flatBOMs(result.payload),
                          {
                              type: "BOM",
                              id: "PARTIAL-LIST"
                          }
                      ]
                    : [{ type: "BOM", id: "PARTIAL-LIST" }]
        }),

        getBOMsHistoryFull: builder.query<PaginationResponse<BOMHistory.Root>, PaginationRequest<BOMHistory.DTO.Filters, BOMHistory.DTO.OrderBy>>({
            query: (req) => ({
                url: `bom-history`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["date"]
            }),
            providesTags: (result) =>
                result
                    ? [
                          ..._.flatten(
                              result.payload.map((bom) => [
                                  {
                                      type: "BOMHistory" as const,
                                      id: bom.id
                                  },
                                  {
                                      type: "Product",
                                      id: bom.product.id
                                  },
                                  ...bom.components.map((component) => ({
                                      type: "Product",
                                      id: component.product.id
                                  }))
                              ])
                          ),
                          { type: "BOMHistory", id: "PARTIAL-LIST" }
                      ]
                    : [{ type: "BOMHistory", id: "PARTIAL-LIST" }]
        }),

        getBOM: builder.query<BOM.Extended, number>({
            query: (id) => ({
                url: `bom/${id}`,
                method: "GET",
                ignoreDatesParsing: ["name"]
            }),
            transformResponse,
            providesTags: (result, error, id) => (result ? [{ type: "BOM", id }, ...flatBOMProductsTags(result)] : [])
        }),

        createBOM: builder.mutation<BOM.Extended, BOM.DTO.Create>({
            query: (body) => ({
                url: `bom`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: (result) => [{ type: "BOM", id: "LIST" }, { type: "BOM", id: "PARTIAL-LIST" }, ...(result ? flatBOMProductsTags(result) : [])]
        }),

        createProduce: builder.mutation<
            BOMHistory.Root,
            BOMHistory.DTO.Create & {
                id: number;
            }
        >({
            query: ({ id, ...body }) => ({
                url: `bom/produce/${id}`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: (result, error, args) =>
                result
                    ? [
                          {
                              type: "BOM",
                              id: args.id
                          },
                          "BOMHistory",
                          {
                              type: "Product",
                              id: result.product.id
                          },
                          ...result.components.map((component) => ({ type: "Product", id: component.product.id }))
                      ]
                    : []
        }),

        updateBOM: builder.mutation<BOM.Extended, BOM.DTO.Update & {id: number}>({
            query: ({ id, ...body }) => ({
                url: `bom/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            invalidatesTags: (result, error, args) => (result ? [{ type: "BOM", id: args.id }, ...flatBOMProductsTags(result)] : [])
        }),

        deleteBOM: builder.mutation<void, number>({
            query: (id) => ({
                url: `bom/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, id) => [
                { type: "BOM", id },
                { type: "BOM", id: "PARTIAL-LIST" }
                // TODO: add product tags invalidation
            ]
        })
    })
});

export const { useGetBOMsQuery, useGetBOMsFullQuery, useGetBOMsHistoryFullQuery, useGetBOMQuery, useCreateBOMMutation, useCreateProduceMutation, useUpdateBOMMutation, useDeleteBOMMutation } = bomApi;

const flatBOMProductsTags = (bom: BOM.Root) => [
    { type: "Product", id: bom.product.id },
    ...bom.components.map((component) => ({
        type: "Product",
        id: component.product.id
    }))
];

const flatBOMs = (boms: BOM.Root[] | undefined) => boms?.flatMap((x) => [{ type: "BOM", id: x.id }, ...flatBOMProductsTags(x)]) ?? [];
