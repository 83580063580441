import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { declOfNum, useFilters, useOrdering, usePagination } from "@helpers/utils";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from "@reusables/BaseTable";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import DeletionModal from "../components/modals/DeletionModal";
import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { Pagination } from "@mui/material";
import { Supplier } from "@/types/general";
import { useGetSuppliersFullQuery } from "@redux/features/suppliers/suppliersApi";
import BaseSyncedFromSourcesBadge from "@reusables/BaseSyncedFromSourcesBadge";

export default function SuppliersPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "suppliers.main" });

    const ability = useAbility(AbilityContext);

    // =========== FILTERS RELATED =========== //
    const [searchingValue, setSearchingValue] = useState<string>();
    // Timer is needed to update searching string only after 1 second of user's inactivity after typing (reduces amount of calls to the server)
    const searchInputTimingCooldown = useRef<NodeJS.Timeout>();

    const { orderBy, setOrderBy } = useOrdering<Supplier.DTO.OrderBy>({ name: "id", type: "desc" });

    const pagination = usePagination({ page: 1, limit: 8 });

    const filters = useFilters(() => ({
        search: searchingValue
    }), [searchingValue]);

    const { data: suppliers, isLoading: isSuppliersLoading } = useGetSuppliersFullQuery({
        filters,
        orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    // const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);

    const [actionEntity, setActionEntity] = useState<Supplier.Root>();
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    return (
        <>
            {/* FILTERS BLOCK */}
            {/* TODO: [suppliers] implement import and export */}
            <BaseAdminFilter
                permissionModule={"supplier"}

                handleSearch={val => {
                    if (searchInputTimingCooldown.current)
                        clearTimeout(searchInputTimingCooldown.current);

                    searchInputTimingCooldown.current = setTimeout(() => {
                        setSearchingValue(val);
                    }, 1000);
                }}

                handleCreate={() => history.push("/dashboard/suppliers/new")}
            />

            <div className="levitation-extended mt-[32px]">
                <Can not I="index" a="supplier">
                    <NoPermissionBanner />
                </Can>

                <Can I="index" a="supplier">
                    <div className="mb-[24px]">
                        {
                            <div
                                className="bold-highlight capitalize">{suppliers?.meta.total} {declOfNum(suppliers?.meta.total ?? 0, [t("table.titleDeclinations.0"), t("table.titleDeclinations.1"), t("table.titleDeclinations.2")])}
                            </div>
                        }
                    </div>

                    <BaseTable
                        data={suppliers?.payload ?? []}
                        isDataLoading={isSuppliersLoading}

                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: row => {
                                    const basicLayout = <>
                                        <div className={"flex flex-row items-center space-x-2"}>
                                            <div className="bold-highlight">{row.name}</div>
                                            <BaseSyncedFromSourcesBadge
                                                integrations={[
                                                    {
                                                        slug: "tripletex",
                                                        integrated: () => !!row.tripletex_id
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="text-sm text-accent">{row.code}</div>
                                    </>;
                                    return <>
                                        <Can I="show" a="supplier">
                                            <div
                                                className="hover:opacity-70 transition-[.15s] cursor-pointer"
                                                onClick={() => history.push(`/dashboard/suppliers/${row.id}/details`)}
                                            >
                                                {basicLayout}
                                            </div>
                                        </Can>

                                        <Can not I="show" a="supplier">
                                            <div>
                                                {basicLayout}
                                            </div>
                                        </Can>
                                    </>;
                                },
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: row => row.contacts[0]?.email ?? "-",
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.2"),
                                getter: row => row.contacts[0]?.phone ?? "-",
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.3"),
                                getter: row => row.contacts[0]?.name ?? "-",
                                comparator: () => 0
                            },
                            {
                                visible: ability.can("update", "supplier") || ability.can("delete", "supplier"),
                                header: <div className="text-center">{t("table.columns.4")}</div>,
                                getter: (row) => <div className="text-center space-x-[12px]">
                                    <Can I="update" a="supplier">
                                        <EditSVG
                                            className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                            onClick={() => history.push(`/dashboard/suppliers/${row.id}/edit`)}
                                        />
                                    </Can>
                                    <Can I="delete" a="supplier">
                                        <TrashSVG
                                            className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                            onClick={() => {
                                                setActionEntity(row);
                                                setIsDeletionModalOpen(true);
                                            }}
                                        />
                                    </Can>
                                </div>
                            }
                        ]}

                        alternate
                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: Supplier.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "supplier_name";
                                            break;
                                        case 1:
                                            name = "email";
                                            break;
                                        case 2:
                                            name = "phone";
                                            break;
                                        case 3:
                                            name = "contact_name";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />

                    <Pagination
                        className="mt-[32px]"
                        {...pagination.adapt(suppliers)}
                    />
                </Can>
            </div>

            {/* <MainExportModal
                isOpen={isExportModalOpen}
                onClose={() => setIsExportModalOpen(false)}
                // pagination={pagination}
            /> */}

            {/* DELETION MODAL */}
            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                entity={actionEntity}
            />
        </>
    );
}