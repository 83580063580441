import * as React from "react";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import { AutocompleteGetTagProps, useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/ic_expand_down.svg";
import { ReactComponent as CrossSVG } from "../../../../assets/icons/ic_cross.svg";

import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { CircularProgress, createFilterOptions } from "@mui/material";
import BaseMaterialButton from "../../BaseMaterialButton";
import BasePopper from "../../BasePopper";
import { useTranslation } from "react-i18next";
import BaseConditionBlocker from "../../blockers/BaseConditionBlocker";
import { InputBasics } from "@reusables/reusablesCore";
import { ArrayElementType } from "@helpers/utils";
import { FixedSizeList } from "react-window";
import ListOption from "@reusables/dropdowns/BaseDropdown/components/ListOption";
import InfiniteLoader from "react-window-infinite-loader";
import { AutocompleteInputChangeReason } from "@mui/base/AutocompleteUnstyled/useAutocomplete";
import { InfiniteLoaderConfig } from "@reusables/dropdowns/BaseDropdown/types";
import { BaseInfiniteLoaderChildrenProps } from "@reusables/dropdowns/BaseInfiniteLoader";


const InputWrapper = styled('div')(
    ({theme}) => `
  width: 100%;
  border: 1px solid;
  background-color: #fff;
  border-radius: 8px;
  padding: 12px 16px;
  padding-right: 28px;
  display: flex;
  flex-wrap: wrap;
  transition: .15s;
  position: relative;

  &.active {
    border-color: #7556FA !important;
  }
  
  &.active .dropdown-icon {
    transform: rotate(180deg);
  }

  & input {
    background-color: #fff;
    height: 28px;
    box-sizing: border-box;
    padding: 0 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 2px;
    outline: 0;
  }
  
  & input::placeholder {
    color: #D9D6DA;
    font-weight: 100;
  }
  
  & .dropdown-icon {
    transition: .25s;
  }
`,
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
    label: string;
}

function Tag(props: TagProps) {
    const {label, onDelete, ...other} = props;
    return (
        <div {...other}>
            <span>{label}</span>
            <CloseIcon style={{marginTop: "-1px"}} onClick={onDelete}/>
        </div>
    );
}

const StyledTag = styled(Tag)<TagProps>(
    ({theme}) => `
  display: flex;
  align-items: center;
  height: 26px;
  line-height: 22px;
  background-color: #F3F2EF;
  color: #3C3769;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: content-box;
  padding: 0 8px;
  font-size: 14px;
  outline: 0;
  overflow: hidden;
  margin: 2px;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    margin-left: 4px;
    margin-top: 2px;
    color: #3C3769;
  }
`,
);

const Listbox = styled('ul')(
    ({theme}) => `
  margin: 2px 0 0;
  padding: 14px;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  border-radius: 10px;
  box-shadow: -6px 5px 33px 0px rgba(59, 65, 208, 0.11);
  z-index: 10000;

  & li { 
    & > div {
      padding: 10px 16px;
      color: #3C3769;
      border-radius: 8px;
      
      display: flex;
      align-items: center;
    }

    & .selected-svg {
      opacity: 0;
      color: #7556FA;
      margin-top: -4px;
    }
    & .unselected-caption {
      opacity: 1;
    }
  }

  & li[aria-selected='true'] {
    & .selected-svg {
      opacity: 1;
    }
    
    & .unselected-caption {
      display: none;
    }
  }

  & li.${autocompleteClasses.focused} > div{
    background-color: rgb(243, 242, 239);
    cursor: pointer;
  }
`,
);

export type BaseDropdownProperties<T> = {
    /**
     * Unique autocomplete id.
     */
    id?: string;
    /**
     * Dropdown options to select.
     */
    options: T[] | undefined,
    /**
     * Getters to acquire label for options and key for comparison.
     */
    getter: {
        key: (item: T) => number | string;
        label: (item: T) => string;
        caption?: (item: T) => string | number;

        /**
         * Should be wrapped with <div> element!
         * @param opt
         * @param icon
         */
        renderOption?: (opt: T, icon: JSX.Element) => JSX.Element
    },
    /**
     * Values change callback.
     * @param newState T[] array of actually selected values
     * @param newItem newly added item T or null if item has been removed
     * @param previousState selected items before change
     */
    onChange?: (newItem: T | undefined, newState: T[], previousState: T[], event: React.SyntheticEvent) => void;

    /**
     * Current value of the input dropdown.
     * @param value
     */
    onInputChange?: (
        event: React.SyntheticEvent,
        value: string,
        reason: AutocompleteInputChangeReason,
    ) => void;

    /**
     * Specifies the function used to filter options.
     */
    filterOptions?: (option: T) => string;
    /**
     * Bold label above the input.
     */
    label?: string;
    /**
     * Input's placeholder, shown if no items selected.
     */
    placeholder?: string;
    /**
     * Should dropdown container be 100% of with or 300px.
     * @default true
     */
    dropdownFitInputWidth?: boolean;
    /**
     * Should loader be shown or not.
     * @default false
     */
    isLoading?: boolean;
    autocomplete?: boolean;
    multiple?: boolean;
    brightLabel?: boolean;
    hideSelectedOptions?: boolean;
    /**
     * Action, shown below all available options in the selection menu.
     */
    action?: {
        title: string;
        onClick?: () => void;
    },

    emptyValue?: string;

    customize?: {
        padding?: string;
        leftIcon?: {
            paddingLeft: string;
            el: JSX.Element;
        },
    },

    hideArrow?: boolean;

    disableClearable?: boolean;

    /**
     * Indicates whether to enable virtualization.
     *
     * @type {boolean}
     * @default true
     * @ignore if
     */
    virtualize?: boolean;
    virtualizationConfig?: {
        // @default = 44
        elementHeight?: number;
        // @default = 4
        overscanCount?: number;
    };

    lazyLoading?: InfiniteLoaderConfig

    onOpen?: () => void;
    onClose?: () => void;

    clearOnBlur?: boolean;
} & Omit<InputBasics<T | T[]>, "onChange">;

function BaseDropdownInner<T>({
                                  dropdownFitInputWidth = true,
                                  isLoading = false,
                                  autocomplete = false,
                                  multiple = false,
                                  ...props
                              }: BaseDropdownProperties<T>, ref: ForwardedRef<HTMLInputElement>) {
    const {t} = useTranslation("", {keyPrefix: "general"});

    const topLevelAnchorRef = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState<T[]>([]);
    const previousState = useRef<T[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const virtualize = props.virtualize ? {
        elementHeight: props.virtualizationConfig?.elementHeight ?? 44,
        overscanCount: props.virtualizationConfig?.overscanCount ?? 4
    } : undefined;

    useEffect(() => setValue(props.value ? Array.isArray(props.value) ? props.value : [props.value] : []), [props.value]);

    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        focused,
        anchorEl,
        setAnchorEl
    } = useAutocomplete({
        id: props.id,
        value: !multiple && !value.length ? null : value,
        isOptionEqualToValue: (option, value) => {
            if (multiple) {
                return compareKeys(props.getter.key(option), props.getter.key(value));
            } else if (value && (value as T[]).length) {
                return compareKeys(props.getter.key(option), props.getter.key((value as T[])[0]));
            }
            return false;
        },
        multiple: multiple,
        options: props.options ?? [],
        filterSelectedOptions: props.hideSelectedOptions,
        filterOptions: createFilterOptions<T>({
            stringify: option =>
                props.filterOptions?.(option) ??
                props.getter.label(option) + (props.getter.caption ? ` ${props.getter.caption(option)}` : "")
        }),
        getOptionLabel: (option) => {
            return option instanceof Array ? option.length ? props.getter.label(option[0]) : "" : props.getter.label(option);
        },
        onChange: (event, newValue) => {
            // If multiple is set, then MUI passed array as newValue, otherwise we await newly selected value
            if (!(newValue instanceof Array)) {
                if (props.disableClearable) {
                    newValue = newValue ? [newValue] : []
                } else {
                    newValue = newValue && !value.filter(item => compareKeys(props.getter.key(item), props.getter.key(newValue as T))).length ? [newValue] : [];
                }
            }

            setValue(newValue);

            const newItem = newValue.length >= previousState.current.length ? newValue[newValue.length - 1] : undefined;
            props.onChange?.(newItem, newValue, previousState.current, event);
            previousState.current = newValue;
        },
        onClose: () => {
            setIsOpen(false);
            props.onClose?.();
        },
        onOpen: () => {
            setIsOpen(true);
            props.onOpen?.();
        },
        open: isOpen,
        disableClearable: props.disableClearable,
        onInputChange: props.onInputChange,
        clearOnBlur: props.clearOnBlur
    });

    const [isDisabled, setIsDisabled] = useState<boolean>();


    const {loadNextPage, hasNextPage, isNextPageLoading} = props.lazyLoading ?? {};
    const itemCount = hasNextPage ? (props.options?.length ?? 0) + 1 : (props.options?.length ?? 0);
    const loadMoreItems = (isNextPageLoading || !loadNextPage) ? () => {
    } : loadNextPage;
    const isItemLoaded = (index: number) => !hasNextPage || index < (props.options?.length ?? 0);

    return (
        <div className="relative" ref={topLevelAnchorRef}>
            <div {...getRootProps}>
                {props.label &&
                    <p className={`mb-2 font-semibold${props.brightLabel ? " text-inputs-label-bright" : " text-inputs-label-dim"}`}>
                        {props.label}
                    </p>}
                <InputWrapper ref={setAnchorEl}
                              className={`${isOpen ? 'active' : ''} ${props.disabled ? "!border-inputs-border-disabled pointer-events-none !bg-inputs-disabled" : props.error ? "!border-inputs-border-error" : "!border-inputs-border-default"}`}
                              sx={{
                                  padding: props.customize?.padding,
                                  paddingLeft: props.customize?.leftIcon ? props.customize.leftIcon.paddingLeft : undefined
                              }}>
                    {/* If multiple values need to be shown or autocomplete enabled, then presenting selected as chips */}
                    {multiple && value.map((option, index) => (
                        <StyledTag label={props.getter.label(option)} {...getTagProps({index})} key={index}/>
                    ))}

                    {props.customize?.leftIcon && <div className="absolute top-50 left-[16px] translate-middle-y">
                        {props.customize.leftIcon.el}
                    </div>}

                    {/* Showing selected value(s) as simple text, rather than chips + blocking ability to enter text, if no autocomplete specified*/}
                    <input
                        ref={ref}
                        {...getInputProps()}
                        placeholder={value.length === 0 ? props.placeholder : ""}
                        readOnly={!autocomplete}
                        className={`text-accent ${props.disabled ? "!bg-inputs-disabled" : ""}`}
                        {...{...(props.emptyValue && value.length == 0 && (autocomplete ? !isOpen : true) && {value: props.emptyValue})}}
                    />

                    <div className="absolute top-50 right-[16px] translate-middle-y text-dropdown-icon">
                        {
                            props.hideArrow 
                            ? null
                            :
                            (isLoading || isNextPageLoading)
                                ? <CircularProgress color="inherit" size={20} style={{marginTop: "5px"}}/>
                                : <DropdownIcon className="dropdown-icon"/>
                        }
                    </div>

                    {
                        props.disabled && props.disableReason &&
                        <BaseConditionBlocker
                            disabled={{
                                state: props.disabled,
                                reason: props.disableReason
                            }}
                            simplifyState={state => setIsDisabled(state)}
                            position="left"
                        />
                    }

                </InputWrapper>
            </div>

            {
                !props.hideErrors &&
                <BasePopper anchorEl={anchorEl} open={isOpen && !!props.error} placement="top-start">
                    <div
                        className="bg-[#fff] p-[14px] rounded-[8px] levitation text-inputs-textError font-thin text-sm space-y-[4px]"
                        style={{width: topLevelAnchorRef.current?.offsetWidth}}>
                        <div className="flex items-center"><CrossSVG
                            style={{transform: "scale(.7)", marginRight: "4px", marginTop: "-1px"}}/>
                            <span>{typeof props.error === "string" ? props.error : props.error?.message}</span></div>
                    </div>
                </BasePopper>
            }

            <BasePopper open={isOpen && !isLoading && !!topLevelAnchorRef.current} anchorEl={topLevelAnchorRef.current}>
                <Listbox {...getListboxProps()} sx={{width: topLevelAnchorRef.current?.offsetWidth}}>
                    {groupedOptions.length ?
                        // If virtualization is enabled, then rendering only visible elements
                        virtualize ?
                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={itemCount}
                                loadMoreItems={loadMoreItems}
                            >
                                {({onItemsRendered, ref}) => (
                                    <FixedSizeList
                                        height={Math.min(groupedOptions.length * virtualize.elementHeight, 222)}
                                        width={"100%"}
                                        itemCount={groupedOptions.length}
                                        itemSize={virtualize.elementHeight}
                                        overscanCount={virtualize.overscanCount}
                                        ref={ref}
                                        onItemsRendered={onItemsRendered}
                                    >
                                        {
                                            ({index, style}) => {
                                                const option = (groupedOptions as T[])[index];
                                                return <ListOption
                                                    option={option}
                                                    getter={props.getter}
                                                    optionProps={getOptionProps({option, index})}
                                                    style={style}
                                                />
                                            }
                                        }
                                    </FixedSizeList>
                                )}
                            </InfiniteLoader>
                            :
                            // If no virtualization, then rendering all elements with the typical approach
                            <div className="max-h-[220px] overflow-auto">
                                {
                                    (groupedOptions as T[]).map((option, index) => (
                                            <li {...getOptionProps({option, index})} key={props.getter.key(option)}>
                                                {
                                                    props.getter.renderOption?.(option, <CheckIcon fontSize="small"
                                                                                                   className="selected-svg"/>)
                                                    ??
                                                    <div>
                                                        <div className="grow">{props.getter.label(option)}</div>
                                                        <CheckIcon fontSize="small" className="selected-svg"/>
                                                        {
                                                            !!props.getter.caption &&
                                                            <span
                                                                className="unselected-caption text-lightGreen-500 font-thin">{props.getter.caption(option)}</span>
                                                        }
                                                    </div>
                                                }
                                            </li>
                                        )
                                    )
                                }
                            </div>
                        :
                        <NothingFoundBanner/>
                    }
                    {
                        props.action && <div className="mt-[14px]">
                            <BaseMaterialButton onClick={props.action.onClick} className="w-full"
                                                fontWeight={600} size="large">
                                {props.action.title}
                            </BaseMaterialButton>
                        </div>
                    }
                </Listbox>
            </BasePopper>
        </div>
    );
}


const NothingFoundBanner = () => {
    return (
        <div className="text-center text-accent opacity-50">
            Nothing found
        </div>
    )
}

function compareKeys<T extends number | string>(key1: T, key2: T): boolean {
    if (typeof key1 === "string") {
        return key1.localeCompare(key2 as string) === 0;
    } else {
        return key1 === key2;
    }
}

const BaseDropdown = forwardRef(BaseDropdownInner) as <T>(props: BaseDropdownProperties<T extends T[] ? ArrayElementType<NonNullable<T>> : NonNullable<T>> & {
    ref?: ForwardedRef<HTMLInputElement>
}) => JSX.Element;

export default BaseDropdown;

/**
 * Adapts the InfiniteLoader configuration to the BaseDropdown configuration.
 * @param props
 * @return
 * Provides properties:
 * - options: options to display
 * - onOpen: function to trigger when dropdown is opened
 * - onInputChange: function to trigger when input value changes
 * - virtualize: whether to enable virtualization
 * - lazyLoading: configuration for lazy loading
 * - isLoading: whether to show loading spinner
 */
export function adaptInfiniteLoader<T>(props: BaseInfiniteLoaderChildrenProps<T>) {
    const isDataPresent = props.data.length > 0;
    return {
        options: props.data,
        onOpen: () => {
            if(!props.data.length){
                props.trigger();
            }
        },
        onInputChange: (_: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason ) => {
            props.onSearch(value);
        },
        virtualize: true,
        lazyLoading: {
            loadNextPage: () => props.trigger(),
            hasNextPage: props.hasNextPage,
            isNextPageLoading: props.isNextPageLoading || props.isTyping,
        },
        autocomplete: true,
        isLoading: props.isNextPageLoading && !isDataPresent,
    }
}