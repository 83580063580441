import { Product } from "@/types/general";
import React from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import PurchasePricesTable
    from "@components/Dashboard/pages/Products/components/Tabs/items/PricesTab/components/PurchasePricesTable";
import SalePricesTable
    from "@components/Dashboard/pages/Products/components/Tabs/items/PricesTab/components/SalePricesTable";

interface PricesTabProperties {
    product: Product.Extended;
}

export default function PricesTab(props: PricesTabProperties) {
    return (
        <>
            <BaseInputsGrid cols={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 2
            }}>
                <PurchasePricesTable product_id={props.product.id} />
                <SalePricesTable product_id={props.product.id} />
            </BaseInputsGrid>
        </>
    );
}