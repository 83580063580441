import React, { useEffect, useState } from "react";
import { Collection } from "@/types/general";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@/components/reusables/BaseInputsGrid";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseInput from "@/components/reusables/BaseInput";
import BaseButton from "@/components/reusables/BaseButton";
import BaseMaterialButton from "@/components/reusables/BaseMaterialButton";
import BaseTable from "@/components/reusables/BaseTable";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import ProductMutationModal from "../modals/ProductMutationModal";
import { ArrayElementType } from "@helpers/utils";
import { Alert, Collapse } from "@mui/material";

type EditingLayoutProperties = {
    collection?: Collection.Root;
    buttonText: string;
    onSubmit: (data: FormTyping) => void;
};

const formScheme = z.object({
    code: z.coerce.string().optional(),
    name: z.string().nonempty(),
    barcode: z.string().nullish(),
    products: z
        .array(
            z.object({
                id: z.number(),
                code: z.string(),
                name: z.string(),
                quantity: z.number()
            })
        )
        .min(1)
});

type FormTyping = z.infer<typeof formScheme>;

export default function EditingLayout({
                                          collection,
                                          ...props
                                      }: EditingLayoutProperties) {
    const { t } = useTranslation("", {
        keyPrefix: "inventory.collections.collectionMutation"
    });

    // === RHF === //
    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState
    } = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    });

    const {
        fields: products,
        append,
        remove,
        update
    } = useFieldArray({
        control,
        name: "products",
        keyName: "loop_id"
    });

    const [isProductMutationModalOpen, setIsProductMutationModalOpen] = useState(false);

    const onSubmit = handleSubmit((data) => {
        props.onSubmit({
            ...data
        });
    });

    const [editedProduct, setEditedProduct] = useState<ArrayElementType<FormTyping["products"]>>();

    useEffect(() => {
        reset();
        if (collection) {
            setValue("code", collection.code);
            setValue("name", collection.name);
            setValue("barcode", collection.barcode);

            collection.products.forEach((e) => {
                append({
                    id: e.id,
                    code: e.code,
                    name: e.name,
                    quantity: e.quantity
                });
            });
        }
    }, [collection, reset]);

    return (
        <>
            <form className="space-y-[40px]" onSubmit={onSubmit}>
                <BaseInputsGrid>
                    <Controller
                        control={control}
                        name="code"
                        disabled={!!collection}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.code.label")}
                                placeholder={t("fields.code.placeholder")}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="name"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.name.label") + " *"}
                                placeholder={t("fields.name.placeholder")}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="barcode"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.barcode.label")}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <Collapse in={!!formState.errors.products}>
                    <Alert severity="error">
                        {t("fields.products.validationErrors.zeroProducts")}
                    </Alert>
                </Collapse>

                <div className="flex items-center justify-between">
                    <div className="text-xl text-accent">{t("fields.products.header")}</div>
                    {
                        products.length > 0 &&
                        <BaseMaterialButton
                            type="button"
                            fontWeight={500}
                            onClick={() => {
                                setEditedProduct(undefined);
                                setIsProductMutationModalOpen(true);
                            }}
                        >
                            {t("fields.products.addButton")}
                        </BaseMaterialButton>
                    }
                </div>
                <div className="border border-solid border-gray-300 rounded-[8px] p-[16px] !mt-[24px]">
                    {products.length ? (
                        <>
                            <BaseTable
                                data={products}
                                columns={[
                                    {
                                        header: t("fields.products.table.columns.0"),
                                        getter: (row) => row.code,
                                        sx: {
                                            minWidth: "150px"
                                        }
                                    },
                                    {
                                        header: t("fields.products.table.columns.1"),
                                        getter: (row) => row.name
                                    },
                                    {
                                        header: t("fields.products.table.columns.2"),
                                        getter: (row) => row.quantity
                                    },
                                    {
                                        header: t("fields.products.table.columns.3"),
                                        getter: (row, index) => (
                                            <>
                                                <div className="space-x-[12px]">
                                                    <EditSVG
                                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                                        onClick={() => {
                                                            setEditedProduct(products[index]);
                                                            setIsProductMutationModalOpen(true);
                                                        }}
                                                    />

                                                    <TrashSVG
                                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                                        onClick={() => {
                                                            remove(index);
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        ),
                                        sx: {
                                            width: "100px"
                                        }
                                    }
                                ]}
                                size={"small"}
                                headerSx={{
                                    background: "#F9F9F9"
                                }}
                            />
                        </>
                    ) : (
                        <div className="flex flex-column items-center justify-center space-y-[24px] h-[340px]">
                            <div className="text-xl text-center text-gray-300 font-thin mx-[24px]">
                                {t("fields.products.placeholder")}
                            </div>
                            <BaseMaterialButton
                                type="button"
                                size="large"
                                fontWeight={500}
                                className={"w-[200px]"}
                                onClick={() => {
                                    setEditedProduct(undefined);
                                    setIsProductMutationModalOpen(true);
                                }}
                            >
                                {t("fields.products.addButton")}
                            </BaseMaterialButton>
                        </div>
                    )}
                </div>

                <div className="h-[24px]"></div>
                <div className="flex justify-center">
                    <BaseButton text={props.buttonText} size="md" type={"submit"} />
                </div>
            </form>

            <ProductMutationModal
                product={editedProduct}
                alreadySelectedProdIds={products.map((value) => value.id)}
                isOpen={isProductMutationModalOpen}
                onSubmit={(prod) => {
                    // If the product is being edited, update the product in the array
                    if (editedProduct?.id) {
                        const index = products.findIndex(
                            (field) => field.id === editedProduct.id
                        );
                        if (index !== -1) {
                            update(index, {
                                id: prod.selectedProduct.id,
                                code: prod.selectedProduct.code,
                                name: prod.selectedProduct.name,
                                quantity: prod.quantity
                            });
                        }
                    } else {
                        // If the product is not being edited, add the product to the array
                        append({
                            id: prod.selectedProduct.id,
                            code: prod.selectedProduct.code,
                            name: prod.selectedProduct.name,
                            quantity: prod.quantity
                        });
                    }
                    setIsProductMutationModalOpen(false);
                }}
                onClose={() => {
                    setIsProductMutationModalOpen(false);
                }}
            ></ProductMutationModal>
        </>
    );
}
