import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import {
    selectAllCustomFieldsGroupedByModule,
    selectCustomFieldById,
    useGetCustomFieldsQuery
} from "@redux/features/customFields/customFieldsApi";
import { useAppSelector } from "@redux/hooks";
import CollapsableModule
    from "@components/Dashboard/pages/SettingsPage/General/CustomFields/components/CollapsableModule";
import MutationModal from "@components/Dashboard/pages/SettingsPage/General/CustomFields/modals/MutationModal";
import DisablingModal from "@components/Dashboard/pages/SettingsPage/General/CustomFields/modals/DisablingModal";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import BetaBadge from "@ready/BetaBadge";
import DeletionModal from "@components/Dashboard/pages/SettingsPage/General/CustomFields/modals/DeletionModal";
import NoPermissionBanner from "@components/ErrorPages/NoPermissionBanner";
import { Can } from "@/casl.config";

export default function CustomFieldsPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.customFields.main" });

    const { isLoading: isLoadingCustomFields } = useGetCustomFieldsQuery();

    const customFieldsGroupedByModule = useAppSelector(state => selectAllCustomFieldsGroupedByModule(state));

    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDisablingModalOpen, setIsDisablingModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    const [selectedFieldId, setSelectedFieldId] = useState<number>();
    const [disablingFieldId, setDisablingFieldId] = useState<number>();
    const [deletionFieldId, setDeletionFieldId] = useState<number>();

    const selectedField = useAppSelector(state => selectCustomFieldById(state, selectedFieldId));
    const disablingField = useAppSelector(state => selectCustomFieldById(state, disablingFieldId));
    const deletionField = useAppSelector(state => selectCustomFieldById(state, deletionFieldId));

    return (
        <>
            <div className={"levitation-extended"}>
                <div className="flex flex-row justify-between items-center mb-[44px]">

                    <div className={"flex items-center space-x-2"}>
                        <div className="font-bold text-2xl text-accent">{t("heading")}</div>
                        <BetaBadge />
                    </div>

                    <Can I="create" a="custom_fields">
                        <BaseMaterialButton
                            fontWeight={500}
                            onClick={() => {
                                setIsMutationModalOpen(true);
                            }}
                        >
                            {t("table.buttons.add")}
                        </BaseMaterialButton>
                    </Can>

                </div>

                <Can not I="index" a="custom_fields">
                    <NoPermissionBanner />
                </Can>

                <Can I="index" a="custom_fields">
                    <BaseRecordRenderer
                        loading={isLoadingCustomFields}
                        record={() => customFieldsGroupedByModule.length > 0}
                        bannerHeight={400}
                        notFoundText={t("table.nothingFound")}
                        hideLevitation
                    >
                        <div className={"min-h-[400px]"}>
                            {
                                customFieldsGroupedByModule.map((module, index) =>
                                    <CollapsableModule
                                        key={index}
                                        module={module}
                                        onEdit={(field) => {
                                            setSelectedFieldId(field.id);
                                            setIsMutationModalOpen(true);
                                        }}
                                        onToggle={(field) => {
                                            setDisablingFieldId(field.id);
                                            setIsDisablingModalOpen(true);
                                        }}
                                        onDelete={(field) => {
                                            setDeletionFieldId(field.id);
                                            setIsDeletionModalOpen(true);
                                        }}
                                    />
                                )
                            }
                        </div>
                    </BaseRecordRenderer>
                </Can>
            </div>

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => {
                    setSelectedFieldId(undefined);
                    setIsMutationModalOpen(false);
                }}
                field={selectedField}
            />

            <DisablingModal
                isOpen={isDisablingModalOpen}
                onClose={() => {
                    setIsDisablingModalOpen(false);
                }}
                field={disablingField}
            />

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => {
                    setIsDeletionModalOpen(false);
                }}
                field={deletionField}
            />
        </>
    );
}