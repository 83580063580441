import {AbilityBuilder, PureAbility} from "@casl/ability";
import {Permission} from "@/types/general";
import {createContext} from "react";
import {createContextualCan} from "@casl/react";

export const ability = new PureAbility<[Permission.Components.Ability, Permission.Components.Modules]>();

export function updateAbility(permissions: Permission.Root[]) {
    const {can: allow, rules} = new AbilityBuilder<typeof ability>(PureAbility);

    permissions.forEach(permission => {
        const {module_key: module, abilities} = permission;
        abilities.forEach(ability => {
            const {key: action} = ability;
            allow(action, module);
        });
    });

    ability.update(rules);
}

export const AbilityContext = createContext(ability)
export const Can = createContextualCan(AbilityContext.Consumer)
