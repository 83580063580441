import React from 'react'
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import { Product } from '@/types/general';
import SerialNumbersTab from './components/SerialNumbersTab';
import BatchNumbersTab from './components/BatchNumbersTab';

interface NumbersTabProperties {
    product: Product.Extended;
}
export default function NumbersTab({product}: NumbersTabProperties) {
    return (
        <BaseInputsGrid cols={{
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 2
        }}>
            <SerialNumbersTab product={product} />
            <BatchNumbersTab product={product} />
        </BaseInputsGrid>
    )
}
