import { Button, styled } from "@mui/material";
import React, { PropsWithChildren } from "react";

export default function TableButton({children, onClick, className}: PropsWithChildren<{onClick?: () => void, className?: string}>){
    return(
        <TableButtonStyled size="small" onClick={onClick} className={className}>
            {children}
        </TableButtonStyled>
    )
}

const TableButtonStyled = styled(Button)(({theme}) => ({
    backgroundColor: theme.custom.purple[100],
    color: theme.custom.purple[400],
    borderRadius: "10px",
    fontSize: "12px",
    textTransform: "initial",
    lineHeight: "initial",
    padding: "2px 8px",
    fontWeight: "400",
    transition: "none",
    border: "1px solid " + theme.custom.purple[100],
    "&:hover":{
        backgroundColor: "none"
    }
}));