import React, { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    ArrayElementType,
    declOfNum,
    fetchArrayReactively,
    fetchEntity,
    genT,
    PredefinedTranslations,
    useFilters,
    useOrdering,
    usePagination,
    useRangeFilter
} from "@helpers/utils";
import { Category, Product } from "@/types/general";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from "@reusables/BaseTable";

import { ReactComponent as VariantsSVG } from "@assets/icons/ic_variants.svg";
import { ReactComponent as CheckSVG } from "@assets/icons/ic_check.svg";
import { ReactComponent as CrossSVG } from "@assets/icons/ic_cross.svg";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseDropdown from "@reusables/dropdowns/BaseDropdownLegacy";
import { MinMaxFilters } from "../types";
import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BaseBadge from "@reusables/BaseBadge";
import { Box, Forklift } from "lucide-react";
import { useGetProductsFullQuery } from "@/redux/features/products/productsApi";
import { Pagination } from "@mui/material";
import DeletionModal from "../components/modals/DeletionModal";
import BaseSyncedFromSourcesBadge from "src/components/reusables/BaseSyncedFromSourcesBadge";
import { useGetFileInfoMutation } from "@/redux/features/importApi";
import { isErrorWithMessage } from "@/redux/api/query";
import { toast } from "react-toastify";
import { useAppDispatch } from "@/redux/hooks";
import { setImportData, setImportFile } from "@/redux/features/importExport/import/importInfoSlice";
import ProductsExportModal from "@components/Dashboard/pages/Products/MainPage/parts/ProductsExportModal";
import _ from "lodash";
import ProductsImportModal from "@components/Dashboard/pages/Products/MainPage/parts/ProductsImportModal";

export default function ProductsPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "products.allProducts" });

    const ability = useAbility(AbilityContext);

    const dispatch = useAppDispatch();

    // =========== Dropdowns related ========== //
    const {
        data: categoriesOptions,
        loading: categoriesOptionsLoading
    } = fetchArrayReactively<Category.Root>({ route: "category" });
    const [selectedCategories, setSelectedCategories] = useState<typeof categoriesOptions>([]);

    const componentsOptions = [
        {
            id: 0,
            value: t("filters.dropdowns.components.options.0") // NO
        },
        {
            id: 1,
            value: t("filters.dropdowns.components.options.1") // YES
        }
    ];
    const [selectedComponents, setSelectedComponents] = useState<typeof componentsOptions>([]);

    const typesOptions = [
        {
            id: 0,
            value: t("filters.dropdowns.types.options.0") // Product
        },
        {
            id: 1,
            value: t("filters.dropdowns.types.options.1") // Service
        }
    ];
    const [selectedType, setSelectedType] = useState<ArrayElementType<typeof typesOptions>>();

    // =========== FILTERS RELATED =========== //
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

    const [sellingPriceRange, setSellingPriceRange, sellingPriceRangeDebounced] = useRangeFilter();
    const [inStockRange, setInStockRange, inStockRangeDebounced] = useRangeFilter();

    const { orderBy, setOrderBy } = useOrdering<Product.DTO.OrderBy>({ name: "id", type: "desc" });

    const filters = useFilters(
        () => ({
            search: searchingValue,
            categories: selectedCategories.map((x) => x.id),
            selling_price_range: sellingPriceRangeDebounced,
            quantity_range: inStockRangeDebounced,
            components: selectedComponents.length ? selectedComponents[0].id : undefined,
            type: selectedType?.id
        }),
        [searchingValue, selectedCategories, sellingPriceRangeDebounced, inStockRangeDebounced, selectedComponents, selectedType]
    );

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data: productsData, isLoading: productsLoading } = useGetProductsFullQuery({
        filters,
        orderBy,
        pagination,
        nest_variants: true
    });

    const paginationAdaptation = pagination.adapt(productsData);

    const {
        data: productsRanges,
        loading: productsRangesLoading
    } = fetchEntity<MinMaxFilters>({ route: "product-filters" });

    const [getFileInfo, { isLoading: fileInfoLoading }] = useGetFileInfoMutation();
    const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
    // const [importData, setImportData] = useState<{payload: { rows: number, hash: string, headers: string[] }}>();
    // const [importFile, setImportFile] = useState<File>();

    const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
    const [activeProductDeletion, setActiveProductDeletion] = useState<Product.Root>();

    return (
        <>
            {/* FILTERS BLOCK */}
            <BaseAdminFilter
                permissionModule={"product"}
                filterItems={
                    <div className="w-[363px]">
                        <BaseInputsGrid cols={1} gap={24}>
                            <BaseDropdown
                                label={t("filters.dropdowns.category.label")}
                                options={categoriesOptions}
                                value={selectedCategories}
                                getter={{
                                    label: (opt) => opt.name,
                                    key: (opt) => opt.id
                                }}
                                onChange={(newState) => setSelectedCategories(newState)}
                                autocomplete
                                multiple
                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}
                                isLoading={categoriesOptionsLoading}
                                brightLabel
                            />

                            {/*TODO [nekear]: fix sliders and uncomment (https://linear.app/suppli-dev/issue/QA-69/problem-with-slide-bar-when-you-try-to-filter-in-product-list) */}
                            {/*<div>*/}
                            {/*    <div className="font-semibold mb-2">*/}
                            {/*        {t("filters.ranges.sellingPrice")}*/}
                            {/*    </div>*/}
                            {/*    <BaseRangeSlider*/}
                            {/*        min={productsRanges?.selling_price.min ?? 0}*/}
                            {/*        max={productsRanges?.selling_price.max ?? 0}*/}
                            {/*        value={sellingPriceRange}*/}
                            {/*        onChange={(e, value) => {*/}
                            {/*            setSellingPriceRange(value ? [roundTo(value[0], 2), roundTo(value[1], 2)] : undefined)*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <div className="font-semibold mb-2">*/}
                            {/*        {t("filters.ranges.quantityInStock")}*/}
                            {/*    </div>*/}
                            {/*    <BaseRangeSlider*/}
                            {/*        min={productsRanges?.quantity_in_stock.min ?? 0}*/}
                            {/*        max={productsRanges?.quantity_in_stock.max ?? 0}*/}
                            {/*        value={inStockRange}*/}
                            {/*        onChange={(e, value) => {*/}
                            {/*            setInStockRange(value)*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            <BaseDropdown
                                label={t("filters.dropdowns.components.label")}
                                options={componentsOptions}
                                value={selectedComponents}
                                getter={{
                                    label: (opt) => opt.value,
                                    key: (opt) => opt.id
                                }}
                                onChange={(newState) => setSelectedComponents(newState)}
                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}
                                brightLabel
                            />

                            <BaseDropdown
                                label={t("filters.dropdowns.types.label")}
                                options={typesOptions}
                                value={selectedType}
                                getter={{
                                    label: (opt) => opt.value,
                                    key: (opt) => opt.id
                                }}
                                onChange={(newState, opt) => setSelectedType(opt)}
                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}
                                brightLabel
                            />
                        </BaseInputsGrid>
                    </div>
                }
                handleSearch={searchInputDebounce}
                handleFilter={(e) => console.log(e)}
                handleImport={(file) => {
                    if (!file) return;

                    const formData = new FormData();
                    formData.append("file", file);

                    getFileInfo(formData).unwrap()
                    .then((data) => {
                        dispatch(setImportData(data));
                        dispatch(setImportFile(file));
                        setIsImportModalOpen(true);
                    })
                    .catch((err) => {
                        if (isErrorWithMessage(err)) {
                            toast.error(err.message);
                        } else {
                            console.log(err);
                        }
                    });
                }}
                handleExport={() => setIsExportModalOpen(true)}
                handleCreate={() => history.push("/dashboard/products/new")}
            />

            <div className="levitation-extended mt-[32px]">
                <Can not I="index" a="product">
                    <NoPermissionBanner />
                </Can>

                <Can I="index" a="product">
                    <div className="mb-[24px]">
                        {
                            <div className="bold-highlight capitalize">
                                {productsData?.meta?.total}{" "}
                                {declOfNum(productsData?.meta?.total ?? 0, [t("table.titleDeclinations.0"), t("table.titleDeclinations.1"), t("table.titleDeclinations.2")])}
                            </div>
                        }
                    </div>

                    <BaseTable
                        data={productsData?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row, index, isCollapsed) => {
                                    const syncedFromSources = <BaseSyncedFromSourcesBadge
                                        integrations={[
                                            {
                                                slug: "tripletex",
                                                integrated: () => !!row.tripletex_id
                                            },
                                            {
                                                slug: "lime",
                                                integrated: () => !!row.lime_id
                                            }
                                        ]}
                                    />;

                                    return <>
                                        <Can I="show" a="product">
                                            <div className="flex flex-row justify-between">
                                                <div className="hover:opacity-70 transition-[.15s] cursor-pointer"
                                                     onClick={() => history.push(`/dashboard/products/${row.id}/details`)}>
                                                    <div className={"flex flex-row items-center space-x-2"}>
                                                        <div className="bold-highlight">{row.name}</div>
                                                        {syncedFromSources}
                                                    </div>
                                                    <div className="text-sm text-accent">{row.code}</div>
                                                </div>
                                                {row.variants && row.variants.length > 0 && (
                                                    <div className="flex flex-grow-0 items-center">
                                                        <VariantsSVG
                                                            className={`${isCollapsed ? "text-purple-400" : "text-[#D9DAFD]"} cursor-pointer hover:opacity-70 transition-[.15s]`}
                                                            // onClick={() => history.push(`/dashboard/products/${row.id}/variants`)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Can>

                                        <Can not I="show" a="product">
                                            <div>
                                                <div className={"flex flex-row items-center space-x-2"}>
                                                    <div className="bold-highlight">{row.name}</div>
                                                    {syncedFromSources}
                                                </div>
                                                <div className="text-sm text-accent">{row.code}</div>
                                            </div>
                                        </Can>
                                    </>;
                                },
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) => {
                                    if (row.is_service) {
                                        return (
                                            <BaseBadge className={"bg-light-green"}>
                                                <Forklift size={12} className={"mr-1"} />
                                                <span>{t("table.isService")}</span>
                                            </BaseBadge>
                                        );
                                    } else {
                                        return (
                                            <BaseBadge className={"bg-purple-400 text-[#fff]"}>
                                                <Box size={12} className={"mr-1 -mt-0.5"} />
                                                <span>{t("table.isProduct")}</span>
                                            </BaseBadge>
                                        );
                                    }
                                }
                            },
                            {
                                header: t("table.columns.2"),
                                getter: (row) => row.category?.name ?? "-"
                            },
                            {
                                header: t("table.columns.3"),
                                getter: (row) => row.prices.selling_price?.toFixed(2) ?? "-",
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.4"),
                                getter: (row) => (row.is_service ? <></> : row.in_stock ?? "-"),
                                comparator: () => 0
                            },
                            {
                                header: () => <div className="text-center">{t("table.columns.5")}</div>,
                                getter: (row, index, isCollapsed) => (
                                    <div className="text-center">
                                        {row.is_component ? <CheckSVG className="text-tables-successIcon" /> : <CrossSVG
                                            className={`${isCollapsed ? "text-accent" : "text-tables-secondaryIcon"}`} />}
                                    </div>
                                )
                            },
                            {
                                visible: ability.can("update", "product"),
                                header: <div className="text-center">{t("table.columns.6")}</div>,
                                getter: (row, index, isCollapsed) => (
                                    <div className="text-center space-x-[12px]">
                                        <Can I="update" a="product">
                                            <EditSVG
                                                className={`${isCollapsed ? "text-accent" : "text-tables-secondaryIcon"} hover:text-tables-highlightedIcon cursor-pointer`}
                                                onClick={() => history.push(`/dashboard/products/${row.id}/edit`)}
                                            />
                                        </Can>
                                        <Can I="delete" a="product">
                                            <TrashSVG
                                                className={`${isCollapsed ? "text-accent" : "text-tables-secondaryIcon"} hover:text-tables-highlightedIcon cursor-pointer`}
                                                onClick={() => setActiveProductDeletion(row)}
                                            />
                                        </Can>
                                        {/*TODO: add QPO to the products*/}
                                        {/*<CartSVG className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"/>*/}
                                    </div>
                                ),
                                preventCollapsePropagation: true
                            }
                        ]}
                        collapse={{
                            content: (rowRelated, state) => {
                                return (
                                    <BaseTable
                                        data={rowRelated.variants ?? []}
                                        columns={[
                                            {
                                                header: t("subtable.columns.0"),
                                                getter: (row) => (
                                                    <Link to={`/dashboard/products/${row.id}/details`}>
                                                        <u>{row.code}</u>
                                                    </Link>
                                                ),
                                                comparator: () => 0
                                            },
                                            {
                                                header: t("subtable.columns.1"),
                                                getter: (row) => (
                                                    <Link to={`/dashboard/products/${row.id}/details`}>
                                                        <u>{row.name}</u>
                                                    </Link>
                                                ),
                                                comparator: () => 0
                                            },
                                            {
                                                header: t("subtable.columns.2"),
                                                getter: (row) => row.prices?.selling_price,
                                                comparator: () => 0
                                            },
                                            {
                                                header: t("subtable.columns.3"),
                                                getter: (row) => row.in_stock ?? "-",
                                                comparator: () => 0
                                            },
                                            {
                                                header: "",
                                                getter: (row) => (
                                                    <div className="text-center space-x-[12px]">
                                                        <Can I="update" a="product">
                                                            <EditSVG
                                                                className="text-accent hover:text-purple-400 cursor-pointer"
                                                                onClick={() => history.push(`/dashboard/products/${row.id}/edit`)} />
                                                        </Can>
                                                        <Can I="delete" a="product">
                                                            <TrashSVG
                                                                className="text-tables-highlightedIcon hover:text-purple-400 cursor-pointer"
                                                                onClick={() => setActiveProductDeletion(row)} />
                                                        </Can>
                                                    </div>
                                                )
                                            }
                                        ]}
                                        size="small"
                                        boldHeaders
                                        hideTableWhenNothingFound
                                        nothingFound={{
                                            height: 200
                                        }}
                                    />
                                );
                            },
                            fill: "#D9DAFD",
                            borderColor: "#B1B2E1"
                        }}
                        alternate
                        isDataLoading={productsLoading}
                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: Product.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "productName";
                                            break;
                                        case 2:
                                            name = "sellingPrice";
                                            break;
                                        case 3:
                                            name = "inStock";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />

                    <Pagination className="mt-[32px]" {...paginationAdaptation} />
                </Can>
            </div>

            <ProductsExportModal
                isOpen={isExportModalOpen}
                onClose={() => setIsExportModalOpen(false)}
                filters={filters}
                orderBy={orderBy}
            />

            {/* IMPORT MODAL */}
            <ProductsImportModal
                isOpen={isImportModalOpen}
                onClose={() => setIsImportModalOpen(false)}
            />

            {/* DELETION MODAL */}
            <DeletionModal isOpen={!!activeProductDeletion} entity={activeProductDeletion}
                           onClose={() => setActiveProductDeletion(undefined)} />
        </>
    );
}
