import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

import { Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import { useGetIndustriesQuery } from "@redux/features/industries/industriesApi";
import { useGetCountriesQuery } from "@redux/api/internalApiSlice";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseButton from "@reusables/BaseButton";

import {
    companiesApi,
    useCreateCompanyMutation,
    useUpdateActiveCompanyMutation,
    useUpdateCompanyMutation
} from "@redux/features/companies/companiesApi";
import { toast } from "react-toastify";
import { setSelectedCompany } from "@redux/features/companies/companiesSlice";
import BasePhoneInput from "@reusables/BasePhoneInput";
import BaseImageUploader from "@reusables/BaseImageUploader";
import { setCompany } from "@redux/features/auth/authSlice";
import { getCountryFlag } from "@helpers/utils";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import CompaniesDropdown from "../../components/elements/CompaniesDropdown";
import { Company } from "@/types/general";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import CurrencyDropdown from "./components/CurrencyDropdown";
import { Typography } from "@mui/material";
import { Printer } from "lucide-react";
import { isErrorWithMessage } from "@redux/api/query";
import { refinements } from "@helpers/refinements";

const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

const MAX_FILE_SIZE = import.meta.env.VITE_MAX_LOGO_SIZE;

const imageDomainURLPrefix = import.meta.env.VITE_API_URL;

const formScheme = z.object({
    mode: z.enum(["create", "update"]),

    name: z.string().trim().nonempty().min(3).max(255),
    industry: z.object({
        id: z.number(),
        name: z.string(),
    }).nullish(),
    country: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
    }),
    street: z.string().trim().refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message),
    street_2: z.string().trim().refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message).nullish(),
    city: z.string().trim().refine(refinements.nonNumeric.refine, refinements.nonNumeric.message),
    zipcode: z.string().trim().refine(refinements.numericRequired.refine, refinements.numericRequired.message),
    phone: z.string().trim(),
    email: z.string().trim().email(),
    website: z.string().trim().refine(refinements.domainName.refine, refinements.domainName.message),
    registration_number: z.string().refine(refinements.validateCRN.refine, refinements.validateCRN.message),
    base_currency: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
        symbol: z.string(),
    }).optional(),
    printer_email: z.string().trim().refine(refinements.email.refine, refinements.email.message).nullish(),
    logo: z.any().optional()
    .refine((file) => (file?.size ?? 0) <= MAX_FILE_SIZE, `Max image size is 1MB.`)
    .refine(
      (file) => !file || file.type === "" || ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, .png and .webp formats are supported."
    )
}).superRefine((data, ctx) => {
    // If mode is "creation", baseCurrency is required
    console.log("mode", data.mode, data.base_currency);
    if (data.mode === "create" && !data.base_currency) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["base_currency"],
            params: {
                i18n: "general.validation.global.required"
            }
        });
    }
});

export type DetailsTabFormTyping = z.infer<typeof formScheme>

export default function DetailsTab() {
    const {t} = useTranslation("", {keyPrefix: "settings.general.companyInformation.tabs.companyDetails"});
    
    const dispatch = useAppDispatch();


    // Use RTK Query's built-in hooks for fetching company data
    const selectedCompany = useAppSelector(state => state.companies.selectedCompany);
    const activeCompany = useAppSelector(state => state.auth.user?.company);

    const [createCompany, {isLoading: isCreationLoading}] = useCreateCompanyMutation();
    const [updateCompany, {isLoading: isUpdateLoading}] = useUpdateCompanyMutation();
    const [updateDefaultCompany, {isLoading: isUpdateDefaultLoading}] = useUpdateActiveCompanyMutation();


    const {data: industryOptions = [], isLoading: industryOptionsLoading} = useGetIndustriesQuery();
    const {data: countryOptions = [], isLoading: countryOptionsLoading} = useGetCountriesQuery();

    const {control, handleSubmit, setValue, reset, watch} = useForm<DetailsTabFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            mode: "create"
        }
    });

    const selectedCountry = useWatch({
        control,
        name: "country",
    });

    const logo = useWatch({
        control,
        name: "logo",
    })

    const [isModalVisible, setModalVisible] = useState(false);
    const [originalRegistrationNumber, setOriginalRegistrationNumber] = useState('');
    const [pendingFormData, setPendingFormData] = useState<DetailsTabFormTyping>();

    // Ref for focusing the company name input on new company creation
    const companyNameInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        reset();

        if (selectedCompany) {
            setOriginalRegistrationNumber(selectedCompany.registration_number.value);

            setValue('mode', 'update');
            setValue('name', selectedCompany.name);
            setValue('industry', selectedCompany.industry);
            setValue('country', selectedCompany.country);
            setValue('street', selectedCompany.street);
            setValue('street_2', selectedCompany.street_2);
            setValue('city', selectedCompany.city);
            setValue('zipcode', selectedCompany.zipcode);
            setValue('phone', selectedCompany.phone);
            setValue('email', selectedCompany.email);
            setValue('website', selectedCompany.website);
            setValue('registration_number', selectedCompany.registration_number.value);
            setValue('printer_email', selectedCompany.printer_email);

            if(selectedCompany.logo){
                fetch(imageDomainURLPrefix + selectedCompany.logo)
                    .then(r => r.blob())
                    .then(blobFile => new File([blobFile], selectedCompany.logo as string))
                    .then(file => {
                        setValue("logo", file);
                    })
            }
        }
    }, [selectedCompany, setValue, reset]);

    // Main submit handler, called on creation / update submit
    const onSubmit = handleSubmit((data) => {
        // If registration number has changed, show confirmation modal (since we have only 2 attempts)
        const isRegistrationNumberChanged = data.registration_number !== originalRegistrationNumber;
        if (selectedCompany && isRegistrationNumberChanged) {
            setPendingFormData(data);
            setModalVisible(true);
            return;
        }

        // Performing mutation
        performMutation(data);
    });

    // Additional submitting, if user confirms registration number change
    const handleModalConfirm = useCallback(() => {
        if (pendingFormData) {
            performMutation(pendingFormData);
            setPendingFormData(undefined);
        }
        setModalVisible(false);
    }, [pendingFormData]);

    // Implemented as a separate function to avoid code duplication and to be able to call it during confirmation modal submit
    const performMutation = (data: DetailsTabFormTyping) => {
        const mutationData = {
            name: data.name,
            country: data.country?.id,
            street: data.street,
            ...(!!data.street_2 && {
                street_2: data.street_2,
            }),
            city: data.city,
            zipcode: data.zipcode,
            phone: data.phone,
            email: data.email,
            website: data.website,

            printer_email: data.printer_email,

            ...(data.registration_number !== originalRegistrationNumber && {
                registration_number: data.registration_number,
            }),

            ...(!!data.industry && {
                industry: data.industry.id,
            }),

            ...(!!data.logo && data.logo.type !== "" && data.logo && {
                logo: data.logo,
            }),

            ...(selectedCompany?.logo && !data.logo && {
                delete_logo: 1 as const,
            }),
        };

        // If updating an existing company, include the company ID
        if (selectedCompany) {
            updateCompany({
                id: selectedCompany.id,
                ...mutationData,
            }).unwrap()
                .then((data) => {
                    toast.success(t("responses.update.success"))
                    setModalVisible(false);

                    dispatch(setSelectedCompany(data));
                })
                .catch((e) => {
                    if (isErrorWithMessage(e)) {
                        toast.error(e.message)
                    } else {
                    toast.error(t("responses.update.error"))
                    }
                });
        } else {
            // If creating a new company
            createCompany({
                ...mutationData,
                registration_number: data.registration_number,
                currency: data.base_currency?.id as number
            }).unwrap()
                .then((data) => {
                    toast.success(t("responses.creation.success"));
                    setModalVisible(false);

                    return handleSelectedCompanyChange(data);
                })
                .catch((e) => {
                    if (isErrorWithMessage(e)) {
                        toast.error(e.message)
                    } else {
                    toast.error(t("responses.creation.error"));
                    }
                });
        }
    }

    const handleSelectedCompanyChange = async (company?: Company.Root) => {
        if(company) {
            const previousState = activeCompany;

            let companyQuery;
            try {
                companyQuery = dispatch(companiesApi.endpoints.getCompany.initiate(company.id));
                const companyData = await companyQuery.unwrap();

                dispatch(setCompany({
                    id: companyData.id,
                    name: companyData.name,
                    currency: companyData.currency
                }));

                const updateResponse = await updateDefaultCompany(company.id).unwrap();
            } catch (e) {
                console.error(e);

                dispatch(setCompany(previousState as NonNullable<typeof previousState>));

                toast.error(t("responses.defaultUpdate.error", {companyName: company.name}));
            } finally {
                companyQuery?.unsubscribe();
            }
        }else{
            dispatch(setSelectedCompany(undefined));
            companyNameInputRef.current?.focus();
        }
    }

    const handleClearImage = () => {
        setValue("logo", undefined);
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="flex flex-col md:flex-row mb-8">
                    <div className="md:flex-grow">
                        <div className="font-semibold text-gray-600">{t("logo.label")}</div>
                        <div className='flex flex-row flex-wrap gap-4 '>
                            <div className='flex mt-6 md:space-x-6'>
                                <Controller
                                    name="logo"
                                    control={control}
                                    render={({field, fieldState}) => (
                                        <BaseImageUploader
                                            {...field}
                                            {...fieldState}
                                            error={fieldState.error}
                                            size={{width: 120, height: 120}}
                                        />
                                    )}
                                />
                            </div>
                            <div className="flex flex-column">
                                <div className="grow mt-4 text-sm text-gray-600">
                                    <div>{t("logo.restriction.0")}</div>
                                    <div>{t("logo.restriction.1")}</div>
                                </div>
                                {!!logo &&
                                    <TrashSVG
                                        className="cursor-pointer text-gray-600"
                                        onClick={() => handleClearImage()}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 md:mt-0 min-w-[200px] text-right">
                        <CompaniesDropdown
                            value={selectedCompany}
                            onChange={handleSelectedCompanyChange}
                        />
                    </div>
                </div>

                <BaseInputsGrid>
                    <Controller
                        name="name"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                ref={companyNameInputRef}
                                error={fieldState.error}
                                label={t("fields.companyName") + " *"}
                            />
                        )}
                    />

                    <Controller
                        name={'registration_number'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.registrationNumber') + " *"}

                                disabled={selectedCompany?.registration_number?.count === 0}
                            />
                        )}
                    />

                    <Controller
                        name={"industry"}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseDropdown
                                {...field}
                                error={fieldState.error}
                                label={t("dropdowns.industry.label")}
                                placeholder={`${t("dropdowns.industry.placeholder")}`}

                                options={industryOptions}
                                isLoading={industryOptionsLoading}

                                getter={{
                                    key: (opt) => opt.id,
                                    label: (opt) => opt.name
                                }}

                                autocomplete
                            />
                        )}
                    />

                    <Controller
                        name={'street'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.street') + " *"}
                            />
                        )}
                    />

                    <Controller
                        name={'street_2'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.street2')}
                            />
                        )}
                    />

                    <Controller
                        name={'zipcode'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.zipCode') + " *"}
                            />
                        )}
                    />

                    <Controller
                        name={'city'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.city') + " *"}
                            />
                        )}
                    />

                    <Controller
                        name={'country'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}
                                label={t("dropdowns.country.label") + " *"}
                                placeholder={t("dropdowns.country.placeholder")}
                                options={countryOptions}
                                getter={{
                                    label: (opt) => opt.name,
                                    key: (opt) => opt.id,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <img src={getCountryFlag(opt.code)} width={20} alt={opt.code}/>
                                            <span className="ml-[8px] grow">{opt.name}</span>
                                            {icon}
                                        </div>
                                    )
                                }}

                                autocomplete
                                isLoading={countryOptionsLoading}
                            />
                        )}
                    />

                    <Controller
                        name={'phone'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BasePhoneInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.phone') + " *"}

                                variant="custom"
                                size="md"
                                country={!selectedCompany ? selectedCountry?.code : ""}
                            />
                        )}
                    />

                    <Controller
                        name={'email'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.email') + " *"}
                            />
                        )}
                    />

                    <Controller
                        name={'website'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.website') + " *"}
                            />
                        )}
                    />

                    {
                        watch("mode") === "create" && (
                            <CurrencyDropdown control={control} />
                        )
                    }

                    <div>
                        <Controller
                            name={'printer_email'}
                            control={control}
                            render={({field, fieldState}) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    type={"email"}
                                    label={t('fields.printerEmail.label')}

                                    sx={{
                                        "& .MuiInputBase-input": {
                                            paddingRight: "38px"
                                        }
                                    }}

                                    icon={{
                                        right: {
                                            el: <Printer color={"#a6a6a6"} size={16}/>,
                                            offset: "16px"
                                        }
                                    }}
                                />
                            )}
                        />
                        <Typography variant="caption" display="block" sx={{opacity: 0.5}}>
                            {t('fields.printerEmail.caption')}
                        </Typography>
                    </div>
                </BaseInputsGrid>

                <div className='flex justify-center mt-5'>
                    {(selectedCompany && originalRegistrationNumber !== watch("registration_number")) ? (
                        <BaseButton
                            text={t("buttons.confirm")}
                            size="md"
                            type="submit"
                            onClick={() => setModalVisible(true)}
                        />
                    ) : (
                        <BaseButton
                            text={t("buttons.save")}
                            size="md"
                            type="submit"
                            loading={isCreationLoading || isUpdateLoading}
                        />
                    )}
                </div>
            </form>

            {isModalVisible && (
                <ConfirmationModal
                    isOpen={isModalVisible}
                    onClose={() => setModalVisible(false)}
                    onConfirm={handleModalConfirm}
                    isSelectedCompany={!!selectedCompany}
                />
            )}
        </>
    )
}