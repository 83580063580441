import React from "react";
import { Control, useFieldArray } from "react-hook-form";
import BaseTable from "@reusables/BaseTable";
import OutgoingLocationCell from "./OutgoingLocationCell";
import QuantityCell from "./QuantityCell";
import { Box, Stack } from "@mui/material";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import InfoCell from "./InfoCell";
import { useTranslation } from "react-i18next";
import { ProduceFormTyping } from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/types";

type TableBodyProps = {
    control: Control<ProduceFormTyping>;
}

export default function TableBody({control}: TableBodyProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal" });

    const {fields: components, update} = useFieldArray({
        control,
        name: "components_picks",
        keyName: "loop_id"
    });

    return (
        <BaseTable
            hideTableWhenNothingFound
            data={components}
            rowKeyGetter={row => row.loop_id}
            sx={{
                "& tbody td": {
                    verticalAlign: "top",
                    "&:nth-child(-n+5)": {
                        paddingTop: "16px"
                    }
                }
            }}
            columns={[
                {
                    header: t("table.columns.0"),
                    getter: (row, index) => row.component?.product?.code
                },
                {
                    header: t("table.columns.1"),
                    getter: (row, index) => row.component?.product?.name
                },
                {
                    header: t("table.columns.2"),
                    getter: (row, index) => <InfoCell index={index} control={control} />                    
                },
                {
                    header: t("table.columns.3"),
                    getter: (row, index) => 
                        <>
                            <OutgoingLocationCell
                                index={index}
                                control={control}
                                onAdd={() => void update(index, {
                                    ...row,
                                    sources: [
                                        ...row.sources,
                                        {
                                            product_id: row.component.product.id,
                                            locations: undefined,
                                            quantity: undefined as never as number
                                        }
                                    ]
                                })}
                            />
                        </>,                    
                    sx: {
                        width: "400px"
                    }
                },
                {
                    header: t("table.columns.4"),
                    getter: (row, index) => <QuantityCell index={index} control={control} />,
                    sx: {
                        width: "100px"
                    }
                },
                {
                    header: t("table.columns.5"),
                    getter: (row, index) => 
                        <Stack
                            spacing={"16px"}
                        >
                            {
                                row.sources.map((source, sourceIndex) => {
                                    return (
                                        <Box
                                            key={sourceIndex}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent={"center"}
                                            height={44}
                                        >
                                            {
                                                sourceIndex !== 0 &&
                                                <DeleteSVG
                                                    className={"text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"}
                                                    onClick={() => update(index, {
                                                        ...row,
                                                        sources: [...row.sources.filter((_, i) => i !== sourceIndex)]
                                                    })}
                                                />
                                            }
                                        </Box>
                                    )
                                })
                            }
                        </Stack>
                }

            ]}
            headerSx={{
                background: "rgba(249, 249, 249, 1)"
            }}
            nothingFound={{
                text: t("products.noComponents"),
                height: 300
            }}
            size="small"
        />
    )
}
