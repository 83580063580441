import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import BaseTable from "@reusables/BaseTable";

import {ReactComponent as EditSVG} from "@assets/icons/ic_edit.svg";
import {ReactComponent as DeleteSVG} from "@assets/icons/ic_trash.svg";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import MutationModal from "./modals/MutationModal";
import {useAppSelector} from "@redux/hooks";
import DeletionModal from "./modals/DeletionModal";
import {selectDeliveryTermsById, useGetDeliveryTermsQuery} from "@redux/features/deliveryTerms/deliveryTermsApi";
import {AbilityContext, Can} from "@/casl.config";
import {useAbility} from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function DeliveryTermsPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.deliveryTerms" });

    const ability = useAbility(AbilityContext);

    // Getting payment terms data using RTK Query
    const { data: deliveryTerms = [], isLoading: isLoadingDeliveryTerms } = useGetDeliveryTermsQuery();

    // Open states determine visibility of modals
    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allows modals to work with data
    const [selectedDeliveryTermsId, setSelectedDeliveryTermsId] = useState<number>();
    const [deletableDeliveryTermsId, setDeletableDeliveryTermsId] = useState<number>();

    const selectedDeliveryTerms = useAppSelector(state => selectDeliveryTermsById(state, selectedDeliveryTermsId));
    const deletableDeliveryTerms = useAppSelector(state => selectDeliveryTermsById(state, deletableDeliveryTermsId));

    return (
        <>
            <div className="levitation-extended">
                <div className="flex flex-row justify-between items-center mb-[44px]">
                        <div className="font-bold text-2xl text-accent">{t("heading")}</div>
                        <Can I="create" a="delivery_term">
                                <BaseMaterialButton fontWeight={500} onClick={() => {
                                setSelectedDeliveryTermsId(undefined);
                                setIsMutationModalOpen(true);
                            }}>
                                {t("table.buttons.add")}
                            </BaseMaterialButton>
                        </Can>
                </div>

                <Can not I="index" a="delivery_term">
                    <NoPermissionBanner />
                </Can>
                            
                <Can I="index" a="delivery_term">
                    <BaseTable
                        data={deliveryTerms}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) => <div className="bold-highlight">{row.name}</div>,
                                comparator: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row, index) => row.description ?? "",
                                comparator: (a, b) => (a.description ?? "").localeCompare(b.description ?? "")
                            },
                            {
                                visible: ability.can("update", "delivery_term") || ability.can("delete", "delivery_term"),
                                header: () => "",
                                getter: (row) => (
                                <div className="text-tables-secondaryIcon">
                                    <Can I="update" a="delivery_term">
                                        <EditSVG
                                            className="mr-[8px] cursor-pointer hover:text-tables-highlightedIcon"
                                            onClick={() => {
                                                setSelectedDeliveryTermsId(row.id);
                                                setIsMutationModalOpen(true);
                                            }}
                                        />
                                    </Can>
                                    <Can I="delete" a="delivery_term">
                                        <DeleteSVG
                                            className="cursor-pointer hover:text-tables-highlightedIcon"
                                            onClick={() => {
                                                setDeletableDeliveryTermsId(row.id);
                                                setIsDeletionModalOpen(true);
                                            }}
                                        />
                                    </Can>
                                </div>
                                ),
                                sx: {
                                    textAlign: "right"
                                }
                            }
                        ]}
                        isDataLoading={isLoadingDeliveryTerms}

                        headerSx={{
                            backgroundColor: "#F9F9F9"
                        }}

                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}

                        size={"small"}
                    />
                </Can>
            </div>

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => {
                    setIsMutationModalOpen(false);
                }}
                deliveryTerms={selectedDeliveryTerms}
            />

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                deliveryTerms={deletableDeliveryTerms}
            />
        </>
    );
}