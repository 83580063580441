import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import BaseInput from "@reusables/BaseInput";
import { Box, CircularProgress, Fade, Stack } from "@mui/material";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { Check, Pin, PinOff } from "lucide-react";
import { cn } from "@/helpers/utils";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateCurrencyMutation } from "@redux/features/currencies/currenciesApi";
import { refinements } from "@/helpers/refinements";
import BaseHoverTransition from "@reusables/wrappers/BaseHoverTransition";
import BaseTooltip from "@reusables/BaseTooltip";
import { useTranslation } from "react-i18next";

type RateCellProps = {
    currencyId: number;
    rate: number;
    isFixed: boolean;
}

const CURRENCY_RATE_PRECISION = import.meta.env.VITE_CURRENCY_RATE_PRECISION;

const precisionRefinement = refinements.maxDecimalPrecision(CURRENCY_RATE_PRECISION);

const rateFieldScheme = z.object({
    id: z.number(),
    rate: z.coerce.number().refine(precisionRefinement.refine, precisionRefinement.message),
    isFixed: z.boolean(),
    originalValue: z.coerce.number()
})

type RateFieldSchemeTyping = z.infer<typeof rateFieldScheme>;

export default function RateCell(props: RateCellProps) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.currencies.table"});

    const form = useForm<RateFieldSchemeTyping>({
        resolver: zodResolver(rateFieldScheme),
        values: {
            id: props.currencyId,
            rate: props.rate,
            isFixed: props.isFixed,
            originalValue: props.rate
        }
    });

    const fullWatch = useWatch({
        control: form.control
    });

    const floatRate = typeof fullWatch.rate == "number" ? fullWatch.rate : parseFloat(fullWatch.rate ?? "0");

    const isRateChanged = fullWatch.originalValue !== floatRate;

    const [updateCurrency, {isLoading: isUpdateCurrencyLoading}] = useUpdateCurrencyMutation();

    const onSubmit = form.handleSubmit((data) => {
        if (isRateChanged && !isUpdateCurrencyLoading) {
            updateCurrency({
                id: data.id,
                fixedRate: data.rate
            });
        }
    });

    const handleUnpin = () => {
        if (!isRateChanged && fullWatch.isFixed && !isUpdateCurrencyLoading && fullWatch.id) {
            updateCurrency({
                id: fullWatch.id,
                fixedRate: null
            });
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <div className="relative w-[200px]">
                    <Controller
                        name={`rate`}
                        control={form.control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                sx={{
                                    ".MuiInput-input": {
                                        padding: "8px",
                                        paddingRight: isRateChanged ? "36px" : "8px"
                                    }
                                }}
                                hideErrors
                            />
                        )}
                    />
                    <Fade in={isRateChanged || fullWatch.isFixed}>
                        <Stack className={cn("absolute top-1 right-1")}>
                            {
                                isRateChanged &&
                                <BaseTooltip title={t("tooltips.save") + ""}>
                                    <BaseHoverTransition>
                                        {
                                            (hovered) =>
                                                <BaseMaterialIconButton
                                                    color={"neutral"}
                                                    size={"small"}
                                                    type={"submit"}
                                                    icon={
                                                        !isUpdateCurrencyLoading ?
                                                            <Check width={hovered ? 18 : 16} height={hovered ? 18 : 16}
                                                                   color={"#686868"} className={"transition-[.25s]"}/>
                                                            :
                                                            <Box sx={{display: "flex", transform: "scale(.5)"}}>
                                                                <CircularProgress color={"secondary"}/>
                                                            </Box>
                                                    }
                                                />
                                        }
                                    </BaseHoverTransition>
                                </BaseTooltip>
                            }

                            {
                                !isRateChanged && fullWatch.isFixed &&
                                <BaseTooltip title={t("tooltips.unpin") + ""}>
                                    <BaseHoverTransition>
                                        {
                                            (hovered) =>
                                                <BaseMaterialIconButton
                                                    color={"neutral"}
                                                    size={"small"}
                                                    type={"button"}
                                                    icon={
                                                        !isUpdateCurrencyLoading ?
                                                            hovered ?
                                                                <PinOff width={16} height={16} color={"#686868"}/>
                                                                :
                                                                <Pin width={16} height={16} color={"#686868"}/>
                                                            :
                                                            <Box sx={{display: "flex", transform: "scale(.5)"}}>
                                                                <CircularProgress color={"secondary"}/>
                                                            </Box>
                                                    }
                                                    onClick={handleUnpin}
                                                />
                                        }
                                    </BaseHoverTransition>
                                </BaseTooltip>
                            }
                        </Stack>
                    </Fade>
                </div>
            </Stack>
        </form>
    )
}