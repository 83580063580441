import React from "react";
import { useTranslation } from "react-i18next";
import CreationEditingLayout from "../components/CreationEditingLayout";
import { useHistory, useParams } from "react-router-dom";
import ProductStepper from "../components/Tabs/ProductStepper";
import { useGetProductQuery } from "@redux/features/products/productsApi";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import BaseBadge from "@reusables/BaseBadge";
import { ExternalLink, Forklift } from "lucide-react";

export default function ProductEditingPage() {
    const history = useHistory();

    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct" });

    const { id } = useParams<{ id: string }>();

    const { data: product, isLoading: productLoading } = useGetProductQuery(parseInt(id));

    return (
        <BaseRecordRenderer loading={productLoading} record={product}>
            <div className={"space-y-[40px]"}>
                <div className="flex mb-[40px]">
                    <div className={"flex flex-row items-center grow space-x-[8px]"}>
                        <div className="flex items-center text-2xl font-bold text-accent">{product?.name}</div>
                        {product?.is_service && (
                            <BaseBadge>
                                <Forklift size={12} className={"mr-1"} />
                                <span>{t("isService")}</span>
                            </BaseBadge>
                        )}
                        {!!product?.parent && (
                            <BaseBadge
                                className="mx-2 bg-badge-blue cursor-pointer border-1 border-solid border-transparent hover:border-purple-400"
                                onClick={() => history.push(`/dashboard/products/${product.parent!.id}/details`)}
                            >
                                <ExternalLink size={12} className={"mr-1"} />
                                <span>{t("isVariant")}</span>
                            </BaseBadge>
                        )}
                    </div>
                </div>
                <ProductStepper informationTab={<CreationEditingLayout product={product} />} product={product} previewMode={false} />
            </div>
        </BaseRecordRenderer>
    );
}
