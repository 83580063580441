import { internalApiSlice, tags, transformResponse } from "@redux/api/internalApiSlice";
import { Company } from "@/types/general";
import {objectToFormData} from "@helpers/utils";

export const companiesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCompany: builder.query<Company.Extended, number>({
            query: (id) => ({
                url: `company/${id}`
            }),
            transformResponse,
            providesTags: (result) => result ? [{ type: "Company", id: result.id }] : []
        }),

        getCompanies: builder.query<Company.Root[], void>({
            query: () => ({
                url: `company`
            }),
            transformResponse,
            providesTags: (result) => result ? [...result.map(({ id }) => ({
                type: "Company" as const,
                id
            })), { type: "Company", id: "LIST" }] : [{ type: "Company", id: "LIST" }]
        }),

        createCompany: builder.mutation<Company.Extended, Company.DTO.Create>({
            query: (body) => {
                const formData = objectToFormData(body);

                return {
                    url: `company`,
                    method: "POST",
                    data: formData
                }
            },
            transformResponse,
            invalidatesTags: [{ type: "Company", id: "LIST" }]
        }),

        updateCompany: builder.mutation<Company.Extended, Company.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => {
                const formData = objectToFormData(body);

                return {
                    url: `company/${id}`,
                    method: "POST",
                    params: { _method: "PUT" },
                    data: formData
                }
            },
            transformResponse,
            invalidatesTags: (result) => result ? [{ type: "Company", id: result.id }] : [],
        }),

        updateCompanyAddresess: builder.mutation<Company.Extended, Company.DTO.UpdateAddresses & {id: number}>({
            query: ({ id, ...body }) => ({
                url: `company-addresses/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            invalidatesTags: (resp, _, args) => [{ type: "Company", id: args.id}]
        }),

        updateActiveCompany: builder.mutation<void, number>({
            query: (id) => ({
                url: `active-company/${id}`,
                method: "PUT"
            }),
            invalidatesTags: tags
        }),

        getCompanyBillingAddress: builder.query<Company.Components.BillingAddress, void>({
            query: () => ({
                url: `company/billing-address`
            }),
            providesTags: (result) => result ? [{ type: "Company", id: "LIST" }] : []
        }),

        createBillingAddress: builder.mutation<Company.Components.BillingAddress, Company.DTO.CreateBilling>({
            query: (body) => ({
                url: `company/billing-address`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: [{ type: "Company" }]
        }),

        
        getDeliveryAddresses: builder.query<Company.Components.DeliveryAddress[], void>({
            query: () => ({
                url: `company/delivery-address`
            }),
            providesTags: (result) => result ? [...result.map(({ id }) => ({
                type: "Company", id
            })), { type: "Company", id: "LIST" }] : [{ type: "Company", id: "LIST" }]
        }),
        
        createDeliveryAddress: builder.mutation<Company.Components.DeliveryAddress, Company.DTO.CreateDelivery>({
            query: (body) => ({
                url: `company/delivery-address`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: [{ type: "Company" }]
        }),
    })
});

export const {
    useGetCompanyQuery,
    useGetCompaniesQuery,
    useUpdateCompanyAddresessMutation,
    useUpdateCompanyMutation,
    useCreateCompanyMutation,
    useUpdateActiveCompanyMutation,
    useCreateBillingAddressMutation,
    useGetCompanyBillingAddressQuery,
    useCreateDeliveryAddressMutation,
    useGetDeliveryAddressesQuery
} = companiesApi;