import React, {PropsWithChildren} from "react";
import {Button, styled, SxProps} from "@mui/material";
import {TransientAdapter} from "@helpers/utils";

type MaterialButtonProperties = PropsWithChildren<{
    size?: "small" | "medium" | "large";
    padding?: string;
    borderRadius?: string;
    useBorder?: boolean;
    fontWeight?: number;
    fontSize?: number;
    outlined?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    sx?: SxProps;
    className?: string;
    type?: "button" | "submit" | "reset" | undefined;
}>;

/**
 * Component, based on Button from MUI. Changes it style to something like Material You style.
 * @param param0 
 * @returns 
 */
export default function BaseMaterialButton({
    children,
    size = "medium",
    padding,
    borderRadius,
    useBorder = true,
    fontWeight = 400,
    onClick,
    outlined = false,
    sx,
    className,
    type="submit"
}: MaterialButtonProperties){
    return(
        <MaterialButton 
            _size={size}
            padding={padding} 
            rounding={borderRadius} 
            _useBorder={useBorder}
            fontWeight={fontWeight}
            onClick={onClick}
            _outlined={outlined}
            sx={sx}
            className={className}
            type={type}
        >
            {children}
        </MaterialButton>
    )
}

const sizes: {
    [P: string]: 
    {fontSize: number; padding: string; borderRadius: number}
} = {
    small: {
        fontSize: 12,
        padding: "3px 8px",
        borderRadius: 10
    },
    medium: {
        fontSize: 16,
        padding: "8px 12px",
        borderRadius: 8
    },
    large: {
        fontSize: 16,
        padding: "12px 18px",
        borderRadius: 8
    }
}


const MaterialButton = styled(Button, TransientAdapter)<{
    _size: string;
    padding?: string;
    rounding?: string;
    _useBorder?: boolean;
    fontWeight?: number;
    fontSize?: number;
    _outlined?: boolean;
}>(({theme, _size, ...props}) => ({
    backgroundColor: !props._outlined ? theme.custom.purple["100"] : undefined,
    color: theme.custom.purple["400"],
    borderRadius: props.rounding ?? sizes[_size].borderRadius,
    fontSize: props.fontSize ?? sizes[_size].fontSize,
    textTransform: "initial",
    lineHeight: "initial",
    padding: props.padding ?? sizes[_size].padding,
    fontWeight: props.fontWeight,
    transition: "background-color .15s",
    border: props._useBorder ? "1px solid " + theme.custom.purple["100"] : "none",
    "&:hover":{
        backgroundColor: props._outlined ? theme.custom.purple["100"] : "none"
    },
}));