import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseLabel from "@reusables/BaseLabel";
import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";
import { useGetProductQuery, useGetProductsQuery } from "@redux/features/products/productsApi";
import { Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseInput from "@reusables/BaseInput";
import BaseDatepicker from "@reusables/BaseDatepicker";
import BaseButton from "@reusables/BaseButton";
import { Product } from "../../types";
import { refinements } from "@helpers/refinements";

const ORDER_PRICE_PRECISION = import.meta.env.VITE_ORDER_PRICE_PRECISION;
const maxDecimalPrecisionRefinement = refinements.maxDecimalPrecision(ORDER_PRICE_PRECISION);

const formScheme = z.object({
    product: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
    }),
    sale_price: z.coerce.number().min(0)
        .refine(maxDecimalPrecisionRefinement.refine, maxDecimalPrecisionRefinement.message),
    valid_from: z.instanceof(dayjs as unknown as typeof Dayjs).nullish(),
    valid_to: z.instanceof(dayjs as unknown as typeof Dayjs).nullish(),
}).superRefine((data, ctx) => {
    if (data.valid_from && data.valid_to) {
        const validFromDate = dayjs(data.valid_from);
        const validToDate = dayjs(data.valid_to);
  
        if (validToDate.isBefore(validFromDate)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                    i18n: "general.validation.global.compareDate"
                },
                path: ["valid_to"], 
            });
        }
    }
});

type FormTyping = z.infer<typeof formScheme>;

export interface MutationProductModalProps {
    isOpen: boolean;
    onClose: () => void;

    onProductAddition: (newProduct: FormTyping) => void;
    product: Product | undefined;
}

export default function MutationProductModal({isOpen, onClose, onProductAddition, product}: MutationProductModalProps): JSX.Element {
    const {t} = useTranslation("", {keyPrefix: "customerGroups.mutation.modals.product"});

    const {data: productsOptions, isLoading: productsOptionsLoading} = useGetProductsQuery();

    const {control, ...form} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    const productWatch = useWatch({
        control,
        name: "product",
    })

    const {data: selectedProduct, isLoading: isSelectedProductLoading} = useGetProductQuery(productWatch?.id, {
        skip: !productWatch?.id,
    });

    useEffect(() => {
        // If the product is not selected or the selected product is different from the product in the form
        if (!product || product.product.id !== productWatch?.id) {
            if (selectedProduct) {
                form.setValue("sale_price", selectedProduct.prices.selling_price);
            }
        }
    }, [selectedProduct, isSelectedProductLoading]);

    useEffect(() => {
        if (product) {
            form.setValue("product", product.product);
            form.setValue("sale_price", product.sale_price);
            form.setValue("valid_from", product.valid_from);
            form.setValue("valid_to", product.valid_to);
        }
    }, [product]);


    const onSubmit = form.handleSubmit(data => {
        onProductAddition(data);

        form.reset({
            valid_from: null,
            valid_to: null,
        });
    });

    const handleClose = () => {
        form.reset(); 
        onClose();
    };

    return (
        <>
            <BaseModal  
                isOpen={isOpen}
                onClose={handleClose}
                width={900}
                padding="56px"
                useCloseIcon
            >
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                    <div className="modal-title">{product ? t("editProduct") : t("addProduct")}</div>
                    <BaseInputsGrid cols={2}>
                        <Controller
                            name="product"
                            control={control}
                            render={({field, fieldState}) => (
                                <BaseDropdown
                                    {...field}
                                    {...fieldState}
                                    label={t("dropdowns.product.label") + " *"}
                                    placeholder={t("dropdowns.product.placeholder")}
                                    options={productsOptions}
                                    getter={{
                                        label: opt => opt.name,
                                        key: opt => opt.id,
                                        caption: opt => opt.code
                                    }}

                                    virtualize

                                    autocomplete
                                    isLoading={productsOptionsLoading}
                                />
                            )}
                        />

                        <Controller
                            name="sale_price"
                            control={control}
                            render={({field, fieldState}) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.unitPrice.label") + " *"}
                                    placeholder={t("fields.unitPrice.placeholder")}
                                    type="number"
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="valid_from"
                            render={({field, fieldState}) => (
                                <BaseDatepicker
                                    {...field}
                                    {...fieldState}
                                    label={t("fields.validFrom.label")}
                                    placeholder={t("fields.validFrom.placeholder")}
                                    disablePast
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="valid_to"
                            render={({field, fieldState}) => (
                                <div className='flex flex-column'>
                                    <BaseLabel>{t("fields.validTill.label")} {<span className='font-thin opacity-50'>{t("fields.validTill.tip")}</span>}</BaseLabel>
                                    <BaseDatepicker
                                        {...field}
                                        error={fieldState.error}
                                        placeholder={t("fields.validTill.placeholder")}
                                        disablePast
                                    />
                                </div>
                            )}
                        />
                    </BaseInputsGrid>

                    <BaseButton
                        text={product ? t("buttons.save") : t("buttons.add")}
                        size="md"
                        className="w-full"
                    />
                </form>
            </BaseModal>
        </>
    )
}
