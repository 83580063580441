import React from "react";
import { Trans, useTranslation } from "react-i18next";
import BaseNotFound from "@reusables/BaseNotFound";
import { Can } from "@/casl.config";
import { Link } from "react-router-dom";

export default function NoPermissionBanner({height}: {height?: number | string}) {
    const { t } = useTranslation();

    return (
        <BaseNotFound
            height={height}
            text={
                <div className="text-center space-y-[12px]">
                    <div>{t("general.accessDenied.main")}</div>
                    <Can I={"index"} a={"role"}>
                        <div className={"text-sm text-notFound-blocks"}>
                            <Trans i18nKey={"general.accessDenied.visitRoles"}
                                   t={t}
                                   components={{
                                       redirect: <Link to="/dashboard/settings/company/roles" className={"text-notFound-blocks text-decoration-underline"}/>
                                   }}
                            />
                        </div>
                    </Can>
                </div>
            }
        />
    );
}