import React from "react";
import ToggleWrapper from "../../../ToggleWrapper";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import {useTranslation} from "react-i18next";
import {ProductSchemeTyping} from "../../../types";
import {Controller, useFormContext} from "react-hook-form";
import {useGetTaxesQuery} from "@redux/features/taxes/taxesApi";


export default function TaxDropdown(){
    const { t } = useTranslation("", { keyPrefix: "products" });

    const {data: options, isLoading: optionsLoading } = useGetTaxesQuery();

    const {control, setValue} = useFormContext<ProductSchemeTyping>();

    return (
      <>
          <ToggleWrapper label={t("general.fields.defaultTax.label")} fieldKey="tax">
              <Controller
                  name="general.tax" // Adjust this to the correct path in your form schema
                  control={control}
                  render={({ field, fieldState }) => (
                      <BaseDropdown
                          {...field}
                          error={fieldState.error}
                          placeholder={t("general.fields.defaultTax.placeholder")}
                          options={options}
                          getter={{
                              key: opt => opt.id,
                              label: opt => `${opt.rate}%`
                          }}
                          isLoading={optionsLoading}
                      />
                  )}
              />
          </ToggleWrapper>
      </>
    )
}