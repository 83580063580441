import {Receive} from "@/types/general";
import React, {useMemo} from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import BaseTable from "@reusables/BaseTable";
import {ReactComponent as DetailsSVG} from "@assets/icons/ic_document_search.svg";
import {Stack} from "@mui/material";
import SerialNumbersModal
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/modals/SerialNumbersModal";
import BatchNumbersModal from "@components/Dashboard/pages/PurchasingPage/Receive/components/modals/BatchNumbersModal";

interface ViewLayoutProperties {
    receipt: Receive.Extended;
}

export default function ViewLayout({receipt}: ViewLayoutProperties) {
    const {t} = useTranslation("", {keyPrefix: "receive.details"});

    // Filtering receipts with serial numbers and batch numbers to show "open serials modal" or "open batches modal"
    const serialNumbersReceipts = useMemo(() => {
        return receipt.receives.filter(receive => receive.serial_numbers?.length);
    }, [receipt]);

    const batchNumbersReceipts = useMemo(() => {
        return receipt.receives.filter(receive => receive.batch_numbers?.length);
    }, [receipt]);

    const [serialNumberModalOpen, setSerialNumberModalOpen] = React.useState(false);
    const [batchNumberModalOpen, setBatchNumberModalOpen] = React.useState(false);

    return (
        <>
            <div className="space-y-[40px]">
                <BaseInputsGrid>
                    <InputLikeContainer
                        label={t("fields.supplier")}
                        value={receipt.supplier.name}
                    />
                    <InputLikeContainer
                        label={t("fields.purchaseOrder")}
                        value={receipt.purchase_order.code ?? receipt.purchase_order.id}
                        linkValue={`/dashboard/purchasing/${receipt.purchase_order.id}/details`}
                    />
                    <InputLikeContainer
                        label={t("fields.receiveDate")}
                        value={receipt.date.format("DD.MM.YYYY")}
                    />
                </BaseInputsGrid>
                <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                    <div className="flex mb-[46px] items-center space-x-[8px]">
                        <div className="text-xl font-thin text-gray-600 grow">{t("products.heading")}</div>
                        <Stack direction={"row"} spacing={3}>
                            {
                                serialNumbersReceipts.length > 0 &&
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    className={"hover:text-accent cursor-pointer"}
                                    onClick={() => setSerialNumberModalOpen(true)}
                                >
                                    <span>{t("products.modalReferences.serialNumbers")}</span>
                                    <DetailsSVG />
                                </Stack>
                            }
                            {
                                batchNumbersReceipts.length > 0 &&
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    className={"hover:text-accent cursor-pointer"}
                                    onClick={() => setBatchNumberModalOpen(true)}
                                >
                                    <span>{t("products.modalReferences.batchNumbers")}</span>
                                    <DetailsSVG />
                                </Stack>
                            }
                        </Stack>
                    </div>
                    <BaseTable
                        hideTableWhenNothingFound
                        data={receipt.receives}
                        columns={[
                            {
                                header: t("products.columns.0"),
                                getter: (row) => row.order.product.code
                            },
                            {
                                header: t("products.columns.1"),
                                getter: (row) => row.order.product.name
                            },
                            {
                                header: t("products.columns.2"),
                                getter: (row, index) => `${row.location.store.name}${row.location.section?.name ? ` - ${row.location.section.name}` : ""}`
                            },
                            {
                                header: t("products.columns.3"),
                                getter: (row, index) => row.received_quantity,
                                sx: {
                                    width: "200px"
                                }
                            }
                        ]}
                        headerSx={{
                            background: "rgba(249, 249, 249, 1)"
                        }}
                        nothingFound={{
                            text: t("products.noProducts")
                        }}
                        size="small"
                    />
                </div>
            </div>

            {/* Serial numbers modal */}
            {
                serialNumbersReceipts.length > 0 &&
                <SerialNumbersModal
                    isOpen={serialNumberModalOpen}
                    onClose={() => setSerialNumberModalOpen(false)}
                    receipts={serialNumbersReceipts}
                    preview
                />
            }

            {/* Batch numbers modal */}
            {
                batchNumbersReceipts.length > 0 &&
                <BatchNumbersModal
                    isOpen={batchNumberModalOpen}
                    onClose={() => setBatchNumberModalOpen(false)}
                    receipts={batchNumbersReceipts}
                    preview
                />
            }
        </>
    );
}

function InputLikeContainer({label, value, linkValue}: {
    label: string,
    value: string | number | undefined,
    linkValue?: string
}) {
    return (
        <div>
            <div className="font-semibold text-inputs-label-dim mb-[24px]">{label}</div>
            {
                linkValue ?
                    <Link to={linkValue} className={"text-accent text-decoration-underline"}>{value}</Link>
                    :
                    <div className="text-accent">
                        {value}
                    </div>
            }
        </div>
    );
}