import React from "react";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useGetSupplierQuery, useUpdateSupplierMutation } from "@redux/features/suppliers/suppliersApi";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import TabsController from "../components/tabs/TabsController";
import { toast } from "react-toastify";
import { isAddressFilled, removeEmpty } from "@helpers/utils";
import _ from "lodash";
import { Supplier } from "@/types/general";
import { isErrorWithMessage } from "@redux/api/query";

export default function SupplierEditingPage() {
    const {t} = useTranslation("", {keyPrefix: "suppliers.editing"});

    const history = useHistory();
    const {id} = useParams<{ id: string }>();

    const {data: supplier, isLoading: supplierLoading} = useGetSupplierQuery(parseInt(id));

    const [updateSupplier, {isLoading: isUpdatingLoading}] = useUpdateSupplierMutation();

    return (
        <BaseRecordRenderer
            record={supplier}
            loading={supplierLoading}
        >
            <BaseLoadingBlocker active={isUpdatingLoading}>
                <div className="space-y-[40px]">
                    <div className='flex items-center' id={"supplier-header"}>
                        <h6 className='text-accent grow'>{supplier?.name}</h6>
                    </div>
                    <TabsController
                        supplier={supplier}
                        onSubmit={data => {
                            if (!supplier) return;

                            const updateData: Supplier.DTO.Update = {
                                name: data.name,
                                code: data.code,
                                ...(supplier.vat !== data.vat && {vat: data.vat}),
                                contacts: data.contacts.filter(con => !_.isEmpty(removeEmpty(con))).map(contact => {
                                    return {
                                        id: contact.id,
                                        name: contact.name as string,
                                        phone: contact.phone as string,
                                        email: contact.email as string
                                    }
                                }),
                                ...(isAddressFilled(data.billing_address, ["is_used_for_return"]) && {
                                    billing: {
                                        name: data.billing_address.name,
                                        street: data.billing_address.street,
                                        street_2: data.billing_address.street_2,
                                        zipcode: data.billing_address.zipcode,
                                        city: data.billing_address.city,
                                        country: data.billing_address.country?.id,
                                        phone: data.billing_address.phone,
                                        email: data.billing_address.email,
                                        is_used_for_return: data.billing_address.is_used_for_return ?? false,
                                    }
                                }),
                                returns: data?.returns.filter(ret => isAddressFilled(ret, ["is_primary"])).map(ret => ({
                                    id: ret.id,
                                    name: ret.name,
                                    street: ret.street,
                                    street_2: ret.street_2,
                                    zipcode: ret.zipcode,
                                    city: ret.city,
                                    country: ret.country?.id,
                                    contact_person: ret.contact_person,
                                    phone: ret.phone,
                                    email: ret.email,
                                    is_primary: ret.is_primary ?? false,
                                })),
                                deleted_contacts: data.deleted_contacts,
                                deleted_returns: data.deleted_returns,

                                ...(data.currency && {currency: data.currency.id}),
                                ...(data.tax_rate && {tax_rate: data.tax_rate.id}),
                                ...(data.payment_terms && {payment_terms: data.payment_terms.id}),
                                ...(data.delivery_terms && {delivery_terms: data.delivery_terms.id}),
                                ...(data.language && {language: data.language.id}),
                            }

                            updateSupplier({id: supplier.id, ...updateData}).unwrap().then(() => {
                                history.push(`/dashboard/suppliers`);

                                toast.success(t("responses.success"));
                            }).catch(e => {
                                if (isErrorWithMessage(e)) {
                                    toast.error(e.message);
                                } else {
                                    toast.error(t("responses.error"));
                                }
                            });
                        }}

                    />
                </div>
            </BaseLoadingBlocker>
        </BaseRecordRenderer>
    )
}
