import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetMeQuery} from '@redux/features/auth/authApi'
import {useGetLanguagesQuery} from '@redux/features/languages/languagesApi';
import {useUpdateUserMutation, useUpdateUserPasswordMutation} from '@redux/features/users/usersApi';
import {useGetCountriesQuery} from '@redux/api/internalApiSlice';

import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BasePhoneInput from "@reusables/BasePhoneInput";
import BaseRecordRenderer from '@reusables/BaseRecordRenderer';
import BaseInputsGrid from '@reusables/BaseInputsGrid';

import {getCountryFlag, requiredIfAnyFilled} from '@helpers/utils';

import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from 'zod'
import {toast} from 'react-toastify';
import _ from 'lodash';

const formScheme = z.object({
    first_name: z.string().min(3),
    last_name: z.string(),
    email: z.string().email(),
    phone: z.string().nullish(),
    country: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
    }),
    language: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
    }).optional(),
    current_password: z.string().optional().refine(
        (data) => !data || data.length >= 8, 
        { message: "Current password must be at least 8 characters long" }
    ),
    new_password: z.string().optional().refine(
        (data) => !data || data.length >= 8, 
        { message: "New password must be at least 8 characters long" }
    ),
    confirm_password: z.string().optional().refine(
        (data) => !data || data.length >= 8, 
        { message: "Confirm password must be at least 8 characters long" }
    ),
}).superRefine((scheme, ctx) => {
    // Add custom validation for required fields
    requiredIfAnyFilled(scheme, ["phone", "language", "current_password", "new_password", "confirm_password"])
        .forEach(key => {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Required`,
                path: [key]
            });
        })
});

type FormTyping = z.infer<typeof formScheme>

export default function Profile() {
    const {t} = useTranslation("", {keyPrefix: "settings.general.myAccount.tabs.profile"});

    const {data: me, isLoading: isMeLoading, refetch: refetchMe} = useGetMeQuery();

    const {data: countryOptions = [], isLoading: countryOptionsLoading} = useGetCountriesQuery();
    const {data: languagesOptions = [], isLoading: languageOptionsLoading} = useGetLanguagesQuery();

    const [updateUser, {isLoading: isUpdateUserLoading}] = useUpdateUserMutation();
    const [updatePassword, {isLoading: isUpdatePasswordLoading}] = useUpdateUserPasswordMutation();

    const {control, handleSubmit, setValue, watch } = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    const [isDataChanged, setIsDataChanged] = useState(false);

    // Use watch to subscribe to all form value changes
    const watchedValues = watch();

    // Function to check if all password fields are filled
    const arePasswordFieldsFilled = () => {
        const { current_password, new_password, confirm_password } = watchedValues;
        return current_password && new_password && confirm_password;
    };

    useEffect(() => {
        if (me) {
            console.log(me);
            setValue("first_name", me.first_name)
            setValue("last_name", me.last_name)
            setValue("email", me.email)
            setValue("phone", me.phone)
            setValue("country", me.country)
            setValue("language", me.language)
            setIsDataChanged(false);
        }
    }, [me, setValue])

    useEffect(() => {
        if (me) {
            // Omit password fields from watched values
            const formData = _.omit(watchedValues, ['current_password', 'new_password', 'confirm_password']);
            // Pick only fields that are present in initial data
            const initialData = _.pick(me, Object.keys(formData));

            setIsDataChanged(!_.isEqual(initialData, formData));
        }
    }, [watchedValues, me]); 

    const onSubmit = handleSubmit((data) => {
        // nothing to submit if no changes were made
        if (!isDataChanged && !arePasswordFieldsFilled()) {
            toast.error(t("responses.noChanges"));
            return;
        }
        // Check if passwords match
        if (data.new_password && data.confirm_password && data.new_password !== data.confirm_password) {
            toast.error(t("responses.password.notMatchError"));
            return; // Stop the submission process
        }

        // Check if new password is the same as the current password
        if(data.current_password && data.new_password && data.current_password === data.new_password) {
            toast.error(t("responses.password.sameError"));
            return;
        }

        if (me && isDataChanged) {
            const payload = {
                id: me.id,
                first_name: data.first_name,
                last_name: data.last_name,
                country: data.country?.id,
                phone: data.phone,
                language: data.language?.id,

                // Only add email to payload if it's different from the initial email
                ...(data.email !== me.email && {email: data.email})
            };
    
            updateUser(payload).unwrap().then(() => {
                toast.success(t("responses.user.success"));
                refetchMe()
            }).catch(() => {
                toast.error(t("responses.user.error"));
            })
        }

        // Update password if all fields are filled
        if (data.current_password && data.new_password && data.confirm_password) {
            updatePassword({
                current_password: data.current_password,
                new_password: data.new_password,
                confirm_password: data.confirm_password,
            }).unwrap().then(() => {
                setValue("current_password", "")
                setValue("new_password", "")
                setValue("confirm_password", "")
                toast.success(t("responses.password.success"));
            }).catch(() => {
                toast.error(t("responses.password.incorrectError"));
            })
        }
    });

    return (
        <BaseRecordRenderer
            loading={isMeLoading || isUpdateUserLoading || isUpdatePasswordLoading}
            dontShowNotFound
            hideLevitation
        >
            <form onSubmit={onSubmit}>
                <div className="text-2xl text-accent mb-5">{t("title")}</div>
                <BaseInputsGrid cols={3}>
                    <Controller
                        control={control}
                        name="first_name"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.firstName") + " *"}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="last_name"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.lastName") + " *"}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="email"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.email") + " *"}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                            <BasePhoneInput
                                {...field}
                                label={t("fields.phone")}
                                variant='custom'
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="country"
                        render={({ field, fieldState }) => (
                            <BaseDropdown
                                {...field}
                                error={fieldState.error}
                                label={t("dropdowns.country.label") + " *"}
                                placeholder={t("dropdowns.country.placeholder")}
                                options={countryOptions}
                                getter={{
                                    key: opt => opt.id,
                                    label: opt => opt.name,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <img src={getCountryFlag(opt.code)} width={20} alt={opt.name + " flag"}/>
                                            <span className="ml-[8px] grow">{opt.name}</span>
                                            {icon}
                                        </div>
                                    )
                                }}
                                isLoading={countryOptionsLoading}
                                autocomplete
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="language"
                        render={({ field }) => (
                            <BaseDropdown
                                {...field}
                                label={t("dropdowns.language.label")}
                                placeholder={t("dropdowns.language.placeholder")}
                                options={languagesOptions}

                                getter={{
                                    key: opt => opt.id,
                                    label: opt => opt.name
                                }}

                                isLoading={languageOptionsLoading}
                                autocomplete
                            />
                        )}
                    />
                </BaseInputsGrid>

                <div className="text-2xl text-accent mb-5 mt-10">{t("password.title")}</div>
                <BaseInputsGrid cols={3}>
                    <Controller
                        control={control}
                        name="current_password"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("password.fields.currentPassword")}
                                type='password'
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="new_password"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("password.fields.newPassword")}
                                type='password'
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="confirm_password"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("password.fields.confirmPassword")}
                                type='password'
                            />
                        )}
                    />
                </BaseInputsGrid>

                <div className='mt-5 flex justify-center'>
                    <BaseButton
                        loading={isUpdateUserLoading || isUpdatePasswordLoading}
                        size="md"
                        text={t('save')}
                        type='submit'
                    />
                </div>
            </form>
        </BaseRecordRenderer>     
    )
}