import React, { useCallback, useMemo } from "react";
import NavigationCard from "../NavigationCard";
import { useGetTotalStatQuery } from "@redux/features/dashboard/dashboardApi";
import { useTranslation } from "react-i18next";
import { jsxSwitch, normalizePrice, useEchoEffect } from "@helpers/utils";
import { useAppSelector } from "@redux/hooks";
import { useAbility } from "@casl/react";
import { AbilityContext } from "@/casl.config";
import _ from "lodash";

export default function NavStatHeader() {
    const { t } = useTranslation("", { keyPrefix: "dashboard.navbar.totalStat" });

    const user = useAppSelector(state => state.auth.user);

    const ability = useAbility(AbilityContext);

    const { data: totalStat, isLoading: totalStatLoading, refetch } = useGetTotalStatQuery();

    const refetchDebounced = useCallback(_.debounce(() => refetch(), 1000), []);

    // Extracting the total stat value and type from the response (both are returned, but one is `null` based on the permissions)
    const totalStatSimplified = useMemo(() => {
        if (totalStat) {
            const type: "total_revenue" | "total_in_stock" = totalStat.total_revenue !== null ? "total_revenue" : "total_in_stock";
            return {
                type: type,
                value: totalStat[type]
            };
        }
    }, [totalStat]);

    useEchoEffect(
        `dashboard.${ability.can("revenue", "dashboard") ? "total_revenue" : "total_in_stock"}.${user?.company.id ?? -1}`,
        "DashboardUpdateEvent",
        () => {
            // This socket doesn't provide any data on update, so we just refetch the data
            refetchDebounced();
        },
        undefined,
        [user, ability]
    );

    return (
        <NavigationCard className="grow px-6 justify-between">
            <div className="flex items-center">
                        <span className="text-lg-bold text-accent mr-4">
                            {
                                totalStatSimplified?.type === "total_revenue" ?
                                    t("totalRevenue")
                                    : t("totalInStock")
                            }
                        </span>
                <span className="text-sm-light color-suppli-gray">
                            {t("thisMonth")}
                        </span>
            </div>

            <div className="d-flex align-items-center">
                <h6 className="text-accent text-2xl font-bold m-0">
                    {
                        normalizePrice(totalStatSimplified?.value)
                    }
                    {" "}
                    {
                        jsxSwitch({
                            total_revenue: <>{user?.company.currency?.symbol}</>,
                            total_in_stock: <></>
                        }, totalStatSimplified?.type)
                    }
                </h6>
            </div>
        </NavigationCard>
    );
}