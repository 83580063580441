import React, { useEffect } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import BaseCheckbox from "@reusables/BaseCheckbox";
import { AdjustmentFormTyping } from "@components/Dashboard/pages/Inventory/Adjustments/components/EditingLayout/types";
import { useTranslation } from "react-i18next";

export default function TypeSelector() {
    const { t } = useTranslation("", { keyPrefix: "inventory.adjustments.createViewEditAdjustments" });
    const { control } = useFormContext<AdjustmentFormTyping>();

    const { field, fieldState } = useController({
        control,
        name: "adjustment_type"
    });

    const selectedProduct = useWatch({
        control,
        name: "product"
    });

    useEffect(() => {
        if (selectedProduct?.is_service) {
            field.onChange(1);
        }
    }, [selectedProduct]);

    return (
        <div>
            <div
                className="font-semibold text-inputs-label-dim mb-[24px]">{t("fieldsGeneral.adjustment.label")}</div>
            <div className="flex space-x-[40px]">
                {/* Quantity checkbox (hidden if product is service)*/}
                {
                    !selectedProduct?.is_service &&
                    <BaseCheckbox
                        value={field.value === 0}
                        label={`${t("fieldsGeneral.adjustment.selectQuantity")}`}
                        onChange={(checked) => checked && field.onChange(0)}
                    />
                }

                {/* Price checkbox */}
                <BaseCheckbox
                    value={field.value === 1}
                    label={`${t("fieldsGeneral.adjustment.selectCostPrice")}`}
                    onChange={(checked) => checked && field.onChange(1)}
                />
            </div>
        </div>
    );
}