import React from "react";
import {useTranslation} from "react-i18next";
import {Alert, Collapse} from "@mui/material";
import {SaleOrdersFormTyping} from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/types";
import {useFormContext, useWatch} from "react-hook-form";

export default function LowStockWarning() {
    const {t} = useTranslation("", {keyPrefix: "sales.orders.general.orders"});

    const {control} = useFormContext<SaleOrdersFormTyping>();

    const orders = useWatch({
        control,
        name: "orders"
    });

    const isLowStock = orders.some(order => !order.product.is_service && order.quantity > (order.product.in_stock ?? 0));

    return (
        <Collapse in={isLowStock}>
            <Alert severity={"warning"}>
                {t("lowStockWarning")}
            </Alert>
        </Collapse>
    )
}