import { BaseLoadingBlocker } from "@/components/reusables/blockers/BaseLoadingBlocker";
import { useCreateCollectionMutation } from "@/redux/features/collections/collectionsApi";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import EditingLayout from "../components/EditingLayout";
import { toast } from "react-toastify";
import { isErrorWithMessage } from "@/redux/api/query";

export default function CollectionsCreationPage() {
    const history = useHistory();

    const { t } = useTranslation("", {
        keyPrefix: "inventory.collections.collectionMutation"
    });

    const [createCollection, { isLoading }] = useCreateCollectionMutation();

    return (
        <>
            <BaseLoadingBlocker active={isLoading}>
                <div className="levitation-extended">
                    <div className="flex mb-[40px]">
                        <h6 className="text-accent grow">{t("creation.heading")}</h6>
                    </div>

                    <EditingLayout
                        buttonText={t("saveButton")}
                        onSubmit={(data) => {
                            createCollection(data)
                                .unwrap()
                                .then(col => {
                                    toast.success(t("creation.responses.success"));

                                    history.push(`/dashboard/inventory/collections`);
                                })
                                .catch((e) => {
                                    if (isErrorWithMessage(e)) {
                                        toast.error(e.message);
                                    } else {
                                        toast.error(t("creation.responses.error"));
                                    }
                                });
                        }}
                    />
                </div>
            </BaseLoadingBlocker>
        </>
    );
}
