import {
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "@redux/api/internalApiSlice";
import { BestSelling, Feed, Revenue, TotalStat } from "@/types/general";
import laravelEcho from "@/config/laravelEcho";
import { RootState } from "@redux/hooks";
import { parseDate } from "@helpers/utils";

export const dashboardApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRevenue: builder.query<Revenue.Root[], Revenue.DTO.Filters>({
            query: ({mode}) => ({
                url: `dashboard/revenue`,
                params: {
                    mode
                }
            }),
            transformResponse,
            providesTags: ["Revenue"]
        }),

        getBestSellingProducts: builder.query<BestSelling.Root[], void>({
            query: () => ({
                url: `dashboard/best-selling`
            }),
            transformResponse,
            providesTags: ["BestSellingProducts"]
        }),

        getFeed: builder.query<
            PaginationResponse<Feed.Root>,
            PaginationRequest<Feed.DTO.Filters, void>
        >({
            query: (req) => ({
                url: `dashboard/feed`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["date"]
            }),
            async onCacheEntryAdded(
                arg,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }
            ) {
                let channel;
                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded;

                    // Getting user id to subscribe to the appropriate channel
                    const store = getState() as RootState;

                    // when data is received from the socket connection to the server,
                    // if it is a message and for the appropriate channel,
                    // update our query result with the received message
                    channel = laravelEcho.private(`dashboard.feed.${store.auth.user?.id}`);

                    channel.listen("FeedAction", (data: Feed.Root) => {
                        // Validating the data received from the socket
                        if(!isFeedAction(data)) {
                            console.warn("Echo:[getFeed >> FeedAction]: Received invalid data from the socket!", data);
                            return;
                        }

                        // Updating the cache with the new feed data
                        try {
                            updateCachedData((draft) => {
                                parseDate(data, "date");
                                draft.payload.unshift(data);
                            });
                        } catch (e) {
                            console.error("[Sockets -> FeedAction]: Error updating cache with new feed data!", e);
                        }
                    });
                } catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                } finally {
                    // cacheEntryRemoved will resolve when the cache subscription is no longer active
                    await cacheEntryRemoved;
                    // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                    channel?.stopListening("FeedAction", (onStopE: any) => console.warn("Stopped listening", onStopE));
                }
            },
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({
                            type: "Feed" as const,
                            id
                        })),
                        { type: "Feed", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "Feed", id: "PARTIAL-LIST" }]
        }),


        getTotalStat: builder.query<TotalStat.Root, void>({
            query: () => ({
                url: `dashboard/total`
            }),
            transformResponse,
            providesTags: ["Total"],
        })
    })
});

export function isFeedAction(data: any): data is Feed.Root {
    return data.id && data.date && "event" in data;
}

export const {
    useGetRevenueQuery,
    useGetFeedQuery,
    useGetBestSellingProductsQuery,
    useGetTotalStatQuery
} = dashboardApi;