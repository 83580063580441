import { createApi } from "@reduxjs/toolkit/query/react";
import { Country, Currency, StockCount, Users } from "@/types/general";
import { axiosBaseQueryWithReauth } from "@redux/api/query";

export const transformResponse = (r: { payload: any }) => r.payload;

export const tags = [
    "PurchaseOrder",
    "SaleOrder",
    "Company",
    "PaymentTerms",
    "DeliveryTerms",
    "Receive",
    "BOM",
    "BOMHistory",
    "Transfer",
    "Location",
    "Employee",
    "Language",
    "Tax",
    "Packaging",
    "Category",
    "Picking",
    "Roles",
    "Integrations",
    "Permission",
    "Adjustment",
    "StockCount",
    "Product",
    "Supplier",
    "Customer",
    "CustomerGroup",
    "User",
    "Revenue",
    "Feed",
    "BestSellingProducts",
    "Transaction",
    "Shipmondo"
];

export const internalApiSlice = createApi({
    reducerPath: "internal_api",
    baseQuery: axiosBaseQueryWithReauth(),
    tagTypes: tags,
    endpoints: (builder) => ({
        getUsers: builder.query<Users.Root[], void>({
            query: () => ({
                url: `user`
            }),
            transformResponse
        }),

        getCurrenciesPublic: builder.query<Currency.Root[], void>({
            query: () => ({
                url: `public/currency`
            }),
            transformResponse
        }),

        getCountries: builder.query<Country.Root[], void>({
            query: () => ({
                url: `country`
            }),
            transformResponse
        }),

        exportOrder: builder.mutation<Blob, { type: "purchase" | "sale", id: number }>({
            query: (args) => ({
                url: `products/generate-pdf`,
                responseType: "blob",
                method: "POST",
                params: {
                    type: args.type,
                    id: args.id
                }
            })
        })
    })
});

export type PaginationResponse<T> = {
    payload: T[],
    meta: {
        total: number;
    }
}

export type PaginationRequest<Filters, OrderingFields> = {
    filters?: Filters;
    orderBy?: { name: OrderingFields, type: "asc" | "desc" }[];
    pagination?: {
        limit: number;
        page: number;
    };
    reactive?: boolean;
}


export function flattenPaginationRequest<T, R>({ filters, orderBy, pagination, reactive }: PaginationRequest<T, R>) {
    return {
        ...(pagination && {
            pagination: {
                itemsPerPage: pagination.limit,
                currentPage: pagination.page
            }
        }),

        ...(orderBy && {
            orderBy: orderBy
        }),

        ...(filters && {
            filters: filters
        }),

        reactive: reactive ? 1 : 0
    };
}

export type ExportRequest<Filters = any, OrderingFields = any> = {
    columns?: {key: string; title?: string}[];
    email: string;
    filters?: Filters;
    orderBy?: { name: OrderingFields, type: "asc" | "desc" }[];
};

export function flattenExportRequest({ columns, email, filters, orderBy }: ExportRequest<any>) {
    return {
        email,
        ...(columns && {
            columns
        }),
        ...(filters && {
            filters
        }),
        ...(orderBy && {
            orderBy
        })
    };
}


export const {
    useGetUsersQuery,
    useGetCurrenciesPublicQuery,
    useGetCountriesQuery,
    useExportOrderMutation
} = internalApiSlice;

