import React, { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    ArrayElementType,
    cn,
    genT,
    jsxSwitch,
    PredefinedTranslations,
    removeEmpty,
    useDatesRangeFilter,
    useOrdering,
    usePagination
} from "@helpers/utils";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from "@reusables/BaseTable";
import { ReactComponent as DetailsSVG } from "@assets/icons/ic_document_search.svg";
import { ReactComponent as DeliveryNoteSVG } from "@assets/icons/ic_pdf.svg";
import { Picking } from "@/types/general";
import _ from "lodash";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseDatepicker from "@reusables/BaseDatepicker";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import DeletionModal from "../components/modals/DeletionModal";
import { Pagination, Stack } from "@mui/material";
import { useGetCustomersQuery } from "@redux/features/customers/customersApi";
import { useGetPickingsFullQuery } from "@redux/features/picking/pickingApi";
import BaseChip from "@reusables/BaseChip";
import { DatepickerRangeContext } from "@reusables/BaseDatepicker/context";
import { AbilityContext, Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { useAbility } from "@casl/react";
import { useAppDispatch } from "@redux/hooks";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import downloadDeliveryNoteHandler from "@components/Dashboard/pages/Sales/Picking/utils";
import { ReactComponent as DeliveryPackage } from "@assets/icons/ic_delivery_package.svg";
import { ReactComponent as DeliveryTruck } from "@assets/icons/ic_delivery_truck.svg";
import { ReactComponent as CheckCircle } from "@assets/icons/ic_check_circle.svg";
import BaseSyncedFromSourcesBadge from "@reusables/BaseSyncedFromSourcesBadge";
import BaseTooltip from "@reusables/BaseTooltip";

export default function PickingMainPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "sales.picking.main" });

    const ability = useAbility(AbilityContext);

    const dispatch = useAppDispatch();

    // Searching value with debounce
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), []);

    // Loading customers for the dropdown
    const { data: customersOptions, isLoading: suppliersLoading } = useGetCustomersQuery();
    const [selectedCustomers, setSelectedCustomers] = useState<NonNullable<typeof customersOptions>>([]);

    // Saving filtering date
    const { datesRange, setDatesRange, filterAdaptedDatesRange } = useDatesRangeFilter();

    // Defining status options
    const invoicingOptions: { label: string; key: "not_invoiced" | "invoiced" }[] = [
        {
            label: t("invoicing.status.0"),
            key: "not_invoiced"
        },
        {
            label: t("invoicing.status.1"),
            key: "invoiced"
        }
    ];

    const [selectedInvoicingOption, setSelectedInvoicingOption] = useState<ArrayElementType<typeof invoicingOptions>>();

    // Table ordering
    const { orderBy, setOrderBy } = useOrdering<Picking.DTO.OrderBy>({ name: "id", type: "desc" });

    const pagination = usePagination({ page: 1, limit: 8 });

    const filters = removeEmpty({
        search: searchingValue,
        customers: selectedCustomers.map(x => x.id),
        dates_range: filterAdaptedDatesRange,
        invoicing: selectedInvoicingOption?.key
    });

    const { data: pickingsResponse, isFetching: arePickingsLoading } = useGetPickingsFullQuery({
        filters,
        orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    const [deletionPickingId, setDeletionPickingId] = useState<number>();

    const [isDeliveryNoteLoading, setIsDeliveryNoteLoading] = useState(false);

    return (
        <>
            {/* FILTERS BLOCK */}
            <BaseAdminFilter
                permissionModule="picking"
                filterItems={
                    <BaseInputsGrid cols={1} gap={24}>
                        <BaseDropdown
                            label={t("filters.customer.label")}
                            brightLabel

                            options={customersOptions}
                            getter={{
                                label: opt => opt.name,
                                key: opt => opt.id
                            }}
                            value={selectedCustomers}

                            onChange={(_, val) => setSelectedCustomers(val)}

                            emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                            isLoading={suppliersLoading}

                            autocomplete
                            multiple
                        />

                        <DatepickerRangeContext.Provider value={{
                            "range": { range: datesRange, setRange: setDatesRange }
                        }}>
                            <BaseDatepicker
                                label={t("filters.date.label")}
                                placeholder={t("filters.date.placeholder")}
                                rangeConfig={{ groupKey: "range", role: "solo" }}
                                brightLabel
                            />
                        </DatepickerRangeContext.Provider>

                        <BaseDropdown
                            label={t("filters.invoicing.label")}
                            brightLabel

                            options={invoicingOptions}
                            getter={{
                                label: opt => opt.label,
                                key: opt => opt.key
                            }}
                            value={selectedInvoicingOption}

                            onChange={setSelectedInvoicingOption}

                            emptyValue={genT(PredefinedTranslations.DropdownsALL)}
                        />
                    </BaseInputsGrid>
                }

                handleSearch={searchInputDebounce}

                handleCreate={() => history.push("/dashboard/picking/new")}

                handleFilter={(e) => console.log(e)}
            />

            <BaseLoadingBlocker active={isDeliveryNoteLoading}>
                <div className="levitation-extended mt-[32px]">
                    <Can not I="index" a="picking">
                        <NoPermissionBanner />
                    </Can>

                    <Can I="index" a="picking">
                        <BaseTable
                            data={pickingsResponse?.payload ?? []}
                            columns={[
                                {
                                    header: t("table.columns.0"),
                                    getter: row => <div className={"flex flex-row space-x-2"}>
                                        <div className="bold-highlight">{row.code ?? row.id}</div>
                                        <BaseSyncedFromSourcesBadge
                                            integrations={[
                                                {
                                                    slug: "shipmondo",
                                                    integrated: () => !!row.shipmondo?.id
                                                }
                                            ]}
                                        />
                                    </div>,
                                    comparator: () => 0
                                },
                                {
                                    header: t("table.columns.1"),
                                    getter: row => <Link
                                        to={`/dashboard/sales/orders/${row.order.id}/details`}><u>{row.order.code ?? row.order.id}</u></Link>,
                                    comparator: () => 0
                                },
                                {
                                    header: t("table.columns.2"),
                                    getter: row => row.order.customer.name,
                                    comparator: () => 0
                                },
                                {
                                    header: t("table.columns.3"),
                                    getter: row => jsxSwitch(
                                        {
                                            0: <BaseChip
                                                className={"bg-[#F3F2EF]"}>{t(`invoicing.status.0`)}</BaseChip>,
                                            1: <BaseChip fill={"green"}>{t(`invoicing.status.1`)}</BaseChip>
                                        },
                                        Object.values(row.invoicing).filter(x => x != null).length ? 1 : 0
                                    ),
                                    comparator: () => 0
                                },
                                {
                                    header: t("table.columns.4"),
                                    getter: (row, index, isCollapsed) => jsxSwitch(
                                        {
                                            "not_shipped": <BaseChip
                                                className={"bg-[#F3F2EF]"}>{t(`delivery.status.not_shipped.0`)}</BaseChip>,
                                            "in_progress":
                                                <BaseChip {...isCollapsed ? { className: "bg-[#ECEDFE]" } : { fill: "purple" }}>
                                                    {t(`delivery.status.in_progress`)}
                                                </BaseChip>,
                                            "delivered": <BaseChip
                                                fill={"green"}>{t(`delivery.status.delivered`)}</BaseChip>
                                        },
                                        row.delivery_status
                                    ),
                                    comparator: () => 0
                                },
                                {
                                    header: t("table.columns.5"),
                                    getter: row => row.date.format("YYYY-MM-DD"),
                                    comparator: () => 0
                                },
                                {
                                    visible: ability.can("update", "picking") || ability.can("show", "picking"),
                                    header: <div className="text-center">{t("table.columns.6")}</div>,
                                    getter: (row, index, isCollapsed) =>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            width={"100%"}
                                            spacing={1}
                                        >
                                            <Can I="show" a="picking">
                                                <DetailsSVG
                                                    width={23}
                                                    height={23}
                                                    className={`${isCollapsed ? "text-tables-highlightedIcon" : "text-tables-secondaryIcon"} hover:text-tables-highlightedIcon cursor-pointer`}
                                                    onClick={() => {
                                                        history.push(`/dashboard/picking/${row.id}/details`);
                                                    }}
                                                />
                                            </Can>
                                            {
                                                row.has_delivery_note && <DeliveryNoteSVG
                                                    width={18}
                                                    height={18}
                                                    className={`${isCollapsed ? "text-tables-highlightedIcon" : "text-tables-secondaryIcon"} hover:text-tables-highlightedIcon cursor-pointer`}
                                                    onClick={() => downloadDeliveryNoteHandler({
                                                        pickingId: row.id,
                                                        dispatch,
                                                        setIsDeliveryNoteLoading,
                                                        defaultErrorMessage: t("responses.deliveryNoteFetchError")
                                                    })}
                                                />
                                            }
                                        </Stack>,
                                    preventCollapsePropagation: true
                                }
                            ]}

                            collapse={{
                                fill: "#D9DAFD",
                                borderColor: "#B3B5EB",
                                content: mainRow => {
                                    const numericalShippingState = mainRow.delivery_status === "not_shipped" ? 0 : mainRow.delivery_status === "in_progress" ? 1 : 2;
                                    return <>
                                        <div className={"mb-[16px] space-y-[8px] flex flex-column items-center"}>
                                            <div className={"flex flex-row items-center space-x-[8px]"}>
                                                <div className={"font-[600] text-md text-accent"}>
                                                    {
                                                        mainRow.delivery_status === "not_shipped"
                                                            ?
                                                            t(`delivery.status.${mainRow.delivery_status}.1`)
                                                            :
                                                            t(`delivery.status.${mainRow.delivery_status}`)
                                                    }
                                                </div>
                                                {
                                                    !!mainRow.shipment_change_date && <div className={"text-[#5A5587]"}>
                                                        ({mainRow.shipment_change_date.format("DD.MM.YYYY")})
                                                    </div>
                                                }
                                            </div>
                                            <div className={"flex flex-row items-center space-x-[16px]"}>
                                                <BaseTooltip title={t("delivery.status.not_shipped.1") + ""}
                                                             placement={"top"}>
                                                    <DeliveryPackage width={24} height={24}
                                                                     className={cn("text-[#B1B2E1]", numericalShippingState >= 0 && "text-accent")} />
                                                </BaseTooltip>
                                                <div
                                                    className={cn("bg-[#B1B2E1] w-[124px] h-[2px]", numericalShippingState >= 1 && "bg-accent")} />
                                                <BaseTooltip title={t("delivery.status.in_progress") + ""}
                                                             placement={"top"}>
                                                    <DeliveryTruck width={24} height={24}
                                                                   className={cn("text-[#B1B2E1]", numericalShippingState >= 1 && "text-accent")} />
                                                </BaseTooltip>
                                                <div
                                                    className={cn("bg-[#B1B2E1] w-[124px] h-[2px]", numericalShippingState >= 2 && "bg-accent")} />
                                                <BaseTooltip title={t("delivery.status.delivered") + ""}
                                                             placement={"top"}>
                                                    <CheckCircle width={24} height={24}
                                                                 className={cn("text-[#B1B2E1]", numericalShippingState >= 2 && "text-accent")} />
                                                </BaseTooltip>
                                            </div>
                                        </div>
                                        <BaseTable
                                            data={mainRow.lines}
                                            columns={[
                                                {
                                                    header: <div
                                                        className="text-accent font-semibold">{t("subtable.columns.0")}</div>,
                                                    getter: subRow => subRow.product.code
                                                },
                                                {
                                                    header: <div
                                                        className="text-accent font-semibold">{t("subtable.columns.1")}</div>,
                                                    getter: subRow => subRow.product.name
                                                },
                                                {
                                                    header: <div
                                                        className="text-accent font-semibold">{t("subtable.columns.2")}</div>,
                                                    getter: subRow => subRow.product.quantity
                                                },
                                                {
                                                    header: <div
                                                        className="text-accent font-semibold">{t("subtable.columns.3")}</div>,
                                                    getter: subRow => subRow.picks.reduce((acc, curr) => acc + curr.picked_quantity, 0)
                                                }
                                            ]}

                                            size="small"
                                            nothingFound={{
                                                height: 200
                                            }}
                                            hideTableWhenNothingFound
                                        />
                                    </>;
                                }
                            }}

                            alternate
                            isDataLoading={arePickingsLoading}
                            manualControls={{
                                ordering: (newOrdering) => {
                                    if (newOrdering) {
                                        let name: Picking.DTO.OrderBy | undefined;

                                        switch (newOrdering?.index) {
                                            case 0:
                                                name = "id";
                                                break;
                                            case 1:
                                                name = "customer";
                                                break;
                                            case 2:
                                                name = "date";
                                                break;
                                            case 3:
                                                name = "invoicing";
                                                break;
                                            default:
                                                name = undefined;
                                        }

                                        if (name)
                                            setOrderBy({
                                                name,
                                                type: newOrdering.order
                                            });
                                    } else {
                                        setOrderBy(undefined);
                                    }
                                }
                            }}
                        />

                        <Pagination
                            className="mt-[32px]"
                            {...pagination.adapt(pickingsResponse)}
                        />
                    </Can>
                </div>
            </BaseLoadingBlocker>

            <DeletionModal
                isOpen={!!deletionPickingId}
                onClose={() => setDeletionPickingId(undefined)}
                entity_id={deletionPickingId}
            />
        </>
    );
}