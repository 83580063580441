import React, { useEffect } from "react";
import { PurchaseOrder } from "@/types/general";
import { FormProvider, useForm } from "react-hook-form";
import { PurchaseOrdersFormTyping, purchaseOrdersScheme } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import GeneralSection from "./parts/GeneralSection";
import ReferencesSection from "./parts/ReferencesSection";
import CurrencySection from "./parts/CurrencySection";
import BaseButton from "@reusables/BaseButton";
import AddressSection from "./parts/AddressSection";
import ProductSection from "./parts/ProductSection";
import dayjs from "dayjs";
import { useGetCurrenciesQuery } from "@redux/features/currencies/currenciesApi";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { emptyFromSO, emptyQPO } from "@redux/features/purchases/purchasesSlice";
import { normalizePrice } from "@helpers/utils";
import OrderReference from "./parts/OrderReference";
import LanguageSection from "./parts/LanguageSection";

interface MutationLayoutProperties {
    purchaseOrder: PurchaseOrder.Extended | undefined;
    onSubmit: (args: PurchaseOrdersFormTyping) => void;
}

export default function MutationLayout(props: MutationLayoutProperties) {
    const {data: currenciesOptions = [], isLoading: isCompanyCurrenciesOptionsLoading} = useGetCurrenciesQuery();
    const baseCurrency = currenciesOptions.find(c => c.isBase);

    const dispatch = useAppDispatch();
    const transitOrders = useAppSelector(state => state.purchases.QPO);
    const fromSOOrders = useAppSelector(state => state.purchases.fromSO);

    const form = useForm<PurchaseOrdersFormTyping>({
        resolver: zodResolver(purchaseOrdersScheme),
        defaultValues: {
            mode: "creation",
            is_billing_for_delivery: false,
            purchase_date: dayjs(),
            orders: [],
            is_split_by_products: false,
            currency: baseCurrency,
            deleted_orders: [],
            reference_number: {}
        },
    });

    useEffect(() => {
        if (props.purchaseOrder) {
            const order = props.purchaseOrder;

            form.setValue("mode", "editing");

            form.setValue("supplier", order.supplier);
            form.setValue("purchase_date", order.purchase_date);
            form.setValue("preferred_delivery_date", order.preferred_delivery_date);
            form.setValue("our_reference", order.our_reference);
            form.setValue("their_reference", order.their_reference);
            form.setValue("payment_terms", order.payment_terms);
            form.setValue("delivery_terms", order.delivery_terms);
            form.setValue("language", order.language);
            if (order.billing_address)
                form.setValue("billing_address", {
                    name: order.billing_address.name,
                    street: order.billing_address.street,
                    city: order.billing_address.city,
                    country: order.billing_address.country,
                });
            form.setValue("delivery_address", order?.delivery_address);

            form.setValue("is_billing_for_delivery", order.is_billing_for_delivery);
            form.setValue("currency", order.currency);
            form.setValue("exchange_rate", order.exchange_rate);
            form.setValue("shipping_cost", order.shipping_cost);
            form.setValue("is_split_by_products", order.is_split_by_products);
            form.setValue("orders", order.orders.map(line => ({
                ...line,
                unit_price: {
                    converted: normalizePrice(line.unit_price * order.exchange_rate),
                    original: line.unit_price,
                },
            })));

            !!order.reference_number &&
                form.setValue("reference_number", order.reference_number)

            form.setValue("deleted_orders", [])
        }
    }, [props.purchaseOrder]);

    // Needed for QUICK PUCHASE ORDER feature, used, for example, on restocking
    useEffect(() => {
        if (transitOrders.orders.length) {
            form.setValue("orders", transitOrders.orders.map(line => ({
                ...line,
                unit_price: {
                    converted: normalizePrice(line.unit_price ?? 0),
                    original: normalizePrice(line.unit_price ?? 0),
                },
            })));

            if (transitOrders.meta.supplier)
                form.setValue("supplier", transitOrders.meta.supplier);

            dispatch(emptyQPO())
        }
    }, [transitOrders])

    // Needed for "PO FROM SO" feature, used, when sold products are low on stock
    useEffect(() => {
        if (fromSOOrders?.lines.length && fromSOOrders?.order) {
            form.setValue("orders", fromSOOrders.lines.map(line => ({
                ...line,
                unit_price: {
                    converted: normalizePrice(line.unit_price ?? 0),
                    original: normalizePrice(line.unit_price ?? 0),
                },
            })));

            form.setValue("reference_number", {
                id: fromSOOrders.order.id,
                code: fromSOOrders.order.code,
            });

            if (fromSOOrders.order.preferred_delivery_date)
                form.setValue("preferred_delivery_date", fromSOOrders.order.preferred_delivery_date.subtract(1, "day"));

            if (fromSOOrders.order.our_reference)
                form.setValue("our_reference", fromSOOrders.order.our_reference);

            dispatch(emptyFromSO())
        }
    }, [fromSOOrders])

    const onSubmit = form.handleSubmit((data: PurchaseOrdersFormTyping) => {
        props.onSubmit(data);
    }, console.error);

    return (
        <FormProvider {...form}>
            <OrderReference/>
            <div className="space-y-[40px]">
                <GeneralSection/>
                <ReferencesSection/>
                <CurrencySection/>
                <AddressSection/>
                <LanguageSection/>
                <ProductSection/>
                <div className="flex justify-center">
                    <BaseButton
                        text={"Save"}
                        size={"md"}
                        buttonWidth={"200px"}
                        onClick={() => onSubmit()}
                    />
                </div>
            </div>
        </FormProvider>
    )
}
