import React, { useEffect, useMemo } from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Controller, useForm, useWatch } from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { Alert, Collapse } from "@mui/material";
import BaseButton from "@reusables/BaseButton";
import { zodResolver } from "@hookform/resolvers/zod";

import { ReactComponent as DropdownIcon } from "../../../../../../../assets/icons/ic_expand_down.svg";


import { useGetCustomersQuery } from "@redux/features/customers/customersApi";
import { salesOrdersApi, useGetSaleOrdersFullQuery } from "@redux/features/sales/orders/salesOrdersApi";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import BaseDatepicker from "@reusables/BaseDatepicker";
import TableHeading from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/TableHeading";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import TableBody from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/TableBody";
import { useHistory } from "react-router-dom";
import type { SaleOrder } from "@/types/general";
import { formScheme, PickingMutationFormTyping } from "./types";
import ShipmondoIntegration
    from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/parts/ShipmondoIntegration";
import PackagesModal from "../modals/PackagesModal";
import BaseLabel from "@/components/reusables/BaseLabel";


export interface MutationLayoutProps {
    title: string;
    onMutation: (data: PickingMutationFormTyping) => void;
}

interface RouterStateType {
    saleOrder: Pick<SaleOrder.Root, "id" | "code" | "customer"> | undefined;
}

export default function MutationLayout({ onMutation, ...props }: MutationLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.general" });

    const history = useHistory();
    const historyState = history.location.state as RouterStateType | undefined;
    const historySaleOrder = historyState?.saleOrder;

    const dispatch = useAppDispatch();

    const isShipmondoIntegrated = useAppSelector((state) => {
        return state.auth.user?.company?.integrations?.shipmondo.integrated ?? false;
    });

    const [isPackagesModalOpen, setIsPackagesModalOpen] = React.useState(false);

    const { control, setValue, handleSubmit, formState, resetField, getValues } = useForm<PickingMutationFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            lines: [],
            picking_date: {
                date: dayjs()
            },
            extractedFromTransit: false,
            shipmondo: {
                enabled: isShipmondoIntegrated
            }
        }
    });

    // [CUSTOMERS] Loading customers for the dropdown
    const { data: customersOptions, isLoading: isCustomersOptionsLoading } = useGetCustomersQuery();

    const selectedCustomer = useWatch({
        control,
        name: "customer"
    });

    // [SALES ORDERS] Loading sales orders for the dropdown, if [CUSTOMER] is selected
    // aka filtering sales orders by customer
    const {
        data: salesOrdersOptions,
        isFetching: isSalesOrdersFetching,
        isLoading: isSalesOrdersLoading
    } = useGetSaleOrdersFullQuery(
        {
            filters: {
                customers: selectedCustomer ? [selectedCustomer.id] : undefined
            },
            orderBy: [{
                name: "id",
                type: "desc"
            }],
            reactive: true
        },
        {
            skip: !selectedCustomer
        }
    );

    const selectedSaleOrder = useWatch({
        control,
        name: "sale_order"
    });

    const [isExtendedSaleOrderLoading, setIsExtendedSaleOrderLoading] = React.useState(false);

    // Loading *extended* sale order (from the selected Root SO) data to obtain products with locations and other crucial data
    useEffect(() => {
        if (selectedSaleOrder) {
            setIsExtendedSaleOrderLoading(true);
            dispatch(salesOrdersApi.endpoints.getSaleOrder.initiate(selectedSaleOrder.id))
                .unwrap()
                .then((extendedSO) => {
                    setValue(
                        "lines",
                        extendedSO.orders.map((orderLine) => ({
                            id: orderLine.id,
                            product: {
                                id: orderLine.product.id,
                                name: orderLine.product.name,
                                code: orderLine.product.code,
                                is_service: orderLine.product.is_service,
                                is_serial_numbers: orderLine.product.is_serial_numbers,
                                is_batch_numbers: orderLine.product.is_batch_numbers,
                                quantity: orderLine.quantity,
                                already_picked_quantity: orderLine.picked_quantity ?? 0,

                                locations: orderLine.product.locations
                            },
                            ...(orderLine.product.is_service
                                ? {
                                    picked_quantity: undefined,
                                    picks: []
                                }
                                : {
                                    picks: [
                                        {
                                            outgoing_location: undefined,
                                            picked_quantity: undefined
                                        }
                                    ]
                                }),
                            serial_numbers: [],
                            batch_numbers: []
                        }))
                    );

                    if (extendedSO.delivery_address?.country || (extendedSO.billing_address.is_used_for_shipping && extendedSO.billing_address.country)) {
                        const country = extendedSO.billing_address.is_used_for_shipping ? extendedSO.billing_address.country : extendedSO.delivery_address?.country;

                        setValue("order_destination_country", country);
                    } else {
                        console.warn("Unable to determine the destination country of the order");
                    }
                })
                .catch((e) => {
                    if (isErrorWithMessage(e)) {
                        toast.error(e);
                    } else {
                        toast.error(t("responses.unableToLoadExtendedSO"));
                    }
                })
                .finally(() => void setIsExtendedSaleOrderLoading(false));
        } else {
            resetField("lines");
            resetField("shipmondo");
        }
    }, [selectedSaleOrder]);

    useEffect(() => {
        if (!getValues("extractedFromTransit")) {
            resetField("sale_order");
            resetField("shipmondo");
            resetField("order_destination_country");
        } else {
            setValue("extractedFromTransit", false);
        }
    }, [selectedCustomer]);

    // Setting data from the history sale order state (used for quick picking)
    useEffect(() => {
        if (historySaleOrder) {
            setValue(
                "customer",
                {
                    id: historySaleOrder.customer.id,
                    code: historySaleOrder.customer.code,
                    name: historySaleOrder.customer.name
                },
                { shouldValidate: false }
            );

            setValue(
                "sale_order",
                {
                    id: historySaleOrder.id,
                    code: historySaleOrder.code
                },
                { shouldValidate: false }
            );

            setValue("extractedFromTransit", true, { shouldValidate: false });
        }
    }, [historySaleOrder]);

    // Just a workaround to operate with custom errors like "serial_numbers" and "batch_numbers" added via superRefine in ZOD
    const serialAndBatchNumberErrors = useMemo(() => {
        const linesErrors = formState.errors.lines as {
            serial_numbers?: { message: string };
            batch_numbers?: { message: string };
        };

        if (linesErrors) {
            return {
                ...(!!linesErrors.serial_numbers && { serial_numbers: linesErrors.serial_numbers.message }),
                ...(!!linesErrors.batch_numbers && { batch_numbers: linesErrors.batch_numbers.message })
            };
        }
    }, [formState]);

    const onSubmit = handleSubmit((data) => {
        onMutation?.(data);
    }, console.error);

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className={"space-y-[40px]"}>
                    <div className="levitation-extended space-y-[40px]">
                        <div className="flex items-center">
                            <h6 className="text-accent grow">{props.title}</h6>
                        </div>

                        {/* Picking header */}
                        <BaseInputsGrid>
                            {/* Supplier */}
                            <Controller
                                name="customer"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}
                                        label={t("fields.customer.label")}
                                        placeholder={t("fields.customer.placeholder")}
                                        options={customersOptions}
                                        getter={{
                                            label: (opt) => opt.name,
                                            key: (opt) => opt.id,
                                            caption: (opt) => opt.code ?? opt.id
                                        }}
                                        isLoading={isCustomersOptionsLoading}
                                        autocomplete

                                    />
                                )}
                            />

                            {/* Purchase order */}
                            <Controller
                                name="sale_order"
                                control={control}
                                render={({ field, fieldState }) => (
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}
                                        label={t("fields.saleOrder.label")}
                                        placeholder={t("fields.saleOrder.placeholder")}
                                        options={salesOrdersOptions?.payload ?? []}
                                        getter={{
                                            label: (opt) => opt.code ?? `${opt.id}`,
                                            key: (opt) => opt.id
                                        }}
                                        isLoading={isSalesOrdersLoading || isSalesOrdersFetching}
                                        autocomplete
                                    />
                                )}
                            />

                            {/* Picking date*/}
                            <Controller
                                name="picking_date.date"
                                control={control}
                                render={({ field, fieldState }) => <BaseDatepicker {...field} {...fieldState}
                                                                                   label={t("fields.pickingDate.label")}
                                                                                   placeholder={t("fields.pickingDate.placeholder")} />}
                            />
                        </BaseInputsGrid>

                        <div className={"space-y-[10px]"}>
                            {/* Error for the general "pick more than one" error */}
                            {
                                <Collapse in={!!formState.errors.lines?.root}>
                                    <Alert severity="error">{formState.errors?.lines?.root?.message}</Alert>
                                </Collapse>
                            }

                            {/* Error for bad SERIALS filling */}
                            {
                                <Collapse in={!!serialAndBatchNumberErrors?.serial_numbers}>
                                    <Alert severity="error">{serialAndBatchNumberErrors?.serial_numbers}</Alert>
                                </Collapse>
                            }

                            {/* Error for bad BATCHES filling */}
                            {
                                <Collapse in={!!serialAndBatchNumberErrors?.batch_numbers}>
                                    <Alert severity="error">{serialAndBatchNumberErrors?.batch_numbers}</Alert>
                                </Collapse>
                            }
                        </div>

                        <BaseLoadingBlocker active={isExtendedSaleOrderLoading}>
                            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                                {/* Product table heading*/}
                                <TableHeading control={control} onLinesUpdate={(lines) => setValue("lines", lines)} />
                                {/*Product table body*/}
                                <TableBody control={control} />
                            </div>
                        </BaseLoadingBlocker>

                        <BaseInputsGrid>
                            <Controller
                                name="packages"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <div className="flex flex-col">
                                        <BaseLabel>{t("fields.packaging.label")}</BaseLabel>
                                        <div className="relative">
                                            <BaseDropdown
                                                placeholder={t("fields.packaging.placeholder")}

                                                {...field}
                                                {...fieldState}
                                                onChange={(_, options) => {
                                                    field.onChange(options);
                                                }}
                                                options={getValues("packages")}
                                                getter={{
                                                    key: (opt) => opt.package.id,
                                                    label: (opt) => `${opt.quantity} x ${opt.package.name}`
                                                }}

                                                onOpen={() => {
                                                    setIsPackagesModalOpen(true);
                                                }}
                                                autocomplete
                                                multiple

                                                hideArrow
                                            />
                                            <DropdownIcon
                                                className={`absolute right-3 top-1/2 text-dropdown-icon`}
                                                style={{ transform: "translateY(-50%) rotate(270deg)" }}
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </BaseInputsGrid>
                    </div>

                    {isShipmondoIntegrated && (
                        <div className={"levitation-extended"}>
                            {
                                <ShipmondoIntegration control={control} resetField={resetField} setValue={setValue} />
                            }
                        </div>
                    )}

                    <div className="flex justify-center">
                        <BaseButton text={t("buttons.save")} size="md" buttonWidth="200px" />
                    </div>
                </div>
            </form>

            <PackagesModal
                isOpen={isPackagesModalOpen}
                onClose={() => setIsPackagesModalOpen(false)}
                packages={getValues("packages")}
                onSave={(packages) => {
                    setValue("packages", packages.packages?.map((p) => ({ package: p.package, quantity: p.quantity })));
                    setIsPackagesModalOpen(false);
                }}
            />
        </>
    );
}
