import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import BaseMaterialButton from '@reusables/BaseMaterialButton';
import BaseTable from '@reusables/BaseTable';

import {selectLanguageById, useGetLanguagesQuery} from '@redux/features/languages/languagesApi';
import {ReactComponent as DeleteSVG} from "@assets/icons/ic_trash.svg";
import {useAppSelector} from '@redux/hooks';

import MutationModal from './modals/MutationModal';
import DeletionModal from './modals/DeletionModal';
import {AbilityContext, Can} from "@/casl.config";
import {useAbility} from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function LanguagesPage() {
    const {t} = useTranslation("", {keyPrefix: "settings.general.languages"});

    const ability = useAbility(AbilityContext);

    // Getting Languages data using RTK Query
    const {data: languages = [], isLoading: isLoadingLanguages} = useGetLanguagesQuery();

    // Open states determine visibility of modals
    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allows modal to work with data
    const [deletableLanguageId, setDeletableLanguageId] = useState<number>();

    // deletableLanguage is used to pass data to modal
    const deletableLanguage = useAppSelector(state => selectLanguageById(state, deletableLanguageId));

    return (
        <>
            <div className="levitation-extended">
                <div className='flex flex-row justify-between items-center mb-[44px]'>
                    <div className='font-bold text-2xl text-accent'>{t("heading")}</div>
                    <Can I="create" a="language">
                        <BaseMaterialButton fontWeight={500} onClick={() => {
                            // setSelectedLanguageId(undefined);
                            setIsMutationModalOpen(true);
                        }}>
                            {t("table.buttons.add")}
                        </BaseMaterialButton>
                    </Can>
                </div>

                <Can not I="index" a="language">
                    <NoPermissionBanner />
                </Can>

                <Can I="index" a="language">
                    <BaseTable
                        data={languages}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) => <div className='bold-highlight'>{row.name}</div>,
                                comparator: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) => row.code,
                                comparator: (a, b) => a.code.localeCompare(b.code)
                            },
                            {
                                visible: ability.can("delete", "language"),
                                header: () => "",
                                getter: (row) => (
                                    <div className='text-tables-secondaryIcon'>
                                        <Can I="update" a="language">
                                            <DeleteSVG 
                                                className='mr-[8px] cursor-pointer hover:text-tables-highlightedIcon' 
                                                onClick={() => {
                                                    setDeletableLanguageId(row.id);
                                                    setIsDeletionModalOpen(true);
                                                }}
                                            />
                                        </Can>
                                    </div>
                                ),
                                sx: {
                                    textAlign: "right"
                                }
                            }
                        ]}
                        isDataLoading={isLoadingLanguages}

                        headerSx={{
                            backgroundColor: "#F9F9F9"
                        }}

                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}

                        size={"small"}
                    />
                </Can>
            </div>

            <MutationModal 
                isOpen={isMutationModalOpen}
                onClose={() => setIsMutationModalOpen(false)}
            />

            <DeletionModal 
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                language={deletableLanguage}
            />
        </>
    );
}
