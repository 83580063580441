import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import { z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@redux/hooks";
import { ExportModalContext } from "@reusables/Modals/BaseExportModal/ExportModalProvider";
import EmailField from "@reusables/Modals/BaseExportModal/parts/EmailField";
import ConfirmButton from "@reusables/Modals/BaseExportModal/parts/ConfirmButton";
import ColumnsSelector from "@reusables/Modals/BaseExportModal/parts/ColumnsSelector";
import ConcurrencyWarning from "@reusables/Modals/BaseExportModal/parts/ConcurrencyWarning";
import AmountSelector from "@reusables/Modals/BaseExportModal/parts/AmountSelector";
import FilterInheritanceWarning from "@reusables/Modals/BaseExportModal/parts/FilterInheritanceWarning";

interface ExportModalComponentProps {
    isOpen?: boolean;
    onClose?: () => void;
    onSubmit?: (data: ExportModalFormTyping) => void;
    isLoading?: boolean;
    children?: React.ReactNode;
    retainDataOnClose?: boolean;
}

const formScheme = z.object({
    isUsePersonalEmail: z.boolean(),
    email: z.string().email(),

    columns: z.array(z.object({
        key: z.string(),
        title: z.string().optional()
    })),

    amount_selector: z.object({
        is_all: z.boolean().nullish(),
        first_number: z.coerce.number().nullish(),
        selected: z.coerce.number().nullish()
    })
});

export type ExportModalFormTyping = z.infer<typeof formScheme>;

function ExportModalComponent(props: ExportModalComponentProps) {
    const { t } = useTranslation("", { keyPrefix: "general.modals.export" });

    const user = useAppSelector(state => state.auth.user);

    const form = useForm<ExportModalFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            isUsePersonalEmail: false,
            email: user?.email,
            columns: [],
            amount_selector: {}
        }
    });

    const onSubmit = form.handleSubmit((data) => {
        props.onSubmit?.(data);
    }, console.error);

    useEffect(() => {
        if (!props.isOpen && !props.retainDataOnClose) {
            form.reset();
        }
    }, [props.isOpen, props.retainDataOnClose]);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={612}
            padding="56px"
            useCloseIcon={true}
            isLoading={props.isLoading}
        >
            <ExportModalContext.Provider value={{ isOpen: !!props.isOpen, onClose: props.onClose }}>
                <FormProvider {...form}>
                    <form className="space-y-[32px]" onSubmit={onSubmit}>
                        <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                        {props.children}
                    </form>
                </FormProvider>
            </ExportModalContext.Provider>
        </BaseModal>
    );
}

const BaseExportModal = Object.assign(ExportModalComponent, {
    Email: EmailField,
    Confirm: ConfirmButton,
    ColumnsSelector: ColumnsSelector,
    ConcurrencyWarning: ConcurrencyWarning,
    FiltersInheritanceWarning: FilterInheritanceWarning,
    AmountSelector: AmountSelector
});


export default BaseExportModal;