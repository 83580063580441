import { BaseLoadingBlocker } from "@/components/reusables/blockers/BaseLoadingBlocker";
import { useGetCollectionQuery, useUpdateCollectionMutation } from "@/redux/features/collections/collectionsApi";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import EditingLayout from "../components/EditingLayout";
import { toast } from "react-toastify";
import { isErrorWithMessage } from "@/redux/api/query";
import BaseRecordRenderer from "@/components/reusables/BaseRecordRenderer";

export default function CollectionsEditingPage() {
    const history = useHistory();

    const { t } = useTranslation("", {
        keyPrefix: "inventory.collections.collectionMutation"
    });

    const [updateCollection, { isLoading }] = useUpdateCollectionMutation();

    const { id } = useParams<{ id: string }>();

    const { data: collection, isLoading: collectionLoading } = useGetCollectionQuery(parseInt(id));

    return (
        <>
            <BaseLoadingBlocker active={isLoading}>
                <BaseRecordRenderer loading={collectionLoading} record={collection}>
                    <div className="flex mb-[40px]">
                        <h6 className="text-accent grow">{t("editing.heading")}</h6>
                    </div>
                    <EditingLayout
                        collection={collection}
                        buttonText={t("saveButton")}
                        onSubmit={(data) => {
                            const sendableBarcode = data?.barcode?.length ?
                                collection?.barcode === data.barcode
                                    ? undefined
                                    : data.barcode
                                : null;

                            updateCollection({
                                id: parseInt(id),
                                products: data.products,
                                name: collection?.name === data.name ? undefined : data.name,
                                barcode: sendableBarcode
                            })
                                .unwrap()
                                .then(() => {
                                    toast.success(t("editing.responses.success"));

                                    history.push(`/dashboard/inventory/collections`);
                                })
                                .catch((e) => {
                                    if (isErrorWithMessage(e)) {
                                        toast.error(e.message);
                                    } else {
                                        toast.error(t("editing.responses.error"));
                                    }
                                });
                        }}
                    />
                </BaseRecordRenderer>
            </BaseLoadingBlocker>
        </>
    );
}
