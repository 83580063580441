import React, {useEffect} from "react";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {
    PurchaseOrdersFormTyping
} from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/types";
import {useTranslation} from "react-i18next";
import {useGetSuppliersQuery} from "@redux/features/suppliers/suppliersApi";
import CreateSupplierModal from "./CreateSupplierModal";
import BaseDatepicker from "@reusables/BaseDatepicker";
import {useLocation} from "react-router-dom";

interface RouterStateType {
    supplier: {
        id: number;
        name: string;
        code?: string;
    }
}

export default function GeneralSection() {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general"});

    const location = useLocation<RouterStateType>();

    const routerState = location.state;

    const {control, setValue} = useFormContext<PurchaseOrdersFormTyping>();

    const mode = useWatch({
        control,
        name: "mode"
    });

    // === RTKQ ===
    // --> Suppliers
    const {data: supplierOptions, isLoading: isSupplierOptionsLoading} = useGetSuppliersQuery(undefined, {
        skip: mode === "editing"
    });

    // === Modals ===
    const [isNewSupplierModalOpen, setIsNewSupplierModalOpen] = React.useState(false);

    useEffect(() => {
        if (routerState?.supplier) {
            setValue("supplier", {
                id: routerState.supplier.id,
                name: routerState.supplier.name,
                code: routerState.supplier.code
            });
        }
    }, [routerState]);

    return (
        <>
            <BaseInputsGrid>
                {/* Supplier */}
                <Controller
                    name="supplier"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            label={`${t("dropdowns.supplier.label")} *`}
                            placeholder={t("dropdowns.supplier.placeholder")}
                            options={supplierOptions}
                            getter={{
                                label: (item) => item.name,
                                key: (item) => item.id,
                                caption: item => item.code ?? item.id
                            }}

                            autocomplete

                            action={{
                                title: t("dropdowns.supplier.buttons.create"),
                                onClick: () => setIsNewSupplierModalOpen(true)
                            }}

                            disableClearable

                            isLoading={isSupplierOptionsLoading}

                            virtualize

                            disabled={mode === "editing"}
                        />
                    )}
                />

                {/* Purchase date */}
                <Controller
                    name="purchase_date"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDatepicker
                            {...field}
                            {...fieldState}

                            label={`${t("fields.purchaseDate.label")} *`}
                            placeholder={t("fields.purchaseDate.placeholder")}
                            disablePast
                        />
                    )}
                />

                {/* Preferred delivery date */}
                <Controller
                    name="preferred_delivery_date"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDatepicker
                            {...field}
                            {...fieldState}

                            label={t("fields.preferredDeliveryDate.label")}
                            placeholder={t("fields.preferredDeliveryDate.placeholder")}
                            disablePast
                        />
                    )}
                />
            </BaseInputsGrid>

            <CreateSupplierModal
                isOpen={isNewSupplierModalOpen}
                onClose={() => setIsNewSupplierModalOpen(false)}
                onCreate={sup => {
                    setValue("supplier", {
                        id: sup.id,
                        name: sup.name,
                        code: sup.code
                    });

                    setIsNewSupplierModalOpen(false)
                }}
            />
        </>
    )
}