import React, { useState } from "react";
import { ModulesSection, RoleFormTyping } from "../../definitions";
import { Control, Controller, useFieldArray } from "react-hook-form";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseAccordion from "@reusables/baseAccordion";
import { useTranslation } from "react-i18next";

interface ModulesContainerProps {
    control: Control<RoleFormTyping>,
    sectionKey: ModulesSection
}

export default function ModulesContainer({ control, sectionKey }: ModulesContainerProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.mutation" });

    const { fields: modules } = useFieldArray({
        control,
        name: `sections.${sectionKey}`
    });

    const [isExpanded, setIsExpanded] = useState(true);

    if(!modules.length)
        return <></>

    return (
        <BaseAccordion
            expanded={isExpanded}
            onExpandChange={(_, isExpanded) => setIsExpanded(isExpanded)}
            summary={
                <div className="text-xl font-semibold text-accent">
                    {t(`title.${sectionKey}`)}
                </div>
            }
            details={
                <BaseInputsGrid cols={3}>
                    {
                        modules.map((module, index) => (
                            <Controller
                                key={module.id}
                                name={`sections.${sectionKey}.${index}.selected_abilities`}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        onChange={(_, multiple) => field.onChange(multiple)}
                                        error={fieldState.error}

                                        label={t(`dropdowns.${module.module_key}.label`) ?? module.module_name}
                                        placeholder={t(`dropdowns.${module.module_key}.placeholder`)}

                                        options={module.abilities}
                                        getter={{
                                            key: opt => opt.key,
                                            label: opt => t(`abilities.${opt.key}`) ?? opt.key_name
                                        }}

                                        emptyValue={t("abilities.accessDenied")}

                                        multiple
                                    />
                                )}
                            />
                        ))
                    }
                </BaseInputsGrid>
            }
        />
    );
}