import React, {useEffect} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {SaleOrdersFormTyping} from "../../types";
import {useTranslation} from "react-i18next";
import BillingAddressInput from "./BillingAddressInput";
import DeliveryAddressInput from "./DeliveryAddressInput";
import {useGetCustomerQuery} from "@redux/features/customers/customersApi";


export default function AddressSection() {
    const {t} = useTranslation("", {keyPrefix: "sales.orders.general"});

    const {control, setValue, getValues} = useFormContext<SaleOrdersFormTyping>();

    const customer = useWatch({
        control,
        name: "customer"
    })

    const mode = useWatch({
        control,
        name: "mode"
    });

    const {data: extendedCustomerData, isLoading: isExtendedCustomerDataLoading} = useGetCustomerQuery(
        customer?.id as number,
        {skip: !customer || mode === "editing"}
    );

    useEffect(() => {
        if (extendedCustomerData?.billing) {
            setValue("billing_address", {
                name: extendedCustomerData.billing.name,
                street: extendedCustomerData.billing.street,
                city: extendedCustomerData.billing.city,
                country: extendedCustomerData.billing.country,
            });
        }
    }, [extendedCustomerData]);


    return (
        <>
            <BaseInputsGrid title={t("categories.address")}>
                <BillingAddressInput
                    isLoading={isExtendedCustomerDataLoading}
                />
                <DeliveryAddressInput
                    options={extendedCustomerData?.deliveries ?? []}
                    isLoading={isExtendedCustomerDataLoading}
                />
            </BaseInputsGrid>
        </>
    )
}