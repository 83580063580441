import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Collapse } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { isExportStateActive } from "@redux/features/importExport/export/types";

export default function ConcurrencyWarning() {
    const { t } = useTranslation("", { keyPrefix: "general.modals.export" });

    const isExportInProgress = useAppSelector(state => isExportStateActive(state.export.value));

    return (
        <>
            {isExportInProgress ? (
                    <Collapse in={isExportInProgress}>
                        <Alert severity="warning">
                            {t("exportIsAlreadyInProgressAlert")}
                        </Alert>
                    </Collapse>
                )
                :
                <div className="text-center text-[16px] text-accent">
                    {t("concurrencyWarning")}
                </div>
            }
        </>
    );
}