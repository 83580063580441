import React, { useEffect } from "react";
import { SaleOrder } from "@/types/general";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import GeneralSection from "./parts/GeneralSection";
import ReferencesSection from "./parts/ReferencesSection";
import BaseButton from "@reusables/BaseButton";
import AddressSection from "./parts/AddressSection";
import ProductSection from "./parts/ProductSection";
import dayjs from "dayjs";
import { SaleOrdersFormTyping, saleOrdersScheme } from "./types";
import { toast } from "react-toastify";
import { normalizePrice } from "@helpers/utils";
import LanguageSection from "./parts/LanguageSection";

interface MutationLayoutProperties {
    saleOrder: SaleOrder.Extended | undefined;
    onSubmit: (args: SaleOrdersFormTyping) => void;
}

export default function MutationLayout(props: MutationLayoutProperties) {
    const form = useForm<SaleOrdersFormTyping>({
        resolver: zodResolver(saleOrdersScheme),
        defaultValues: {
            mode: "creation",
            is_billing_for_delivery: false,
            order_date: dayjs(),
            orders: [],
            is_invoiced: false,
            deleted_orders: []
        }
    });

    useEffect(() => {
        if (props.saleOrder) {
            form.setValue("mode", "editing");

            form.setValue("customer", props.saleOrder.customer);
            form.setValue("order_date", props.saleOrder.order_date);
            form.setValue("preferred_delivery_date", props.saleOrder.preferred_delivery_date);
            form.setValue("our_reference", props.saleOrder.our_reference);
            form.setValue("their_reference", props.saleOrder.their_reference);
            form.setValue("payment_terms", props.saleOrder.payment_terms);
            form.setValue("delivery_terms", props.saleOrder.delivery_terms);
            form.setValue("language", props.saleOrder.language);
            if (props.saleOrder.billing_address)
                form.setValue("billing_address", {
                    name: props.saleOrder.billing_address.name,
                    street: props.saleOrder.billing_address.street,
                    city: props.saleOrder.billing_address.city,
                    country: props.saleOrder.billing_address.country
                });
            form.setValue("delivery_address", props.saleOrder?.delivery_address);

            form.setValue("is_billing_for_delivery", props.saleOrder.is_billing_for_delivery);
            form.setValue(
                "orders",
                props.saleOrder.orders.map((order) => ({
                    ...order,
                    unit_price: normalizePrice(order.unit_price)
                }))
            );

            form.setValue("deleted_orders", []);
        }
    }, [props.saleOrder]);

    const onSubmit = form.handleSubmit((data: SaleOrdersFormTyping) => {
        if (props.saleOrder?.is_invoiced) {
            for (let i = 0; i < form.getValues("orders").length; i++) {
                const originalQuantity = props.saleOrder?.orders[i].quantity;
                const newQuantity = form.getValues("orders")[i].quantity;

                // If the new quantity is less than the original, show error and stop the mutation
                if (newQuantity < originalQuantity) {
                    toast.error(`Quantity for product ${form.getValues("orders")[i].product.name} cannot be less than the original quantity.`);
                    return;
                }
            }
        }
        props.onSubmit(data);
    }, console.error);

    return (
        <FormProvider {...form}>
            <div className="space-y-[40px]">
                <GeneralSection />
                <ReferencesSection />
                <AddressSection />
                <LanguageSection />
                <ProductSection />
                <div className="flex justify-center">
                    <BaseButton text={"Save"} size={"md"} buttonWidth={"200px"} onClick={() => onSubmit()} />
                </div>
            </div>
        </FormProvider>
    );
}