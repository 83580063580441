
import React from 'react'
import {Customer, SaleOrder} from "@/types/general";
import {calculateOrderPriceWithTax, jsxSwitch, useOrdering, usePagination} from '@helpers/utils';
import {useTranslation} from 'react-i18next';
import {useGetSaleOrdersFullQuery} from '@redux/features/sales/orders/salesOrdersApi';
import {useHistory} from 'react-router-dom';
import BaseTable from '@reusables/BaseTable';
import BaseChip from '@reusables/BaseChip';
import {Pagination} from '@mui/material';
import {useGetCurrenciesQuery} from '@redux/features/currencies/currenciesApi';


export default function SaleOrdersTab({ customer }: { customer: Customer.Extended }) {
    const { t } = useTranslation("", { keyPrefix: "customers.general.tabs.salesOrders" });

    const history = useHistory();

    const {data: currencyOptions = [], isLoading: currencyOptionsLoading} = useGetCurrenciesQuery();

    const baseCurrency = currencyOptions.find(c => c.isBase);

    const {orderBy, setOrderBy} = useOrdering<SaleOrder.DTO.OrderBy>({"name": "id", "type": "desc"});
    
    const filters = {
        customers: [customer.id],
    };

    const pagination = usePagination({page: 1, limit: 8});

    const {data: saleOrders, isLoading: saleOrdersLoading} = useGetSaleOrdersFullQuery({
        filters,
        orderBy,
        pagination
    }, {
        skip: !customer,
    });
    
    return (
        <>
            <BaseTable
                data={saleOrders?.payload ?? []}
                isDataLoading={saleOrdersLoading}

                columns={[
                    {
                        header: t("columns.0"),
                        getter: (row) => <u className='cursor-pointer'
                                            onClick={() => history.push(`/dashboard/sales/orders/${row.id}/details`)}>{row.code ?? row.id}</u>

                    },
                    {
                        header: t("columns.1"),
                        getter: (row) => jsxSwitch(
                            {
                                0: <BaseChip fill="none"
                                             className="bg-gray-300 text-accent">{t("invoicedStatus.0")}</BaseChip>,
                                1: <BaseChip fill="green">{t("invoicedStatus.1")}</BaseChip>,
                            },
                            row.is_invoiced ? 1 : 0
                        )
                    },
                    {
                        header: t("columns.2"),
                        getter: (row) => jsxSwitch(
                            {
                                0: <BaseChip fill="none"
                                             className="bg-gray-300 text-accent">{t("shippedStatus.0")}</BaseChip>,
                                1: <BaseChip fill="blue">{t("shippedStatus.1")}</BaseChip>,
                                2: <BaseChip fill="green">{t("shippedStatus.2")}</BaseChip>,
                            },
                            row.shipment_state
                        )
                    },
                    {
                        header: t("columns.3"),
                        getter: (row) => `${baseCurrency?.symbol} ${row.orders.map(order => calculateOrderPriceWithTax(order.quantity, order.unit_price, order?.discount ?? 0, order.tax?.rate ?? 0)).reduce((a, b) => a + b, 0).toFixed(4)}`
                    },
                    {
                        header: t("columns.4"),
                        getter: (row) => row.order_date.format("DD.MM.YYYY"),
                    }
                ]}
            />

            <Pagination
                className="mt-[32px]"
                {...pagination.adapt(saleOrders)}
            />
        </>
    );
}
