import React from "react";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import { Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ProductType } from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";
import { useAppSelector } from "@redux/hooks";
import { useAbility } from "@casl/react";
import { AbilityContext } from "@/casl.config";

export default function Prices({ editing }: { editing: boolean }) {
    const { t } = useTranslation("", { keyPrefix: "products.general" });

    const baseCurrency = useAppSelector(state => state.auth.user?.company.currency);

    const { control } = useFormContext<ProductType>();

    const ability = useAbility(AbilityContext);

    return (
        <div className="space-y-[10px]">
            <div className={"space-y-[40px]"}>
                <BaseInputsGrid title={t("categories.prices")}>
                    {
                        !editing &&
                        <Controller
                            name="prices.purchase"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.purchasePrice") + " *"}
                                    placeholder="0.00"
                                    type="number"

                                    sx={{
                                        "& .MuiInputBase-input": {
                                            paddingRight: "25px"
                                        }
                                    }}

                                    icon={{
                                        right: {
                                            el: <span className={"text-purple-500"}>{baseCurrency?.symbol}</span>,
                                            offset: "10px"
                                        }
                                    }}
                                />
                            )}
                        />
                    }


                    {
                        (!editing || ability.can("update_selling_price", "product")) &&
                        <Controller
                            name="prices.sale"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.sellingPrice") + " *"}
                                    placeholder="0.00"
                                    type="number"

                                    sx={{
                                        "& .MuiInputBase-input": {
                                            paddingRight: "25px"
                                        }
                                    }}

                                    icon={{
                                        right: {
                                            el: <span className={"text-purple-500"}>{baseCurrency?.symbol}</span>,
                                            offset: "10px"
                                        }
                                    }}
                                />
                            )}
                        />
                    }

                    <Controller
                        name="prices.extra_cost"
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.extraCost")}
                                placeholder="0.00"
                                type="number"

                                sx={{
                                    "& .MuiInputBase-input": {
                                        paddingRight: "25px"
                                    }
                                }}

                                icon={{
                                    right: {
                                        el: <span className={"text-purple-500"}>{baseCurrency?.symbol}</span>,
                                        offset: "10px"
                                    }
                                }}
                            />
                        )}
                    />
                </BaseInputsGrid>
            </div>
            <Typography variant="caption" display="block" gutterBottom sx={{ opacity: 0.5 }}>
                {t("fields.dynamicPricePrompt")}
            </Typography>
        </div>
    );
}