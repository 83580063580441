import React, { useMemo } from "react";
import { Control, Controller, useController, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { Alert, Stack } from "@mui/material";
import BaseInput from "@reusables/BaseInput";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { SerialNumbersScheme } from "./index";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";

export function SerialGroup({index, control, preview}: {index: number, control: Control<SerialNumbersScheme>, preview?: boolean;}){
    const { t } = useTranslation("", { keyPrefix: "receive.modals.serialNumbers" });

    const {field: receipt, fieldState: {error}} = useController({
        control,
        name: `receipts.${index}`
    });

    const serialNumbersError = useMemo(() => {
        if(error && "serial_numbers" in error){
            return (error.serial_numbers as {root?: {message: string}} & {message?: string});
        }
    }, [error])

    const { fields: serialNumbers, prepend, remove } = useFieldArray({
        control,
        name: `receipts.${index}.serial_numbers` as 'receipts.0.serial_numbers'
    });

    return (
        <div>
            <div className="flex items-center mb-[32px]">
                <div className="grow text-lg font-semibold text-accent">{receipt.value.product.name}</div>
                {
                    !preview &&
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            {/* Serial inputs number limit (limited with the received quantity for the product) */}
                            <div className={`opacity-50 ${serialNumbers.length > receipt.value.received_quantity ? "text-[#E25C5C]" : ""}`}>
                                ({serialNumbers.length} / {receipt.value.received_quantity})
                            </div>
                            {/* Button to append serial inputs to the product */}
                            {/* Prepending, because RHF somehow can't show validation the right way */}
                            <BaseMaterialButton onClick={() => {
                                prepend({
                                    serial_number: ""
                                })
                            }}>
                                {t("buttons.addSerialNumber")}
                            </BaseMaterialButton>
                        </Stack>
                }
            </div>

            {/* Error, if inputs number exceeds received quantity */}
            {
                !!serialNumbersError?.root &&
                <Alert severity="error" className="mb-[32px]">
                    {serialNumbersError.root?.message}
                </Alert>
            }

            <BaseInputsGrid cols={2} gap={{
                x: 32,
                y: 8
            }}>
                {
                    serialNumbers.map((serialNumber, serialIndex) => (
                        <Stack
                            key={serialNumber.id}
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            {/* Serial number value */}
                            <Controller
                                control={control}
                                name={`receipts.${index}.serial_numbers.${serialIndex}.serial_number`}
                                render={({ field, fieldState }) => (
                                    <>
                                        <BaseInput
                                            {...field}
                                            error={fieldState.error}

                                            placeholder={t("field.placeholder")}
                                            className="w-full"
                                            sx={{
                                                "& input": {
                                                    background: "#F9F9F9",
                                                    padding: "12px",
                                                    border: 0
                                                }
                                            }}

                                            disabled={preview}
                                        />
                                    </>
                                )}
                            />

                            {/* Deletion button */}
                            {
                                !preview &&
                                <span>
                                    <DeleteSVG
                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                        onClick={() => {
                                            remove(serialIndex)
                                        }}
                                    />
                                </span>
                            }
                        </Stack>
                    ))
                }
            </BaseInputsGrid>
        </div>
    )

}