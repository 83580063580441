import React, { useState } from "react";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { selectRoleById, useGetRolesQuery } from "@redux/features/roles/rolesApi";
import { useTranslation } from "react-i18next";
import BaseTable from "@reusables/BaseTable";

import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "@redux/hooks";
import DeletionModal from "../components/modals/DeletionModal";

export default function RolesMainPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles" });

    const history = useHistory();

    // Getting Roles data using RTK Query
    const { data: roles = [], isLoading: isLoadingRoles } = useGetRolesQuery();

    // Open states determine visibility of modal
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allows modals to work with data
    const [deletableRoleId, setDeletableRoleId] = useState<number>();

    const deletableRole = useAppSelector(state => selectRoleById(state, deletableRoleId));

    return (
        <>
            <div>
                <div className="flex flex-row justify-between items-center mb-[44px]">
                    <div className="font-bold text-2xl text-accent">{t("heading")}</div>
                    <BaseMaterialButton fontWeight={500} onClick={() => {
                        // setIsMutationModalOpen(true);
                        history.push(`/dashboard/settings/company/roles/new`);
                    }}>
                        {t("table.buttons.add")}
                    </BaseMaterialButton>
                </div>

                <BaseTable
                    data={roles}
                    columns={[
                        {
                            header: t("table.columns.0"),
                            getter: (row) => <div className="bold-highlight">{row.name}</div>,
                            comparator: (a, b) => a.name.localeCompare(b.name)
                        },
                        {
                            header: t("table.columns.1"),
                            getter: (row) => row.description || "-"
                        },
                        {
                            header: () => "",
                            getter: (row) => (
                                <div className="flex justify-end text-tables-secondaryIcon">
                                    <EditSVG
                                        className="mr-[8px] cursor-pointer hover:text-tables-highlightedIcon"
                                        onClick={() => {
                                            history.push(`/dashboard/settings/company/roles/${row.id}`);
                                        }}
                                    />
                                    <DeleteSVG
                                        className="cursor-pointer hover:text-tables-highlightedIcon"
                                        onClick={() => {
                                            setDeletableRoleId(row.id);
                                            setIsDeletionModalOpen(true);
                                        }}
                                    />
                                </div>
                            )
                        }
                    ]}
                    isDataLoading={isLoadingRoles}

                    headerSx={{
                        backgroundColor: "#F9F9F9"
                    }}

                    sx={{
                        borderCollapse: "separate",
                        borderSpacing: "0 15px"
                    }}

                    size={"small"}
                />
            </div>

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                role={deletableRole}
            />
        </>
    );
}
