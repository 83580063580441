import React, { useEffect } from "react";
import { useGetLanguagesQuery } from "@redux/features/languages/languagesApi";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { SaleOrdersFormTyping } from "../../types";
import { useTranslation } from "react-i18next";
import { useGetCustomerQuery } from "@redux/features/customers/customersApi";

export default function LanguageSection() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.language" });
    const { data: languageOptions, isLoading: isLanguageOptionsLoading } = useGetLanguagesQuery();
    const { control, setValue, getValues } = useFormContext<SaleOrdersFormTyping>();

    const customer = useWatch({
        control,
        name: "customer"
    })

    const mode = useWatch({
        control,
        name: "mode"
    });

    const {data: extendedCustomerData, isLoading: isExtendedCustomerDataLoading} = useGetCustomerQuery(
        customer?.id as number,
        {skip: !customer}
    );

    useEffect(() => {
        if (extendedCustomerData && mode === "creation") {
            setValue("language", extendedCustomerData.language || undefined);
        }
    }, [extendedCustomerData, setValue, customer]);

    return (
        <>
            <BaseInputsGrid title={t("header")} cols={3}>
                <Controller
                    control={control}
                    name="language"
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}
                            options={languageOptions}
                            getter={{
                                key: (opt) => opt.id,
                                label: (opt) => opt.name
                            }}
                            label={t("label")}
                            placeholder={t("placeholder")}
                            isLoading={isLanguageOptionsLoading}
                            autocomplete
                        />
                    )}
                />
            </BaseInputsGrid>
        </>
    );
}