
import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@redux/hooks";
import { Unit } from "@/types/general";


export const unitsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUnitsList: builder.query<Unit.Public[], void>({
            query: () => ({
                url: 'public/unit'
            }),
            transformResponse,
        }),
        getUnits: builder.query<Unit.Root[], void>({
            query: () => ({
                url: `unit`
            }),
            providesTags: [{ type: "Units", id: "LIST" }],
            transformResponse,
        }),

        createUnit: builder.mutation<Unit.Root, Unit.DTO.CreateAdd>({
            query: (body) => ({
                url: `unit`,
                method: "POST",
                data: body,
                
            }),
            transformResponse,

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdUnit } = await queryFulfilled;
                    dispatch(unitsApi.util.updateQueryData("getUnits", undefined, (draft) => {
                      
                        draft.push({
                            id: createdUnit.id,
                            name: createdUnit.name,
                            code: createdUnit.code,
                            custom_name: createdUnit.custom_name,
                        })
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        updateUnit: builder.mutation<Unit.Root, Unit.DTO.CreateUpdate & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `unit/${id}`,
                method: "PUT",
                data: body
            }), 
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedUnit } = await queryFulfilled;
                    dispatch(unitsApi.util.updateQueryData("getUnits", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === updatedUnit.id);
                        if (index !== -1) {
                            draft[index] = updatedUnit;
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        deleteUnit: builder.mutation<void, number>({
            query: (id) => ({
                url: `unit/${id}`,
                method: "DELETE"
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(unitsApi.util.updateQueryData("getUnits", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        })
    })
});


const selectUnitsResult = unitsApi.endpoints.getUnits.select();

const selectAllUnits = createSelector(
    selectUnitsResult,
    (units) => units?.data ?? []
)


export const selectUnitsById = createSelector(
    selectAllUnits,
    (state: RootState, pt_id: number | undefined) => pt_id,
    (units, unit_id) => unit_id != undefined ? units.find(pt => pt.id === unit_id) : undefined
);

export const {
    useUpdateUnitMutation,
    useCreateUnitMutation,
    useGetUnitsQuery,
    useGetUnitsListQuery,
    useDeleteUnitMutation,
} = unitsApi;