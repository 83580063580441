import React from "react";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useCreateSupplierMutation } from "@redux/features/suppliers/suppliersApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Supplier } from "@/types/general";
import { isAddressFilled, removeEmpty } from "@helpers/utils";
import _ from "lodash";
import { toast } from "react-toastify";
import TabsController from "../components/tabs/TabsController";
import { isErrorWithMessage } from "@redux/api/query";

export default function SupplierCreationPage() {
    const history = useHistory();
    const {t} = useTranslation("", {keyPrefix: "suppliers.creation"});

    const [createSupplier, {isLoading: isCreationLoading}] = useCreateSupplierMutation();

    return (
        <BaseLoadingBlocker active={isCreationLoading}>
            <div className='levitation-extended space-y-[40px]'>
                <div className='flex items-center' id={"supplier-header"}>
                    <h6 className='text-accent grow'>{t("heading")}</h6>
                </div>
                <TabsController onSubmit={data => {
                    const creationData: Supplier.DTO.Create = {
                        name: data.name,
                        code: data.code,
                        vat: data.vat,
                        contacts: data.contacts.filter(con => !_.isEmpty(removeEmpty(con))).map(contact => {
                            return {
                                id: contact.id,
                                name: contact.name as string,
                                phone: contact.phone as string,
                                email: contact.email as string
                            }
                        }),
                        ...(isAddressFilled(data.billing_address, ["is_used_for_return"]) && {
                            billing: {
                                name: data.billing_address.name,
                                street: data.billing_address.street,
                                street_2: data.billing_address.street_2,
                                zipcode: data.billing_address.zipcode,
                                city: data.billing_address.city,
                                country: data.billing_address.country?.id,
                                phone: data.billing_address.phone,
                                email: data.billing_address.email,
                                is_used_for_return: data.billing_address.is_used_for_return ?? false,
                            }
                        }),
                        returns: data?.returns.filter(ret => isAddressFilled(ret, ["is_primary"])).map(ret => ({
                            id: ret.id,
                            name: ret.name ?? "",
                            street: ret.street,
                            street_2: ret.street_2,
                            zipcode: ret.zipcode,
                            city: ret.city,
                            country: ret.country?.id,
                            contact_person: ret.contact_person,
                            phone: ret.phone,
                            email: ret.email,
                            is_primary: ret.is_primary ?? false,
                        })),

                        ...(data.currency && {currency: data.currency.id}),
                        ...(data.tax_rate && {tax_rate: data.tax_rate.id}),
                        ...(data.payment_terms && {payment_terms: data.payment_terms.id}),
                        ...(data.delivery_terms && {delivery_terms: data.delivery_terms.id}),
                        ...(data.language && {language: data.language.id})
                    };

                    createSupplier(creationData).unwrap().then((resp) => {
                        history.push(`/dashboard/suppliers/${resp.id}/details`)

                        toast.success(t("responses.success"))
                    }).catch(e => {
                        if (isErrorWithMessage(e)) {
                            toast.error(e.message)
                        } else {
                            toast.error(t("responses.error"))
                        }
                    })
                }}/>
            </div>
        </BaseLoadingBlocker>
    )
}
