import { internalApiSlice, transformResponse } from "../../api/internalApiSlice";
import { Integration, PowerOffice } from "@/types/general";

export const integrationsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getIntegrations: builder.query<Integration.Root, void>({
            query: () => ({
                url: `integration`
            }),
            providesTags: [{ type: "Integrations", id: "LIST" }],
            transformResponse
        }),

        connectIntegration: builder.mutation<void, Integration.DTO.Create & { slug: string }>({
            query: ({ slug, ...body }) => ({
                url: `integration/${slug}`,
                method: "POST",
                data: body
            }),
            invalidatesTags: [{ type: "Integrations", id: "LIST" }]
        }),

        detachIntegration: builder.mutation<void, string>({
            query: (slug) => ({
                url: `integration/detach/${slug}`,
                method: "POST",
                data: slug
            }),
            invalidatesTags: [{ type: "Integrations", id: "LIST"}]
        }),

        getPOGOSalesAccounts: builder.query<PowerOffice.SalesAccounts.Root[], void>({
            query: () => ({
                url: `poweroffice/sales-accounts`
            }),
            transformResponse
        }),

        importTripletexData: builder.mutation<void, { store_id: number; section_id?: number }>({
            query: (data) => ({
                url: `integration/tripletex/import`,
                method: "POST",
                data: {
                    default_product_location: data
                }
            })
        })
    })
});


export const {
    useConnectIntegrationMutation,
    useGetIntegrationsQuery,
    useDetachIntegrationMutation,
    useGetPOGOSalesAccountsQuery,
    useImportTripletexDataMutation
} = integrationsApi;