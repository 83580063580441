import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseTable from "@reusables/BaseTable";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import BaseButton from "@reusables/BaseButton";
import ComponentMutationModal from "../modals/ComponentMutationModal";
import { ArrayElementType, normalizePrice } from "@helpers/utils";
import SummaryBlock from "./parts/SummaryBlock";
import { productsApi } from "@redux/features/products/productsApi";
import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import { BOM, Product } from "@/types/general";
import { Alert, Collapse } from "@mui/material";
import BaseInfiniteLoader from "@/components/reusables/dropdowns/BaseInfiniteLoader";
import { useAppDispatch } from "@redux/hooks";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import i18n from "i18next";

type MutationProperties = {
    bom?: BOM.Extended;
    buttonText: string;
    onSubmit: (data: BOMFormTyping) => void;
};

const formScheme = z.object({
    product: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string()
    }),
    name: z.string().nonempty(),
    components: z.array(
        z.object({
            id: z.number(),
            code: z.string(),
            name: z.string(),
            quantity: z.coerce.number().min(1),
            in_stock: z.number().nullish().optional(),
            prices: z.object({
                purchase_price: z.number()
            })
        })
    ).min(1)
});

export type BOMFormTyping = z.infer<typeof formScheme>;
export type BOMFormComponentTyping = ArrayElementType<BOMFormTyping["components"]>;

export default function MutationLayout({ bom, ...props }: MutationProperties) {
    const { t } = useTranslation("", { keyPrefix: "bom.mutation" });

    const dispatch = useAppDispatch();

    const [productsOptions, setProductsOptions] = useState<Product.Slim[]>([]);

    const methods = useForm<BOMFormTyping>({
        resolver: zodResolver(formScheme)
    });

    const {
        fields: components,
        append,
        update,
        remove
    } = useFieldArray({
        control: methods.control,
        name: "components",
        keyName: "loop_id"
    });

    const [isComponentMutationModalOpen, setIsComponentMutationModalOpen] = useState(false);

    const onSubmit = methods.handleSubmit((data) => {
        props.onSubmit(data);
    });

    const [editedComponent, setEditedComponent] = useState<ArrayElementType<BOMFormTyping["components"]>>();

    useEffect(() => {
        const { setValue, reset } = methods;

        reset();
        if (bom) {
            setValue("product", bom.product);
            setValue("name", bom.name);
            bom.components.forEach((component) => {
                append({
                    id: component.product.id,
                    code: component.product.code,
                    name: component.product.name,
                    quantity: component.quantity,
                    prices: {
                        purchase_price: component?.product?.unit_price
                    },
                    in_stock: component.product?.in_stock
                });
            });
        }
    }, [bom]);

    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);
    return (
        <>
            <FormProvider {...methods}>
                <form className="space-y-[40px]" onSubmit={onSubmit}>
                    <BaseInputsGrid>
                        <Controller
                            control={methods.control}
                            name="product"
                            render={({ field, fieldState }) => (
                                <BaseInfiniteLoader
                                    fetch={(search, page, limit) => {
                                        setIsProductLazyLoadingFetching(true);
                                        dispatch(productsApi.endpoints.getProductsFull.initiate(
                                                {
                                                    filters: {
                                                        search,
                                                        // components: 0 // This line is responsible for the removed components
                                                    },
                                                    pagination: {
                                                        page,
                                                        limit
                                                    },
                                                },
                                                {
                                                    subscribe: false,
                                                }
                                            )
                                        ).unwrap()
                                            .then(res => {
                                                setProductsOptions(res?.payload ?? []);
                                            })
                                            .catch(e => {
                                                if (isErrorWithMessage(e)) {
                                                    toast.error(e.message);
                                                } else {
                                                    toast.error(i18n.t("general.responses.somethingWentWrong"));
                                                }
                                            })
                                            .finally(() => {
                                                setIsProductLazyLoadingFetching(false);
                                            });
                                    }}
                                    limit={100}
                                    result={productsOptions}
                                    isLoading={isProductLazyLoadingFetching}
                                    skip={!!bom}
                                >
                                    {
                                        (infinity) => (
                                            <>
                                                <BaseDropdown
                                                    {...field}
                                                    {...fieldState}

                                                    {...adaptInfiniteLoader(infinity)}


                                                    label={t("dropdowns.productName.label") + " *"}
                                                    placeholder={t("dropdowns.productName.placeholder")}

                                                    getter={{
                                                        label: opt => opt.name,
                                                        key: opt => opt.id,
                                                        caption: opt => opt.code
                                                    }}

                                                    autocomplete
                                                    virtualize

                                                    disabled={!!bom?.id}
                                                />
                                            </>
                                        )
                                    }
                                </BaseInfiniteLoader>
                            )}
                        />

                        <Controller
                            control={methods.control}
                            name="name"
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.bomName") + " *"}
                                />
                            )}
                        />
                    </BaseInputsGrid>

                    <Collapse in={!!methods.formState.errors.components}>
                        <Alert severity="error">
                            {t("validationErrors.zeroComponents")}
                        </Alert>
                    </Collapse>

                    <div className="border border-solid border-gray-300 space-y-6 rounded-[8px] p-[16px]">
                        <div className="flex justify-between items-center">
                            <div className="text-xl font-semibold text-accent">{t("table.components")}</div>
                            <BaseMaterialButton
                                fontWeight={500}
                                type="button"
                                className="flex"
                                onClick={() => {
                                    setIsComponentMutationModalOpen(true);
                                    setEditedComponent(undefined);
                                }}
                            >
                                {t("table.addNewComponent")}
                            </BaseMaterialButton>
                        </div>
                        <BaseTable
                            data={components}
                            rowKeyGetter={row => row.loop_id}
                            immutable
                            columns={[
                                {
                                    header: t("table.columns.0"),
                                    getter: row => row.name
                                },
                                {
                                    header: t("table.columns.1"),
                                    getter: (row, index) => (
                                        <Controller
                                            control={methods.control}
                                            name={`components.${index}.quantity`}
                                            render={({ field, fieldState }) => (
                                                <BaseInput
                                                    {...field}
                                                    error={fieldState.error}
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            padding: "8px",
                                                            width: "100px"
                                                        }
                                                    }}
                                                    type="number"
                                                />
                                            )}
                                        />
                                    )
                                },
                                {
                                    header: t("table.columns.2"),
                                    getter: row => normalizePrice(row?.prices.purchase_price)
                                },
                                {
                                    header: t("table.columns.3"),
                                    getter: row => normalizePrice(row?.quantity * row?.prices.purchase_price)
                                },
                                {
                                    header: t("table.columns.4"),
                                    getter: row => row?.in_stock ?? 0
                                },
                                {
                                    header: t("table.columns.5"),
                                    getter: (row, index) => (
                                        <div className="space-x-[12px]">
                                            <EditSVG
                                                className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                                onClick={() => {
                                                    setEditedComponent(row);
                                                    setIsComponentMutationModalOpen(true);
                                                }}
                                            />
                                            <TrashSVG
                                                className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                                onClick={() => remove(index)}
                                            />
                                        </div>
                                    )
                                }
                            ]}
                            size="small"
                            headerSx={{
                                background: "#F9F9F9"
                            }}
                        />

                        {components.length > 0 && <SummaryBlock />}
                    </div>

                    <div className="flex justify-center">
                        <BaseButton
                            type="submit"
                            text={props.buttonText}
                            size="md"
                        />
                    </div>
                </form>
            </FormProvider>

            <ComponentMutationModal
                component={editedComponent}
                alreadySelectedCompIds={components.map(c => c.id)}
                isOpen={isComponentMutationModalOpen}
                onClose={() => setIsComponentMutationModalOpen(false)}
                onSubmit={(comp) => {
                    comp.components.map((c) => {
                        if (editedComponent) {
                            update(components.findIndex((c) => c.id === editedComponent.id), {
                                id: c.selectedComponent.id,
                                name: c.selectedComponent.name,
                                code: c.selectedComponent.code,
                                quantity: c.quantity,
                                prices: {
                                    purchase_price: c.selectedComponent.prices?.purchase_price
                                },
                                in_stock: c.selectedComponent.in_stock
                            });
                        } else {
                            append({
                                id: c.selectedComponent.id,
                                name: c.selectedComponent.name,
                                code: c.selectedComponent.code,
                                quantity: c.quantity,
                                prices: {
                                    purchase_price: c.selectedComponent.prices?.purchase_price
                                },
                                in_stock: c.selectedComponent.in_stock
                            });
                        }
                    });
                    setIsComponentMutationModalOpen(false);
                }}
            />
        </>
    );
}
