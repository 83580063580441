import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseDatepicker from "@reusables/BaseDatepicker";
import { DatepickerRangeContext } from "@reusables/BaseDatepicker/context";
import BaseTable from "@reusables/BaseTable";
import { removeEmpty, useOrdering, usePagination } from "@helpers/utils";
import { useGetProductBatchNumbersFullQuery } from "@redux/features/products/productsApi";
import { Product } from "@/types/general";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";

import { Pagination } from "@mui/material";
import _ from "lodash";
import { useDatesRangeFilter } from "./utils";
import UpdateBatchNumberModal from "../modals/UpdateBatchNumberModal";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "@/casl.config";
import { ChevronDown } from "lucide-react";
import FilteringHeader
    from "@components/Dashboard/pages/Products/components/Tabs/items/NumbersTab/components/FilteringHeader";

const searchDebounceCooldown = 1000;

export default function BatchNumbersTab({ product }: { product: Product.Extended }) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.numbers.batchNumbers" });

    const ability = useAbility(AbilityContext);

    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedBatchNumber, setSelectedBatchNumber] = useState<Product.Augmentations.BatchNumbers.Root>();

    const [isCollapsed, setIsCollapsed] = useState(true);

    // Searching value with debounce
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, searchDebounceCooldown), [setSearchingValue, searchDebounceCooldown]);

    // Dates range
    const {
        datesRange,
        setDatesRange,
        filterAdaptedDatesRange,
        expiredDatesRange,
        setExpiredDatesRange,
        filterAdaptedExpiredDatesRange
    } = useDatesRangeFilter();

    const filters = removeEmpty({
        search: searchingValue,
        dates_range: {
            from: filterAdaptedDatesRange?.from,
            to: filterAdaptedDatesRange?.to,
            expired_from: filterAdaptedExpiredDatesRange?.from,
            expired_to: filterAdaptedExpiredDatesRange?.to
        }
    });

    const { orderBy, setOrderBy } = useOrdering<Product.Augmentations.BatchNumbers.DTO.OrderBy>({
        name: "created_at",
        type: "asc"
    });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data: batchNumbers, isFetching: isBatchNumbersLoading } = useGetProductBatchNumbersFullQuery({
        product_id: product.id,
        pagination,
        filters,
        orderBy
    });

    return (
        <>
            <div className="space-y-[16px]">
                <div className="flex flex-row justify-between items-center gap-10">
                    <div className="flex gap-2 items-center flex-shrink-0 hover:cursor-pointer"
                         onClick={() => setIsCollapsed(!isCollapsed)}>
                        <ChevronDown
                            className={"text-accent cursor-pointer"}
                            style={{
                                transition: "transform 0.3s",
                                transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)"
                            }}
                        />
                        <div className="text-xl font-light text-accent">{t("heading")}</div>
                    </div>
                    <FilteringHeader
                        onSearchTyping={searchInputDebounce}
                        onSearchToggle={(state) => {
                            if (state && !isCollapsed) {
                                setIsCollapsed(true);
                            }
                        }}
                        isSearchLoading={isBatchNumbersLoading}
                        typingResetCooldown={searchDebounceCooldown}
                        filters={
                            <div className="flex flex-col gap-4">
                                <DatepickerRangeContext.Provider
                                    value={{
                                        dates_range: { range: datesRange, setRange: setDatesRange }
                                    }}
                                >
                                    <BaseDatepicker
                                        label={t("filters.additionDate.label")}
                                        placeholder={t("filters.additionDate.placeholder")}
                                        rangeConfig={{ groupKey: "dates_range", role: "solo" }}
                                    />
                                </DatepickerRangeContext.Provider>
                                <DatepickerRangeContext.Provider
                                    value={{
                                        dates_range: { range: expiredDatesRange, setRange: setExpiredDatesRange }
                                    }}
                                >
                                    <BaseDatepicker
                                        label={t("filters.expirationDate.label")}
                                        placeholder={t("filters.expirationDate.placeholder")}
                                        rangeConfig={{ groupKey: "dates_range", role: "solo" }}
                                    />
                                </DatepickerRangeContext.Provider>
                            </div>
                        }
                    />
                </div>
                <BaseTable
                    isDataLoading={isBatchNumbersLoading}
                    data={batchNumbers?.payload ?? []}
                    hideData={!isCollapsed}
                    columns={[
                        {
                            header: t("table.columns.0"),
                            getter: (row) => row.batch_number,
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.1"),
                            getter: (row) => <>{row.created_at.format("DD.MM.YYYY")}</>,
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.2"),
                            getter: (row) => <>{row.expiration_date?.format("DD.MM.YYYY")}</>,
                            comparator: () => 0
                        },
                        {
                            visible: ability.can("update", "batch_number"),
                            header: t("table.columns.3"),
                            getter: (row) => (
                                <div className="flex justify-end text-tables-secondaryIcon">
                                    <Can I="update" a="batch_number">
                                        <EditSVG
                                            className="mr-[18px] cursor-pointer hover:text-tables-highlightedIcon"
                                            onClick={() => {
                                                setSelectedBatchNumber(row);
                                                setIsUpdateModalOpen(true);
                                            }}
                                        />
                                    </Can>
                                </div>
                            )
                        }
                    ]}
                    manualControls={{
                        ordering: (newOrdering) => {
                            if (newOrdering) {
                                let name: Product.Augmentations.BatchNumbers.DTO.OrderBy | undefined;

                                switch (newOrdering.index) {
                                    case 0:
                                        name = "id";
                                        break;
                                    case 1:
                                        name = "created_at";
                                        break;
                                    case 2:
                                        name = "expiration_date";
                                        break;
                                    default:
                                        name = undefined;
                                }

                                if (name) {
                                    setOrderBy({
                                        name,
                                        type: newOrdering.order
                                    });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }
                    }}
                    headerSx={{
                        backgroundColor: "#F9F9F9",
                        "& th": {
                            "&:not(:last-child)": {
                                width: "200px"
                            },
                            "&:first-child": {
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px"
                            },
                            "&:last-child": {
                                textAlign: "end",
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px"
                            }
                        }
                    }}
                />

                {isCollapsed && <Pagination className="mt-[32px]" {...pagination.adapt(batchNumbers)} />}
            </div>
            <UpdateBatchNumberModal isOpen={isUpdateModalOpen} onClose={() => setIsUpdateModalOpen(false)}
                                    batchNumber={selectedBatchNumber} product={product} />
        </>
    );
}
