import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import DeletionModal from "../components/modals/DeletionModal";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import {useGetSupplierQuery} from "@redux/features/suppliers/suppliersApi";
import {Can} from "@/casl.config";
import {BaseTinyActionIcon, TinyActionIconType} from "@reusables/BaseTinyActionIcon";
import GeneralTabPreview from "../components/tabs/previews/GeneralTab";
import AddressTabPreview from "../components/tabs/previews/AddressTab";
import SettingsTab from "../components/tabs/previews/SettingsTab";
import SupplierStepper from "../components/Stepper/SupplierStepper";
import PurchaseOrderTab from "../components/tabs/previews/PurchaseOrderTab";
import ReceivesTab from "../components/tabs/previews/ReceivesTab";
import BaseTooltip from "@reusables/BaseTooltip";

export default function SupplierDetailsPage() {
    const {t} = useTranslation("", {keyPrefix: "suppliers.viewPage"});
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const { data: supplier, isLoading: supplierLoading } = useGetSupplierQuery(parseInt(id));
    const [activeTab, setActiveTab] = useState<number>(0);

    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    return (
      <>
          <BaseRecordRenderer
            record={supplier}
            loading={supplierLoading}
          >
              <div className="flex items-center mb-[40px]">
                  <h6 className="text-accent grow">{supplier?.name}</h6>
                  <div className="space-x-[8px] flex items-center h-full">
                        <BaseTooltip title={`${t("sideButtons.createPurchase")}`} placement="bottom-start">
                            <BaseTinyActionIcon
                                type={TinyActionIconType.PurchaseOrder}
                                onClick={() => void history.push(`/dashboard/purchasing/new`, {supplier: supplier})}
                            />
                        </BaseTooltip>
                        {/* <BaseTooltip title={`${t("sideButtons.startReceiving")}`} placement="bottom-start">
                            <BaseTinyActionIcon
                                type={TinyActionIconType.StartReceiving}
                                onClick={() => void history.push(`/dashboard/receive/new`)}
                            />
                        </BaseTooltip> */}
                        <Can I="update" a="supplier">
                            <BaseTooltip title={`${t("sideButtons.edit")}`} placement="bottom-start">
                                <BaseTinyActionIcon
                                    type={TinyActionIconType.Edit}
                                    onClick={() => history.push(`/dashboard/suppliers/${id}/edit`)}
                                />
                            </BaseTooltip>
                        </Can>

                        <Can I="delete" a="supplier">
                            <BaseTooltip title={`${t("sideButtons.delete")}`} placement="bottom-start">
                                <BaseTinyActionIcon
                                    type={TinyActionIconType.Delete}
                                    onClick={() => setIsDeletionModalOpen(true)}
                                />
                            </BaseTooltip>
                        </Can>
                  </div>
              </div>
              <SupplierStepper
                tabs={[
                    {
                        title: t => t("general.heading"),
                        content: <GeneralTabPreview supplier={supplier as NonNullable<typeof supplier>} />
                    },
                    {
                        title: t => t("address.heading"),
                        content: <AddressTabPreview supplier={supplier as NonNullable<typeof supplier>} />
                    },
                    {
                        title: t => "Purchase Orders",
                        content: <PurchaseOrderTab supplier={supplier as NonNullable<typeof supplier>} />
                    },
                    {
                        title: t => "Receives",
                        content: <ReceivesTab supplier={supplier as NonNullable<typeof supplier>} />
                    },
                    {
                        title: t => t("settings.heading"),
                        content: <SettingsTab supplier={supplier as NonNullable<typeof supplier>} />
                    }
                ]}

                manual={{
                    activeTab: activeTab,
                    onClick: setActiveTab
                }}
              />
          </BaseRecordRenderer>

          <DeletionModal
            isOpen={isDeletionModalOpen}
            onClose={() => setIsDeletionModalOpen(false)}
            onDeletion={() => history.push("/dashboard/suppliers")}
            entity={supplier}
          />
      </>
    );
}