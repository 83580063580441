import React, { memo, useEffect } from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import { Integration } from "@/types/general";
import { zodResolver } from "@hookform/resolvers/zod";
import { useConnectIntegrationMutation } from "@redux/features/integrations/integrationsApi";
import { toast } from "react-toastify";
import { isErrorWithMessage } from "@redux/api/query";
import { useAppDispatch } from "@redux/hooks";
import { setIntegrationStatus } from "@redux/features/auth/authSlice";

const formScheme = z.object({
    name: z.string(),
    slug: z.enum(["poweroffice", "shipmondo", "lime", "tripletex"] as const),
    fields: z.array(z.object({
        name: z.string(),
        label: z.string(),
        value: z.string().nonempty()
    }))
});

type FormTyping = z.infer<typeof formScheme>

interface IntegrationsModalProps {
    integration?: Integration.Components.Integration,
    isOpen: boolean,

    onClose: () => void;
}

export default memo(function IntegrationsModal({ integration, isOpen, onClose }: IntegrationsModalProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.integrations.thirdParty.modals.creation" });

    const dispatch = useAppDispatch();

    const [connectIntegration, { isLoading: isConnectionLoading }] = useConnectIntegrationMutation();

    const { control, handleSubmit, getValues, reset, setValue } = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    });

    const { fields } = useFieldArray({
        control,
        name: "fields"
    });

    const onSubmit = handleSubmit(data => {
        connectIntegration({
            slug: data.slug,
            ...data.fields.reduce((acc: Record<string, string>, field) => {
                acc[field.name] = field.value;
                return acc;
            }, {})
        })
            .unwrap()
            .then(() => {
                toast.success(t("responses.success"));

                dispatch(setIntegrationStatus({
                    slug: data.slug,
                    integrated: true
                }))

                onClose();
            }).catch(e => {
            if (isErrorWithMessage(e)) {
                toast.error(e.message);
            } else {
                toast.error(t("responses.error"));
            }
        });
    }, console.error);

    useEffect(() => {
        if (isOpen && integration) {
            setValue("name", integration.name);
            setValue("slug", integration.slug);
            setValue("fields", integration.modal.fields.map(field => ({
                name: field.name,
                label: field.label,
                value: ""
            })));
        } else {
            reset();
        }
    }, [integration, isOpen, reset, setValue]);

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={800}
            padding="45px"
            useCloseIcon
            isLoading={isConnectionLoading}
        >
            <form onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent mb-4">
                    {t("creationHeading", { type: getValues("name") })}
                </div>

                <BaseInputsGrid cols={1}>
                    {fields.map((field, index) => (
                        <Controller
                            key={field.id}
                            control={control}
                            name={`fields.${index}.value`}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    placeholder={getValues(`fields.${index}.label`)}
                                />
                            )}
                        />
                    ))}
                </BaseInputsGrid>

                <BaseButton
                    className="mt-8"
                    buttonWidth="100%"
                    text={t("buttons.save")}
                    size="md"
                    type="submit"
                />
            </form>
        </BaseModal>
    );
});