import React from "react";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useHistory } from "react-router-dom";
import MutationLayout from "../components/MutationLayout";
import { useCreateBOMMutation } from "@redux/features/bom/bomApi";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function BOMCreationPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "bom.creation" });

    const [createBOM, { isLoading: isBOMLoading }] = useCreateBOMMutation();

    return (
        <BaseLoadingBlocker active={isBOMLoading}>
            <div className="levitation-extended">
                <div className="flex mb-[40px]">
                    <h6 className="text-accent grow">{t("heading")}</h6>
                </div>
                <MutationLayout
                    bom={undefined}
                    buttonText={t("buttons.create")}
                    onSubmit={(data) => {
                        createBOM({
                            product_id: data.product.id,
                            name: data.name,
                            components: data.components.map(component => ({
                                product_id: component.id,
                                quantity: component.quantity
                            }))
                        }).unwrap()
                            .then((data) => {
                                history.push(`/dashboard/bill-of-materials/${data.id}/details`);
                            })
                            .catch((error) => {
                                if (isErrorWithMessage(error)) {
                                    toast.error(error.message);
                                } else {
                                    toast.error(t("responses.error"));
                                }
                            });
                    }}
                />
            </div>
        </BaseLoadingBlocker>
    );
}
