import React from "react";
import { useTranslation } from "react-i18next";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseTable from "@reusables/BaseTable";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { SaleOrdersFormTyping } from "../../types";
import { useGetTaxesQuery } from "@redux/features/taxes/taxesApi";
import TotalCell from "./TotalCell";
import { ReactComponent as TrashIcon } from "@assets/icons/ic_trash.svg";
import { Percent } from "lucide-react";
import SummaryBlock from "./SummaryBlock";
import AddProductModal from "./AddProductModal";
import QuantityCell
    from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/parts/ProductSection/QuantityCell";
import LowStockWarning
    from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/parts/ProductSection/LowStockWarning";
import { Customer } from "@/types/general";
import AddCollectionModal from "./AddCollectionModal";
import _ from "lodash";
import { normalizePrice } from "@helpers/utils";
import { Alert, Collapse } from "@mui/material";

export default function ProductSection() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders" });

    const { control, getValues, setValue, formState } = useFormContext<SaleOrdersFormTyping>();

    const { fields: orders, append, remove, replace } = useFieldArray({
        control,
        name: "orders",
        keyName: "loop_id"
    });

    const mode = useWatch({
        control,
        name: "mode"
    });

    const isEditing = mode === "editing";

    const { data: taxesOptions, isLoading: isTaxesOptionsLoading } = useGetTaxesQuery(undefined, {
        skip: isEditing
    });

    const [isAddProductModalOpen, setIsAddProductModalOpen] = React.useState(false);
    const [isAddCollectionModalOpen, setIsAddCollectionModalOpen] = React.useState(false);

    return (
        <>
            <Collapse in={!!formState.errors.orders?.message}>
                <Alert severity={"error"}>{formState.errors.orders?.message}</Alert>
            </Collapse>
            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {orders.length ? (
                    <>
                        <div className="mb-[26px] space-y-[16px]">
                            <div className="flex items-center space-x-[8px]">
                                <div className="text-xl font-thin text-gray-600 grow">
                                    {t("heading")}
                                </div>
                                {!isEditing && (
                                    <BaseMaterialButton
                                        fontWeight={500}
                                        onClick={() => setIsAddProductModalOpen(true)}
                                    >
                                        {t("buttons.addProduct")}
                                    </BaseMaterialButton>
                                )}
                                {!isEditing && (
                                    <BaseMaterialButton
                                        fontWeight={500}
                                        onClick={() => setIsAddCollectionModalOpen(true)}
                                    >
                                        {t("buttons.addCollection")}
                                    </BaseMaterialButton>
                                )}
                            </div>
                            {!isEditing && <LowStockWarning />}
                        </div>

                        <BaseTable
                            data={orders}
                            immutable
                            rowKeyGetter={(row) => row.loop_id}
                            columns={[
                                {
                                    // PRODUCT CODE
                                    header: t("columns.0"),
                                    getter: (row) => row.product.code,
                                    sx: {
                                        minWidth: "150px"
                                    }
                                },
                                {
                                    // PRODUCT
                                    header: t("columns.1"),
                                    getter: (row) => row.product.name
                                },
                                {
                                    // QUANTITY
                                    header: t("columns.2"),
                                    getter: (row, index) => <QuantityCell index={index} isEditing={isEditing} />,

                                    sx: {
                                        maxWidth: "150px",
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    // UNIT PRICE
                                    header: t("columns.3"),
                                    getter: (row, index) =>
                                        <Controller
                                            name={`orders.${index}.unit_price`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <BaseInput
                                                    {...field}
                                                    error={fieldState.error}

                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            padding: "8px",
                                                            paddingRight: "20px"
                                                        }
                                                    }}

                                                    disabled={isEditing}

                                                    hideErrors
                                                />
                                            )}
                                        />,

                                    sx: {
                                        maxWidth: "150px",
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    // DISCOUNT
                                    header: t("columns.4"),
                                    getter: (row, index) =>
                                        <Controller
                                            name={`orders.${index}.discount`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <BaseInput
                                                    {...field}
                                                    error={fieldState.error}

                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            padding: "8px",
                                                            paddingRight: "30px"
                                                        }
                                                    }}

                                                    icon={{
                                                        right: {
                                                            el: <Percent width={14} height={14}
                                                                         className={"text-purple-500"} />,
                                                            offset: "10px"
                                                        }
                                                    }}

                                                    disabled={isEditing}

                                                    hideErrors
                                                />
                                            )}
                                        />,
                                    sx: {
                                        maxWidth: "150px",
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    // TAX
                                    header: t("columns.5"),
                                    getter: (row, index) =>
                                        <Controller
                                            name={`orders.${index}.tax`}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <BaseDropdown
                                                        {...field}
                                                        error={fieldState.error}

                                                        options={taxesOptions}

                                                        getter={{
                                                            key: opt => opt.id,
                                                            label: opt => `${opt.rate}%`
                                                        }}

                                                        customize={{
                                                            padding: "4px"
                                                        }}

                                                        disabled={isEditing}

                                                        isLoading={isTaxesOptionsLoading}
                                                    />
                                                </>
                                            )}
                                        />
                                    ,
                                    sx: {
                                        maxWidth: "150px",
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    // TOTAL
                                    header: t("columns.6"),
                                    getter: (row, index) => <TotalCell index={index} />,
                                    sx: {
                                        minWidth: "120px"
                                    }
                                },
                                {
                                    // ACTION
                                    visible: !isEditing,
                                    header: () => <div className="text-center">{t("columns.7")}</div>,
                                    getter: (row, index) => <TrashIcon
                                        className="cursor-pointer"
                                        onClick={() => {
                                            // Adding to the array only records with ID
                                            if (row.id) {
                                                const deletedOrders = getValues("deleted_orders");
                                                // Update the 'deleted_orders' field with the new ID
                                                setValue("deleted_orders", [...deletedOrders, row.id]);
                                            }

                                            // Remove the order from the form
                                            remove(index);
                                        }} />,
                                    sx: {
                                        textAlign: "center"
                                    }
                                }
                            ]}

                            size={"small"}
                            headerSx={{
                                background: "#F9F9F9"
                            }}
                        />

                        <SummaryBlock />
                    </>
                ) : (
                    <>
                        <div className="text-xl text-center text-gray-300 font-thin">
                            {t("noProducts")}
                        </div>
                        <div className="grid grid-cols-2 gap-[40px] mt-[24px]">
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddProductModalOpen(true)}
                            >
                                {t("buttons.addProduct")}
                            </BaseMaterialButton>
                            <BaseMaterialButton
                                size="large"
                                fontWeight={500}
                                onClick={() => setIsAddCollectionModalOpen(true)}
                            >
                                {"Add collection"}
                            </BaseMaterialButton>
                        </div>
                    </>
                )}

                <AddProductModal
                    isOpen={isAddProductModalOpen}
                    onClose={() => setIsAddProductModalOpen(false)}
                    onOrderAddition={(product) => {
                        append({
                            product: {
                                ...product.product,
                                in_stock: product.in_stock,
                                min_sale_quantity: product.min_sale_quantity,
                                has_bom: product.product.has_bom
                            },
                            quantity: product.quantity as number,
                            unit_price: product.unit_price as number,
                            discount: product.discount
                        });
                        setIsAddProductModalOpen(false);
                    }}
                    customer={getValues("customer") as Customer.Root}
                />

                <AddCollectionModal
                    isOpen={isAddCollectionModalOpen}
                    onClose={() => setIsAddCollectionModalOpen(false)}
                    onOrderAddition={(collection) => {
                        const currentOrderLinesList = _.keyBy(
                            _.map(orders, order => _.omit(order, "loop_id")),
                            "product.id"
                        );

                        collection.collection.products.forEach((prod) => {
                            if (!currentOrderLinesList[prod.id]) {
                                currentOrderLinesList[prod.id] = {
                                    product: prod,
                                    quantity: prod.quantity * collection.quantity,
                                    unit_price: normalizePrice(prod.prices.selling_price),
                                    tax: prod.tax
                                };
                            } else {
                                currentOrderLinesList[prod.id].quantity += prod.quantity * collection.quantity;
                            }
                        });

                        replace(Object.values(currentOrderLinesList));
                    }}
                />
            </div>
        </>
    );
}