import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
    Collapse,
    LinearProgress,
    Skeleton,
    SxProps,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Ordering } from "./types";
import Sorter from "./Sorter";
import { TransientAdapter } from "@helpers/utils";
import { PaginationWrapper } from "../../../types/types";
import { useTranslation } from "react-i18next";

/**
 * Philosophy: we needed easy to use component, that can be freely modified to be used in various situations.
 * Besides basic functionality of adding new columns, editing already existing and so on, component should have ability to control columns sorting,
 * and it should use generics to provide that desired flexibility.
 */

type BaseTableProperties<T> = {
    /**
     * Raw data in format of array of rows. Shouldn't contain any table configuration, imagine that this data has been acquired from backend.
     */
    data: T[];
    hideData?: boolean;
    immutable?: boolean;
    /**
     * Columns configuration, including headers, sorting etc.
     */
    columns: ColumnConfiguration<T>[];
    /**
     * General collapse configuration for the table.
     */
    collapse?: {
        content: (rowRelated: T, state?: boolean) => JSX.Element | undefined;
        onOpen?: (rowRelated: T) => void;
        isLoading?: (rowRelated: T, state?: boolean) => boolean;
        // background color, when collapse is active.
        fill: string;
        // border color, when collapse is active.
        borderColor: string;
    };
    /**
     * Allows adding callbacks, when some table functionality enabled only after user clicked the row.
     */
    rowOnClick?: (rowRelated: T, isCollapse: boolean) => void;
    /**
     * Size of the table, checkout MUI tables guide for more.
     */
    size?: "small" | "medium";
    maxHeight?: number;
    stickyHeader?: boolean;
    alternate?: boolean;
    boldHeaders?: boolean;
    hideTableWhenNothingFound?: boolean;
    /**
     * You can make table repeat other`s table cells size at minimum or extend it.
     */
    mimic?: React.RefObject<HTMLTableElement>,
    innerRef?: React.RefObject<HTMLTableElement>,
    /**
     * Loading state needed for skeleton
     */
    isDataLoading?: boolean,
    pagination?: PaginationWrapper,
    /**
     * Block, which will be shown when 0 rows loaded and loading has finished.
     */
    nothingFound?: {
        text?: string;
        height?: number | string;
    };

    manualControls?: {
        ordering?: (val: Ordering | undefined) => void;
        pastTransformation?: (result: T[]) => void;
    }

    /**
     * Custom header row styles.
     */
    headerSx?: SxProps,

    sx?: SxProps;

    rowKeyGetter?: (row: T, index: number) => string | number;

}

/**
 * As comes from name, standard column configuration, which might be easily extended, if needed.
 */
type ColumnConfiguration<T> = {
    header: (() => string | JSX.Element) | string | JSX.Element;
    getter: (row: T, rowIndex: number, collapseState: boolean) => JSX.Element | string | number;
    comparator?: (a: T, b: T) => number;
    sx?: SxProps;
    cellClasses?: string;
    // Prevents collapse reaction, when clicked on a column cell
    preventCollapsePropagation?: boolean;
    // defaults to true
    visible?: boolean;
}

/**
 * BaseTable support numerous callbacks, styling properties, ability to add new columns, modify them, modify headers and enable sorting.
 * It is fully typed and works on generics, meaning you get ideal coupling with used DTO interface.
 * @param params see {@link BaseTableProperties} for more information or check out Vantevo docs for this component.
 * @returns custom table
 */
export default function BaseTable<T>({
                                         data,
                                         hideData = false,
                                         columns,
                                         collapse,
                                         rowOnClick,
                                         size = "medium",
                                         maxHeight,
                                         stickyHeader = false,
                                         alternate = false,
                                         boldHeaders = false,
                                         hideTableWhenNothingFound = false,
                                         isDataLoading = false,
                                         pagination,
                                         mimic,
                                         sx,
                                         innerRef,
                                         nothingFound,
                                         manualControls,
                                         headerSx,
                                         rowKeyGetter = (row, index) => index,
                                         ...props
                                     }: BaseTableProperties<T>): JSX.Element {

    const {t} = useTranslation();

    const [paginatedData, setPaginatedData] = useState<T[]>([]); // used for displaying data
    const renderingData = props.immutable ? data : paginatedData; // used for rendering data and to overcome RHF specifics
    const dataCacheForSort = useMemo(() => [...data], [data]); // used for caching array for ordering data to get rid of creating additional arrays

    /**
     * Contains information about current ordering status. If no ordering object specified in props, orderBy will be "undefined".
     */
    const [orderBy, setOrderBy] = useState<Ordering>();

    /**
     * Sorting paginated data, when orderBy field is updated. Uses {@link comparePayloadObjects} function under the hood, which takes into account, if no ordering is specified.
     */
    useEffect(() => {
        if(props.immutable) return;

        let result = data;

        if (orderBy && !manualControls?.ordering) {
            // sort modifies existing array, sorting "in place", so we won't have ability to revert sort to chaotic, as it was at the start.
            result = dataCacheForSort.sort((a, b) => comparePayloadObjects(a, b, orderBy, columns));
        }

        if (pagination) {
            const {currentPage} = pagination.page;
            const {elementsPerPage} = pagination.perPage;
            setPaginatedData(result.slice((currentPage - 1) * elementsPerPage, currentPage * elementsPerPage));
        } else {
            setPaginatedData([...result]);
        }

        manualControls?.pastTransformation?.(result);
    }, [orderBy, pagination, data]);

    const [collapsedRowIndex, setCollapsedRowIndex] = useState<number>();

    useEffect(() => {
        manualControls?.ordering?.(orderBy);
    }, [orderBy]);

    /**
     * Mimic feature to repeat other`s table cell width correspondingly
     * Simply put, just loops over first row`s cells and saves its width to further use it for header cells width
     */
    const [mimicSizes, setMimicSizes] = useState<number[]>();

    useLayoutEffect(() => {
        if (mimic && mimic.current) {
            const firstRow = mimic.current.rows[0];

            const sizes = [];

            for (let i = 0; i < firstRow.cells.length; i++) {
                sizes.push(firstRow.cells[i].offsetWidth);
            }

            setMimicSizes(sizes);
        } else {
            setMimicSizes(undefined);
        }
    }, [mimic]);

    /**
     * Header reference, needed for skeleton to repeat header cells width.
     */
    const tableHeaderRowRef = useRef<HTMLTableRowElement>(null);

    return (
        <>
            <TableContainer sx={{maxHeight: maxHeight}}>
                {
                    !isDataLoading && renderingData.length == 0 && hideTableWhenNothingFound
                        ? null
                        :
                        <Table stickyHeader={stickyHeader} size={size} sx={{
                            "& *:not(.material-icons)": { // excluding to not disable material icons font
                                fontFamily: "Poppins"
                            },
                            ...sx
                        }} ref={innerRef}>
                            <TableHead>
                                <TableRow ref={tableHeaderRowRef} sx={headerSx}>
                                    {
                                        columns.map((config, index) => {
                                            if ("visible" in config && !config.visible) return;

                                            // Header title may contain a string or JSX.Element. If developer specified a function, it will be executed and result will be used.
                                            const headerTitle = config.header instanceof Function ? config.header() : config.header;

                                            // Depends on whether ordering is set or not.
                                            const isOrderable = "comparator" in config;

                                            return (
                                                <HeaderCell
                                                    key={`hc_${index}`}
                                                    width={mimicSizes?.at(index)}
                                                    _boldHeaders={boldHeaders}
                                                    onClick={() => {
                                                        if (isOrderable) {
                                                            setOrderBy(getOrderingObject(index, orderBy));
                                                            setCollapsedRowIndex(undefined);
                                                        }
                                                    }}
                                                    _isOrderable={isOrderable}>

                                                    {/* Here we want to get rid of useless div wrapper, when header cell is not sortable */}
                                                    {
                                                        isOrderable
                                                            ?
                                                            <div className="flex select-none">
                                                                {headerTitle}
                                                                {
                                                                    isOrderable &&
                                                                    <Sorter index={index} currentOrdering={orderBy}/>
                                                                }
                                                            </div>
                                                            :
                                                            headerTitle
                                                    }
                                                </HeaderCell>
                                            );
                                        })
                                    }
                                </TableRow>
                            </TableHead>

                            {/* By default, skeleton draws 10 rows and cells are filled with skeletons repeating header cells` width*/}
                            {isDataLoading ?
                                <TableBody>
                                    {
                                        [...Array(renderingData.length > 0 ? renderingData.length : 10)].map((row, rowIndex) => (
                                            <BodyRow key={`skel_r_${rowIndex}`}>
                                                {
                                                    [...Array(columns.length)].map((col, colIndex) => {
                                                        if (tableHeaderRowRef && tableHeaderRowRef.current) {
                                                            return (
                                                                <BodyCell key={`skel_c_${rowIndex}-${colIndex}`}>
                                                                    <Skeleton variant="rectangular" animation="wave"
                                                                              width={tableHeaderRowRef.current.cells[colIndex]?.width}
                                                                              height={16}
                                                                              className={"my-1"}
                                                                    />
                                                                </BodyCell>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                }
                                            </BodyRow>
                                        ))
                                    }
                                </TableBody> :

                                // Actual data is shown when loading set to false and data array contains 1 or more elements
                                (!hideData && renderingData.length > 0 ?
                                    <TableBody>
                                        {
                                            renderingData
                                                .map((row, rowIndex) => {
                                                    const isRowOpen = collapsedRowIndex == rowIndex;
                                                    const parentRowKey = rowKeyGetter(row, rowIndex);
                                                    // Columns have the same order as headers
                                                    return (
                                                        <>
                                                            <BodyRow
                                                                key={parentRowKey}
                                                                _alternate={alternate && rowIndex % 2 == 0}
                                                                _clickable={!!collapse || !!rowOnClick}
                                                                _collapsableFill={isRowOpen ? collapse?.fill : undefined}
                                                                _collapsableBorder={isRowOpen ? collapse?.borderColor : undefined}
                                                                sx={{
                                                                    ...(isRowOpen &&
                                                                        {
                                                                            "td:first-of-type": {
                                                                                borderTopLeftRadius: "8px"
                                                                            },
                                                                            "td:last-child": {
                                                                                borderTopRightRadius: "8px"
                                                                            }
                                                                        }
                                                                    ),
                                                                }}
                                                                onClick={() => rowOnClick?.(row, isRowOpen)}
                                                                data-row-key={parentRowKey}
                                                            >
                                                                {
                                                                    columns.map((config, index) => {
                                                                        if ("visible" in config && !config.visible) return;

                                                                        const cellContent = config.getter(row, rowIndex, isRowOpen);

                                                                        return (
                                                                            <BodyCell
                                                                                key={`${parentRowKey}--${index}`}
                                                                                className={`truncate ${config.cellClasses}`}
                                                                                sx={config.sx}
                                                                                onClick={() => {
                                                                                    if (collapse && !config.preventCollapsePropagation) {
                                                                                        if (!isRowOpen) {
                                                                                            collapse.onOpen?.(row);
                                                                                        }

                                                                                        setCollapsedRowIndex(isRowOpen ? undefined : rowIndex);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {cellContent}
                                                                            </BodyCell>
                                                                        );
                                                                    })
                                                                }
                                                                {
                                                                    isRowOpen && collapse?.isLoading?.(row, isRowOpen) &&
                                                                    <div
                                                                        className="absolute w-full bottom-[-2px] left-0">
                                                                        <LinearProgress/>
                                                                    </div>
                                                                }
                                                            </BodyRow>

                                                            {/* Collapse is basically an additional empty row, which may show its content, if collapse is set to "open" */}
                                                            {
                                                                collapse &&
                                                                <BodyRow
                                                                    key={"collapse_deps_on_br_" + rowIndex}
                                                                    _collapsableFill={isRowOpen ? collapse?.fill : undefined}
                                                                    sx={{
                                                                        "td:first-of-type": {
                                                                            borderBottomLeftRadius: "8px"
                                                                        },
                                                                        "td:last-child": {
                                                                            borderBottomRightRadius: "8px"
                                                                        }
                                                                    }}
                                                                >
                                                                    <BodyCell
                                                                        key={`collapse_deps_on_br_${rowIndex}_cell`}
                                                                        _slim={!isRowOpen || collapse?.isLoading?.(row, isRowOpen)}
                                                                        colSpan={columns.length}
                                                                        sx={{
                                                                            paddingLeft: 0,
                                                                            paddingRight: 0
                                                                        }}
                                                                    >
                                                                        <Collapse
                                                                            in={isRowOpen && !collapse?.isLoading?.(row, isRowOpen)}
                                                                            timeout="auto"
                                                                            unmountOnExit>
                                                                            {collapse.content(row, isRowOpen)}
                                                                        </Collapse>
                                                                    </BodyCell>
                                                                </BodyRow>
                                                            }
                                                        </>
                                                    );
                                                })
                                        }
                                    </TableBody>
                                    : null)
                            }
                        </Table>
                }
                {/* NOT FOUND banner is displayed, when loading set to "false" and data array is empty */}
                {!hideData && !isDataLoading && renderingData.length == 0 ?
                    <div className="flex items-center justify-center text-xl font-thin text-notFound-blocks"
                         style={{height: nothingFound?.height ?? 500}}>
                        {nothingFound?.text ?? t("general.nothingFound.table")}
                    </div>
                    : null
                }
            </TableContainer>
        </>
    );
}

/**
 * MUI Dynamic stylization of TableCell, adding color, font thickness, font size and cursor if column is orderable.
 */
const HeaderCell = styled(TableCell, TransientAdapter)<{
    _isOrderable: boolean,
    _boldHeaders: boolean,
    width?: string | number;
}>(({theme, ...props}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.custom.gray[600],
        fontWeight: 100,
        fontSize: 16,
        ...(props._isOrderable && {cursor: "pointer"}),
        ...(props._boldHeaders && {
            fontWeight: 600,
            color: theme.custom.textAccent
        }),
        width: props.width,
        "&:hover": {
            ".arrow": {
                opacity: .3
            }
        }
    }
}));

/**
 * MUI Dynamic stylization of TableRow, adding gray background if table type is set to "Alternate".
 */
const BodyRow = styled(
    TableRow,
    TransientAdapter
)<{
    _alternate?: boolean,
    _clickable?: boolean,
    _collapsableFill?: string,
    _collapsableBorder?: string
}>(({theme, ...props}) => ({
    position: "relative",
    transition: "background-color .15s",
    ...(props._alternate && {
        backgroundColor: theme.custom.gray[50]
    }),
    ...(props._collapsableFill && {
        backgroundColor: props._collapsableFill + "!important"
    }),
    ...(props._collapsableBorder && {
        borderBottom: `1px solid ${props._collapsableBorder}`,
        transition: "all .15s" // setting transition here to remove smooth fading away
    }),
    ...(props._clickable && {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.custom.gray[200]
        }
    })
}));

/**
 * MUI Dynamic stylization of TableCell, adding background, resetting line-height and more.
 */
const BodyCell = styled(
    TableCell,
    TransientAdapter
)<{
    _slim?: boolean; // slim is used, when collapse block is hidden to remove ugly space between top-level rows
}>(({theme, ...props}) => ({
    color: theme.custom.blue[900],
    lineHeight: "unset",
    transition: "padding border-radius .15s",
    ...(props._slim && {
        paddingBottom: 0,
        paddingTop: 0
    })
}));

/**
 * Helper function to define next ordering type.
 * Cycle is: "nothing" -> "desc" -> "asc" -> "nothing".
 * @param index index of column in *columns*, which is being sorted
 * @param currentOrdering the same as "orderBy" entity (current ordering state)
 * @returns new ordering state
 */
function getOrderingObject<T>(index: number, currentOrdering?: Ordering): Ordering | undefined {
    if (!currentOrdering || currentOrdering.index != index) {
        return {
            index: index,
            order: "desc"
        };
    } else if (currentOrdering.order == "desc") {
        return {
            index: index,
            order: "asc"
        };
    } else {
        return undefined;
    }
}

/**
 * Helper function to compare data of two rows. Declines undefined values, uses custom comparators at {@link ColumnConfiguration}.
 * @param aRow
 * @param bRow
 * @param orderBy current ordering state
 * @param columns columns configurations, which is used to extract the comparator
 * @returns comparison result number
 */
function comparePayloadObjects<T>(
    aRow: T,
    bRow: T,
    orderBy: Ordering | undefined,
    columns: ColumnConfiguration<T>[]
): number {

    if (!orderBy)
        return 0;

    if (!("comparator" in columns[orderBy.index]))
        return 0;


    // If both of the payloads are undefined => they are equivalent
    //    the first payload is undefined     => the second has bigger weight
    //    the second payload is undefined    => the first has bigger weight
    if (!aRow && !bRow) {
        return 0;
    } else if (!aRow) {
        return -1;
    } else if (!bRow) {
        return 1;
    }

    const value = columns[orderBy.index].comparator?.(aRow, bRow) ?? 0;

    return orderBy.order == "asc" ? value : -value;
}
