import React from 'react'
import { useTranslation } from 'react-i18next';
import BaseButton from '@reusables/BaseButton';
import BaseModal from '@reusables/Modals/BaseModal';
import { useDeleteRoleMutation } from '@redux/features/roles/rolesApi';
import { Role } from '@/types/general';
import { toast } from 'react-toastify';

interface DeleteModalProperties {
    isOpen: boolean;
    role?: Role.Root;
    onClose: () => void;
}

export default function DeletionModal(props: DeleteModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.companyInformation.tabs.roles.modals.deletion"});

    const [deleteRole, {isLoading: isDeletionLoading}] = useDeleteRoleMutation();

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isDeletionLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">{t("subheading")}.</div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose}/>
                    <BaseButton text={t("buttons.delete")} size="md" onClick={() => {
                        if (props.role) {
                            deleteRole(props.role.id).unwrap().then(resp => {
                                toast.success(t("responses.success"));
                            }).catch(e => {
                                console.error(e);

                                toast.error(t("responses.error"));
                            })
                                .finally(() => props.onClose());
                        }
                    }}/>
                </div>
            </div>
        </BaseModal>
    )
}
