import React from "react";
import { useTranslation } from "react-i18next";
import { useCreatePickingMutation } from "@redux/features/picking/pickingApi";
import { useHistory } from "react-router-dom";
import MutationLayout from "../components/MutationLayout";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { toast } from "react-toastify";
import { downloadBlobExport } from "@helpers/utils";
import { isErrorWithMessage } from "@redux/api/query";

export default function PickingCreationPage() {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.mutation" });

    const history = useHistory();

    const [createPicking, { isLoading: isCreationLoading }] = useCreatePickingMutation();

    return (
        <BaseLoadingBlocker active={isCreationLoading}>
            <MutationLayout
                title={t("heading")}
                onMutation={data => {
                    createPicking({
                        sale_order_id: data.sale_order.id,
                        date: data.picking_date.date.format("YYYY-MM-DD"),
                        lines: data.lines
                            .filter(x => {
                                if (x.product.is_service) {
                                    return x.picked_quantity && x.picked_quantity > 0;
                                } else {
                                    return x.picks.some(y => (y.picked_quantity ?? 0) > 0 && y.outgoing_location);
                                }
                            })
                            .map(x => ({
                                sale_order_line_id: x.id,
                                product_id: x.product.id,
                                ...(!x.product.is_service && {
                                    locations: x.picks.map(y => ({
                                        location_id: y.outgoing_location?.location_id as number,
                                        ...(y.outgoing_location?.sub_location_id && { sub_location_id: y.outgoing_location.sub_location_id }),
                                        picked_quantity: y.picked_quantity as number
                                    }))
                                }),
                                ...(x.product.is_service && {
                                    picked_quantity: x.picked_quantity
                                }),
                                ...(x.product.is_serial_numbers && {
                                    serial_numbers: x.serial_numbers
                                }),
                                ...(x.product.is_batch_numbers && {
                                    batch_numbers: x.batch_numbers.map(({ batch_number, expiration_date }) => ({
                                        batch_number,
                                        expiration_date: expiration_date?.format("YYYY-MM-DD")
                                    }))
                                })
                            })),
                        ...(data.packages?.length && {
                            packages: data.packages.map(x => ({
                                id: x.package.id,
                                quantity: x.quantity
                            })),
                        }),

                        ...(data.shipmondo?.enabled && {
                            shipmondo: {
                                carrier_code: data.shipmondo.carrier?.code,
                                product_code: data.shipmondo.product?.code,
                                service_codes: data.shipmondo.services?.map(x => x.code).join(",")
                            }
                        })
                    }).unwrap()
                        .then(resp => {
                            toast.success(t("responses.success"));
                            downloadBlobExport(() => resp, `delivery_note`, "pdf");
                            history.push("/dashboard/picking");
                        })
                        .catch(e => {
                            if (isErrorWithMessage(e)) {
                                toast.error(e.message);
                            } else {
                                toast.error(t("responses.error"));
                            }
                        });
                }}
            />
        </BaseLoadingBlocker>
    );
}
