import React, { useEffect } from 'react';
import BaseButton from '@/components/reusables/BaseButton';
import BaseInputsGrid from '@/components/reusables/BaseInputsGrid';
import BaseNotFound from '@/components/reusables/BaseNotFound';
import BaseModal from '@/components/reusables/Modals/BaseModal';
import { stopPropagate } from '@/helpers/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import SerialGroup from './SerialGroup';
import { DevTool } from '@hookform/devtools';
import { useTranslation } from 'react-i18next';

const formScheme = z.object({
    product: z.object({
        quantity: z.coerce.number(),
        serial_numbers: z.array(
            z.object({
                serial_number: z.string()
            })
        )
    })
});

export type SerialNumbersScheme = z.infer<typeof formScheme>

interface SerialNumbersModalProps {
    isOpen: boolean;
    onClose: () => void;

    product: {
        quantity: number;
        serial_numbers?: {
            serial_number: string;
        }[]
    };

    onSave?: (data: SerialNumbersScheme) => void;

    preview?: boolean;
}

export default function SerialNumbersModal(props: SerialNumbersModalProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal.modals.serialNumbersModal" });

    const { control, handleSubmit, setValue, reset } = useForm<SerialNumbersScheme>({
        resolver: zodResolver(formScheme)
    });

    const onSubmit = handleSubmit(data => {
        console.log(data);
        props.onSave?.(data);
    });

    useEffect(() => {
        if (props.isOpen) {
            const formReadySerialNumbers = props.product.serial_numbers?.length ? props.product.serial_numbers : [{ serial_number: "" }];

            setValue("product.quantity", props.product.quantity);
            setValue("product.serial_numbers", formReadySerialNumbers);
        } else {
            reset();
        }
    }, [props.isOpen, props.product.serial_numbers, setValue, reset]);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={900}
            padding="56px"
            position="center"
        >
            <form onSubmit={stopPropagate(onSubmit)} className="space-y-8">
                {
                    props.product ?
                        <SerialGroup
                            control={control}
                            preview={props.preview}
                        />
                    :
                    <BaseNotFound text={"No serial numbers found"} height={250} />
                }
                <BaseInputsGrid cols={props.preview ? 1 : 2}>
                    <BaseButton
                        text={t("buttons.cancel")}
                        size="md"
                        buttonWidth="100%"
                        primaryOutlined
                        type="button"
                        onClick={props.onClose}
                    />

                    {
                        !props.preview && (
                            <BaseButton
                                text={t("buttons.attach")}
                                size="md"
                                buttonWidth="100%"
                            />
                        )
                    }
                </BaseInputsGrid>
                <DevTool control={control} />
            </form>
        </BaseModal>
    )
}
