import React from "react";
import {useTranslation} from "react-i18next";
import CreationEditingLayout from "../components/CreationEditingLayout";

export default function ProductCreationPage() {
    const { t } = useTranslation("", { keyPrefix: "products.newProduct" });

    return (
        <div className="levitation-extended">
            <div className="flex mb-[40px] items-center">
                <h6 className="text-accent grow">{t("heading")}</h6>
                <div id="product-creation-header">

                </div>
            </div>
            <CreationEditingLayout
                product={undefined}
            />
        </div>
    );
}