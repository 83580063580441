import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Integration } from "@/types/general";
import TypeSelector from "../components/TypeSelector";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import IntegrationCard from "../components/IntegrationCard";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { useGetIntegrationsQuery } from "@redux/features/integrations/integrationsApi";
import IntegrationModal
    from "@components/Dashboard/pages/SettingsPage/Integrations/ThirdPartyIntegrations/components/IntegrationModal";
import DisableModal from "../components/modals/DisableModal";


export function ThirdPartyIntegrationsPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.integrations.thirdParty" });

    const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery();
    const [selectedType, setSelectedType] = useState<Integration.Components.Type | "all">("all");

    const [connectionModalOpen, setConnectionModalOpen] = useState(false);
    const [disableModalOpen, setDisableModalOpen] = useState(false);
    const [connectionIntegration, setConnectionIntegration] = useState<Integration.Components.Integration>();

    return (
        <>
            <div className="relative levitation-extended space-y-[40px]">
                {/* Integrations heading */}
                <div className="flex justify-between items-center">
                    <h6 className="text-accent grow">{t("heading")}</h6>
                    <div className="text-accent">
                        <span className="text-[#A6DD4C]">{integrations?.current}</span> out of {integrations?.limit}
                    </div>
                </div>

                {/* Types selector*/}
                <TypeSelector
                    selectedType={selectedType}
                    onTypeChange={setSelectedType}
                />

                {/* Integrations list (cards) */}
                <BaseRecordRenderer
                    record={integrations}
                    loading={isIntegrationsLoading}
                    hideLevitation
                >
                    <BaseInputsGrid cols={1}>
                        {integrations?.integrations.map((integration, index) =>
                            <IntegrationCard
                                key={index}
                                name={integration.name}
                                description={integration.description}
                                imageUrl={integration.imageUrl}
                                onModalOpen={() => {
                                    setConnectionIntegration(integration);
                                    integration.integrated 
                                    ? setDisableModalOpen(true)
                                    : setConnectionModalOpen(true);
                                }}
                                integrated={integration.integrated}
                                hidden={selectedType !== "all" && integration.integrationType !== selectedType}
                                isImportAvailable={integration.isImportAvailable}
                                slug={integration.slug}
                                isIntegrationsLimitReached={integrations.current >= integrations.limit}
                            />
                        )}
                    </BaseInputsGrid>
                </BaseRecordRenderer>
            </div>

            {/* Integration connection modal */}
            <IntegrationModal
                integration={connectionIntegration}
                isOpen={connectionModalOpen}
                onClose={() => setConnectionModalOpen(false)}
            />

            {/*Integration disable modal */}
            <DisableModal
                isOpen={disableModalOpen}
                onClose={() => setDisableModalOpen(false)}
                slug={connectionIntegration?.slug}
            />
        </>
    );
}
