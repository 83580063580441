import React, { useEffect, useMemo, useState } from "react";
import BaseInput from "@reusables/BaseInput";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, useWatch } from "react-hook-form";
import TableHeading from "./parts/TableHeading/TableHeading";
import TableBody from "./parts/TableBody/TableBody";
import BaseButton from "@reusables/BaseButton";
import { useGetLocationsQuery } from "@redux/features/locations/locationsApi";
import { useCreateProduceMutation, useGetBOMQuery } from "@redux/features/bom/bomApi";
import { toast } from "react-toastify";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import ProduceSuccessModal from "../ProduceSuccessModal";
import { useHistory } from "react-router-dom";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { isErrorWithMessage } from "@redux/api/query";
import { useTranslation } from "react-i18next";
import { useMaxProducts } from "../../helpers/useMaxProducts";
import {
    produceFormScheme,
    ProduceFormTyping,
    ProductionSuccessData
} from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/types";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import SerialNumbersModal from "./modals/SerialNumbersModal";
import BatchNumbersModal from "./modals/BatchNumbersModal";
import { useGetProductQuery } from "@redux/features/products/productsApi";
import { Alert, Collapse } from "@mui/material";
import { DevTool } from "@hookform/devtools";

interface ProduceModalProps {
    isOpen: boolean;
    onClose: () => void;
    entity_id?: number;
}

export default function ProduceModal({ isOpen, onClose, entity_id }: ProduceModalProps) {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal" });

    const { control, handleSubmit, setValue, getValues, formState, reset } = useForm<ProduceFormTyping>({
        resolver: zodResolver(produceFormScheme)
    });

    const { data: bom, isLoading: isBomLoading } = useGetBOMQuery(entity_id as number, { skip: !entity_id });

    const { data: locationsOption = [], isLoading: isLocationsLoading } = useGetLocationsQuery();

    const [createProduce, { isLoading: isProduceLoading }] = useCreateProduceMutation();

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
    const [successModalBOMData, setSuccessModalBOMData] = useState<ProductionSuccessData>();

    const [isSerialNumbersModalOpen, setIsSerialNumbersModalOpen] = useState<boolean>(false);
    const [isBatchNumbersModalOpen, setIsBatchNumbersModalOpen] = useState<boolean>(false);

    const {data: productExtended, isLoading: isProductExtendedLoading } = useGetProductQuery(bom?.product.id as number, { skip: !bom });

    const quantity = useWatch({ control, name: "quantity" });

    useEffect(() => {
        if (!isOpen) {
            reset();
            return;
        }

        if (bom && productExtended) {
            setValue("product_id", bom?.id);
            setValue("is_serial_number", productExtended?.is_serial_number ?? false);
            setValue("is_batch_number", productExtended?.is_batch_number ?? false);
            setValue("components_picks", bom.components?.map(component => ({
                component: {
                    product: {
                        id: component.product.id,
                        name: component.product.name,
                        code: component.product.code,
                        quantity: component.quantity,
                        in_stock: component.product.in_stock
                    },
                    locations: component.locations?.map(location => ({
                        store: {
                            id: location.store.id,
                            name: location.store.name
                        },
                        section: location.section ? {
                            id: location.section.id,
                            name: location.section.name
                        } : null,
                        in_stock: location.in_stock
                    }), [])
                },
                sources: [{
                    product_id: component.product.id,
                    locations: undefined,
                    quantity: undefined
                } as never]
            })));
        }
    }, [bom, productExtended, isOpen]);

    // Calculate the maximum number of products that can be produced
    const maxProducts = useMaxProducts(bom?.components ?? []);

    const onSubmit = handleSubmit((data) => {
        if (bom) {
            createProduce({
                id: bom.id,
                quantity: data.quantity,
                serial_numbers: data.serial_numbers?.map(serialNumber => ({
                    serial_number: serialNumber.serial_number
                })),
                batch_numbers: data.batch_numbers?.map(batchNumber => ({
                    batch_number: batchNumber.batch_number,
                    expiration_date: batchNumber.expiration_date?.format("YYYY-MM-DD")
                })),
                produce_location: data.produce_location && {
                    store_id: data.produce_location.store.id,
                    section_id: data.produce_location.section?.id
                },
                components_picks: data.components_picks.map(component => ({
                    product_id: component.component.product.id,
                    sources: component.sources.map(source => ({
                        product_id: source.product_id,
                        store_id: source.locations?.store.id as number,
                        section_id: source.locations?.section?.id ?? undefined,
                        quantity: source.quantity
                    }))
                }))
            }).unwrap()
                .then(() => {
                    setSuccessModalBOMData({
                        product: bom.product,
                        quantity: data.quantity
                    });
                    onClose();
                    setIsSuccessModalOpen(true);
                })
                .catch((error) => {
                    if (isErrorWithMessage(error)) {
                        toast.error(error.message);
                    } else {
                        toast.error(t("responses.error"));
                    }
                    console.error(error)
                });
        } else {
            console.warn("BOM is not provided. Unable to perform `onSubmit` for produce modal.");
        }
    });

    // Workaround to operate serial_numbers and batch_numbers errors
    const serialAndBatchNumberErrors = useMemo(() => {
        return {
            ...(!!formState.errors.serial_numbers && { serial_numbers: formState.errors.serial_numbers }),
            ...(!!formState.errors.batch_numbers && { batch_numbers: formState.errors.batch_numbers })
        }
    }, [formState]);

    return (
        <>
            <BaseModal
                isOpen={isOpen}
                onClose={onClose}
                // useCloseIcon // UI/UX decision because it overlaps main content on scroll
                padding="56px"
                width={1000}
            >
                <BaseRecordRenderer loading={isBomLoading || isProductExtendedLoading} record={bom} hideLevitation>
                    <BaseLoadingBlocker active={isProduceLoading}>
                        <form className="space-y-[40px]" onSubmit={onSubmit}>
                            <div className="space-y-[40px]">
                                <div className="flex justify-center items-center">
                                    <h6 className="text-accent">{`${t("heading")}: ${bom?.name}`}</h6>
                                </div>

                                <div className="flex justify-between items-center h-11">
                                    <div className="text-accent">
                                        {t("quantityCanProduced")}: <span className="font-semibold">{maxProducts}</span>
                                    </div>
                                    {
                                        quantity > 0 && (
                                            <div className="space-x-2">
                                                {/* Serial Numbers Modal button */}
                                                {
                                                    productExtended?.is_serial_number && (
                                                        <BaseMaterialButton
                                                            type="button"
                                                            onClick={() => setIsSerialNumbersModalOpen(true)}
                                                        >
                                                            {t("buttons.addSerialNumbers")}
                                                        </BaseMaterialButton>
                                                    )
                                                }

                                                {/* Batch Numbers Modal button */}
                                                {
                                                    productExtended?.is_batch_number && (
                                                        <BaseMaterialButton
                                                            type="button"
                                                            onClick={() => setIsBatchNumbersModalOpen(true)}
                                                        >
                                                            {t("buttons.addBatchNumbers")}
                                                        </BaseMaterialButton>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                
                                <div className={`space-y-[10px] ${!!serialAndBatchNumberErrors.serial_numbers || !!serialAndBatchNumberErrors.batch_numbers ? "block" : "hidden"}`}>
                                    {/* Serial Numbers Error */}
                                    <Collapse in={!!serialAndBatchNumberErrors.serial_numbers}>
                                        <Alert severity="error">{serialAndBatchNumberErrors.serial_numbers?.message}</Alert>
                                    </Collapse>

                                    {/* Batch Numbers Error */}
                                    <Collapse in={!!serialAndBatchNumberErrors.batch_numbers}>
                                        <Alert severity="error">{serialAndBatchNumberErrors.batch_numbers?.message}</Alert>
                                    </Collapse>
                                </div>

                                <BaseInputsGrid cols={2}>
                                    <Controller
                                        control={control}
                                        name="quantity"
                                        render={({ field, fieldState }) => (
                                            <BaseInput
                                                {...field}
                                                error={fieldState.error}
                                                type="number"
                                                label={t("fields.quantity") + " *"}
                                            />
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name="produce_location"
                                        render={({ field, fieldState }) => (
                                            <BaseDropdown
                                                {...field}
                                                {...fieldState}
                                                options={locationsOption}
                                                getter={{
                                                    label: (opt) => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                                    key: (opt) => opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`
                                                }}
                                                label={t("dropdowns.location.label") + " *"}
                                                placeholder={t("dropdowns.location.placeholder")}

                                                isLoading={isLocationsLoading}

                                                autocomplete
                                            />
                                        )}
                                    />
                                </BaseInputsGrid>

                                <div className="space-y-4">
                                    {/* Components Table heading */}
                                    <TableHeading control={control}
                                                  onComponentsUpdate={components => setValue("components_picks", components)} />
                                    {/* Components Table Body */}
                                    <TableBody control={control} />
                                </div>

                                <BaseInputsGrid cols={2}>
                                    <BaseButton
                                        text={t("buttons.cancel")}
                                        type="button"
                                        size="large"
                                        className="w-full"
                                        onClick={onClose}
                                        primaryOutlined
                                    />
                                    <BaseButton
                                        text={t("buttons.continue")}
                                        type="submit"
                                        size="large"
                                        className="w-full"
                                    />
                                </BaseInputsGrid>
                            </div>
                            <DevTool control={control} />
                        </form>
                    </BaseLoadingBlocker>
                </BaseRecordRenderer>
            </BaseModal>

            {/* Serial Numbers Modal */}
            <SerialNumbersModal
                isOpen={isSerialNumbersModalOpen}
                onClose={() => setIsSerialNumbersModalOpen(false)}
                product={{
                    quantity: getValues("quantity") ?? 0,
                    serial_numbers: getValues("serial_numbers") ?? []
                }}
                onSave={(data) => {
                    const readySerialNumbers = data.product.serial_numbers.map(serialNumber => ({
                        serial_number: serialNumber.serial_number
                    }));

                    setValue("serial_numbers", readySerialNumbers);
                    console.log(data);

                    setIsSerialNumbersModalOpen(false);
                }}
            />

            {/* Batch Numbers Modal */}
            <BatchNumbersModal
                isOpen={isBatchNumbersModalOpen}
                onClose={() => setIsBatchNumbersModalOpen(false)}
                product={{
                    quantity: getValues("quantity") ?? 0,
                    batch_numbers: getValues("batch_numbers") ?? []
                }}
                onSave={(data) => {
                    const readyBatchNumbers = data.product.batch_numbers.map(batchNumber => ({
                        batch_number: batchNumber.batch_number,
                        expiration_date: batchNumber.expiration_date
                    }));

                    setValue("batch_numbers", readyBatchNumbers);
                    console.log(data);
                    setIsBatchNumbersModalOpen(false);
                }}
            />
            

            <ProduceSuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => {
                    setIsSuccessModalOpen(false);
                    history.push("/dashboard/bill-of-materials");
                }}
                data={successModalBOMData}
            />
        </>
    );
}
