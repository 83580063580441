import React from "react";
import {Customer} from "@/types/general";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {useTranslation} from "react-i18next";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";

export default function AddressTabPreview({ customer }: { customer: Customer.Extended }) {
    const { t } = useTranslation("", { keyPrefix: "customers.general.tabs.address" });

    return (
      <div className="space-y-[40px]">
          <div className="text-xl text-accent">{t("categories.billing")}</div>
          <div>
              <BaseCheckbox
                label={`${t("checkboxes.useForShipping")}`}
                checked={!!customer.billing?.is_used_for_shipping}
                disabled
              />
          </div>
          <BaseInputsGrid>
              <InputLikeContainer
                label={t("fields.name.label")}
                content={customer.billing?.name}
              />

              <InputLikeContainer
                label={t("fields.street.label")}
                content={customer.billing?.street}
              />

              <InputLikeContainer
                label={t("fields.street2.label")}
                content={customer.billing?.street_2}
              />

              <InputLikeContainer
                label={t("fields.zipcode.label")}
                content={customer.billing?.zipcode}
              />

              <InputLikeContainer
                label={t("fields.city.label")}
                content={customer.billing?.city}
              />

              <InputLikeContainer
                label={t("fields.country.label")}
                content={customer.billing?.country?.name}
              />

              <InputLikeContainer
                label={t("fields.phone.label")}
                content={customer.billing?.phone}
              />

              <InputLikeContainer
                label={t("fields.email.label")}
                content={customer.billing?.email}
              />
          </BaseInputsGrid>

          {
              customer.deliveries.map((address, index) =>
                <div key={index} className="space-y-[24px]">
                    {
                        index === 0 ?
                          <div>
                              <div className="text-xl text-accent">{t("categories.delivery")}</div>
                          </div>
                          :
                          <div>
                              <div className="text-xl text-accent">{t("categories.delivery")}</div>
                          </div>
                    }
                    <div>
                        <BaseCheckbox
                          label={`${t("checkboxes.useAsPrimary")}`}
                          checked={!!address.is_primary}
                          disabled
                        />
                    </div>
                    <BaseInputsGrid>
                        <InputLikeContainer
                          label={t("fields.name.label")}
                          content={address.name}
                        />

                        <InputLikeContainer
                          label={t("fields.street.label")}
                          content={address.street}
                        />

                        <InputLikeContainer
                          label={t("fields.street2.label")}
                          content={address.street_2}
                        />

                        <InputLikeContainer
                          label={t("fields.zipcode.label")}
                          content={address.zipcode}
                        />

                        <InputLikeContainer
                          label={t("fields.city.label")}
                          content={address.city}
                        />

                        <InputLikeContainer
                          label={t("fields.country.label")}
                          content={address.country?.name}
                        />

                        <InputLikeContainer
                          label={t("fields.contact_person.label")}
                          content={address.contact_person}
                        />

                        <InputLikeContainer
                          label={t("fields.phone.label")}
                          content={address.phone}
                        />

                        <InputLikeContainer
                          label={t("fields.email.label")}
                          content={address.email}
                        />
                    </BaseInputsGrid>
                </div>
              )
          }
      </div>
    );
}

function InputLikeContainer({ label, content }: { label: string, content: JSX.Element | string | undefined | number }) {
    return (
      <div>
          <div className="font-semibold text-inputs-label-dim mb-[8px]">{label}</div>
          <div className="text-accent">
              {content ?? "-"}
          </div>
      </div>
    );
}