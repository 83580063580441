import React, {cloneElement, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as TrashSVG} from "../../../../../../assets/icons/ic_trash.svg";
import {useHistory, useParams} from "react-router-dom";
import SkeletonLayout from "../components/SkeletonLayout/SkeletonLayout";
import ViewLayout from "../components/ViewLayout";
import BaseNotFound from "@reusables/BaseNotFound";
import {useGetTransferQuery} from "@redux/features/transfers/transfersApi";
import DeletionModal from "../components/modals/DeletionModal";

export default function TransfersDetailsPage() {
    const { t } = useTranslation("", { keyPrefix: "inventory.stockTransfers" });

    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const { data: transfer, isLoading: transferLoading } = useGetTransferQuery(parseInt(id));

    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    const icons: { icon: JSX.Element, onClick: () => void }[] = [
        // {
        //     icon: <TrashSVG />,
        //     onClick: () => setIsDeletionModalOpen(true)
        // }
    ];

    return (
      <>
          <div className="levitation-extended">
              <div className="flex mb-[40px]">
                  <h6 className="text-accent grow">{t("viewEditTransfer.heading")} #{transfer?.code}</h6>
                  {
                      !transferLoading && !!transfer &&
                        <div className="space-x-[8px] flex items-center h-full">
                            {icons.map((item, index) =>
                              <div
                                className="flex justify-center items-center" key={index}
                                style={{
                                    border: "1px solid transparent",
                                    background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, rgba(43, 42, 53, 1), rgba(105, 89, 252, 1)) border-box",
                                    borderRadius: "6px",
                                    height: 32,
                                    width: 32,
                                    cursor: "pointer"
                                }}
                                onClick={item.onClick}
                              >
                                  {cloneElement(item.icon, { width: 16, height: 16, className: "text-actionableIcons" })}
                              </div>
                            )}
                        </div>
                  }
              </div>
              {
                  transferLoading ?
                    <SkeletonLayout/>
                  :
                    transfer ?
                    <ViewLayout transfer={transfer}/>
                      :
                      <BaseNotFound text={t("stockTransfer.notFound")}/>

              }
          </div>

          {/* DELETION MODAL */}
          <DeletionModal
              isOpen={isDeletionModalOpen}
              onClose={() => setIsDeletionModalOpen(false)}
              onDeletion={() => {
                  history.push("/dashboard/inventory/transfers")
              }}
          />
      </>
    );
}