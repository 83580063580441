import {
    ExportRequest,
    flattenExportRequest,
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "../../api/internalApiSlice";
import { DefaultLocation, Product } from "@/types/general";
import { objectToFormData } from "@helpers/utils";

const productsDateFields = ["order_date"];

export const productsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Getting all products paginated
        getProductsFull: builder.query<
            PaginationResponse<Product.Extended>,
            PaginationRequest<Product.DTO.Filters, Product.DTO.OrderBy> & {
            nest_variants?: boolean;
        }
        >({
            query: (req) => ({
                url: "product",
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req),
                    nest_variants: req.nest_variants ? 1 : 0
                },
                parseDates: productsDateFields
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        // Adding all products and their variants to the cache
                        ...getProductsRootTags(result.payload),
                        { type: "Product", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "Product", id: "PARTIAL-LIST" }]
        }),

        // Getting specific product
        getProducts: builder.query<Product.Slim[], void>({
            query: () => ({
                url: `product?slim=1`
            }),
            transformResponse,
            providesTags: [{ type: "Product", id: "LIST" }]
        }),

        // Getting specific product
        getProduct: builder.query<Product.Extended, number>({
            query: (id) => ({
                url: `product/${id}`,
                parseDates: productsDateFields
            }),
            transformResponse,
            providesTags: (result) => result ? getProductRootTags(result) : []
        }),

        createProduct: builder.mutation<Product.Extended, Product.DTO.Create>({
            query: (body) => {
                const formData = objectToFormData(body);

                return {
                    url: `product`,
                    method: "POST",
                    data: formData,
                    parseDates: productsDateFields
                };
            },
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdProduct } = await queryFulfilled;
                    const createResult = dispatch(
                        productsApi.util.updateQueryData("getProducts", undefined, (draft) => {
                            draft.unshift({
                                id: createdProduct.id,
                                name: createdProduct.name,
                                code: createdProduct.code,
                                prices: createdProduct.prices,
                                tax: createdProduct.tax,
                                is_component: createdProduct.is_component,
                                is_service: createdProduct.is_service,
                                has_bom: createdProduct.has_bom
                            });
                        })
                    );
                } catch (err) {
                    console.error(err);
                }
            },
            invalidatesTags: (result, error, body) => [
                {
                    type: "Product",
                    id: "PARTIAL-LIST"
                },
                ...(body.variant_parent_id ? [{ type: "Product", id: body.variant_parent_id }] : [])
            ]
        }),

        updateProductInformation: builder.mutation<Product.Extended, Product.DTO.UpdateInformation & { id: number }>({
            query: ({ id, ...body }) => {
                const formData = objectToFormData({ _method: "PUT", ...body });

                return {
                    url: `product/${id}`,
                    method: "POST",
                    data: formData,
                    parseDates: productsDateFields
                };
            },
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedProduct } = await queryFulfilled;
                    const createResult = dispatch(
                        productsApi.util.updateQueryData("getProducts", undefined, (draft) => {
                            const index = draft.findIndex((product) => product.id === updatedProduct.id);
                            draft[index] = {
                                id: updatedProduct.id,
                                name: updatedProduct.name,
                                code: updatedProduct.code,
                                prices: updatedProduct.prices,
                                tax: updatedProduct.tax,
                                is_component: updatedProduct.is_component,
                                is_service: updatedProduct.is_service,
                                has_bom: updatedProduct.has_bom
                            };
                        })
                    );
                } catch (err) {
                    console.error(err);
                }
            },
            invalidatesTags: (result, error, args) => [
                {
                    type: "Product",
                    id: args.id
                },
                "PurchaseOrder",
                "SaleOrder",
                "Picking",
                "Transfer"
            ]
        }),

        updateProductLocations: builder.mutation<Product.Extended, Product.DTO.UpdateLocations & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `product/${id}/locations`,
                method: "PUT",
                data: body,
                parseDates: productsDateFields
            }),
            invalidatesTags: (result, error, args) => [{ type: "Product", id: args.id }, "Transfer", "Picking"]
        }),

        getProductDefaultLocations: builder.query<DefaultLocation.Root[], number>({
            query: (id) => ({
                url: `defaultLocation/${id}`
            }),
            transformResponse,
            providesTags: (result, error, id) =>
                result
                    ? [
                        {
                            type: "Product",
                            id
                        },
                        ...result.map((loc) => ({ type: "Location", id: loc.id }))
                    ]
                    : []
        }),

        getProductTranslations: builder.query<Product.Augmentations.Translation.Root[], { id: number }>({
            query: (req) => ({
                url: `product/${req.id}/product-translation`
            }),
            transformResponse,
            providesTags: [{ type: "Translation", id: "LIST" }]
        }),

        getProductTranslationsFull: builder.query<PaginationResponse<Product.Augmentations.Translation.Root>, {
            id: number
        } & PaginationRequest<undefined, undefined>>({
            query: (req) => ({
                url: `product/${req.id}/product-translation`,
                method: "GET",
                params: {
                    // reactive: req.reactive ? 1 : 0,
                    ...flattenPaginationRequest(req)
                }
            }),

            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({
                            type: "Translation" as const,
                            id
                        })),
                        { type: "Translation", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "Translation", id: "PARTIAL-LIST" }]
        }),

        createProductTranslation: builder.mutation<Product.Augmentations.Translation.Root, {
            product_id: number
        } & Product.Augmentations.Translation.DTO.Create>({
            query: (body) => ({
                url: `product/${body.product_id}/product-translation`,
                method: "POST",
                data: body
            }),
            invalidatesTags: [
                { type: "Translation", id: "LIST" },
                { type: "Translation", id: "PARTIAL-LIST" }
            ],
            transformResponse
        }),

        updateProductTranslation: builder.mutation<Product.Augmentations.Translation.Root, Product.Augmentations.Translation.DTO.Update & {
            id: number;
            product_id: number
        }>({
            query: ({ id, ...body }) => {
                return {
                    url: `product/${body.product_id}/product-translation/${id}`,
                    method: "PUT",
                    data: body
                };
            },

            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: updatedProduct } = await queryFulfilled;
            //         dispatch(
            //            productsApi.util.updateQueryData("getCollections", undefined, (draft) => {
            //                 const index = draft.findIndex((product) => product.id === updatedProduct.id);
            //                 draft[index] = {
            //                     id: updatedProduct.id,
            //                     name: updatedProduct.name,
            //                     code: updatedProduct.code,
            //                     barcode: updatedProduct.barcode,
            //                     totalQuantity: updatedProduct.totalQuantity,
            //                     products: updatedProduct.products
            //                 };
            //             })
            //         );
            //     } catch (err) {
            //         console.error(err);
            //     }
            // },
            invalidatesTags: [
                { type: "Translation", id: "LIST" },
                { type: "Translation", id: "PARTIAL-LIST" }
            ]
        }),

        deleteProductTranslation: builder.mutation<void, { product_id: number; translation_id: number }>({
            query: (ids) => ({
                url: `product/${ids.product_id}/product-translation/${ids.translation_id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, id) => [{ type: "Translation" }]
        }),

        getProductSerialNumbersFull: builder.query<
            PaginationResponse<Product.Augmentations.SerialNumbers.Root>, 
            PaginationRequest<Product.Augmentations.SerialNumbers.DTO.Filters, Product.Augmentations.SerialNumbers.DTO.OrderBy> & { product_id: number }
        >({
            query: (req) => ({
                url: `product/${req.product_id}/serial-numbers`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["created_at"]
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({
                            type: "SerialNumber" as const,
                            id
                        })),
                        { type: "SerialNumber", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "SerialNumber", id: "PARTIAL-LIST" }]
        }),

        updateProductSerialNumber: builder.mutation<Product.Augmentations.SerialNumbers.Root, Product.Augmentations.SerialNumbers.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `serial-number/${id}`,
                method: "PUT",
                data: body,
                parseDates: ["expiration_date"]
            }),
            transformResponse,
            invalidatesTags: [
                { type: "SerialNumber", id: "LIST" },
                { type: "SerialNumber", id: "PARTIAL-LIST" }
            ]
        }),

        getProductBatchNumbersFull: builder.query<
            PaginationResponse<Product.Augmentations.BatchNumbers.Root>,
            PaginationRequest<Product.Augmentations.BatchNumbers.DTO.Filters, Product.Augmentations.BatchNumbers.DTO.OrderBy> & { product_id: number }
        >({
            query: (req) => ({
                url: `product/${req.product_id}/batch-numbers`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["created_at", "expiration_date"]
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({
                            type: "BatchNumber" as const,
                            id
                        })),
                        { type: "BatchNumber", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "BatchNumber", id: "PARTIAL-LIST" }]
        }),

        updateProductBatchNumber: builder.mutation<Product.Augmentations.BatchNumbers.Root, Product.Augmentations.BatchNumbers.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `batch-number/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            invalidatesTags: [
                { type: "BatchNumber", id: "LIST" },
                { type: "BatchNumber", id: "PARTIAL-LIST" }
            ]
        }),

        getProductPurchasePrices: builder.query<
            PaginationResponse<Product.Augmentations.Prices.PurchaseRoot>,
            PaginationRequest<undefined, undefined> & { product_id: number }
        >({
            query: (req) => ({
                url: `product/${req.product_id}/prices`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req),
                    priceType: "purchase"
                },
                parseDates: ["date"]
            }),
            providesTags: (result, error, req) => [{ type: "Product", id: req.product_id }]
        }),

        getProductSalePrices: builder.query<
            PaginationResponse<Product.Augmentations.Prices.SaleRoot>,
            PaginationRequest<undefined, undefined> & { product_id: number }
        >({
            query: (req) => ({
                url: `product/${req.product_id}/prices`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req),
                    priceType: "sale"
                },
                parseDates: ["date"]
            }),
            providesTags: (result, error, req) => [{ type: "Product", id: req.product_id }]
        }),

        deleteProduct: builder.mutation<void, number>({
            query: (id) => ({
                url: `product/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, id) => [
                {
                    type: "Product",
                    id
                },
                "PurchaseOrder",
                "SaleOrder",
                "Picking",
                "Transfer"
            ]
        }),

        exportProducts: builder.mutation<void, ExportRequest<Product.DTO.Filters>>({
            query: (args) => ({
                url: `product/export`,
                responseType: "blob",
                method: "POST",
                data: flattenExportRequest(args)
            })
        })
    })
});

export const {
    useGetProductsQuery,
    useCreateProductMutation,
    useGetProductQuery,
    useUpdateProductInformationMutation,
    useUpdateProductLocationsMutation,
    useGetProductDefaultLocationsQuery,
    useGetProductsFullQuery,
    useLazyGetProductsFullQuery,
    useDeleteProductMutation,
    useDeleteProductTranslationMutation,
    useGetProductTranslationsFullQuery,
    useUpdateProductTranslationMutation,
    useCreateProductTranslationMutation,
    useGetProductTranslationsQuery,
    useGetProductSerialNumbersFullQuery,
    useUpdateProductSerialNumberMutation,
    useGetProductBatchNumbersFullQuery,
    useUpdateProductBatchNumberMutation,
    useGetProductPurchasePricesQuery,
    useGetProductSalePricesQuery,
    useExportProductsMutation
} = productsApi;

const getProductSlimTags = (product: Product.Slim) =>
    [
        {
            type: "Product",
            id: product.id
        },
        ...(product.has_bom ? [{ type: "BOM" }] : [])
    ];
const getProductRootTags = (product: Product.Root) => [
    ...getProductSlimTags(product),
    // Adding all connected variants to the cache as products, which changes will lead to the correct parent invalidation
    ...(product?.variants?.map((v) => ({ type: "Product", id: v.id })) ?? [])
];

const getProductsSlimTags = (products: Product.Slim[]) => products.flatMap(getProductSlimTags);
const getProductsRootTags = (products: Product.Root[]) => products.flatMap(getProductRootTags);
