import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    declOfNum,
    fetchArrayReactively,
    fetchEntity,
    genT,
    normalizePrice,
    PredefinedTranslations,
    removeEmpty,
    roundTo,
    useOrdering,
    usePagination,
    useRangeFilter
} from "@helpers/utils";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseTable from "@reusables/BaseTable";

import { ReactComponent as CarretRightSVG } from "@assets/icons/ic_carret_right.svg";
import { ReactComponent as ExportSVG } from "@assets/icons/ic_export.svg";
import { Pagination } from "@mui/material";
import BaseMaterialCheckbox from "@reusables/BaseMaterialCheckbox";
import BaseDropdown from "@reusables/dropdowns/BaseDropdownLegacy";
import BaseTooltip from "@reusables/BaseTooltip";
import { MinMaxFilters } from "./types";
import BaseRangeSlider from "@reusables/sliders/BaseRangeSlider";
import { Category, Stock } from "@/types/general";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { useHistory } from "react-router-dom";
import { Can } from "@/casl.config";
import TableStocksExportModal from "@components/Dashboard/pages/Inventory/Stock/parts/TableStocksExportModal";
import { useGetStocksFullQuery } from "@redux/features/stock/stockApi";
import _ from "lodash";
import SingleStockExportModal from "@components/Dashboard/pages/Inventory/Stock/parts/SingleStockExportModal";

export default function StockPage() {
    const { t } = useTranslation("", { keyPrefix: "inventory.stock" });
    const history = useHistory();

    // =========== DROPDOWNS RELATED =========== //

    // ---> Category selection <--- //
    const {
        data: categoriesOptions,
        loading: categoriesOptionsLoading
    } = fetchArrayReactively<Category.Root>({ route: "category" });
    const [selectedCategories, setSelectedCategories] = useState<typeof categoriesOptions>([]);

    // =========== FILTERS RELATED =========== //
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

    // ---> Purchasing Price Range configuration <--- //
    const [purchasingPriceRange, setPurchasingPriceRange, debouncedPurchasingPriceRange] = useRangeFilter();

    // ---> In Stock Range configuration <--- //
    const [inStockRange, setInStockRange, debouncedInStockRange] = useRangeFilter();

    const { orderBy, setOrderBy } = useOrdering<Stock.DTO.OrderBy>();

    const pagination = usePagination({ page: 1, limit: 10 });

    const filters = removeEmpty({
        search: searchingValue,
        categories: selectedCategories.map(x => x.id),
        purchasing_price_range: debouncedPurchasingPriceRange, // might be undefined, if values are equal to range min:max values.
        stock_range: debouncedInStockRange // might be undefined, if values are equal to range min:max values.
    });

    const { data: stockResponse, isFetching: isStockResponseFetching } = useGetStocksFullQuery({
        filters,
        orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    // =========== OTHER STUFF =========== //
    const [selectedStocks, setSelectedStocks] = useState<number[]>([]);
    useEffect(() => {
        setSelectedStocks([]);
    }, [stockResponse?.payload]);

    const { data: stocksRanges, loading: stocksRangesLoading } = fetchEntity<MinMaxFilters>({ route: "stock-filters" });

    // =========== MODALS ================ //
    const [exportModalOpenType, setExportModalOpenType] = useState<"general" | "single">();
    return (
        <>
            {/* FILTERS BLOCK */}
            <div className="flex space-x-[24px]">
                <BaseAdminFilter
                    filterItems={
                        <div className="w-[363px]">
                            <BaseInputsGrid cols={1} gap={24}>
                                <BaseDropdown
                                    label={t("modals.filter.fields.category.label")}
                                    options={categoriesOptions}
                                    value={selectedCategories}
                                    getter={{
                                        label: opt => opt.name,
                                        key: opt => opt.id
                                    }}

                                    onChange={newState => setSelectedCategories(newState)}

                                    autocomplete
                                    multiple

                                    emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                    isLoading={categoriesOptionsLoading}

                                    brightLabel
                                />

                                <div>
                                    <div className="font-semibold mb-2">
                                        {t("modals.filter.fields.purchasePrice.label")}
                                    </div>
                                    <BaseRangeSlider
                                        min={roundTo(stocksRanges?.cost_price.min, 2)}
                                        max={roundTo(stocksRanges?.cost_price.max, 2)}
                                        value={purchasingPriceRange}
                                        onChange={(e, value) => {
                                            setPurchasingPriceRange(value ? [roundTo(value[0], 2), roundTo(value[1], 2)] : undefined);
                                        }}
                                        showInputs
                                    />
                                </div>
                                <div>
                                    <div className="font-semibold mb-2">
                                        {t("modals.filter.fields.inStock.label")}
                                    </div>
                                    <BaseRangeSlider
                                        min={roundTo(stocksRanges?.quantity.min, 2)}
                                        max={roundTo(stocksRanges?.quantity.max, 2)}
                                        value={inStockRange}
                                        onChange={(e, value) => {
                                            setInStockRange(value);
                                        }}
                                        showInputs
                                    />
                                </div>
                            </BaseInputsGrid>
                        </div>
                    }

                    handleSearch={searchInputDebounce}

                    handleFilter={(e) => console.log(e)}
                    handleExport={() => setExportModalOpenType("general")}

                    className="grow"
                />

                <Can I={"manage"} a={"stock_count"}>
                    <div
                        className="flex justify-between space-x-[16px] p-[24px] rounded-xl items-center bg-[#D9DAFD] w-[242px]">
                        <span className="text-accent font-[600]">{t("redirect")}</span>
                        <div>
                            <BaseMaterialIconButton
                                style={"light"}
                                icon={<CarretRightSVG />}
                                onClick={() => history.push("/dashboard/inventory/stock-count")}
                                className={"!bg-[#7556FA] border-0 hover:!bg-accent !text-[#fff]"}
                            />
                        </div>
                    </div>
                </Can>
            </div>

            <div className="levitation-extended mt-[32px]">
                <div className="mb-[24px]">
                    {
                        <div
                            className="bold-highlight capitalize">{stockResponse?.meta?.total} {declOfNum(stockResponse?.meta?.total ?? 0, [t("table.titleDeclinations.0"), t("table.titleDeclinations.1"), t("table.titleDeclinations.2")])}</div>
                    }
                </div>
                <BaseTable
                    data={stockResponse?.payload ?? []}
                    columns={[
                        {
                            header: <BaseMaterialCheckbox
                                checked={!isStockResponseFetching && selectedStocks.length === stockResponse?.payload.length}
                                onChange={(e, state) => {
                                    if (state) {
                                        setSelectedStocks(stockResponse?.payload?.map(stock => stock.id) ?? []);
                                    } else {
                                        setSelectedStocks([]);
                                    }
                                }
                                } />,
                            getter: (row, index, isCollapsed) => <BaseMaterialCheckbox
                                checked={selectedStocks.includes(row.id)}
                                onChange={(e, state) => {
                                    const resultingChecked = selectedStocks;

                                    const index = resultingChecked.indexOf(row.id);
                                    if (index > -1)
                                        resultingChecked.splice(index, 1);

                                    if (state)
                                        resultingChecked.push(row.id);

                                    console.log(resultingChecked);

                                    setSelectedStocks([...resultingChecked]);
                                }}
                                borderColor={isCollapsed ? "#3C3769" : undefined}
                            />,
                            preventCollapsePropagation: true
                        },
                        {
                            header: t("table.columns.0"),
                            getter: row => <>
                                <div className="bold-highlight">{row.name}</div>
                                <div className="text-sm text-accent">{row.code}</div>
                            </>,
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.1"),
                            getter: row => row.category?.name ?? "-",
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.2"),
                            getter: row => normalizePrice(row.cost_price + (row.extra_cost ?? 0)),
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.3"),
                            getter: row => row.in_stock,
                            comparator: () => 0
                        },
                        {
                            header: t("table.columns.4"),
                            getter: row => normalizePrice(row.in_stock * (row.cost_price + (row.extra_cost ?? 0))),
                            comparator: () => 0
                        },
                        {
                            header: <div className="text-center">{t("table.columns.5")}</div>,
                            getter: (row, index, isCollapsed) => <div className="text-center">
                                <BaseTooltip title={`${t("modals.export.exportButton")}`} placement="bottom">
                                    <ExportSVG
                                        className={`cursor-pointer ${isCollapsed ? "text-tables-highlightedIcon" : "text-tables-secondaryIcon"} hover:text-tables-highlightedIcon`}
                                        onClick={() => {
                                            setSelectedStocks([row.id]);
                                            setExportModalOpenType("single");
                                        }}
                                    />
                                </BaseTooltip>
                            </div>,
                            preventCollapsePropagation: true
                        }
                    ]}

                    collapse={{
                        fill: "#D9DAFD",
                        borderColor: "#B3B5EB",
                        content: mainRow => {
                            return <BaseTable
                                data={mainRow.product_locations}
                                columns={[
                                    {
                                        header: <div
                                            className="text-accent font-semibold">{t("subtable.columns.locationName")}</div>,
                                        getter: subRow => subRow.location_name,
                                        sx: {
                                            width: 400
                                        }
                                    },
                                    {
                                        header: <div
                                            className="text-accent font-semibold">{t("subtable.columns.sectionName")}</div>,
                                        getter: subRow => subRow.section_name ?? "",
                                    },
                                    {
                                        header: <div
                                            className="text-accent font-semibold">{t("subtable.columns.sectionSector")}</div>,
                                        getter: subRow => subRow.section_sector ?? "",
                                    },
                                    {
                                        header: <div
                                            className="text-accent font-semibold">{t("subtable.columns.sectionRow")}</div>,
                                        getter: subRow => subRow.section_row ?? "",
                                    },
                                    {
                                        header: <div
                                            className="text-accent font-semibold">{t("subtable.columns.sectionShelfHeight")}</div>,
                                        getter: subRow => subRow.section_shelf_height ?? "",
                                    },
                                    {
                                        header: <div
                                            className="text-accent font-semibold">{t("subtable.columns.inStock")}</div>,
                                        getter: subRow => subRow.in_stock ?? ""
                                    }
                                ]}

                                size="small"
                                nothingFound={{
                                    height: 200
                                }}
                                hideTableWhenNothingFound
                            />;
                        }
                    }}

                    alternate
                    isDataLoading={isStockResponseFetching}
                    manualControls={{
                        ordering: (newOrdering) => {
                            if (newOrdering) {
                                let name: Stock.DTO.OrderBy | undefined;

                                switch (newOrdering?.index) {
                                    case 1:
                                        name = "productName";
                                        break;
                                    case 2:
                                        name = "category";
                                        break;
                                    case 3:
                                        name = "purchasePrice";
                                        break;
                                    case 4:
                                        name = "inStock";
                                        break;
                                    default:
                                        name = undefined;
                                }

                                if (name)
                                    setOrderBy({
                                        name,
                                        type: newOrdering.order
                                    });
                            } else {
                                setOrderBy(undefined);
                            }
                        }
                    }}
                />
            </div>

            <Pagination
                className="mt-[32px]"
                {...pagination.adapt(stockResponse)}
            />

            {/* EXPORT MODALS */}
            <TableStocksExportModal
                isOpen={exportModalOpenType === "general"}
                onClose={() => setExportModalOpenType(undefined)}
                selectedStocks={selectedStocks}
                filters={filters}
                orderBy={orderBy}
            />

            <SingleStockExportModal
                isOpen={exportModalOpenType === "single"}
                onClose={() => {
                    setExportModalOpenType(undefined);
                    setSelectedStocks([]);
                }}
                stockRecord={selectedStocks[0]}
            />
        </>
    );
}

