import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { PaymentTerms } from "@/types/general";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@redux/hooks";

export const paymentTermsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentTerms: builder.query<PaymentTerms.Root[], void>({
            query: () => ({
                url: `payment-terms`
            }),
            providesTags: [{ type: "PaymentTerms", id: "LIST" }],
            transformResponse
        }),

        createPaymentTerms: builder.mutation<PaymentTerms.Root, PaymentTerms.DTO.CreateUpdate>({
            query: (body) => ({
                url: `payment-terms`,
                method: "POST",
                data: body
            }),

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdPaymentTerm } = await queryFulfilled;
                    const createResult = dispatch(paymentTermsApi.util.updateQueryData("getPaymentTerms", undefined, (draft) => {
                        draft.push({
                            id: createdPaymentTerm.id,
                            code: createdPaymentTerm.code,
                            name: createdPaymentTerm.name,
                            days: createdPaymentTerm.days,
                            type: createdPaymentTerm.type,
                        })
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        updatePaymentTerms: builder.mutation<PaymentTerms.Root, PaymentTerms.DTO.CreateUpdate & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `payment-terms/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPaymentTerm } = await queryFulfilled;
                    const updateResult = dispatch(paymentTermsApi.util.updateQueryData("getPaymentTerms", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === updatedPaymentTerm.id);
                        if (index !== -1) {
                            draft[index] = updatedPaymentTerm;
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        deletePaymentTerms: builder.mutation<void, number>({
            query: (id) => ({
                url: `payment-terms/${id}`,
                method: "DELETE"
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data: deletedPaymentTerm } = await queryFulfilled;
                    const deleteResult = dispatch(paymentTermsApi.util.updateQueryData("getPaymentTerms", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        })
    })
});

const selectPaymentTermsResult = paymentTermsApi.endpoints.getPaymentTerms.select();

const selectAllPaymentTerms = createSelector(
    selectPaymentTermsResult,
    (paymentTerms) => paymentTerms?.data ?? []
)

export const selectPaymentTermsById = createSelector(
    selectAllPaymentTerms,
    (state: RootState, pt_id: number | undefined) => pt_id,
    (paymentTerms, pt_id) => pt_id != undefined ? paymentTerms.find(pt => pt.id === pt_id) : undefined
);

export const {
    useGetPaymentTermsQuery,
    useCreatePaymentTermsMutation,
    useUpdatePaymentTermsMutation,
    useDeletePaymentTermsMutation
} = paymentTermsApi;