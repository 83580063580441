import { Permission } from "@/types/general";
import { z } from "zod";

/**
 * Type for identifying different sections of modules (we have different accordions for each section)
 */
export type ModulesSection = "contacts" | "products" | "purchases" | "sales" | "general" | "dashboard" | "integrations";

/**
 * Zod schema for permissions to generalize it`s structure
 */
const abilityScheme = z.object({
    key_name: z.string(),
    key: z.enum([
        "index",
        "create",
        "update",
        "delete",
        "show",
        "export",
        "import",
        "invite",
        "currency",
        "manage",
        "sales_activity",
        "best_selling_products",
        "revenue",
        "restocking",
        "feed",
        "produce",
        "production_history",
        "start_import",
        "update_selling_price",
        "run",
        "disable"
    ])
});

/**
 * Zod schema for modules, parameterized by allowed module keys
 * @param modules - available modules for the specified section
 */
const moduleScheme = (modules: [Permission.Components.Modules, ...Permission.Components.Modules[]]) => z.object({
    module_name: z.string(),
    module_key: z.enum(modules),
    abilities: z.array(abilityScheme),
    selected_abilities: z.array(abilityScheme)
});

/**
 * [MORE LIKE UI CONFIGURATION]
 * Definition of module divisions, categorized by sections.
 */
export const modulesDivision: Record<
    ModulesSection,
    [Permission.Components.Modules, ...Permission.Components.Modules[]]
> = {
    contacts: ["supplier", "customer", "customer_group"],
    products: ["product", "bom", "adjustment", "collection", "transfer", "location", "stock_count", "serial_number", "batch_number"],
    purchases: ["purchase_order", "receive"],
    sales: ["sale_order", "picking", "return"],
    general: [
        "company",
        "address",
        "employee",
        "default",
        "tax",
        "currency",
        "unit",
        "payment_term",
        "delivery_term",
        "language",
        "package",
        "category",
        "profile",
        "user",
        "role",
        "billing",
        "subscription",
        "export",
        "custom_fields"
    ],
    dashboard: [
        "dashboard",
    ],
    integrations: [
        "integration"
    ]
};

/**
 * Zod schema for the entire form.
 * Each module lies in the corresponding section.
 * We use the same schema for each section, therefore parametrized `moduleScheme` is used.
 */
export const formScheme = z.object({
    name: z.string().nonempty(),
    description: z.string().optional(),

    sections: z.object({
        // The "Contacts" section permissions
        contacts: z.array(moduleScheme(modulesDivision.contacts)),

        // The "Products" section permissions
        products: z.array(moduleScheme(modulesDivision.products)),

        // The "Purchases" section permissions
        purchases: z.array(moduleScheme(modulesDivision.purchases)),

        // The "Sales" section permissions
        sales: z.array(moduleScheme(modulesDivision.sales)),

        // The General section permissions
        general: z.array(moduleScheme(modulesDivision.general)),

        // The dashboard section permissions
        dashboard: z.array(moduleScheme(modulesDivision.dashboard)),

        // The integrations section permissions
        integrations: z.array(moduleScheme(modulesDivision.integrations))
    })
});

// Type alias for the entire form scheme
export type RoleFormTyping = z.infer<typeof formScheme>;

