import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import { Permission, Role } from "@/types/general";
import _ from "lodash";
import { formScheme, modulesDivision, ModulesSection, RoleFormTyping } from "../../definitions";
import ModulesContainer from "../ModulesContainer";

interface RoleMutationLayoutProps {
    role?: Role.Root;
    permissions: Permission.Root[];

    onMutation: (data: RoleFormTyping) => void;
}

export default function RoleMutationLayout({role, permissions, onMutation}: RoleMutationLayoutProps) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.companyInformation.tabs.roles.mutation"});

    const {control, handleSubmit, reset, setValue, getValues} = useForm<RoleFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            name: "",
            sections: {
                contacts: [],
                products: [],
                purchases: [],
                sales: [],
                dashboard: [],
                general: [],
                integrations: []
            }
        },
        shouldUnregister: false,
    });

    useEffect(() => {
        if (permissions) {
            // Mapping permissions array to add additional `selected_permissions` field
            const permissionsArray = _.keyBy(_.map(permissions, module => ({
                ...module,
                selected_abilities: [] as unknown as typeof module.abilities
            })), ({module_key}) => module_key);

            // If we have role set, then need to add the selected permissions into `selected_permissions` fields of corresponding perms
            if (role) {
                // Loop through the role's permissions to set 'selected_permissions' in the form values
                _.forEach(role.permissions, ({module_key, abilities}) => {
                    permissionsArray[module_key].selected_abilities = abilities;
                });
            }

            // To show ui the correct way we need to group the permissions by their sections
            const groupedModules = _.mapValues(modulesDivision, (includedModulesList) => {
                return _.filter(permissionsArray, ({module_key}) => {
                    return _.includes(includedModulesList, module_key);
                });
            })

            // Updating RHF
            setValue("name", role?.name ?? "");
            setValue("description", role?.description);

            // P.S. I would better set sections as a whole object, but there is a bug in RHF, when it set value somehow
            _.forEach(groupedModules, (modules, sectionKey) => {
                setValue(`sections.${sectionKey as keyof typeof groupedModules}`, modules)
            })
        } else {
            reset()
        }
    }, [role, permissions, modulesDivision]);

    const onSubmit = handleSubmit(onMutation);

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="flex flex-col space-y-[40px]">
                    {/*{*/}
                    {/*    (user?.role.id !== role?.id) &&*/}
                    {/*    <Alert severity="warning">*/}
                    {/*        {*/}
                    {/*            <Trans*/}
                    {/*                i18nKey="reloadWarning.others"*/}
                    {/*                t={t}*/}
                    {/*                components={{badge: <div className={"inline-flex items-center bg-white rounded-md border px-1.5 py-0.5 text-xs text-accent font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"} />}}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*    </Alert>*/}
                    {/*}*/}
                    <BaseInputsGrid cols={2}>
                        <Controller
                            name="name"
                            control={control}
                            render={({field, fieldState}) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.roleName.label") + " *"}
                                />
                            )}
                        />

                        <Controller
                            name="description"
                            control={control}
                            render={({field, fieldState}) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.roleDescription.label")}
                                />
                            )}
                        />
                    </BaseInputsGrid>

                    {
                        _.keys(getValues("sections")).map((sectionKey) => (
                            <ModulesContainer
                                key={sectionKey}
                                control={control}
                                sectionKey={sectionKey as ModulesSection}
                            />
                        ))
                    }

                    <div>
                        <BaseButton
                            buttonWidth="100%"
                            text={role ? `${t("buttons.save")}` : `${t("buttons.create")}`}
                            size="md"
                            type={"submit"}
                        />
                    </div>
                </div>
                {/*<DevTool control={control} />*/}
            </form>
        </>
    );
}
