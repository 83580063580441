import React, { useMemo } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// axios
// Custom route helpers
import PrivateRoute from "./components/route-helper/PrivateRoute";
import UnauthRoute from "./components/route-helper/UnauthRoute";
import ScrollToTop from "./components/route-helper/ScrollToTop";

// Redux
import { useSelector } from "react-redux";

// Stripe
import "@stripe/stripe-js";

// MUI
import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material";

// TODO: Import these styles from app.scss or index.scss
// custom styles
import "./assets/stylesheets/_typography.scss";
import "./assets/stylesheets/_utilities.scss";
import "./assets/stylesheets/_card.scss";

// Landing pages
// TODO: refactor below code to landing and auth pages
import NewsPage from "./components/BlogPage";
import HomePage from "./components/HomePage";
import LoginPage from "./components/LoginPage";
import PostPage from "./components/PostPage";
import PricingPage from "./components/PricingPage";
import PartnersPage from "./components/PartnersPage";
import PrivacyPageTerm from "./components/PrivacyTermPage";
import ProductPage from "./components/ProductPage";
// import SignUpPage from "./components/SignUpPage";
import ForgotPasswordPage from "./components/ForgotPassword/ForgotPasswordPage";
import NewPasswordPage from "./components/ForgotPassword/NewPasswordPage";
import SupportPage from "./components/SupportPage";
import AboutPage from "./components/AboutPage";
import PaymentPage from "./components/Payment/PaymentPage";
import EmailVerificationPage from "./components/VerifyEmail/EmailVerificationPage";

// Dashboard components
import Sidebar from "./components/Dashboard/Sidebar";
import DashboardNav from "./components/DashboardNav";

// Dashboard pages
import DashboardPage from "./components/Dashboard/DashboardPage";
import CalendarPage from "./components/Dashboard/pages/CalendarPage";

// Products
// --- All products
// --- Components
// Purchasing
// --- All purchases
import PurchasesPage from "./components/Dashboard/pages/PurchasingPage/PurchasesPage";

// --- Invoices
// Inventory
import LocationsPage from "./pages/admin/settings/locations";
import CreateEditLocationPage from "./pages/admin/settings/locations/create";
import QuickPurchaseOrderPage from "./components/Dashboard/pages/Inventory/QuickPurchaseOrderPage";

// Orders
//Customers page
import UserLogsPage from "./components/Dashboard/pages/UserLogsPage";
import SettingsSidebar from "./components/Dashboard/pages/SettingsPage";
import SuccessPage from "./components/Payment/SuccessPage";
import CancelPage from "./components/Payment/CancelPage";
import LicensePage from "./components/LicensePage";

import CompanyCreate from "./pages/main/company/create";

// users settings
import UsersSettingPage from "./components/Dashboard/pages/SettingsPage/General/UsersSettingPage";
import AddUser from "./components/Dashboard/pages/SettingsPage/General/UsersSettingPage/AddUser";

// payment terms
import PaymentTermsPage from "./components/Dashboard/pages/SettingsPage/General/PaymentTerms";

// delivery terms
import DeliveryTermsPage from "./components/Dashboard/pages/SettingsPage/General/DeliveryTerms";

// languages
import LanguagesPage from "./components/Dashboard/pages/SettingsPage/General/Languages";

// company page
import CompanyPage from "./components/Company/CompanyPage";

// error pages
import NotFoundPage from "./components/ErrorPages/NotFoundPage";

//shipment
import ShipmentPage from "./components/Dashboard/pages/ShipmentPage";
import SalesOrderLayoutPage from "./components/Dashboard/pages/SettingsPage/Layouts/SalesOrder";

// toast components
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import { GlobalStoreState } from "@redux/store";
import getThemePalette from "./mui.config";
import { themeConfig } from "./config/theme";
import DetailsPurchasePage from "./components/Dashboard/pages/PurchasingPage/PurchasesPage/DetailsPage";
import AdjustmentsMainPage from "./components/Dashboard/pages/Inventory/Adjustments/MainPage";
import AdjustmentsCreationPage from "./components/Dashboard/pages/Inventory/Adjustments/CreationPage";
import AdjustmentsViewPage from "./components/Dashboard/pages/Inventory/Adjustments/ViewPage";
import LocationsMainPage from "./components/Dashboard/pages/SettingsPage/General/Locations/MainPage";
import LocationsCreationPage from "./components/Dashboard/pages/SettingsPage/General/Locations/CreationPage";
import LocationsEditingPage from "./components/Dashboard/pages/SettingsPage/General/Locations/EditingPage";
import StockPage from "./components/Dashboard/pages/Inventory/Stock";
import ProductsPage from "./components/Dashboard/pages/Products/MainPage";
import ProductCreationPage from "./components/Dashboard/pages/Products/CreationPage";
import ProductEditingPage from "./components/Dashboard/pages/Products/EditingPage";
import ProductViewPage from "./components/Dashboard/pages/Products/ViewPage";
import TransfersPage from "./components/Dashboard/pages/Inventory/Transfers/MainPage";
import TransfersCreationPage from "./components/Dashboard/pages/Inventory/Transfers/CreationPage";
import TransfersDetailsPage from "./components/Dashboard/pages/Inventory/Transfers/ViewPage";

import CustomersPage from "./components/Dashboard/pages/Customers/Customer/MainPage";
import CustomerCreationPage from "./components/Dashboard/pages/Customers/Customer/CreationPage";
import CustomerEditingPage from "./components/Dashboard/pages/Customers/Customer/EditingPage";
import CustomerDetailsPage from "./components/Dashboard/pages/Customers/Customer/ViewPage";
import CustomerGroupsPage from "./components/Dashboard/pages/Customers/CustomerGroups/MainPage";
import CustomerGroupCreationPage from "./components/Dashboard/pages/Customers/CustomerGroups/CreationPage";
import CustomerGroupEditingPage from "./components/Dashboard/pages/Customers/CustomerGroups/EditingPage";
import CustomerGroupDetailsPage from "./components/Dashboard/pages/Customers/CustomerGroups/ViewPage";

import SuppliersPage from "./components/Dashboard/pages/Suppliers/MainPage";
import SupplierCreationPage from "./components/Dashboard/pages/Suppliers/CreationPage";
import SupplierEditingPage from "./components/Dashboard/pages/Suppliers/EditingPage";
import SupplierDetailsPage from "./components/Dashboard/pages/Suppliers/ViewPage";

import PurchaseCreationPage from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/CreationPage";

import SalesOrdersPage from "@components/Dashboard/pages/Sales/Orders";
import SaleOrderCreationPage from "@components/Dashboard/pages/Sales/Orders/CreationPage";
import SaleOrderEditingPage from "@components/Dashboard/pages/Sales/Orders/EditingPage";
import SaleOrderDetailsPage from "@components/Dashboard/pages/Sales/Orders/DetailsPage";

// import CompanyInformationRouter from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/CompanyInformationRouter";
// import CompanyInformationPage from "@components/Dashboard/pages/SettingsPage/General/Company";
import RestockingPage from "@components/Dashboard/DashboardPage/subpages/RestockingPage";
import ReceiveMainPage from "@components/Dashboard/pages/PurchasingPage/Receive/MainPage";
import ReceiveCreationPage from "@components/Dashboard/pages/PurchasingPage/Receive/CreationPage";
import ReceiveViewPage from "@components/Dashboard/pages/PurchasingPage/Receive/ViewPage";
import MyAccountRouter from "@components/Dashboard/pages/SettingsPage/General/MyAccount/MyAccountRouter";
import TaxRatesPage from "./components/Dashboard/pages/SettingsPage/General/TaxRatesPage";
import PackagingPage from "./components/Dashboard/pages/SettingsPage/General/Packaging";
import CategoriesPage from "./components/Dashboard/pages/SettingsPage/General/Categories";
import PickingMainPage from "@components/Dashboard/pages/Sales/Picking/MainPage";
import PickingCreationPage from "src/components/Dashboard/pages/Sales/Picking/CreationPage";
import PickingDetailsPage from "@components/Dashboard/pages/Sales/Picking/ViewPage";
import {
  ThirdPartyIntegrationsPage
} from "@components/Dashboard/pages/SettingsPage/Integrations/ThirdPartyIntegrations/MainPage";
import { useAbility } from "@casl/react";
import { AbilityContext, updateAbility } from "./casl.config";
import { useAppSelector } from "@redux/hooks";
import StockCountPage from "./components/Dashboard/pages/Inventory/StockCount";
import UnitsPage from "./components/Dashboard/pages/SettingsPage/General/Units";
import CompanyInformationPage
  from "./components/Dashboard/pages/SettingsPage/General/CompanyInfo/CompanyInformationRouter";
import CurrenciesPage from "./components/Dashboard/pages/SettingsPage/General/Currencies";
import PurchaseOrderEditingPage from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/EditingPage";
import CollectionsMainPage from "@components/Dashboard/pages/Inventory/Collections/MainPage";
import CollectionsCreationPage from "@components/Dashboard/pages/Inventory/Collections/CreationPage";
import CollectionsEditingPage from "@components/Dashboard/pages/Inventory/Collections/EditingPage";
import FeedPage from "@components/Dashboard/DashboardPage/subpages/FeedPage";
import BOMPage from "./components/Dashboard/pages/BOM/MainPage";
import BOMEditingPage from "./components/Dashboard/pages/BOM/EditingPage";
import BOMCreationPage from "./components/Dashboard/pages/BOM/CreationPage";
import BOMDetailsPage from "./components/Dashboard/pages/BOM/DetailsPage";
import ProducingHistoryPage from "./components/Dashboard/pages/BOM/ProducingHistoryPage";
import SignUpPage from "./components/SignUp";
import VerifyEmailPage from "./components/SignUp/modals/VerifyEmailPage";
import CustomFieldsPage from "@components/Dashboard/pages/SettingsPage/General/CustomFields";

const BASE_URL = "http://localhost:8000";

const App: React.FC = () => {
  // Workaround to load permissions from persisted auth slice after page reload
  const ability = useAbility(AbilityContext);
  const permissions = useAppSelector(
    (state) => state.auth.user?.role.permissions
  );

  if (!ability.rules.length && permissions) updateAbility(permissions);

  // ! Theme updating is done via Redux Toolkit, not the approach, suggested by MUI library
  const themeMode = useSelector((state: GlobalStoreState) => state.theme.theme);
  const theme = useMemo(
    () =>
      createTheme({
        ...themeConfig,
        ...getThemePalette(themeMode),
      } as ThemeOptions),
    [themeMode]
  );
  // const theme = useMemo(() => createTheme(themeConfig), [themeConfig]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/about" exact>
              <AboutPage />
            </Route>
            <Route path="/support" exact>
              <SupportPage />
            </Route>
            <Route path="/help" exact>
              <PrivacyPageTerm />
            </Route>
            <Route path="/price" exact>
              <PricingPage />
            </Route>
            <Route path="/partners" exact>
              <PartnersPage />
            </Route>
            <Route path="/product" exact>
              <ProductPage />
            </Route>
            <Route path="/news" exact>
              <NewsPage />
            </Route>
            <Route path="/news/:slug" exact>
              <PostPage />
            </Route>

            {/* UNAUTH ROUTES */}
            {/* Allow pages if not logged in */}
            <UnauthRoute path="/login" exact>
              <LoginPage />
            </UnauthRoute>

            <UnauthRoute path="/registration" exact>
              <SignUpPage />
            </UnauthRoute>

            <UnauthRoute path="/verify-email" exact>
              <VerifyEmailPage />
            </UnauthRoute>

            <UnauthRoute path="/forgot-password" exact>
              <ForgotPasswordPage />
            </UnauthRoute>

            <UnauthRoute path="/reset-password" exact>
              <NewPasswordPage />
            </UnauthRoute>

            {/* PRIVATE ROUTES */}
            {/* Allow pages if logged in */}
            <PrivateRoute path="/payment" exact>
              <PaymentPage />
            </PrivateRoute>
            <PrivateRoute path="/success" exact>
              <SuccessPage />
            </PrivateRoute>
            <PrivateRoute path="/cancel" exact>
              <CancelPage />
            </PrivateRoute>
            <PrivateRoute path="/licenses" exact>
              <LicensePage />
            </PrivateRoute>

            <PrivateRoute path="/company" exact>
              <CompanyPage />
            </PrivateRoute>

            <PrivateRoute path="/company/create" exact>
              <CompanyCreate />
            </PrivateRoute>

            <PrivateRoute path="/email-verify">
              <EmailVerificationPage />
            </PrivateRoute>

            {/* Dashboard routes */}
            <Route
              path="/dashboard"
              render={({ match: { url } }) => (
                <>
                  <main
                    className="main"
                    style={{
                      position: "relative",
                      padding: "0",
                    }}
                  >
                    <div className="ellipse-left"></div>
                    <div className="ellipse-right"></div>
                    <div className="background-blur">
                      <div className="flex flex-row">
                        <Sidebar minifyOnURLs={["/dashboard/settings"]} />
                        <PrivateRoute
                          path={`${url}/settings`}
                          component={SettingsSidebar}
                        />
                        {/*<div className={`w-[calc(100%-${window.location.pathname.includes("settings") ? 84 : 302}px)] pt-[56px] 2xl:px-[50px] sm:px-[10px]`}>*/}
                        <div
                          className={`w-[calc(100%-302px)] pt-[56px] 2xl:px-[50px] sm:px-[10px]`}
                        >
                          <DashboardNav />

                          {/* DASHBOARD */}
                          <PrivateRoute
                            path={`${url}/main`}
                            component={DashboardPage}
                            exact
                          />

                          <PrivateRoute
                              path={`${url}/main/feed`}
                              component={FeedPage}
                              exact
                          />

                          <PrivateRoute
                            path={`${url}/main/restocking`}
                            component={RestockingPage}
                            exact
                          />

                          {/* CALENDAR */}
                          <PrivateRoute
                            path={`${url}/calendar`}
                            component={CalendarPage}
                            exact
                          />

                          {/* PRODUCTS */}

                          {/* Products / All */}
                          <PrivateRoute
                            path={`${url}/products`}
                            component={ProductsPage}
                            exact
                          />
                          {/* Products / New */}
                          <PrivateRoute
                            path={`${url}/products/new`}
                            component={ProductCreationPage}
                            exact
                          />
                          <PrivateRoute
                            path={`${url}/products/:id/edit`}
                            component={ProductEditingPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/products/:id/details`}
                            component={ProductViewPage}
                            exact
                          />

                          {/* Bill Of Materials / All */}
                          <PrivateRoute
                              path={`${url}/bill-of-materials`}
                              component={BOMPage}
                              exact
                          />

                          {/* Bill Of Materials / New */}
                          <PrivateRoute
                              path={`${url}/bill-of-materials/new`}
                              component={BOMCreationPage}
                              exact
                          />

                          {/* Bill Of Materials / Edit */}
                          <PrivateRoute
                              path={`${url}/bill-of-materials/:id/edit`}
                              component={BOMEditingPage}
                              exact
                          />

                          <PrivateRoute
                              path={`${url}/bill-of-materials/:id/details`}
                              component={BOMDetailsPage}
                              exact
                          />

                          <PrivateRoute
                            path={`${url}/bill-of-materials/production-history`}
                            component={ProducingHistoryPage}
                            exact
                          />

                          {/* All Purchases */}
                          <PrivateRoute
                            path={`${url}/purchasing`}
                            component={PurchasesPage}
                            exact
                          />
                          {/* New Purchases */}
                          <PrivateRoute
                            path={`${url}/purchasing/new`}
                            component={PurchaseCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/purchasing/:id/details`}
                            component={DetailsPurchasePage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/purchasing/:id/edit`}
                            component={PurchaseOrderEditingPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/receive`}
                            component={ReceiveMainPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/receive/new`}
                            component={ReceiveCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/receive/:id/details`}
                            component={ReceiveViewPage}
                            exact
                          />

                          {/* INVENTORY/ADJUSTMENTS */}
                          <PrivateRoute
                            path={`${url}/inventory/adjustments`}
                            component={AdjustmentsMainPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/inventory/adjustments/new`}
                            component={AdjustmentsCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/inventory/adjustments/:id/details`}
                            component={AdjustmentsViewPage}
                            exact
                          />

                          {/* INVENTORY/COLLECTIONS */}

                          <PrivateRoute
                            path={`${url}/inventory/collections`}
                            component={CollectionsMainPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/inventory/collections/new`}
                            component={CollectionsCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/inventory/collections/:id/edit`}
                            component={CollectionsEditingPage}
                            exact
                          />

                          {/* INVENTORY/STOCK */}
                          <PrivateRoute
                            path={`${url}/inventory/stock`}
                            component={StockPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/inventory/stock-count`}
                            component={StockCountPage}
                            exact
                          />

                          {/* LOCATIONS */}
                          <PrivateRoute
                            path={`${url}/settings/locations`}
                            component={LocationsMainPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/settings/locations/new`}
                            component={LocationsCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/settings/locations/:id/edit`}
                            component={LocationsEditingPage}
                            exact
                          />

                          <PrivateRoute
                            path="/dashboard/settings/third-party"
                            component={ThirdPartyIntegrationsPage}
                            exact
                          />

                          <PrivateRoute
                            path="/dashboard/settings/units"
                            component={UnitsPage}
                            exact
                          />

                          <PrivateRoute
                            path="/dashboard/settings/currencies"
                            component={CurrenciesPage}
                            exact
                          />

                          {/* Create New Quick Purchase Order */}
                          <PrivateRoute
                            path={`${url}/inventory/quick-purchase-order`}
                            component={QuickPurchaseOrderPage}
                            exact
                          />

                          {/* Stock transfers pages */}
                          <PrivateRoute
                            path={`${url}/inventory/transfers`}
                            component={TransfersPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/inventory/transfers/new`}
                            component={TransfersCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/inventory/transfers/:id/details`}
                            component={TransfersDetailsPage}
                            exact
                          />

                          {/* SALE ORDERS */}
                          <PrivateRoute
                            path={`${url}/sales/orders`}
                            component={SalesOrdersPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/sales/orders/new`}
                            component={SaleOrderCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/sales/orders/:id/details`}
                            component={SaleOrderDetailsPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/sales/orders/:id/edit`}
                            component={SaleOrderEditingPage}
                            exact
                          />

                          {/*PICKING*/}
                          <PrivateRoute
                            path={`${url}/picking`}
                            component={PickingMainPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/picking/new`}
                            component={PickingCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/picking/:id/details`}
                            component={PickingDetailsPage}
                            exact
                          />

                          {/*Shipments*/}
                          <PrivateRoute
                            path={`${url}/shipments/all`}
                            component={ShipmentPage}
                            exact
                          />

                          {/* Customers */}
                          <PrivateRoute
                            path={`${url}/customers`}
                            component={CustomersPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/customers/new`}
                            component={CustomerCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/customers/:id/edit`}
                            component={CustomerEditingPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/customers/:id/details`}
                            component={CustomerDetailsPage}
                            exact
                          />

                          {/* Customers Groups */}
                          <PrivateRoute
                            path={`${url}/customer-groups`}
                            component={CustomerGroupsPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/customer-groups/new`}
                            component={CustomerGroupCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/customer-groups/:id/edit`}
                            component={CustomerGroupEditingPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/customer-groups/:id/details`}
                            component={CustomerGroupDetailsPage}
                            exact
                          />

                          {/* Suppliers */}
                          <PrivateRoute
                            path={`${url}/suppliers`}
                            component={SuppliersPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/suppliers/new`}
                            component={SupplierCreationPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/suppliers/:id/edit`}
                            component={SupplierEditingPage}
                            exact
                          />

                          <PrivateRoute
                            path={`${url}/suppliers/:id/details`}
                            component={SupplierDetailsPage}
                            exact
                          />

                          {/* USER-LOGS */}
                          <PrivateRoute
                            path={`${url}/user-logs`}
                            component={UserLogsPage}
                            exact
                          />
                          {/* //* Settings pages */}
                          <Route
                            path={`${url}/settings`}
                            render={({ match: { url } }) => (
                              <>
                                {/* GENERAL SETTINGS */}
                                <PrivateRoute
                                  path={`${url}/company`}
                                  component={CompanyInformationPage}
                                />

                                {/* INVENTORY/Location */}
                                <PrivateRoute
                                  path={`${url}/general/locations`}
                                  component={LocationsPage}
                                  exact
                                />

                                {/* Create New Location */}
                                <PrivateRoute
                                  path={`${url}/general/locations/new`}
                                  component={CreateEditLocationPage}
                                  exact
                                />
                                <PrivateRoute
                                  path={`${url}/general/locations/:id/edit`}
                                  component={CreateEditLocationPage}
                                  exact
                                />

                                {/* USERS */}
                                <PrivateRoute
                                  path={`${url}/general/users`}
                                  component={UsersSettingPage}
                                  exact
                                />
                                {/* Create New USERS */}
                                <PrivateRoute
                                  path={`${url}/general/users/add`}
                                  component={AddUser}
                                  exact
                                />

                                {/* PAYMENT TERMS */}
                                <PrivateRoute
                                  path={`${url}/general/payment-terms`}
                                  component={PaymentTermsPage}
                                  exact
                                />

                                {/* DELIVERY TERMS */}
                                <PrivateRoute
                                  path={`${url}/general/delivery-terms`}
                                  component={DeliveryTermsPage}
                                  exact
                                />

                                {/* DELIVERY TERMS */}
                                <PrivateRoute
                                  path={`${url}/general/tax`}
                                  component={TaxRatesPage}
                                  exact
                                />

                                {/* PACKAGING */}
                                <PrivateRoute
                                  path={`${url}/general/packaging`}
                                  component={PackagingPage}
                                  exact
                                />

                                {/* CATEGORIES */}
                                <PrivateRoute
                                  path={`${url}/general/categories`}
                                  component={CategoriesPage}
                                  exact
                                />

                                {/* LANGUAGES */}
                                <PrivateRoute
                                  path={`${url}/general/languages`}
                                  component={LanguagesPage}
                                  exact
                                />

                                {/* CUSTOM FIELDS */}
                                <PrivateRoute
                                    path={`${url}/general/custom-fields`}
                                    component={CustomFieldsPage}
                                    exact
                                />

                                {/* SALES ORDER LAYOUT  PAGE */}
                                <PrivateRoute
                                  path={`${url}/layouts/sales-order`}
                                  component={SalesOrderLayoutPage}
                                  exact
                                />
                                <PrivateRoute
                                  path={`${url}/general/account`}
                                  component={MyAccountRouter}
                                />
                              </>
                            )}
                          />

                          <div style={{ height: "40px" }}></div>
                        </div>
                      </div>
                    </div>
                  </main>
                </>
              )}
            />

            {/* 404 catch route */}
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
      <ToastContainer position="top-center" />
    </ThemeProvider>
  );
};

export default App;
