import React from "react";
import DashboardCard from "../../DashboardCard";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import BaseTable from "@reusables/BaseTable";
import TableButton from "@reusables/BaseTable/TableButton";
import { useTranslation } from "react-i18next";
import { useGetRestockingQuery } from "@redux/features/purchases/purchasesApi";
import { useRestockingRedirect } from "@components/Dashboard/DashboardPage/logic";
import { useAppDispatch } from "@redux/hooks";
import FeedTable from "@components/Dashboard/DashboardPage/subpages/FeedPage/FeedTable";
import RevenueWidget from "@components/Dashboard/DashboardPage/subpages/MainPage/widgets/Revenue";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@components/ErrorPages/NoPermissionBanner";
import { Permission } from "@/types/general";
import { AbilityContext } from "@/casl.config";
import BestSellingProductsWidget from "@components/Dashboard/DashboardPage/subpages/MainPage/widgets/BestSelling";


export default function MainPage() {
    const { t } = useTranslation("", { keyPrefix: "dashboard.dashboard.stats" });
    const dispatch = useAppDispatch();

    const { data: restockingData = [], isLoading: restockingIsLoading } = useGetRestockingQuery();

    const handleRestockingRedirect = useRestockingRedirect(dispatch);

    return (
        <div className="grid grid-cols-1 2xl:grid-cols-2 xl:gap-[24px] sm:gap-[10px]">
            <DashboardCardAbilityChecker ability={"revenue"}>
                <RevenueWidget />
            </DashboardCardAbilityChecker>

            <DashboardCardAbilityChecker ability={"best_selling_products"}>
                <BestSellingProductsWidget />
            </DashboardCardAbilityChecker>

            <DashboardCardAbilityChecker ability={"feed"}>
                <DashboardCard
                    title={t("feed.heading")}
                    headerActions={
                        <Link to={"/dashboard/main/feed"}>
                            <IconButton>
                                <ArrowForwardOutlined
                                    sx={{
                                        color: "#6959FC"
                                    }}
                                />
                            </IconButton>
                        </Link>
                    }
                >
                    <FeedTable variant={"compact"} />
                </DashboardCard>
            </DashboardCardAbilityChecker>

            <DashboardCardAbilityChecker ability={"restocking"}>
                <DashboardCard
                    title={t("restocking.heading")}
                    headerActions={
                        <Link to={"/dashboard/main/restocking"}>
                            <IconButton>
                                <ArrowForwardOutlined
                                    sx={{
                                        color: "#6959FC"
                                    }}
                                />
                            </IconButton>
                        </Link>
                    }
                >
                    <BaseTable
                        data={restockingData}
                        isDataLoading={restockingIsLoading}
                        nothingFound={{
                            text: t("restocking.notFound").toUpperCase()
                        }}
                        hideTableWhenNothingFound
                        columns={[
                            {
                                header: t("restocking.columns.0"),
                                getter: (row) => row.product.name
                            },
                            {
                                header: t("restocking.columns.1"),
                                getter: (row) => `${row.in_stock}/${row.min_inventory_quantity}`
                            },
                            {
                                header: "",
                                getter: (row) => (
                                    <div className="text-right">
                                        <TableButton
                                            onClick={() =>
                                                handleRestockingRedirect(row.product.id, row.in_stock)
                                            }
                                        >
                                            {t("restocking.orderButton")}
                                        </TableButton>
                                    </div>
                                ),
                                cellClasses: "w-full !p-[14px]"
                            }
                        ]}
                        stickyHeader
                        alternate
                    />
                </DashboardCard>
            </DashboardCardAbilityChecker>
        </div>
    );
}

function DashboardCardAbilityChecker(props: { children: JSX.Element, ability: Permission.Components.Ability }) {
    const ability = useAbility(AbilityContext);

    if (!ability.can(props.ability, "dashboard")) {
        return <div className={"levitation-extended"}>
            <NoPermissionBanner />
        </div>;
    }

    return props.children;
}

