import { useTranslation } from "react-i18next";
import { z } from "zod";
import React from "react";
import { useGetCollectionsQuery } from "@/redux/features/collections/collectionsApi";
import BaseModal from "@/components/reusables/Modals/BaseModal";
import BaseInputsGrid from "@/components/reusables/BaseInputsGrid";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseInput from "@/components/reusables/BaseInput";
import BaseButton from "@/components/reusables/BaseButton";

const formScheme = z.object({
    collection: z.object({
        id: z.number(),
        code: z.string(),
        name: z.string(),
        barcode: z.string().nullish(),
        totalQuantity: z.number(),
        products: z.array(
            z.object({
                id: z.number(),
                name: z.string(),
                code: z.string(),
                in_stock: z.number().nullish(),
                prices: z.object({
                    selling_price: z.number()
                }),
                min_purchase_quantity: z.number(),
                min_sale_quantity: z.number(),

                tax: z.object({
                    id: z.number(),
                    rate: z.number()
                }).nullish(),

                has_bom: z.boolean(),

                quantity: z.number()
            })
        )
    }),
    quantity: z.coerce.number().min(0)
});

type FormTyping = z.infer<typeof formScheme>;

export interface AddCollectionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onOrderAddition: (chosenCollection: FormTyping) => void;
}

export default function AddCollectionModal({
                                               isOpen,
                                               onOrderAddition,
                                               onClose
                                           }: AddCollectionModalProps): JSX.Element {
    const { t } = useTranslation("", {
        keyPrefix: "sales.orders.general.orders.modals.addCollection"
    });

    const { data: collectionOptions, isLoading: collectionOptionsLoading } =
        useGetCollectionsQuery();

    const { control, ...form } = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    });

    const onSubmit = form.handleSubmit((data) => {
        onOrderAddition(data);
        form.reset();
        onClose();
    }, console.error);

    return (
        <>
            <BaseModal
                isOpen={isOpen}
                onClose={onClose}
                isLoading={collectionOptionsLoading}
                width={612}
                padding="56px"
            >
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                    <div className="modal-title">{t("heading")}</div>
                    <BaseInputsGrid cols={1}>
                        <Controller
                            name="collection"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseDropdown
                                    getter={{
                                        label: (opt) => opt.name,
                                        key: (opt) => opt.id
                                    }}
                                    {...field}
                                    {...fieldState}
                                    label={t("fields.collection.label") + " *"}
                                    isLoading={collectionOptionsLoading}
                                    placeholder={t("fields.collection.placeholder")}
                                    options={collectionOptions}
                                />
                            )}
                        />

                        <Controller
                            name="quantity"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.quantity.label")}
                                    placeholder={t("fields.quantity.placeholder")}
                                />
                            )}
                        />
                    </BaseInputsGrid>
                    <BaseButton text={t("buttons.add")} size="md" className="w-full" />
                </form>
            </BaseModal>
        </>
    );
}
