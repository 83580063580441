import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, CompanyPayload, TokensPayload, UserPayload } from "@redux/features/auth/types";
import { Integration } from "@/types/general";


export const authSlice = createSlice({
    name: "auth",
    initialState: {} as AuthState,
    reducers: {
        authenticate: (state, action: PayloadAction<TokensPayload>) => {
            const tokens = action.payload;
            state.tokens = tokens;

            state.isAuthenticated = true;

            localStorage.setItem("sanctum", tokens.access);
        },

        logout(state) {
            state.tokens = undefined;
            state.user = undefined;
            state.isAuthenticated = false;

            localStorage.setItem("sanctum", "");
        },

        setUser(state, action: PayloadAction<UserPayload>) {
            state.user = action.payload;
        },

        setRole(state, action: PayloadAction<UserPayload["role"]>) {
            if (state.user) {
                state.user.role = action.payload;
            } else {
                console.warn("Trying to set permissions for an unauthenticated user!");
            }
        },

        setAccessToken(state, action: PayloadAction<string>) {
            if (state.tokens) {
                state.tokens.access = action.payload;
            } else {
                console.warn("Trying to set an access token for an unauthenticated user!");
            }
        },

        setCompany(state, action: PayloadAction<CompanyPayload>) {
            if (state.user) {
                state.user.company = action.payload;
            } else {
                console.warn("Trying to set a company for an unauthenticated user!");
            }
        },

        setIntegrations(state, action: PayloadAction<CompanyPayload["integrations"]>) {
            if (state.user) {
                state.user.company.integrations = action.payload;
            } else {
                console.warn("Trying to set integrations for an unauthenticated user!");
            }
        },

        setIntegrationStatus(state, action: PayloadAction<{
            slug: Integration.Components.Slug;
            integrated: boolean;
        }>) {
            const integration = state.user?.company.integrations?.[action.payload.slug];
            if (state.user && integration) {
                state.user!.company.integrations![action.payload.slug] = {
                    ...integration,
                    integrated: action.payload.integrated
                };
            } else {
                console.warn("Trying to set an integration for an unauthenticated user!");
            }
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    authenticate,
    logout,
    setRole,
    setCompany,
    setUser,
    setAccessToken,
    setIntegrations,
    setIntegrationStatus
} = authSlice.actions;

export default authSlice.reducer;