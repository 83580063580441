import React, {memo, useEffect} from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import {CreationModalProperties} from "../../../../types";
import {useTranslation} from "react-i18next";
import BaseInput from "@reusables/BaseInput";
import {Location} from "@/types/general";
import {ReactComponent as QuestionSVG} from "@assets/icons/ic_question.svg";
import BaseCheckbox from "@reusables/BaseCheckbox";
import BaseTooltip from "@reusables/BaseTooltip";
import Fade from "@mui/material/Fade/Fade";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {Controller, useForm, useWatch} from "react-hook-form";
import {useCreateLocationMutation} from "@redux/features/locations/locationsApi";
import {toast} from "react-toastify";
import {stopPropagate} from "@helpers/utils";

const locationScheme = z.object({
    name: z.string(),
    is_sections_toggled: z.boolean().optional(),
    section: z.object({
        name: z.string().optional()
    })
}).refine(data => {
        return data.is_sections_toggled ? data.section.name?.trim() !== "" : true;
    },
    {
        params: {
            i18n: "general.validation.global.required"
        },
        path: ["section", "name"]
    }
);

type LocationTyping = z.infer<typeof locationScheme>
export default memo(function LocationCreationModal(props: CreationModalProperties<Location.Extended>) {
    const {t} = useTranslation("", {keyPrefix: "products.createEditProduct.modals.location.creationModal"});

    const {control, handleSubmit, reset} = useForm<LocationTyping>({
        resolver: zodResolver(locationScheme)
    });

    const isSectionsToggledWatch = useWatch({
        control,
        name: "is_sections_toggled"
    });

    const [createLocation, {isLoading: creationLoading}] = useCreateLocationMutation();

    const onSubmit = handleSubmit((data) => {
        createLocation({
            name: data.name,
            ...(data.is_sections_toggled && {
                sections: [
                    {
                        name: data.section.name as string
                    }
                ]
            })
        })
            .unwrap()
            .then(location => {
                props.onCreation(location);
            })
            .catch(err => {
                console.error(err);
                toast.error("responses.error");
            })
            .finally(() => {
                props.onClose?.();
            })
    })

    useEffect(() => {
        if (!props.isOpen) reset();
    }, [props.isOpen]);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={creationLoading}
            useCloseIcon
        >
            <form className="space-y-[32px]" onSubmit={stopPropagate(onSubmit)}>
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>

                <Controller
                    name="name"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            label={t("locationField.label")}
                            error={fieldState.error}
                        />
                    )}
                />

                <div className="flex items-center">
                    <Controller
                        name="is_sections_toggled"
                        control={control}
                        render={({field}) => (
                            <BaseCheckbox
                                {...field}
                                label={<span className="ml-[16px]">{t("sectionsCheckbox.title")}</span>}
                            />
                        )}
                    />
                    <BaseTooltip title={`${t("sectionsCheckbox.tip")}`} placement="top">
                        <QuestionSVG className="text-specialIcons-question"/>
                    </BaseTooltip>
                </div>

                <Controller
                    name="section.name"
                    control={control}
                    render={({field, fieldState}) => (
                        <Fade in={isSectionsToggledWatch}>
                            <div className={isSectionsToggledWatch ? "block" : "hidden"}>
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("sectionField.label") + " *"}
                                />
                            </div>
                        </Fade>
                    )}
                />

                <div>
                    <BaseButton text={t("createButton")} className="w-full" size="md"/>
                </div>
            </form>
        </BaseModal>
    );
})