import React from "react";
import { useTranslation } from "react-i18next";
import MutationLayout from "../components/MutationLayout";
import { CustomerGroups } from "@/types/general";
import { useCreateCustomerGroupMutation } from "@redux/features/customerGroups/customerGroupsApi";
import { useHistory } from "react-router-dom";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import dayjs from "dayjs";

export default function CustomerGroupCreationPage() {
    const history = useHistory();
    const {t} = useTranslation("", {keyPrefix: "customerGroups.creation"});

    const [createCustomerGroup, {isLoading: isCreationLoading}] = useCreateCustomerGroupMutation();

    return (
        <BaseLoadingBlocker active={isCreationLoading}>
            <div className='levitation-extended space-y-[40px]'>
                <div className='text-2xl text-accent font-bold'>{t("heading")}</div>
                <MutationLayout 
                    customerGroup={undefined}
                    onSubmit={data => {
                        const creationData: CustomerGroups.DTO.Create = {
                            code: data.code,
                            name: data.name,
                            discount: data.discount,
                            customers: data.customers?.map(customer => customer.value.id),
                            products: data.products?.map(product => {
                                return {
                                    id: product.product.id,
                                    sale_price: product.sale_price,
                                    valid_from: product.valid_from?.format("YYYY-MM-DD") || dayjs().format("YYYY-MM-DD"),
                                    valid_to: product.valid_to?.format("YYYY-MM-DD")
                                }
                            })
                        };

                        createCustomerGroup(creationData).unwrap().then(resp => {
                            history.push(`/dashboard/customer-groups/${resp.id}/details`);

                            toast.success(t("responses.success"));
                        }).catch(e => {
                            if(isErrorWithMessage(e)) {
                                toast.error(e.message)
                            } else {
                                toast.error(t("responses.error"))
                            }
                        });
                    }}
                />
            </div>
        </BaseLoadingBlocker>
    );
}
