import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCreateRoleMutation } from "@redux/features/roles/rolesApi";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useGetPermissionsQuery } from "@redux/features/permissions/permissionsApi";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { Role } from "@/types/general";
import _ from "lodash";
import { toast } from "react-toastify";
import RoleMutationLayout from "../components/MutationLayout";

export default function RolesCreationPage(){
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.creation" });

    const [createRole, { isLoading: isCreationLoading }] = useCreateRoleMutation();
    const {data: permissions = [], isLoading: isPermissionsLoading} = useGetPermissionsQuery();


    return (
        <BaseLoadingBlocker active={isCreationLoading}>
            <BaseRecordRenderer
                loading={isPermissionsLoading}
                record={permissions}
                hideLevitation
            >
                <div className="space-y-[40px]">
                    <div className="flex items-center">
                        <h6 className="text-accent grow">{t("heading")}</h6>
                    </div>
                    <RoleMutationLayout
                        permissions={permissions}
                        onMutation={data => {
                            const requestData: Role.DTO.Create = {
                                name: data.name,
                                description: data.description,
                                permissions: _.flatten(_.values(data.sections)).map(({
                                    module_key,
                                    module_name,
                                    selected_abilities
                                }) => ({
                                    module_key,
                                    module_name,
                                    abilities: selected_abilities
                                }))
                            };

                            createRole(requestData).unwrap().then(() => {
                                history.push("/dashboard/settings/company/roles");
                                toast.success(t("responses.success"));
                            }).catch(e => {
                                console.error(e);
                                toast.error(t("responses.error"));
                            })
                        }}
                    />
                </div>
            </BaseRecordRenderer>
        </BaseLoadingBlocker>
    )
}