import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Control, useController, useWatch } from "react-hook-form";
import { CustomerGroupsFormTyping } from "../../types";

import { Fade, Stack } from "@mui/material";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import { useGetCustomersQuery } from "@redux/features/customers/customersApi";
import _ from "lodash";


type CustomerSectionProps = {
    control: Control<CustomerGroupsFormTyping>;
    index: number;
    onRemove: () => void;
} 

export default function CustomerDropdown({control, index, onRemove}: CustomerSectionProps) {
    const {t} = useTranslation("", {keyPrefix: "customerGroups.mutation.dropdowns.customer"});

    const {data: customersOptions = [], isLoading: isCustomersOptionsLoading} = useGetCustomersQuery();

    const {field, fieldState} = useController({
        name: `customers.${index}.value`,
        control,
    });

    const customersList = useWatch({
        control,
        name: "customers",
    })

    const filteredCustomersList = useMemo(() => {
        const customersListKeyedBy = _.keyBy(customersList, "value.id");

        return customersOptions.filter((customer) => field.value?.id === customer.id || !(customer.id in customersListKeyedBy));
    }, [customersList])

    return (
        <>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <div className='relative w-full'>
                    <BaseDropdown
                        {...field}
                        {...fieldState}
                        label={t("label") + " *"}
                        placeholder={t("placeholder")}

                        options={filteredCustomersList}
                        getter={{
                            label: (item) => item.name,
                            key: (item) => item.id,
                        }}
                        isLoading={isCustomersOptionsLoading}
                        autocomplete

                        hideArrow={!!field.value}
                    />
                    <Fade in={!!field.value}>
                        <div className='absolute top-[48px] right-3'>
                            <TrashSVG
                                className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                onClick={onRemove}
                            />
                        </div>
                    </Fade>
                </div>
            </Stack>
        </>
    )
}
