import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {ReceiptsMutationFormTyping} from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout";
import BaseMaterialCheckbox from "@reusables/BaseMaterialCheckbox";

export default function FullCheckbox() {
    const {control} = useFormContext<ReceiptsMutationFormTyping>()

    const receipts = useWatch({
        control,
        name: "receipts"
    })

    return (
        <BaseMaterialCheckbox
            checked={receipts.every(receipt => receipt.received_quantity && receipt.location)}
        />
    )
}